import React, { useState, useEffect } from "react";
import {
    ActivityIndicator,
    Button,
    View,
} from "react-native";
import { MyContext } from '../context/mycontext';
import * as ImagePicker from "expo-image-picker";

export const BrowserImg = (props: any) => {
    // return Return Base64 image data
    // pass data via useContext
    let mContext = React.useContext(MyContext);

    const [img, setImg] = useState('');
    const [uploading, setUploading] = useState(false);

    const changeArr = (_img) => {
        const index = props.index;
        let arr_tg = [];
        mContext.arr_img.forEach(ele => {
            let item = ele;
            if (item.index == index) {
                item.value = _img;
            }
            arr_tg.push(item);
        });

        mContext.setArrImg(arr_tg);
    }

    useEffect(() => {
        return () => {
        };
    }, []);


    const _maybeRenderUploadingIndicator = () => {
        if (uploading) {
            return <ActivityIndicator animating size="large" />;
        }
    };

    const _maybeRenderControls = () => {
        if (!uploading) {
            return (
                <Button
                    onPress={() => _pickImage()}
                    title={props.title != undefined ? props.title : 'Chọn ảnh'}
                />
            );
        }
    };

    const _askPermission = async (failureMessage) => {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();

        if (status === "denied") {
            alert(failureMessage);
        }
    };

    const _pickImage = async () => {
        await _askPermission(
            "We need the camera-roll permission to read pictures from your phone..."
        );

        let pickerResult = await ImagePicker.launchImageLibraryAsync({
            allowsEditing: true,
            // aspect: [4, 3],
            base64: true,
        });

        if (!pickerResult.cancelled) {
            const img_base64 = `data:image/jpeg;base64,${pickerResult.base64}`;

            setImg(img_base64);
            changeArr(img_base64);
        }
    };

    return (
        <View>
            {_maybeRenderControls()}
            {_maybeRenderUploadingIndicator()}
        </View>
    );
}