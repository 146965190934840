import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, ScrollView, Platform, Pressable } from 'react-native';
import { CheckBox, Dialog, Icon, Image, Overlay } from 'react-native-elements';
import { ButtonAdd, ButtonCancel, ButtonDelete, ButtonEdit, ButtonSave, ButtonSearch, CloseButtons, OpenButtons } from '../../components/mybuttons'

import moment from "moment";

import { PostModel, PushnotificationModel } from "../../models/models"

import { Header } from '../../components/header';

import { stylesButtons, stylesCategory, stylesColumns } from "../../config/mystyles";
import { getsMyItemChecked } from "../../config/myfunc";
import { MyPaging, MySelectCalendarSearch, MyListingPost, MyDisplayFromToDate } from "../../components/myboxs";
import { IsLoadingData } from "../../components/myother";

import { MyContext } from '../../context/mycontext';
import { arr_pattern, MyControlInputText, MyControlSparateCalendar } from "../../components/mycontrolinputs";
import { config, LANG, ROLE_VIEW_NEWS, windowHeight, windowWidth } from "../../config/config";

import { useForm } from "react-hook-form";
import { MyInputSearchName } from "../../components/myinputs";
import { BrowserImg } from "../../components/myBrowserImg";
import { mycolors } from "../../config/mycolors";
import { isEmpty } from "lodash";

function ListNews({ navigation }) {
    const [isLoading, setLoading] = useState<boolean>(false);

    const [taskItems, setTaskItems] = useState([]);

    const [pagingParams, setPagingParams] = useState({});
    const [currentPage, setCurrentPage] = React.useState(1);
    const [listCategory, setListCategory] = useState([]);

    const [searchName, setSearchName] = React.useState('');
    const [visibleSearchCalendar, setVisibleSearchCalendar] = React.useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const [openList, setOpenList] = React.useState(true);
    const [openForm, setOpenForm] = React.useState(false);
    const [openButton, setOpenButton] = React.useState(true);

    const [visible, setVisible] = React.useState(true);
    const [visibleDialog, setVisibleDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');

    const [permission, setPermission] = React.useState(false);

    const [arr_img, setArrImg] = React.useState([{ index: 1, value: '' }]);

    const [id, setID] = useState('');

    const dialogTitle = LANG.News;

    function checkItem(item: any) {
        let arr: any = [];
        taskItems.forEach(el => {
            let el_tg: any = el;
            if (el_tg.id == item.id) {
                if (!el_tg['checked'])
                    el_tg['checked'] = true;
                else
                    el_tg['checked'] = false;
            }
            arr.push(el_tg);
        });

        setTaskItems(arr);

        setOpenButton(true);
        setVisible(true);
    };

    function deleteItems() {
        const arr = getsMyItemChecked(taskItems);

        if (arr.length == 0) {
            setVisibleDialog(true);
            setDialogMessage(LANG.ChooseAtLeastOneToDelete);
            return;
        }

        PostModel.delete_arr({ 'ids': arr })
            .then((results) => {
                const data: any = results.data || {};
                if (data['posts'] != undefined) {
                    setTaskItems(data['posts'] || []);
                    setPagingParams(data['paging'])
                }

                setVisibleDialog(true);
                setDialogMessage(LANG.DeleteSuccessfully);
            })
            .catch(err => {
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            });
    };

    function editItem() {
        let arr: any = getsMyItemChecked(taskItems);
        const lastItem = arr.pop();

        if (lastItem == undefined) {
            setVisibleDialog(true);
            setDialogMessage(LANG.ChooseAtLeastOne);
        } else {
            PostModel.get(lastItem)
                .then((results) => {
                    const post: any = results.data.post || {};

                    if (__DEV__) {
                        console.log('data get:', post);
                    }

                    setID(post.id);
                    fields.forEach(field => {
                        switch (field.Type) {
                            case 'category':
                                setValue(`${field.name}`, post[`${field.name}`]);
                                listCategory.forEach(item => {
                                    if (item.id == post[`${field.name}`]) {
                                        setCurrentCategory(item);
                                    }
                                })
                                break;
                            case 'gallery':
                                let arr_img = post[`${field.name}`];
                                console.log('arr_img: ', arr_img);
                                if (arr_img != undefined) {
                                    let a_img = arr_img.map(item => {
                                        item['value'] = `${config.apiUrl}/${item['src']}?rdn=${new Date().getTime()}`
                                        return item;
                                    });
                                    setArrImg(a_img);
                                }
                                break;
                            case 'calendar':
                            default:
                                setValue(`${field.name}`, post[`${field.name}`]);
                                break;
                        }
                    });

                    setOpenForm(true);
                    setOpenList(false);
                    setOpenButton(false);
                })
                .catch(err => {
                    setVisibleDialog(true);
                    setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
                });
        }
    };

    function Paging(page = 1) {
        setLoading(true);
        PostModel.gets(page)
            .then((results) => {
                const data: any = results["data"] || [];
                setTaskItems(data["posts"] || []);
                setPagingParams(data['paging'])
            })
            .catch(err => {
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false));
    };

    function Search(search_name = '', from_date = '') {
        setLoading(true);
        PostModel.gets(1, search_name, from_date)
            .then((results) => {
                const data: any = results["data"] || [];
                setTaskItems(data["posts"] || []);
                setPagingParams(data['paging'])
                setCurrentPage(1);
            })
            .catch(err => {
                console.log(err);
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false));
    };

    async function gets_init() {
        let p_posts = PostModel.gets(1);

        setLoading(true);
        Promise.all([p_posts]).then((values) => {
            const data_posts = values[0]["data"] || [];

            setPermission(data_posts["permission"] || false);

            setListCategory(ROLE_VIEW_NEWS);

            setTaskItems(data_posts["posts"] || []);
            setPagingParams(data_posts['paging'])
        })
            .catch(err => {
                console.log(`${err.message}`);

                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false));
    }

    /***
     * begin: react hook form
    */
    const { control, handleSubmit, reset, formState: { errors }, getValues, setValue, setFocus } = useForm();

    const onSubmit = data => {
        const data_submit = data;

        if (__DEV__) {
            console.log('data_submit: ', data_submit);
        }

        if (id != '') {
            PostModel.update(id, data_submit)
                .then((results) => {
                    const data: any = results.data || {};
                    if (__DEV__) {
                        console.log('result update: ', data);
                    }
                    if (data.post != undefined && data.post.id != undefined) {
                        const obj_task: any = {
                            'id': data.post.id,
                            'title': data.post.title,
                            'date_publish': data.post.date_publish,
                            'date_created': data.post.date_created,
                            'checked': false
                        };
                        let arr: any = [];
                        taskItems.forEach(el => {
                            let el_tg: any = el;
                            if (el_tg['id'] == obj_task.id) {
                                arr.push(obj_task);
                            } else {
                                arr.push(el);
                            }
                        });
                        setTaskItems(arr);
                        setDialogMessage(LANG.UpdateSuccessfully);
                    } else {
                        setVisibleDialog(true);
                        setDialogMessage(LANG.CanNotUpdate);
                    }

                    setArrImg([]);

                    setOpenForm(false);
                    setOpenList(true);
                    setOpenButton(true);
                    setVisibleDialog(true);

                    reset();
                })
                .catch(err => {
                    setVisibleDialog(true);
                    setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
                })
                .finally(() => {
                    setID('');
                    setLoading(false);
                });
        } else {
            PostModel.create(data_submit)
                .then((results) => {
                    const data: any = results.data || {};
                    if (__DEV__) {
                        console.log('result create: ', data);
                    }
                    if (data['posts'] != undefined) {
                        setTaskItems(data['posts'] || []);
                        setPagingParams(data['paging'])

                        setDialogMessage(LANG.AddNewSuccessfully);

                        PushnotificationModel.run_schedule();
                    } else {
                        setVisibleDialog(true);
                        setDialogMessage(LANG.CanNotAddNew);
                    }

                    setArrImg([]);

                    setOpenForm(false);
                    setOpenList(true);
                    setOpenButton(true);
                    setVisibleDialog(true);

                    reset();
                })
                .catch(err => {
                    setVisibleDialog(true);
                    setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
                })
                .finally(() => {
                    setID('');
                    setLoading(false);
                });
        }
    };

    const myHandleSubmit = () => {
        setValue('photo', arr_img);

        let other_validate = true;
        fields.forEach((field) => {
            switch (field.Type) {
                case "calendar":
                    if (field.name == 'date_publish') {
                        let d = getValues(field.child_date['date']);
                        if (parseInt(d) < 10) {
                            d = `0${parseInt(d)}`;
                        }
                        let m = getValues(field.child_date['month']);
                        if (parseInt(m) < 10) {
                            m = `0${parseInt(m)}`;
                        }

                        let hour = getValues(field.child_date['hour']);
                        if (parseInt(hour) < 10) {
                            hour = `0${parseInt(hour)}`;
                        }
                        let minute = getValues(field.child_date['minute']);
                        if (parseInt(minute) < 10) {
                            minute = `0${parseInt(minute)}`;
                        }

                        const y = getValues(field.child_date['year']);
                        const date_publish = `${y}/${m}/${d} ${hour}:${minute}:00`;
                        setValue(field.name, date_publish);

                        const validate = moment(`${m}/${d}/${y}`, 'MM/DD/YYYY', true).isValid();
                        if (!validate && d != '' && m != '' && y != '') {
                            setDialogMessage(`Tháng ${m}/${y} không có ngày ${d}!`)
                            setVisibleDialog(true);
                            setFocus(field.child_date['date']);

                            other_validate = false;
                        }
                    }
                    break;
                case "category":
                    setValue(field.name, currentCategory.id);
                default:
                    break;
            }
        });

        if (other_validate) {
            handleSubmit(onSubmit)();
        }
    }

    const api_fields = [
        {
            name: "title",
            lable: LANG.Field.NewsTitle,
            placeholder: LANG.Field.EnterNewsTitle,
            rules: {
                required: true,
                pattern: false,
            },
            icon: {
                name: "info-circle",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
        },
        {
            name: "content",
            lable: LANG.Field.NewsContent,
            placeholder: LANG.Field.EnterNewsContent,
            rules: {
                required: true,
                pattern: false,
            },
            icon: {
                name: "info-circle",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
            multiline: true,
        },
        {
            name: "link",
            lable: 'Liên kết(https://...)',
            placeholder: 'bài viết, youtube, vimeo ...',
            rules: {
                required: false,
                pattern: false,
            },
            icon: {
                name: "link",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
        },
        {
            name: "image",
            lable: 'Thư viện ảnh',
            placeholder: '',
            rules: {
                required: true,
                pattern: false,
            },
            icon: {
                name: "picture-o",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
            Type: "gallery",
        },
        {
            name: "role_view",
            lable: "Đối tượng xem",
            placeholder: "Hãy chọn đối tượng xem tin",
            rules: {
                pattern: false,
            },
            icon: {
                name: "users",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
            Type: "category"
        },
        {
            name: "date_publish",
            child_date: {
                'date': 'date_of_month',
                'month': 'month_of_year',
                'year': 'year_of_calendar',
                'hour': 'hour_of_datetime',
                'minute': 'minute_of_datetime'
            },
            visible_hour_minute: true,
            lable: 'Hẹn ngày gửi(ngày/tháng/năm)',
            placeholder: '',
            rules: {
                required: true,
                pattern: false,
            },
            icon: {
                name: "calendar",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
            Type: "calendar",
        },
    ];

    const arr_validate = {
        validate_password: {
            matchesPreviousPassword: (value) => {
                const { password } = getValues();
                return password === value || LANG.PasswordRequiredValidate;
            }
        }
    };

    let column = {};
    let row = {};
    if (windowWidth <= 768) {
        column = stylesColumns.columnSmall;
        row = stylesColumns.rowSmall;
    } else if (windowWidth >= 769) {
        // column = stylesColumns.columnMedium;
        // row = stylesColumns.rowMedium;
    }

    const [fields, setFields] = React.useState([]);

    const [visibleCategory, setVisibleCategory] = React.useState(false);

    /***
     * end: react hook form
    */

    const [currentCategory, setCurrentCategory] = React.useState({ id: '', title: '' });
    const selectCategory = (_category) => {
        setCurrentCategory(_category);
    }

    useEffect(() => {
        setFields(api_fields);

        const unsubscribe = navigation.addListener('focus', () => {
            // if screen is focused then reload
            console.log('Screen is focused post.admin ' + Math.random());
            gets_init();
        });

        return () => {
            // cleanup
            // Execute before the next effect or unmount
        };
    }, []);

    return (
        <View style={styles.container}>
            <Header title={LANG.News} view="chat" navigation={navigation} />

            <Dialog
                isVisible={visibleDialog}
                onBackdropPress={() => setVisibleDialog(!visibleDialog)}
            >
                <Dialog.Title title={dialogTitle} />
                <Text>{dialogMessage}</Text>
            </Dialog>

            {visibleCategory && !isEmpty(listCategory) && (
                <Overlay
                    fullScreen={Platform.OS == 'web' ? false : true}
                    isVisible={visibleCategory}
                    onBackdropPress={() => setVisibleCategory(!visibleCategory)}
                >
                    {currentCategory.id != '' && (
                        <View style={{ flexDirection: "row" }}>
                            <Text style={{ margin: 12, marginBottom: 6, fontWeight: "bold" }}>Bỏ chọn đối tượng(bấm)</Text>
                            <Icon
                                name="remove"
                                type="font-awesome"
                                color="#f00"
                                size={22}
                                iconStyle={{ marginTop: 10 }}
                                onPress={() => {
                                    setVisibleCategory(!visibleCategory);
                                    setCurrentCategory({ id: '', title: '' });
                                }}
                            />
                        </View>
                    )}
                    <Text style={{ margin: 12, marginBottom: 6, fontWeight: "bold" }}>Chọn đối tượng xem tin:</Text>
                    <ScrollView style={Platform.OS == 'web' && { maxHeight: windowHeight - 240 }}>
                        <View style={Platform.OS == 'web' && { width: 350 }}>
                            <View style={{ ...stylesCategory.row, width: "auto" }}>
                                {listCategory.map((item, index) =>
                                    <CheckBox
                                        key={item.id}
                                        title={item.title}
                                        checked={currentCategory.id == item.id ? true : false}
                                        checkedIcon='dot-circle-o'
                                        uncheckedIcon='circle-o'
                                        onPress={() => {
                                            setVisibleCategory(!visibleCategory);
                                            setCurrentCategory(item);
                                        }}
                                    />
                                )}
                            </View>
                        </View>
                    </ScrollView>
                    <View style={{ marginTop: 12 }}>
                        <ButtonCancel onPress={() => setVisibleCategory(!visibleCategory)} />
                    </View>
                </Overlay>
            )}

            <ScrollView
                contentContainerStyle={{
                    flexGrow: 1,
                }}
                keyboardShouldPersistTaps="handled"
            >
                {openForm && (
                    <View style={styles.box}>
                        <View style={row}>
                            {fields.map((field, key) => {
                                switch (field.Type) {
                                    case "calendar":
                                        return (
                                            <View key={key} style={column}>
                                                <View style={{ marginBottom: 20 }}>
                                                    <Text style={styles.textCalendar}>{field.lable}:</Text>
                                                </View>

                                                <View style={{ display: "flex", flexDirection: "row" }}>
                                                    <Icon
                                                        name={field.icon.name}
                                                        type="font-awesome"
                                                        color="#86939e"
                                                        size={25}
                                                        style={{ marginTop: 12, marginLeft: 12 }}
                                                    />
                                                    <MyContext.Provider value={{
                                                        setValue,
                                                        getValues,
                                                        reset,
                                                    }}>
                                                        <MyControlSparateCalendar
                                                            child_date={field.child_date}
                                                            visible_hour_minute={field.visible_hour_minute}
                                                            control={control}
                                                            errors={errors}
                                                            default_fulldate={getValues(field.name)}
                                                        />
                                                    </MyContext.Provider>
                                                </View>
                                            </View>
                                        );
                                        break;
                                    case "category":
                                        return (
                                            <View key={key} style={column}>
                                                <Text style={styles.text}> {field.lable}:</Text>
                                                <Pressable
                                                    style={{ flexDirection: "row", alignSelf: "flex-start", marginTop: 30 }}
                                                    onPress={() => { setVisibleCategory(true) }}>
                                                    <Icon
                                                        name={field.icon.name}
                                                        type="font-awesome"
                                                        color="#86939e"
                                                        size={25}
                                                        style={{ marginLeft: 12 }}
                                                    />
                                                    <Text style={{ minWidth: 30, marginLeft: 20, marginRight: 6 }}>
                                                        {currentCategory.title != "" ? currentCategory.title : "Chọn đối tượng"}
                                                    </Text>
                                                    <Icon
                                                        name="caret-down"
                                                        type="font-awesome"
                                                        color="#86939e"
                                                        size={22}
                                                        iconStyle={{}}
                                                    />
                                                </Pressable>
                                            </View>
                                        );
                                        break;
                                    case "gallery":
                                        return (
                                            <View key={key} style={column}>
                                                <MyContext.Provider value={
                                                    {
                                                        arr_img,
                                                        setArrImg,
                                                    }}>
                                                    {arr_img.map((item: any, index) => {
                                                        return (
                                                            <View key={index}
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    marginBottom: 12
                                                                }}
                                                            >

                                                                {item.value == '' ? (
                                                                    <BrowserImg index={item.index} title="Chọn ảnh" />
                                                                ) : (
                                                                    <View>
                                                                        <Icon
                                                                            name='remove'
                                                                            type='font-awesome'
                                                                            color={mycolors.red}
                                                                            onPress={() => setArrImg(arr_img.filter(arr => arr.index != item.index))}
                                                                        />
                                                                        <Image
                                                                            source={{ uri: item.value }}
                                                                            style={{ width: 250, height: 220, margin: 12 }}
                                                                        />
                                                                    </View>
                                                                )}
                                                            </View>
                                                        );
                                                    })}
                                                </MyContext.Provider>
                                                <ButtonAdd title="Thêm ảnh" onPress={() => setArrImg([...arr_img, { index: Math.random(), value: '' }])} />
                                            </View>
                                        )
                                        break;
                                    default:
                                        return (
                                            <View key={key} style={column}>
                                                <Text style={styles.text}> {field.lable}:</Text>
                                                <MyControlInputText
                                                    action={id != '' ? 'edit' : 'add'}
                                                    field={field}
                                                    control={control}
                                                    errors={errors}
                                                    pattern={field.rules.pattern != undefined ? arr_pattern[field.rules.pattern] : ''}
                                                    validate={field.rules.validate != undefined ? arr_validate[field.rules.validate] : ''}
                                                />
                                                {field.confirmField != null && (
                                                    <>
                                                        <Text style={styles.text}>{field.confirmField.lable}:</Text>
                                                        <MyControlInputText
                                                            action={id != '' ? 'edit' : 'add'}
                                                            field={field.confirmField}
                                                            control={control}
                                                            errors={errors}
                                                            pattern={field.confirmField.rules.pattern != undefined ? arr_pattern[field.confirmField.rules.pattern] : ''}
                                                            validate={field.confirmField.rules.validate != undefined ? arr_validate[field.confirmField.rules.validate] : ''}
                                                        />
                                                    </>
                                                )}
                                            </View>
                                        );
                                        break;
                                }
                            })}
                        </View>

                        <View style={{ flexDirection: "row", alignSelf: "center", marginTop: 30, marginBottom: 12 }}>
                            {isLoading == true ? (
                                <IsLoadingData title={LANG.Updating} />
                            ) : (
                                <>
                                    <ButtonCancel onPress={() => { setOpenForm(false); setOpenButton(true); setOpenList(true); }} />
                                    <ButtonSave onPress={() => myHandleSubmit()} />
                                </>
                            )}
                        </View>
                    </View>
                )}

                {openList && (
                    <>
                        <View style={{ flexDirection: "row", alignSelf: "center", marginTop: 12 }}>
                            <MyContext.Provider value={{
                                Search,
                                searchName,
                                setSearchName,
                            }}>
                                <MyInputSearchName />
                            </MyContext.Provider>

                            <MyContext.Provider value={{
                                visibleSearchCalendar,
                                setVisibleSearchCalendar,

                                fromDate,
                                setFromDate,

                                toDate,
                                setToDate,
                            }}>
                                <MySelectCalendarSearch />
                            </MyContext.Provider>

                            <ButtonSearch onPress={() => Search(searchName, fromDate)} />
                        </View>

                        <MyContext.Provider value={{
                            fromDate,
                            setFromDate,

                            toDate,
                            setToDate,
                        }}>
                            <MyDisplayFromToDate />
                        </MyContext.Provider>

                        {isLoading ? (
                            <IsLoadingData />
                        ) : (
                            <MyContext.Provider value={
                                {
                                    taskItems,
                                    checkItem,

                                    pagingParams,
                                    Paging,

                                    currentPage,
                                    setCurrentPage,
                                }}>
                                <MyListingPost />
                                <MyPaging />
                            </MyContext.Provider>
                        )}
                    </>
                )}

            </ScrollView>

            {permission == true && <>
                {openButton && (
                    <View style={{ flexDirection: "row", alignSelf: "center", "position": "absolute", bottom: 5 }}>
                        <ButtonAdd onPress={() => { setOpenForm(true); setOpenButton(false); setOpenList(false); }} />
                        <ButtonEdit onPress={() => editItem()} />
                        <ButtonDelete onPress={() => deleteItems()} />
                    </View>
                )}

                <View style={stylesButtons.open_close}>
                    {visible == true ? (
                        <OpenButtons onPress={() => { setOpenButton(false); setVisible(false); }} />
                    ) : (
                        <CloseButtons onPress={() => { setOpenButton(true); setVisible(true); }} />
                    )}
                </View>
            </>}

        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#E8EAED',
    },
    box: {

    },
    text: {
        marginTop: 18,
        marginBottom: Platform.OS == 'web' ? -12 : -24,
        color: '#000',
        alignSelf: "flex-start",
        paddingLeft: 12
    },
    textCalendar: {
        marginTop: 18,
        marginBottom: Platform.OS == 'web' ? -12 : -12,
        color: '#000',
        alignSelf: "flex-start",
        paddingLeft: 18
    }
});

export default ListNews;
