import React from "react";
import {
  StyleSheet,
  View,
  Text,
  StyleProp,
  TextStyle,
  ViewStyle,
  Alert,
  Pressable,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import {
  Header as HeaderRNE,
  Icon,
  Badge,
  Dialog,
} from "react-native-elements";
import { mycolors } from "../config/mycolors";

import AsyncStorage from "@react-native-async-storage/async-storage";

import { useNetInfo } from "@react-native-community/netinfo";

import { AuthContext } from "../context/mycontext";
import { ButtonRegister, ButtonStaffFunc } from "./mybuttons";

type HeaderComponentProps = {
  title: string;
  view?: string;
  navigation?: any;
  isHome?: any;
};

type ParamList = {
  Detail: {
    openDrawer: void;
  };
};

const Header: React.FunctionComponent<HeaderComponentProps> = (props) => {
  const navigationDrawer =
    useNavigation<DrawerNavigationProp<ParamList, "Detail">>();
  const navigation = props.navigation || null;

  const netInfo = useNetInfo();

  let mAuthContext = React.useContext(AuthContext);

  const [visibleDialog, setVisibleDialog] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState("");
  const [visibleDialogRequiredLogin, setDialogRequiredLogin] =
    React.useState(false);

  const openSidebarLeft = () => {
    if (mAuthContext.token === "" || mAuthContext.token === null) {
      setDialogRequiredLogin(true);
    } else {
      navigationDrawer.openDrawer();
    }
  };

  return (
    <HeaderRNE
      style={styles.headerContainer}
      leftComponent={{
        icon: "menu",
        color: "#fff",
        onPress: openSidebarLeft,
      }}
      rightComponent={
        props.view && (
          <View style={styles.headerRight}>
            <Pressable
              onPress={() => {
                if (mAuthContext.token === "" || mAuthContext.token === null) {
                  setDialogRequiredLogin(true);
                } else {
                  setVisibleDialog(true);
                }
              }}
            >
              <View style={{ width: 43 }}>
                <Icon name="bell" color="black" type="font-awesome" />
              </View>
              <Badge
                value={mAuthContext.numMessage}
                status="error"
                containerStyle={{ position: "absolute", top: 0, left: 25 }}
              />
            </Pressable>
          </View>
        )
      }
      centerComponent={
        <View style={{ flexDirection: "row" }}>
          <Dialog
            isVisible={visibleDialogRequiredLogin}
            onBackdropPress={() =>
              setDialogRequiredLogin(!visibleDialogRequiredLogin)
            }
            overlayStyle={{ maxWidth: 650, width: "94%" }}
          >
            <Dialog.Title title="Thông báo" />
            <Text>Bạn cần đăng nhập để sử dụng chức năng này.</Text>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                borderRadius: 5,
                alignItems: "center",
                marginBottom: 12,
              }}
            >
              <ButtonRegister
                onPress={() => {
                  setDialogRequiredLogin(false);
                  navigation.navigate({
                    name: "Login",
                    params: { screen: "signin" },
                  });
                }}
              />
              <ButtonRegister
                title="Đăng ký"
                onPress={() => {
                  setDialogRequiredLogin(false);
                  navigation.navigate({
                    name: "Login",
                    params: { screen: "signup" },
                  });
                }}
              />
            </View>
          </Dialog>

          <Dialog
            isVisible={visibleDialog}
            onBackdropPress={() => setVisibleDialog(!visibleDialog)}
            overlayStyle={{ maxWidth: 450 }}
          >
            <Dialog.Title title="Mới Nhất" />
            <View style={styles.box}>
              <ButtonStaffFunc
                icon="newspaper-o"
                title="Tin tức"
                onPress={() => {
                  setVisibleDialog(false);
                  navigation != undefined && navigation.navigate("ShowNews");
                }}
              />
            </View>
            <View style={styles.box}>
              <ButtonStaffFunc
                icon="comments"
                title="Chat mới nhất"
                onPress={() => {
                  setVisibleDialog(false);
                  mAuthContext.role == "customer"
                    ? Promise.all([
                        AsyncStorage.getItem("user_id"),
                        AsyncStorage.getItem("display_name"),
                      ]).then((values) => {
                        navigation.navigate("ChatCustomer", {
                          _user_id: values[0],
                          _user_name: values[1],
                        });
                      })
                    : navigation.navigate("ListNewChat");
                }}
              />
            </View>
            <View style={styles.box}>
              <ButtonStaffFunc
                icon="calendar"
                title="Lịch hẹn mới nhất"
                onPress={() => {
                  setVisibleDialog(false);
                  mAuthContext.role == "customer"
                    ? navigation.navigate("BookingCustomer")
                    : navigation.navigate("ListNewMessage");
                }}
              />
            </View>
          </Dialog>

          {netInfo.isConnected ? (
            <Text style={styles.heading}>{props.title}</Text>
          ) : (
            <Text style={styles.headingLostConnect}>
              {netInfo.isConnected != undefined && "Mất kết nối internet!"}
            </Text>
          )}
        </View>
      }
    />
  );
};

type SubHeaderProps = {
  title: string;
  textStyle?: StyleProp<TextStyle>;
  containerStyle?: StyleProp<ViewStyle>;
};

const SubHeader = ({ title, containerStyle, textStyle }: SubHeaderProps) => {
  return (
    <View style={[styles.headerContainer, containerStyle]}>
      <Text style={[styles.heading, textStyle]}>{title}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: mycolors.primary,
    marginBottom: 20,
    width: "100%",
    paddingVertical: 15,
  },
  heading: {
    color: "white",
    fontSize: 15,
    fontWeight: "bold",
  },
  headingLostConnect: {
    color: "#f3eb00",
    fontSize: 13,
    fontWeight: "bold",
  },
  headerRight: {
    display: "flex",
    flexDirection: "row",
    marginTop: 5,
  },
  subheaderText: {
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },
  box: {
    backgroundColor: mycolors.primary,
    alignContent: "center",
    marginTop: 6,
    marginBottom: 6,
    padding: 10,
  },
});

export { Header, SubHeader };
