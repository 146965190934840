import React, { useState, useEffect, useRef } from "react";
import { View, StyleSheet, ScrollView, Platform } from 'react-native';
import * as Notifications from 'expo-notifications';

import { Header } from '../../components/header';
import { ButtonStaffFunc } from "../../components/mybuttons";
import { LANG } from "../../config/config";
import { mycolors } from "../../config/mycolors";
import { PushnotificationModel, registerForPushNotificationsAsync } from "../../models/models";

if (Platform.OS != 'web') {
    Notifications.setNotificationHandler({
        handleNotification: async () => ({
            shouldShowAlert: true,
            shouldPlaySound: true,
            shouldSetBadge: true,
        }),
    });
}

function HomeManager({ navigation }) {
    const [expoPushToken, setExpoPushToken] = useState('');
    const [notification, setNotification]: any = useState(false);
    const notificationListener: any = useRef();
    const responseListener: any = useRef();

    if (Platform.OS != 'web') {
        useEffect(() => {
            registerForPushNotificationsAsync().then(token => {
                setExpoPushToken(token);
                if (__DEV__) {
                    console.log('token:', token);
                }
                PushnotificationModel.save({ 'push_token': token }).
                    then((result) => {
                        if (__DEV__) {
                            console.log('data save token:', result.data);
                        }
                    });
            });

            // This listener is fired whenever a notification is received while the app is foregrounded
            notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
                setNotification(notification);
            });

            // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
            responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
                console.log(response);
            });

            return () => {
                Notifications.removeNotificationSubscription(notificationListener.current);
                Notifications.removeNotificationSubscription(responseListener.current);
            };
        }, []);
    }

    return (
        <View style={styles.container}>
            <Header title={`${LANG.HomePage} ${LANG.Manager}`} view="chat" navigation={navigation} />
            <ScrollView
                contentContainerStyle={{
                    flexGrow: 1,
                    marginTop: 6,
                }}
                keyboardShouldPersistTaps="handled"
            >
                <View style={styles.box}>
                    <ButtonStaffFunc
                        icon="users"
                        title={LANG.Customer}
                        onPress={() => {
                            navigation.navigate('CustomerStaff');
                        }}
                    />
                </View>
                <View style={styles.box}>
                    <ButtonStaffFunc
                        icon="user-plus"
                        title={LANG.Supplier}
                        onPress={() => {
                            navigation.navigate('ListSupplier');
                        }}
                    />
                </View>
                <View style={styles.box}>
                    <ButtonStaffFunc
                        icon="heartbeat"
                        color_icon="#f7e608"
                        title={LANG.Service}
                        onPress={() => {
                            navigation.navigate('Service');
                        }}
                    />
                </View>
                <View style={styles.box}>
                    <ButtonStaffFunc
                        icon="product-hunt"
                        title={LANG.Product}
                        onPress={() => {
                            navigation.navigate('Product');
                        }}
                    />
                </View>
                <View style={styles.box}>
                    <ButtonStaffFunc
                        icon="pencil-square-o"
                        title={LANG.PostNews}
                        onPress={() => {
                            navigation.navigate('ListNews');
                        }}
                    />
                </View>
                <View style={styles.box}>
                    <ButtonStaffFunc
                        icon="newspaper-o"
                        title={LANG.News}
                        onPress={() => {
                            navigation.navigate('ShowNews');
                        }}
                    />
                </View>
                <View style={styles.box}>
                    <ButtonStaffFunc
                        icon="bar-chart"
                        title={LANG.Report}
                        onPress={() => {
                            navigation.navigate('ReportInternal');
                        }}
                    />
                </View>
            </ScrollView>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#E8EAED',
    },
    box: {
        backgroundColor: mycolors.primary,
        alignContent: "center",
        marginTop: 6,
        marginBottom: 6,
        marginLeft: 12,
        marginRight: 12,
        padding: 10
    },
    text: {
        color: '#fff'
    },
    boxTop: {
        padding: 12,
        alignItems: "center",
    },
    textTop: {
        color: "#000",
    }
});

export default HomeManager;
