import React from "react";
import { View, Text, StyleSheet, ScrollView, Platform } from 'react-native';
import { Dialog } from 'react-native-elements';

import { Header } from '../../components/header';

import { MyContext } from '../../context/mycontext';
import { ReportModel } from "../../models/models";
import { MyDisplayFromToDate, MySelectCalendarSearch } from "../../components/myboxs";
import { ButtonSearch } from "../../components/mybuttons";
import { IsLoadingData } from "../../components/myother";

import { LANG } from "../../config/config";
import { formatCurrenCy } from "../../config/myfunc";

function ReportCustomer({ navigation }) {
    // const id = 1;
    const [id, setID] = React.useState('');

    const dialogTitle = 'Thống Kê';

    const [isLoading, setLoading] = React.useState<boolean>(false);

    const [searchName, setSearchName] = React.useState('');
    const [visibleSearchCalendar, setVisibleSearchCalendar] = React.useState(false);
    const [fromDate, setFromDate] = React.useState('');
    const [toDate, setToDate] = React.useState('');

    const [visibleDialog, setVisibleDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');

    const [listReport, setListReport] = React.useState(null);

    const fontSize = Platform.OS == 'web' ? 15 : 13;

    function Search(from_date = '', to_date = '') {
        setLoading(true);
        ReportModel.for_customer(from_date, to_date)
            .then((results) => {
                const data_report: any = results["data"] || [];
                const posts = data_report['posts'] || [];

                if (__DEV__) {
                    console.log('data search:', posts);
                }

                setListReport(posts);
            })
            .catch(err => {
                if (__DEV__) {
                    console.log('err:', err);
                }
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false));
    };

    async function gets_init() {
        const p_report = ReportModel.for_customer();

        setLoading(true);
        Promise.all([p_report]).then((values) => {
            const data_report = values[0]["data"] || [];
            const posts = data_report['posts'] || [];

            if (__DEV__) {
                console.log('init data report: ', posts);
            }

            setListReport(posts);
        })
            .catch(err => {
                console.log(`${err.message} \n${LANG.CanNotConnect}`);

                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false))
    }

    React.useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            if (__DEV__) {
                // if screen is focused then reload
                console.log('Screen is focused parent' + Math.random());
            }
            gets_init();
        });

        return () => {
            return unsubscribe();
        };
    }, []);

    return (
        <View style={styles.container}>
            <Header title={dialogTitle} view="chat" navigation={navigation} />

            <Dialog
                isVisible={visibleDialog}
                onBackdropPress={() => setVisibleDialog(!visibleDialog)}
            >
                <Dialog.Title title={dialogTitle} />
                <Text>{dialogMessage}</Text>
            </Dialog>

            <ScrollView
                contentContainerStyle={{
                    flexGrow: 1,
                }}
                keyboardShouldPersistTaps="handled"
            >
                <View style={{ flexDirection: "row", alignSelf: "center", marginTop: 12 }}>
                    <MyContext.Provider value={{
                        visibleSearchCalendar,
                        setVisibleSearchCalendar,

                        fromDate,
                        setFromDate,

                        toDate,
                        setToDate,
                    }}>
                        <MySelectCalendarSearch />
                    </MyContext.Provider>
                    <ButtonSearch onPress={() => Search(fromDate, toDate)} title="Lọc" />
                </View>

                <MyContext.Provider value={{
                    fromDate,
                    setFromDate,

                    toDate,
                    setToDate,
                }}>
                    <MyDisplayFromToDate />
                </MyContext.Provider>

                {isLoading ? (
                    <IsLoadingData />
                ) : (
                    <View style={{ paddingLeft: 12, paddingRight: 12, marginTop: 12 }}>
                        {listReport != null && listReport.map((name, key) =>
                            <View key={key} style={{ display: "flex", flexDirection: "row" }}>
                                {Platform.OS == 'web'
                                    ? <>
                                        <Text style={{ width: 120, fontSize }}>
                                            {`${name['field_label']}:`}
                                        </Text>
                                        <Text style={{ width: 180, textAlign: "right", fontSize, color: "red" }}>
                                            {`${formatCurrenCy(name['total'])} `}
                                        </Text>
                                    </>
                                    : <>
                                        <Text style={{ fontSize }}>
                                            {`${name['field_label']}:`}
                                        </Text>
                                        <Text style={{ flex: 1, textAlign: "right", fontSize, color: "red" }}>
                                            {`${formatCurrenCy(name['total'])} `}
                                        </Text>
                                    </>
                                }
                            </View>
                        )}
                    </View>
                )}
            </ScrollView>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#E8EAED',
    },
    text: {
        color: '#fff'
    }
});


export default ReportCustomer;