import React from "react";
import { Text, View } from 'react-native';

export const IsLoadingData = (props) => {
    return <View style={{ alignSelf: "center" }}><Text>{props.title != undefined ? props.title : 'Đang tải dữ liệu...'}</Text></View>
}

export const NoDataLoading = () => {
    return <View style={{ alignSelf: "center" }}><Text>Không có dữ liệu!</Text></View>
}

export const NoDataChecking = () => {
    return <View style={{ alignSelf: "center" }}><Text>Không có mục được chọn!</Text></View>
}

export const NoDataUnChecking = () => {
    return <View style={{ alignSelf: "center" }}><Text>Không có hoặc đã check hết các mục!</Text></View>
}