export const mycolors = {
    primary: 'rgba(40, 79, 143,1)',
    primary2: 'rgba(40, 79, 143,.85)',
    primary3: 'rgba(40, 79, 143,.65)',

    secondary: 'rgba(79, 190, 149,1)',
    secondary2: 'rgba(79, 190, 149,0.75)',

    gray: 'rgba(39, 39, 39, 1)',
    gray2: 'rgba(39, 39, 39, 0.65)',
    red: 'rgba(214, 61, 57, 1)',
};
