import React, { useState } from "react";
import { StyleSheet, Text, View, ScrollView, Platform } from 'react-native';
import { Dialog } from 'react-native-elements';

import { Header } from '../../components/header';

import { ButtonSearch } from '../../components/mybuttons'
import { checkSingleItem } from "../../config/myfunc";
import { MyPaging, MySelectCalendarSearch, MyDisplayFromToDate, MyListingPayment, SelectOneReportModal } from "../../components/myboxs";
import { IsLoadingData } from "../../components/myother";

import { MyContext } from '../../context/mycontext';
import { LANG, LIST_REPORT_MENU } from "../../config/config";

import { PaymentModel } from "../../models/models"
import { MyInputSearchName } from "../../components/myinputs";
const dialogTitle = 'Thống kê';


function ReportListPayment({ route, navigation }) {
    const [isLoading, setLoading] = useState<boolean>(false);

    const [taskItems, setTaskItems] = useState([]);

    const [pagingParams, setPagingParams] = useState({});
    const [currentPage, setCurrentPage] = React.useState(1);

    const [searchName, setSearchName] = React.useState('');
    const [visibleSearchCalendar, setVisibleSearchCalendar] = React.useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const [visible, setVisible] = React.useState(true);
    const [visibleDialog, setVisibleDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');

    function checkItem(item: any) {
        const arr = checkSingleItem(taskItems, item);
        setTaskItems(arr);

        setVisible(true);
    }

    function Paging(page = 1) {
        setLoading(true);
        PaymentModel.gets_report(page)
            .then((results) => {
                const data: any = results["data"] || [];
                setTaskItems(data["posts"] || []);
                setPagingParams(data['paging'])
            })
            .catch(err => {
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false));
    }

    function Search(search_name = '', from_date = '', to_date = '') {
        setLoading(true);
        PaymentModel.gets_report(1, search_name, from_date, to_date)
            .then((results) => {
                const data: any = results["data"] || [];
                if (__DEV__) {
                    console.log('data search: ', data);
                }

                setTaskItems(data["posts"] || []);
                setPagingParams(data['paging'])
                setCurrentPage(1);
            })
            .catch(err => {
                console.log(err);
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false));
    };

    async function gets_init() {
        const p_posts = PaymentModel.gets_report(1, '');
        setLoading(true);
        Promise.all([p_posts]).then((values) => {
            const data_posts = values[0]["data"] || [];

            if (__DEV__) {
                console.log('data_posts:', data_posts);
            }

            setTaskItems(data_posts["posts"] || []);
            setPagingParams(data_posts["paging"]);
        })
            .catch(err => {
                console.log(`${err.message}`);

                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false))
    }

    React.useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            // if screen is focused then reload
            console.log('Screen is focused buy order: ' + Math.random());

            gets_init();
        });

        // cleanup
        return unsubscribe;
    }, [route]);

    return (
        <View style={styles.container}>
            <Header title={dialogTitle} view="chat" navigation={navigation} />

            <Dialog
                isVisible={visibleDialog}
                onBackdropPress={() => setVisibleDialog(!visibleDialog)}
            >
                <Dialog.Title title={dialogTitle} />
                <Text>{dialogMessage}</Text>
            </Dialog>

            <ScrollView
                contentContainerStyle={{
                    flexGrow: 1,
                }}
                keyboardShouldPersistTaps="handled"
            >
                <View style={{ flexDirection: "row", alignSelf: "center", marginTop: 12, marginLeft: 20, marginRight: 20 }}>
                    <MyContext.Provider value={{ currentType: LIST_REPORT_MENU[1] }}>
                        <SelectOneReportModal navigation={navigation} />
                    </MyContext.Provider>
                </View>

                <View style={{ flexDirection: "row", alignSelf: "center", marginTop: 12 }}>
                    <MyContext.Provider value={{
                        Search,
                        searchName,
                        setSearchName,
                    }}>
                        <MyInputSearchName placeholder="Nhập tên người" />
                    </MyContext.Provider>

                    <MyContext.Provider value={{
                        visibleSearchCalendar,
                        setVisibleSearchCalendar,

                        fromDate,
                        setFromDate,

                        toDate,
                        setToDate,
                    }}>
                        <MySelectCalendarSearch />
                    </MyContext.Provider>

                    <ButtonSearch onPress={() => Search(searchName, fromDate, toDate)} />
                </View>

                <MyContext.Provider value={{
                    fromDate,
                    setFromDate,

                    toDate,
                    setToDate,
                }}>
                    <MyDisplayFromToDate />
                </MyContext.Provider>

                {isLoading ? (
                    <IsLoadingData />
                ) : (
                    <MyContext.Provider value={
                        {
                            taskItems,
                            checkItem,

                            pagingParams,
                            Paging,

                            currentPage,
                            setCurrentPage,
                        }}>
                        <MyListingPayment />
                        <MyPaging />
                    </MyContext.Provider>
                )}

            </ScrollView>
        </View >
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#E8EAED',
    },
    checkbox: {
        color: '#000',
        marginBottom: Platform.OS == 'web' ? -12 : -24,
        alignSelf: "flex-start",
        paddingLeft: 12
    }
});

export default ReportListPayment;
