import moment from 'moment'

export function convertDateForMoment(_date: string) {
    // format for MomentJS
    // date same as: 2021/07/28 06:39:07, or 2021/07/28
    if (_date == null || _date == undefined || _date == '') {
        return '';
    }
    return _date.split('/').join('-');
}

export function displayMomentWithFormat(_date) {
    if (_date != undefined && _date != "") {
        const arr = `${_date}`.trim().split(" ");
        if (arr[1] != undefined && arr[1] != "00:00:00") {
            return `${moment(_date).format('DD/MM/YYYY HH:mm:ss')}`;
        } else {
            return `${moment(_date).format('DD/MM/YYYY')}`;
        }
    } else {
        return '';
    }
}

export function validatePhone(testPhone: string) {
    const re = /^0[0-9\b]{9,11}/;
    return re.test(testPhone);
}

export function formatCurrenCy(_currency = 0) {
    return _currency.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + 'đ'
}

export function fieldPriceSell(fieldName: string) {
    return `price_${fieldName}`;
}

export function fieldPriceBuy(fieldName: string) {
    return `price_buy_${fieldName}`;
}

export function convertFullDate(_startDate: string, _startHour: string, _startMinute: string) {
    // same as 2021-07-28 06:39:07
    return `${convertDateForMoment(_startDate)} ${_startHour}:${_startMinute}:00`;
}

export function getHoursOfDay() {
    let arr_hour: any = [];
    for (let index = 0; index <= 23; index++) {
        arr_hour.push(index < 10 ? `0${index}` : index);
    }
    return arr_hour;
}

export function getMinutesOfHour() {
    let arr_minute: any = [];
    for (let index = 0; index <= 59; index++) {
        arr_minute.push(index < 10 ? `0${index}` : index);
    }
    return arr_minute;
}

export function removeCharFromStr(str: any) {
    if (str == '') {
        return '';
    }
    let arr = str.split('');
    arr = arr.filter(char => {
        if ('0123456789'.indexOf(char) >= 0) {
            return char;
        } else {
            return '';
        }
    })
    var str = arr.join('');

    return str;
}

export function maskInputNumber(value: string | number) {
  if (value != "") {
    if (typeof value != "string") {
      value = value.toString();
    }
    return value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  return value;
}

export function checkMyItem(_list_items: any, _select_item: any) {
    let arr: any = [];
    _list_items.forEach(el => {
        let el_tg: any = el;
        if (el_tg.id == _select_item.id) {
            if (!el_tg['checked'])
                el_tg['checked'] = true;
            else
                el_tg['checked'] = false;
        }
        arr.push(el_tg);
    });

    return arr;
}

export function checkItemByIds(_list_items: any, _ids_item: any) {
    let arr: any = [];
    _list_items.forEach(el => {
        let el_tg: any = el;
        if (_ids_item.indexOf(el_tg.id) >= 0) {
            el_tg['checked'] = true;
        }
        arr.push(el_tg);
    });

    return arr;
}


export function checkSingleItem(_list_items: any, _select_item: any) {
    const task = _list_items.map(item => {
        if (_select_item.id == item.id) {
            if (!item['checked'])
                item['checked'] = true;
            else
                item['checked'] = false;
        } else {
            item['checked'] = false;
        }
        return item;
    })
    return task;
};

export function getsMyItemChecked(_list_items: any) {
    let arr: any = [];
    _list_items.forEach(el => {
        let el_tg: any = el;
        if (el_tg['checked']) {
            arr.push(el_tg.id);
        }
    });

    return arr;
}
