import React from "react";
import {
  ScrollView,
  StyleSheet,
  View,
  Image,
  Text,
  Pressable,
  Dimensions,
} from "react-native";
import * as WebBrowser from "expo-web-browser";
import { COLORS_BRAND } from "../config/config";

const { width } = Dimensions.get("window");
const height = width * 0.6;

export default function MySlider(props) {
  const [active, setActive] = React.useState(0);

  const changeActive = ({ nativeEvent }) => {
    const slide = Math.ceil(
      nativeEvent.contentOffset.x / nativeEvent.layoutMeasurement.width
    );
    if (slide !== active) {
      setActive(slide);
    }
  };

  return (
    <View style={{ width, height }}>
      <ScrollView
        pagingEnabled
        horizontal
        onScroll={changeActive}
        showsHorizontalScrollIndicator={false}
        style={styles.scroll}
      >
        {props.items.map((item, index) => (
          <View key={index} style={styles.item}>
            <Image source={{ uri: item.img }} style={styles.image} />
            <View style={styles.item_bottom}>
              <Pressable
                onPress={() => {
                  WebBrowser.openBrowserAsync(item.link);
                }}
              >
                <Text style={styles.item_bottom_text}>
                  {item.name}{" "}
                  <Text style={styles.item_arrow}> &#10148; Chi tiết</Text>
                </Text>
              </Pressable>
            </View>
          </View>
        ))}
      </ScrollView>
      <View style={styles.pagination}>
        {props.items.map((i, k) => (
          <View key={k}>
            <Text
              style={k === active ? styles.pagingActiveText : styles.pagingText}
            >
              ⬤
            </Text>
          </View>
        ))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  scroll: {
    width,
    height,
  },
  image: {
    width,
    height,
    resizeMode: "cover",
  },
  item: {
    position: "relative",
  },
  item_bottom: {
    minHeight: 32,
    paddingTop: 6,
    paddingBottom: 6,
    width: "100%",
    position: "absolute",
    bottom: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    justifyContent: "center",
    alignItems: "center",
  },
  item_bottom_text: {
    color: "white",
    fontSize: 12,
  },
  item_arrow: {
    color: COLORS_BRAND.secondary,
  },
  pagination: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    bottom: 40,
    left: 0,
    right: 0,
  },
  pagingText: {
    fontSize: 12,
    color: COLORS_BRAND.primary,
    marginLeft: 5,
    marginRight: 5,
  },
  pagingActiveText: {
    fontSize: 12,
    color: COLORS_BRAND.secondary,
    marginLeft: 5,
    marginRight: 5,
  },
});
