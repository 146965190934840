import React, { useState, useRef } from 'react';
import {
    StyleSheet,
    View,
    LayoutAnimation,
    UIManager,
} from 'react-native';
import { Text, Input, Button, Icon, InputProps, Dialog } from 'react-native-elements';
import { Header } from '../../components/header';

import { UserModel } from "../../models/models"

import { mycolors } from '../../config/mycolors';
import { stylesInput } from '../../config/mystyles';
import { HEIGHT_FOR_KEYBOARD, LANG, windowHeight, windowWidth } from '../../config/config';

// Enable LayoutAnimation on Android
UIManager.setLayoutAnimationEnabledExperimental &&
    UIManager.setLayoutAnimationEnabledExperimental(true);

type TabSelectorProps = {
    selected: boolean;
};

const TabSelector: React.FunctionComponent<TabSelectorProps> = ({
    selected,
}) => {
    return (
        <View style={styles.selectorContainer}>
            <View style={selected && styles.selected} />
        </View>
    );
};

type ChangePasswordState = {};
type ChangePasswordProps = {};

const ChangePassword: React.FunctionComponent<ChangePasswordState> = (
    props: ChangePasswordProps
) => {
    const { } = props;
    const navigation = props.navigation;
    const [isLoading, setLoading] = useState<boolean>(false);

    const [visibleDialog, setVisibleDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState<any>('');

    const [oldPassword, setOldPassword] = useState<string>('');
    const [isOldPasswordValid, setOldPasswordValid] = useState<boolean>(true);

    const [password, setPassword] = useState<string>('');
    const [isPasswordValid, setPasswordValid] = useState<boolean>(true);

    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [isConfirmPasswordValid, setConfirmPasswordValid] = useState<boolean>(
        true
    );
    const passwordInput = useRef<InputProps>(null);
    const confirmationInput = useRef<InputProps>(null);

    const changePassword = async () => {
        setLoading(true);

        setTimeout(() => {
            const isPasswordValidFlag =
                password.length >= 5 || passwordInput.current.shake();

            LayoutAnimation.easeInEaseOut();
            setLoading(false);
            setPasswordValid(isPasswordValidFlag);
            if (isPasswordValidFlag) {
                UserModel.change_password(
                    {
                        'old_password': oldPassword
                        , 'password': password
                        , 'verify_password': confirmPassword
                    })
                    .then((results) => {
                        const data = results.data;
                        setVisibleDialog(true);
                        setDialogMessage(data.message);
                    })
                    .catch(err => {
                        setVisibleDialog(true);
                        setDialogMessage(LANG.CanNotConnect);
                    });
            }
        }, 1500);
    };

    return (
        <View style={styles.container}>
            <Header title="Đổi Mật Khẩu" view="chat" />

            <Dialog
                isVisible={visibleDialog}
                onBackdropPress={() => {
                    setVisibleDialog(!visibleDialog);
                }}
            >
                <Dialog.Title title="Thành Viên" />
                <Text>{dialogMessage}</Text>
            </Dialog>

            <View style={styles.bgImage}>
                <View style={styles.formContainer}>
                    <Text style={styles.rowSelector}>Mật khẩu cũ</Text>
                    <Input
                        leftIcon={
                            <Icon
                                name="lock"
                                type="simple-line-icon"
                                color={stylesInput.icon.color}
                                size={25}
                                style={{ backgroundColor: 'transparent' }}
                            />
                        }
                        value={oldPassword}
                        placeholder={'Mật khẩu cũ'}
                        keyboardAppearance="light"
                        autoCapitalize="none"
                        autoCorrect={false}
                        secureTextEntry={true}
                        blurOnSubmit={true}
                        containerStyle={stylesInput.containerStyle}
                        inputStyle={stylesInput.inputStyle}
                        onSubmitEditing={() => changePassword()}
                        onChangeText={(text) => setOldPassword(text)}
                        errorMessage={
                            isOldPasswordValid ? '' : 'Mật khẩu ít nhất 5 ký tự'
                        }
                    />

                    <Text style={styles.rowSelector}>Mật khẩu mới</Text>
                    <Input
                        leftIcon={
                            <Icon
                                name="lock"
                                type="simple-line-icon"
                                color={stylesInput.icon.color}
                                size={25}
                                style={{ backgroundColor: 'transparent' }}
                            />
                        }
                        value={password}
                        placeholder={'Mật khẩu mới'}
                        keyboardAppearance="light"
                        autoCapitalize="none"
                        autoCorrect={false}
                        secureTextEntry={true}
                        blurOnSubmit={true}
                        containerStyle={stylesInput.containerStyle}
                        inputStyle={stylesInput.inputStyle}
                        ref={passwordInput}
                        onSubmitEditing={() => changePassword()}
                        onChangeText={(text) => setPassword(text)}
                        errorMessage={
                            isPasswordValid ? '' : 'Mật khẩu ít nhất 5 ký tự'
                        }
                    />

                    <Text style={styles.rowSelector}>Xác nhận mật khẩu</Text>
                    <Input
                        leftIcon={
                            <Icon
                                name="lock"
                                type="simple-line-icon"
                                color={stylesInput.icon.color}
                                size={25}
                                style={{ backgroundColor: 'transparent' }}
                            />
                        }
                        value={confirmPassword}
                        placeholder={'Xác nhận mật khẩu'}
                        secureTextEntry={true}
                        keyboardAppearance="light"
                        autoCapitalize="none"
                        autoCorrect={false}
                        keyboardType="default"
                        returnKeyType={'done'}
                        blurOnSubmit={true}
                        containerStyle={stylesInput.containerStyle}
                        inputStyle={stylesInput.inputStyle}
                        ref={confirmationInput}
                        onSubmitEditing={() => changePassword()}
                        onChangeText={(text) => setConfirmPassword(text)}
                        errorMessage={
                            isConfirmPasswordValid ? '' : 'Vui lòng xác nhận mật khẩu'
                        }
                    />

                    <Button
                        buttonStyle={styles.loginButton}
                        activeOpacity={0.8}
                        title={'Đổi mật khẩu'}
                        icon={{
                            name: 'save',
                            type: 'font-awesome',
                            size: 15,
                            color: 'white',
                        }}
                        onPress={() => changePassword()}
                        titleStyle={styles.loginTextButton}
                        loading={isLoading}
                        disabled={isLoading}
                        iconContainerStyle={{ marginRight: 10 }}
                        containerStyle={{
                            marginHorizontal: 3,
                            alignSelf: "center",
                        }}
                    />
                </View>
                <View style={{ height: HEIGHT_FOR_KEYBOARD + 30 }}></View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#E8EAED',
    },
    rowSelector: {
        alignSelf: "flex-start",
        marginLeft: 15
    },
    selectorContainer: {
        flex: 1,
        alignItems: 'center',
    },
    selected: {
        position: 'absolute',
        borderRadius: 50,
        height: 0,
        width: 0,
        top: -5,
        borderRightWidth: 70,
        borderBottomWidth: 70,
        borderColor: 'white',
        backgroundColor: 'white',
    },
    loginTextButton: {
        fontSize: 16,
        color: 'white',
        fontWeight: 'bold',
    },
    loginButton: {
        backgroundColor: mycolors.primary,
        borderRadius: 3,
        height: 50,
        width: 200,
    },
    formContainer: {
        backgroundColor: 'white',
        width: windowWidth - 30,
        borderRadius: 10,
        paddingTop: 32,
        paddingBottom: 32,
        alignItems: 'center',
    },
    bgImage: {
        flex: 1,
        backgroundColor: mycolors.secondary,
        top: 0,
        left: 0,
        width: '100%',
        height: windowHeight,
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export default ChangePassword;
