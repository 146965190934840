import React, { useState, useEffect, useCallback } from 'react'
import { StyleSheet, View, LogBox } from 'react-native'
import { MyContext } from '../../context/mycontext';
import { GiftedChat } from 'react-native-gifted-chat'

import AsyncStorage from "@react-native-async-storage/async-storage";
import { COLLECTION_CHAT, COLLECTION_CHAT_READ, firebaseConfig, HEAD_PUSH_NOTIFICATION, LANG } from "../../config/config";

import * as firebase from 'firebase'
import 'firebase/firestore'

if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig)
}
LogBox.ignoreLogs(['Setting a timer for a long period of time'])
const db = firebase.firestore()

import { Header } from '../../components/header';
import { PushnotificationModel } from '../../models/models';
import { BoxOpenShortMenu } from '../../components/myboxs';
import { MyControlShortMenu } from '../../components/mycontrolinputs';
const dialogTitle = LANG.Chat;

function ChatStaff({ route, navigation }) {
    const [userChat, setUserChat] = useState(null);
    const [messages, setMessages] = useState([]);

    const [customerID, setCustomerID] = React.useState('');
    const [customerName, setCustomerName] = React.useState('');

    const [visibleShortMenu, setVisibleShortMenu] = React.useState(false);

    const { _customer_id, _customer_name } = route.params || ['random_' + (new Date()).getMilliseconds(), ''];

    const chatsRef = db.collection(COLLECTION_CHAT).doc('rooms').collection(`room_${_customer_id}`);
    const chatsRead = db.collection(COLLECTION_CHAT_READ).doc('read');

    const asyncUserChat = async () => {
        try {
            await Promise.all([AsyncStorage.getItem('user_id'), AsyncStorage.getItem('display_name')]).then((values) => {
                const _user_id = values[0];
                const _user_name = values[1];
                if (_user_id !== null) {
                    setUserChat({ '_id': _user_id, 'name': _user_name });
                }
            });
        } catch (error) {
            if (__DEV__) {
                console.log('error get Storage: ', error);
            }
        }
    };

    async function readChatForCustomer(_customer_id) {
        await chatsRead.update({
            [`room_${_customer_id}.staff_read`]: 1,
            [`room_${_customer_id}.customer_read`]: 0,
        });
    };

    useEffect(() => {
        // read all
        chatsRead.update({
            [`room_${_customer_id}.staff_read`]: 1,
            [`room_${_customer_id}.customer_id`]: _customer_id,
            [`room_${_customer_id}.customer_name`]: _customer_name,
        });

        // // gets all fields of document
        // chatsRead.get().then((res) => {
        //     const obj_data = res.data();
        //     let arr = [];
        //     for (let key in obj_data) {
        //         if (`room_${_customer_id}` == key) {
        //             obj_data[key]['staff_read'] = 1;
        //         }
        //         arr.push({ [`${key}`]: obj_data[key] });
        //     }
        //     const obj = Object.assign({}, ...arr);
        //     chatsRead.update(obj);
        // });
    }, [route]);

    useEffect(() => {
        asyncUserChat();

        setCustomerID(_customer_id);
        setCustomerName(_customer_name);

        setMessages([]);

        const unsubscribe = chatsRef.onSnapshot((querySnapshot) => {
            const messagesFirestore = querySnapshot
                .docChanges()
                .filter(({ type }) => type === 'added')
                .map(({ doc }) => {
                    const message = doc.data();
                    //createdAt is firebase.firestore.Timestamp instance
                    //https://firebase.google.com/docs/reference/js/firebase.firestore.Timestamp
                    return { ...message, createdAt: message.createdAt.toDate() }
                })
                .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
            appendMessages(messagesFirestore)
        })

        return () => unsubscribe()
    }, [route]);

    const appendMessages = useCallback(
        (messages) => {
            setMessages((previousMessages) => GiftedChat.append(previousMessages, messages))
        },
        [messages]
    )

    async function handleSend(messages) {
        readChatForCustomer(_customer_id);

        const title = HEAD_PUSH_NOTIFICATION + ' nhắn tin';
        const content = messages[0]['text'];
        PushnotificationModel.add_message_one_user({
            title, content, 'user_id': _customer_id
        })
            .then((result) => {
                if (__DEV__) {
                    console.log('push notification when chat', result['data']['message']);
                }

                PushnotificationModel.run_schedule();
            });

        const writes = messages.map((m) => chatsRef.add(m))
        await Promise.all(writes)
    }

    return (
        <View style={styles.container}>
            {visibleShortMenu && (
                <MyContext.Provider value={
                    {
                        visibleShortMenu,
                        setVisibleShortMenu,

                    }}>
                    <MyControlShortMenu
                        navigation={navigation}
                        customer_id={customerID}
                        customer_name={customerName}
                    />
                </MyContext.Provider>
            )}

            <Header title={dialogTitle} view="chat" navigation={navigation} />
            <MyContext.Provider value={{
                setVisibleShortMenu,
            }}>
                <BoxOpenShortMenu
                    customerName={customerName}
                    customerID={customerID}
                    navigation={navigation}
                    inChat={true}
                />
            </MyContext.Provider>

            {customerID != ''
                && <GiftedChat
                    messages={messages}
                    user={userChat}
                    onSend={handleSend}
                    placeholder="Nhập nội dung"
                    label="Gửi"
                />
            }
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#E8EAED',
    }
});

export default ChatStaff;
