import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, ScrollView, Platform } from 'react-native';
import { Dialog } from 'react-native-elements';
import { ButtonAdd, ButtonCancel, ButtonDelete, ButtonEdit, ButtonSave, ButtonSearch, CloseButtons, OpenButtons } from '../../components/mybuttons'

import moment from "moment";

import { ProductCategoryModel } from "../../models/models"

import { Header } from '../../components/header';

import { stylesButtons, stylesColumns } from "../../config/mystyles";
import { getsMyItemChecked } from "../../config/myfunc";
import { MyPaging, MySelectCalendarSearch, MyDisplayFromToDate, MyListingProductCategory } from "../../components/myboxs";
import { IsLoadingData } from "../../components/myother";

import { MyContext } from '../../context/mycontext';
import { arr_pattern, MyControlInputText } from "../../components/mycontrolinputs";
import { LANG, windowWidth } from "../../config/config";

import { useForm } from "react-hook-form";
import { MyInputSearchName } from "../../components/myinputs";

function ProductCategory({ navigation }) {
    const [isLoading, setLoading] = useState<boolean>(false);

    const [taskItems, setTaskItems] = useState([]);

    const [pagingParams, setPagingParams] = useState({});
    const [currentPage, setCurrentPage] = React.useState(1);

    const [searchName, setSearchName] = React.useState('');
    const [visibleSearchCalendar, setVisibleSearchCalendar] = React.useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const [openList, setOpenList] = React.useState(true);
    const [openForm, setOpenForm] = React.useState(false);
    const [openButton, setOpenButton] = React.useState(true);

    const [visible, setVisible] = React.useState(true);
    const [visibleDialog, setVisibleDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');
    const [visibleCalendar, setVisibleCalendar] = React.useState(false);

    const [permission, setPermission] = React.useState(false);

    const [id, setID] = useState('');

    const dialogTitle = 'Chuyên Mục Sản Phẩm';

    function checkItem(item: any) {
        let arr: any = [];
        taskItems.forEach(el => {
            let el_tg: any = el;
            if (el_tg.id == item.id) {
                if (!el_tg['checked'])
                    el_tg['checked'] = true;
                else
                    el_tg['checked'] = false;
            } else {
                el_tg['checked'] = false;
            }
            arr.push(el_tg);
        });

        setTaskItems(arr);

        setOpenButton(true);
        setVisible(true);
    };

    function deleteItems() {
        const arr = getsMyItemChecked(taskItems);

        if (arr.length == 0) {
            setVisibleDialog(true);
            setDialogMessage(LANG.ChooseAtLeastOneToDelete);
            return;
        }

        ProductCategoryModel.delete_arr({ 'ids': arr })
            .then((results) => {
                const data: any = results.data || {};
                if (data['posts'] != undefined) {
                    setTaskItems(data['posts'] || []);
                    setPagingParams(data['paging'])
                }

                setVisibleDialog(true);
                setDialogMessage(LANG.DeleteSuccessfully);
            })
            .catch(err => {
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            });
    };

    function editItem() {
        let arr: any = getsMyItemChecked(taskItems);
        const lastItem = arr.pop();

        if (lastItem == undefined) {
            setVisibleDialog(true);
            setDialogMessage(LANG.ChooseAtLeastOne);
        } else {
            ProductCategoryModel.get(lastItem)
                .then((results) => {
                    const post: any = results.data.post || {};

                    setID(post.id);
                    fields.forEach(field => {
                        if (field.Type == 'calendar') {
                            if (field.subFieldHour != undefined) {
                                setValue(`${field.name}`, moment(post[`${field.name}`]).format('YYYY/MM/DD hh:mm:ss'));
                            } else {
                                setValue(`${field.name}`, moment(post[`${field.name}`]).format('YYYY/MM/DD'));
                            }
                        } else {
                            setValue(`${field.name}`, post[`${field.name}`]);
                        }
                    });

                    setOpenForm(true);
                    setOpenList(false);
                    setOpenButton(false);
                })
                .catch(err => {
                    setVisibleDialog(true);
                    setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
                });
        }
    };

    function Paging(page = 1) {
        setLoading(true);
        ProductCategoryModel.gets(page)
            .then((results) => {
                const data: any = results["data"] || [];
                setTaskItems(data["posts"] || []);
                setPagingParams(data['paging'])
            })
            .catch(err => {
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false));
    };

    function Search(search_name = '') {
        setLoading(true);
        ProductCategoryModel.gets(1, search_name)
            .then((results) => {
                const data: any = results["data"] || [];
                setTaskItems(data["posts"] || []);
                setPagingParams(data['paging'])
                setCurrentPage(1);
            })
            .catch(err => {
                console.log(err);
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false));
    };

    async function gets_init() {
        let p_posts = ProductCategoryModel.gets(1);

        setLoading(true);
        Promise.all([p_posts]).then((values) => {
            const data_posts = values[0]["data"] || [];

            if (__DEV__) {
                console.log('init data_posts:', data_posts);
            }

            setPermission(data_posts["permission"] || false);

            setTaskItems(data_posts["posts"] || []);
            setPagingParams(data_posts['paging'])
        })
            .catch(err => {
                console.log(`${err.message}`);

                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false));
    }

    /***
     * begin: react hook form
    */
    const { control, handleSubmit, reset, formState: { errors }, getValues, setValue } = useForm();

    const onSubmit = data => {
        const data_submit = data;

        if (id != '') {
            ProductCategoryModel.update(id, data_submit)
                .then((results) => {
                    const data: any = results.data || {};
                    if (data.post != undefined && data.post.id != undefined) {
                        const obj_task: any = {
                            'id': data.post.id,
                            'title': data.post.title,
                            'date_created': data.post.date_created,
                            'checked': false
                        };
                        let arr: any = [];
                        taskItems.forEach(el => {
                            let el_tg: any = el;
                            if (el_tg['id'] == obj_task.id) {
                                arr.push(obj_task);
                            } else {
                                arr.push(el);
                            }
                        });
                        setTaskItems(arr);

                        setDialogMessage(LANG.UpdateSuccessfully);
                    } else {
                        setVisibleDialog(true);
                        setDialogMessage(LANG.CanNotUpdate);
                    }

                    setOpenForm(false);
                    setOpenList(true);
                    setOpenButton(true);
                    setVisibleDialog(true);

                    reset();
                })
                .catch(err => {
                    setVisibleDialog(true);
                    setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
                })
                .finally(() => {
                    setID('');
                    setLoading(false);
                });
        } else {
            ProductCategoryModel.create(data_submit)
                .then((results) => {
                    const data: any = results.data || {};
                    if (data['posts'] != undefined) {
                        setTaskItems(data['posts'] || []);
                        setPagingParams(data['paging'])

                        setDialogMessage(LANG.AddNewSuccessfully);
                    } else {
                        setVisibleDialog(true);
                        setDialogMessage(LANG.CanNotAddNew);
                    }

                    setOpenForm(false);
                    setOpenList(true);
                    setOpenButton(true);
                    setVisibleDialog(true);

                    reset();
                })
                .catch(err => {
                    setVisibleDialog(true);
                    setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
                })
                .finally(() => {
                    setID('');
                    setLoading(false);
                });
        }
    };

    const myHandleSubmit = () => {
        // convert before submit
        fields.forEach(field => {
            if (field.Type == "calendar") {
                // do anything
            }
            if (field.Type == "image") {
                // do anything
            }
        });

        handleSubmit(onSubmit)();
    }

    const api_fields = [
        {
            name: "title",
            lable: "Tên chuyên mục",
            placeholder: "Hãy nhập tên chuyên mục",
            rules: {
                required: true,
                pattern: false,
            },
            icon: {
                name: "info-circle",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
        },
        {
            name: "numbered",
            lable: "Thứ tự",
            placeholder: "Hãy nhập thứ tự hiển thị",
            rules: {
                required: false,
                pattern: false,
            },
            icon: {
                name: "info-circle",
                size: 25
            },
            keyboardType: "numeric",
            isPassword: false,
        },
        {
            name: "content",
            lable: "Mô tả(nếu có)",
            placeholder: "Hãy nhập mô tả",
            rules: {
                required: true,
                pattern: false,
            },
            icon: {
                name: "info-circle",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
            multiline: true,
        },
    ];

    const arr_validate = {
        validate_password: {
            matchesPreviousPassword: (value) => {
                const { password } = getValues();
                return password === value || LANG.PasswordRequiredValidate;
            }
        }
    };

    let column = {};
    let row = {};
    if (windowWidth <= 768) {
        column = stylesColumns.columnSmall;
        row = stylesColumns.rowSmall;
    } else if (windowWidth >= 769) {
        // column = stylesColumns.columnMedium;
        // row = stylesColumns.rowMedium;
    }

    const [fields, setFields] = React.useState([]);

    /***
     * end: react hook form
    */


    useEffect(() => {
        setFields(api_fields);

        const unsubscribe = navigation.addListener('focus', () => {
            console.log('Screen is focused post.admin ' + Math.random());
            gets_init();
        });

        return () => {
            // cleanup
        };
    }, []);

    return (
        <View style={styles.container}>
            <Header title="Chuyên mục sản phẩm" view="chat" navigation={navigation} />

            <Dialog
                isVisible={visibleDialog}
                onBackdropPress={() => setVisibleDialog(!visibleDialog)}
            >
                <Dialog.Title title={dialogTitle} />
                <Text>{dialogMessage}</Text>
            </Dialog>

            <ScrollView
                contentContainerStyle={{
                    flexGrow: 1,
                }}
                keyboardShouldPersistTaps="handled"
            >
                {openForm && (
                    <View style={styles.box}>
                        <View style={row}>
                            {fields.map((field, key) => {
                                return (
                                    <View key={key} style={column}>
                                        <Text style={styles.text}>{field.lable}:</Text>
                                        <MyControlInputText
                                            field={field}
                                            control={control}
                                            errors={errors}
                                            pattern={field.rules.pattern != undefined ? arr_pattern[field.rules.pattern] : ''}
                                            validate={field.rules.validate != undefined ? arr_validate[field.rules.validate] : ''}
                                        />
                                    </View>
                                )
                            })}
                        </View>


                        <View style={{ flexDirection: "row", alignSelf: "center", marginTop: 30 }}>
                            {isLoading == true ? (
                                <IsLoadingData title={LANG.Updating} />
                            ) : (
                                <>
                                    <ButtonCancel onPress={() => { setOpenForm(false); setOpenButton(true); setOpenList(true); }} />
                                    <ButtonSave onPress={() => myHandleSubmit()} />
                                </>
                            )}
                        </View>
                    </View>
                )}

                {openList && (
                    <>
                        <View style={{ flexDirection: "row", alignSelf: "center", marginTop: 12 }}>
                            <MyContext.Provider value={{
                                Search,
                                searchName,
                                setSearchName,
                            }}>
                                <MyInputSearchName />
                            </MyContext.Provider>

                            <MyContext.Provider value={{
                                visibleSearchCalendar,
                                setVisibleSearchCalendar,

                                fromDate,
                                setFromDate,

                                toDate,
                                setToDate,
                            }}>
                                <MySelectCalendarSearch />
                            </MyContext.Provider>

                            <ButtonSearch onPress={() => Search(searchName)} />
                        </View>

                        <MyContext.Provider value={{
                            fromDate,
                            setFromDate,

                            toDate,
                            setToDate,
                        }}>
                            <MyDisplayFromToDate />
                        </MyContext.Provider>

                        {isLoading ? (
                            <IsLoadingData />
                        ) : (
                            <MyContext.Provider value={
                                {
                                    taskItems,
                                    checkItem,

                                    pagingParams,
                                    Paging,

                                    currentPage,
                                    setCurrentPage,
                                }}>
                                <MyListingProductCategory />
                                <MyPaging />
                            </MyContext.Provider>
                        )}
                    </>
                )}

            </ScrollView>

            {permission == true && <>
                {openButton && (
                    <View style={{ flexDirection: "row", alignSelf: "center", "position": "absolute", bottom: 5 }}>
                        <ButtonAdd onPress={() => { setOpenForm(true); setOpenButton(false); setOpenList(false); }} />
                        <ButtonEdit onPress={() => editItem()} />
                        <ButtonDelete onPress={() => deleteItems()} />
                    </View>
                )}

                <View style={stylesButtons.open_close}>
                    {visible == true ? (
                        <OpenButtons onPress={() => { setOpenButton(false); setVisible(false); }} />
                    ) : (
                        <CloseButtons onPress={() => { setOpenButton(true); setVisible(true); }} />
                    )}
                </View>
            </>}

        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#E8EAED',
    },
    box: {

    },
    text: {
        marginTop: 18,
        marginBottom: Platform.OS == 'web' ? -12 : -24,
        color: '#000',
        alignSelf: "flex-start",
        paddingLeft: 12
    },
    input: {
        maxWidth: 500,
        alignSelf: "center",
        borderColor: "#000",
        borderWidth: 1,
    }
});

export default ProductCategory;
