import React, { useState } from "react";
import { StyleSheet, Text, View, ScrollView, Platform } from 'react-native';
import { Dialog } from 'react-native-elements';

import moment from 'moment'

import { Header } from '../../components/header';

import { ButtonAdd, ButtonCancel, ButtonDelete, ButtonEdit, ButtonRefresh, ButtonSave, ButtonSearch, CloseButtons, OpenButtons } from '../../components/mybuttons'
import { stylesButtons, stylesColumns } from "../../config/mystyles";
import { checkSingleItem, getsMyItemChecked } from "../../config/myfunc";
import { MyPaging, MySelectCalendarSearch, MyDisplayFromToDate, MyListingCustomerStaff } from "../../components/myboxs";
import { IsLoadingData } from "../../components/myother";

import { MyContext } from '../../context/mycontext';
import { arr_pattern, MyControlCheckbox, MyControlInputText, MyControlSparateCalendar } from "../../components/mycontrolinputs";
import { LANG, windowWidth } from "../../config/config";

import { useForm } from "react-hook-form";

import { PersonModel } from "../../models/models"
import { MyInputSearchName } from "../../components/myinputs";
let objPersonModel = new PersonModel('customer');
const dialogTitle = LANG.Customer;

function CustomerStaff({ route, navigation }) {
    const [isLoading, setLoading] = useState<boolean>(false);

    const [taskItems, setTaskItems] = useState([]);

    const [pagingParams, setPagingParams] = useState({});
    const [currentPage, setCurrentPage] = React.useState(1);

    const [searchName, setSearchName] = React.useState('');
    const [visibleSearchCalendar, setVisibleSearchCalendar] = React.useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const [openList, setOpenList] = React.useState(true);
    const [openForm, setOpenForm] = React.useState(false);
    const [openButton, setOpenButton] = React.useState(true);

    const [visible, setVisible] = React.useState(true);
    const [visibleDialog, setVisibleDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');

    const [permission, setPermission] = React.useState(false);

    // fields of table
    const [id, setID] = useState('');
    // same as birthday

    function checkItem(item: any) {
        const arr = checkSingleItem(taskItems, item);
        setTaskItems(arr);

        setOpenButton(true);
        setVisible(true);
    }

    function deleteItems() {
        let arr: any = getsMyItemChecked(taskItems);

        if (arr.length == 0) {
            setVisibleDialog(true);
            setDialogMessage(LANG.ChooseAtLeastOneToDelete);
            return;
        }

        objPersonModel.delete_arr({ 'ids': arr })
            .then((results) => {
                const data = results.data || {};
                const posts_reload = data.posts_reload || {};
                if (posts_reload.posts != null) {
                    setTaskItems(posts_reload.posts);
                } else if (data.posts != undefined) {
                    setTaskItems(data.posts);
                }

                setVisibleDialog(true);
                setDialogMessage(`${data.message}`);
            })
            .catch(err => {
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            });
    }

    function editItem() {
        let arr: any = getsMyItemChecked(taskItems);
        const lastItem = arr.pop();

        if (lastItem == undefined) {
            setVisibleDialog(true);
            setDialogMessage(LANG.ChooseAtLeastOne);
        } else {
            objPersonModel.get(lastItem)
                .then((results) => {
                    const post: any = results.data.post || {};

                    if (__DEV__) {
                        console.log('data user:', post);
                    }

                    setID(post.id);
                    fields.forEach(field => {
                        if (field.Type == 'calendar') {
                            if (field.subFieldHour != undefined) {
                                setValue(`${field.name}`, moment(post[`${field.name}`]).format('YYYY/MM/DD hh:mm:ss'));
                            } else {
                                setValue(`${field.name}`, moment(post[`${field.name}`]).format('YYYY/MM/DD'));
                            }
                        } else {
                            console.log(field.name, post[`${field.name}`]);
                            setValue(`${field.name}`, post[`${field.name}`]);
                        }
                    });

                    setOpenForm(true);
                    setOpenList(false);
                    setOpenButton(false);
                })
                .catch(err => {
                    setVisibleDialog(true);
                    setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
                });
        }
    }

    function handleActivated(user_id) {
      objPersonModel.activate(user_id).then((results) => {
        const data = results.data || {};
        if (data.is_activated) {
          const tasks = taskItems.map((item: any) => {
            if (item.id == user_id) {
              item.is_activated = data.is_activated;
            }
            return item;
          });
          setTaskItems(tasks);
        }
      });
    }

    function Paging(page = 1) {
        setLoading(true);
        objPersonModel.gets(page)
            .then((results) => {
                const data: any = results["data"] || [];
                setTaskItems(data["posts"] || []);
                setPagingParams(data['paging'])
            })
            .catch(err => {
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false));
    }

    function Search(search_name = '', from_date = '') {
        setLoading(true);
        objPersonModel.gets(1, search_name, from_date)
            .then((results) => {
                const data: any = results["data"] || [];

                if (__DEV__) {
                    console.log('data search:', data);
                }

                setTaskItems(data["posts"] || []);
                setPagingParams(data['paging'])
                setCurrentPage(1);
            })
            .catch(err => {
                console.log(err);
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false));
    };

    async function gets_init(_customer_id='') {
        let p_posts = objPersonModel.gets(1);

        let p_just_done_customer = {};
        if (_customer_id != '') {
            p_just_done_customer = objPersonModel.get(_customer_id);
        }

        setLoading(true);
        Promise.all([p_posts, p_just_done_customer]).then((values) => {
            const data_posts = values[0]["data"] || [];
            const data_just_done_customer = values[1]["data"] || [];
            let list_items = data_posts["posts"] || [];

            if (__DEV__) {
                console.log('data init: ', data_posts);
                console.log('data_just_done_customer: ', data_just_done_customer);
            }

            setPermission(data_posts["permission"] || false);
            
            if (data_just_done_customer['post'] != null) {
                let list_all_items = [data_just_done_customer['post'] || []];
                list_items.forEach(item => {
                    if (item.id != _customer_id) {
                        list_all_items = list_all_items.concat(item);
                    }
                });
                setTaskItems(list_all_items);
            } else {
                setTaskItems(list_items);
            }

            setPagingParams(data_posts['paging'])
        })
            .catch(err => {
                console.log(`${err.message}`);

                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false))
    }


    /***
     * begin: react hook form
    */
    const { control, handleSubmit, reset, formState: { errors }, getValues, setValue, setFocus } = useForm();

    const onSubmit = data => {
        const data_submit = data;

        if (id != '') {
            objPersonModel.update(id, data_submit)
                .then((results) => {
                    const data: any = results.data || {};

                    if (__DEV__) {
                        console.log('data: ', data);
                    }

                    if (data.post != undefined && data.post.id != undefined) {
                        const obj_task: any = { ...data.post, 'checked': false };
                        let arr: any = [];
                        taskItems.forEach(el => {
                            let el_tg: any = el;
                            if (el_tg['id'] == obj_task.id) {
                                arr.push(obj_task);
                            } else {
                                arr.push(el);
                            }
                        });
                        setTaskItems(arr);

                        setVisibleDialog(true);
                        setDialogMessage(`${data.message}`);
                    } else {
                        setVisibleDialog(true);
                        setDialogMessage(`${data.message}`);
                    }

                    setOpenForm(false);
                    setOpenList(true);
                    setOpenButton(true);
                    setVisibleDialog(true);

                    reset();
                })
                .catch(err => {
                    setVisibleDialog(true);
                    setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
                })
                .finally(() => {
                    setID('');
                    setLoading(false);
                });
        } else {
            objPersonModel.create(data_submit)
                .then((results) => {
                    const data: any = results.data || {};

                    if (__DEV__) {
                        console.log('Data create:', data);
                    }

                    if (data.post != undefined && data.post.id != undefined) {
                        const obj_task: any = { ...data.post };
                        setTaskItems([obj_task, ...taskItems]);

                        setVisibleDialog(true);
                        setDialogMessage(`${data.message}`);
                    } else {
                        setVisibleDialog(true);
                        setDialogMessage(`${data.message}`);
                    }

                    setOpenForm(false);
                    setOpenList(true);
                    setOpenButton(true);
                    setVisibleDialog(true);

                    reset();
                })
                .catch(err => {
                    setVisibleDialog(true);
                    setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
                    setLoading(false);
                })
                .finally(() => {
                    setID('');
                    setLoading(false);
                });
        }
    };

    const myHandleSubmit = () => {
        // convert before submit

        let other_validate = true;
        fields.forEach((field) => {
            switch (field.Type) {
                case "calendar":
                    if (field.name == 'birthday') {
                        let d = getValues(field.child_date['date']);
                        if (parseInt(d) < 10) {
                            d = `0${parseInt(d)}`;
                        }
                        let m = getValues(field.child_date['month']);
                        if (parseInt(m) < 10) {
                            m = `0${parseInt(m)}`;
                        }
                        const y = getValues(field.child_date['year']);
                        const date_created = `${y}/${m}/${d} 00:00:00`;
                        setValue(field.name, date_created);

                        const validate = moment(`${m}/${d}/${y}`, 'MM/DD/YYYY', true).isValid();
                        if (!validate && d != '' && m != '' && y != '') {
                            setDialogMessage(`Tháng ${m}/${y} không có ngày ${d}!`)
                            setVisibleDialog(true);
                            setFocus(field.child_date['date']);

                            other_validate = false;
                        }
                    }
                    break;
                case "image":
                default:
                    break;
            }
        });

        if (other_validate) {
            handleSubmit(onSubmit)();
        }
    }

    const api_fields = [
        {
            name: "display_name",
            lable: LANG.Field.CustomerName,
            placeholder: LANG.Field.EnterFullname,
            rules: {
                required: true,
                pattern: false,
            },
            icon: {
                name: "info-circle",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
        },
        {
            name: "birthday",
            child_date: {
                'date': 'date_of_month',
                'month': 'month_of_year',
                'year': 'year_of_calendar'
            },
            lable: LANG.Field.Birthday,
            placeholder: LANG.Field.EnterBirthday,
            rules: {
                required: true,
                pattern: false,
            },
            icon: {
                name: "calendar",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
            Type: "calendar",
            subFieldHour: false,
            subFieldMinute: false,
        },
        {
            name: "sex",
            lable: LANG.Field.Sex,
            rules: {
                required: true,
                pattern: false,
            },
            icon: {
                name: "info-circle",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
            Type: "checkbox",
        },
        {
            name: "name",
            lable: LANG.Field.Phone,
            placeholder: LANG.Field.EnterPhone,
            rules: {
                required: LANG.Field.PhoneRequired,
                minLength: 9,
                maxLength: 11,
                pattern: "phone"
            },
            icon: {
                name: "phone",
                size: 25
            },
            keyboardType: "numeric",
            isPassword: false,
        },
        {
            name: "address",
            lable: LANG.Field.Address,
            placeholder: LANG.Field.EnterAddress,
            rules: {
                pattern: false,
            },
            icon: {
                name: "address-card",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
        },
        {
            name: "card",
            lable: LANG.Field.Card,
            placeholder: LANG.Field.EnterCard,
            rules: {
                pattern: false,
            },
            icon: {
                name: "address-card",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
        },
        {
            name: "email",
            lable: "Email",
            placeholder: LANG.Field.Email,
            rules: {
                // required: LANG.Field.EmailRequired,
                required: false,
                pattern: "email",
            },
            icon: {
                name: "envelope",
                size: 25
            },
            keyboardType: "email-address",
            isPassword: false,
        }
        ,
        {
            name: "other_info",
            lable: LANG.Field.OtherInfo,
            placeholder: LANG.Field.EnterOtherInfo,
            rules: {
                pattern: false,
            },
            icon: {
                name: "info-circle",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
            multiline: true
        },
        {
            name: "reset_password",
            lable: LANG.Field.ResetPassword,
            rules: {
                required: true,
                pattern: false,
            },
            icon: {
                name: "info-circle",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
            Type: "checkbox",
        },
        {
            name: "is_activated",
            lable: LANG.Field.IsActivated,
            rules: {
                required: true,
                pattern: false,
            },
            icon: {
                name: "info-circle",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
            Type: "checkbox",
        },
    ];

    const arr_validate = {
        validate_password: {
            matchesPreviousPassword: (value) => {
                const { password } = getValues();
                return password === value || LANG.PasswordRequiredValidate;
            }
        }
    };

    let column = {};
    let row = {};
    if (windowWidth <= 768) {
        column = stylesColumns.columnSmall;
        row = stylesColumns.rowSmall;
    } else if (windowWidth >= 769) {
        // column = stylesColumns.columnMedium;
        // row = stylesColumns.rowMedium;
    }

    const [fields, setFields] = React.useState([]);

    /***
      * end: react hook form
    */

    React.useEffect(() => {
        setFields(api_fields);

        const unsubscribe = navigation.addListener('focus', () => {
            // if screen is focused then reload
            console.log('Screen is focused member ' + Math.random());

            const { _customer_id } = route.params || [''];
            if (_customer_id != undefined) {
                console.log('_customer_id: ', _customer_id);
            }

            gets_init(_customer_id);
        });

        // cleanup
        return unsubscribe;
    }, [route]);


    return (
        <View style={styles.container}>
            <Header title={dialogTitle} view="chat" navigation={navigation} />

            <Dialog
                isVisible={visibleDialog}
                onBackdropPress={() => setVisibleDialog(!visibleDialog)}
            >
                <Dialog.Title title={dialogTitle} />
                <Text>{dialogMessage}</Text>
            </Dialog>

            <ScrollView
                contentContainerStyle={{
                    flexGrow: 1,
                }}
                keyboardShouldPersistTaps="handled"
            >
                {openForm && (
                    <View style={styles.box}>
                        <View style={row}>
                            {fields.map((field, key) => {
                                switch (field.Type) {
                                    case "calendar":
                                        return (
                                            <View key={key} style={column}>
                                                <View style={{ marginBottom: 20 }}>
                                                    <Text style={styles.textCalendar}>{field.lable}:</Text>
                                                </View>

                                                <MyContext.Provider value={{
                                                    setValue,
                                                    getValues,
                                                    reset,
                                                }}>
                                                    <MyControlSparateCalendar
                                                        child_date={field.child_date}
                                                        control={control}
                                                        errors={errors}
                                                        default_fulldate={getValues(field.name)}
                                                    />
                                                </MyContext.Provider>
                                            </View>
                                        );
                                        break;
                                    case "checkbox":
                                        return (
                                            <View key={key} style={column}>
                                                <Text style={styles.text}>{field.lable}:</Text>
                                                <MyContext.Provider value={{ setValue }}>
                                                    <MyControlCheckbox
                                                        value={getValues(`${field.name}`)}
                                                        field={field}
                                                        control={control}
                                                    />
                                                </MyContext.Provider>
                                            </View>
                                        );
                                        break;
                                    default:
                                        return (
                                            <View key={key} style={column}>
                                                <Text style={styles.text}> {field.lable}:</Text>
                                                <MyControlInputText
                                                    action={id != '' ? 'edit' : 'add'}
                                                    field={field}
                                                    control={control}
                                                    errors={errors}
                                                    pattern={field.rules.pattern != undefined ? arr_pattern[field.rules.pattern] : ''}
                                                    validate={field.rules.validate != undefined ? arr_validate[field.rules.validate] : ''}
                                                />
                                                {field.confirmField != null && (
                                                    <>
                                                        <Text style={styles.text}>{field.confirmField.lable}:</Text>
                                                        <MyControlInputText
                                                            action={id != '' ? 'edit' : 'add'}
                                                            field={field.confirmField}
                                                            control={control}
                                                            errors={errors}
                                                            pattern={field.confirmField.rules.pattern != undefined ? arr_pattern[field.confirmField.rules.pattern] : ''}
                                                            validate={field.confirmField.rules.validate != undefined ? arr_validate[field.confirmField.rules.validate] : ''}
                                                        />
                                                    </>
                                                )}
                                            </View>
                                        );
                                        break;
                                }
                            })}
                        </View>

                        <View style={{ flexDirection: "row", alignSelf: "center", marginTop: 30, marginBottom: 45 }}>
                            {isLoading == true ? (
                                <IsLoadingData title={LANG.Updating} />
                            ) : (
                                <>
                                    <ButtonCancel onPress={() => { setID(''); setOpenForm(false); setOpenButton(true); setOpenList(true); }} />
                                    <ButtonSave onPress={() => myHandleSubmit()} />
                                </>
                            )}
                        </View>
                    </View>
                )}

                {openList && (
                    <>
                        <View style={{ flexDirection: "row", alignSelf: "center", marginTop: 12 }}>
                            <MyContext.Provider value={{
                                Search,
                                searchName,
                                setSearchName,
                            }}>
                                <MyInputSearchName />
                            </MyContext.Provider>

                            <MyContext.Provider value={{
                                visibleSearchCalendar,
                                setVisibleSearchCalendar,

                                fromDate,
                                setFromDate,
                            }}>
                                <MySelectCalendarSearch />
                            </MyContext.Provider>

                            <ButtonSearch onPress={() => Search(searchName, fromDate)} />
                            <ButtonRefresh onPress={() => {
                                setSearchName('');
                                setFromDate('');
                                Search('', '');
                            }} />
                        </View>

                        <MyContext.Provider value={{
                            fromDate,
                            setFromDate,
                        }}>
                            <MyDisplayFromToDate />
                        </MyContext.Provider>

                        {isLoading ? (
                            <IsLoadingData />
                        ) : (
                            <MyContext.Provider value={
                                {
                                    taskItems,
                                    checkItem,

                                    pagingParams,
                                    Paging,

                                    currentPage,
                                    setCurrentPage,

                                    handleActivated
                                }}>
                                <MyListingCustomerStaff navigation={navigation} />
                                <MyPaging />
                            </MyContext.Provider>
                        )}

                    </>
                )}

            </ScrollView>

            {permission == true && <>
                {openButton && (
                    <View style={{ flexDirection: "row", alignSelf: "center", "position": "absolute", bottom: 5 }}>
                        <ButtonAdd onPress={() => {
                            reset();
                            setOpenForm(true); setOpenButton(false); setOpenList(false);
                        }}
                        />
                        <ButtonEdit onPress={() => editItem()} />
                        <ButtonDelete onPress={() => deleteItems()} />
                    </View>
                )}

                <View style={stylesButtons.open_close}>
                    {visible == true ? (
                        <OpenButtons onPress={() => { setOpenButton(false); setVisible(false); }} />
                    ) : (
                        <CloseButtons onPress={() => { setOpenButton(true); setVisible(true); }} />
                    )}
                </View>
            </>}
            

        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#E8EAED',
    },
    box: {
        flex: 1,
        margin: 12,
        marginTop: 6,
        marginBottom: 6,
        padding: 20
    },
    containerStyle:
    {
        marginTop: 6,
        width: '100%',
    },
    text: {
        marginTop: 18,
        marginBottom: Platform.OS == 'web' ? -12 : -24,
        color: '#000',
        alignSelf: "flex-start",
        paddingLeft: 12
    },
    textCalendar: {
        marginTop: 18,
        marginBottom: Platform.OS == 'web' ? -12 : -12,
        color: '#000',
        alignSelf: "flex-start",
        paddingLeft: 12
    },
    input: {
        maxWidth: 500,
        alignSelf: "center",
        borderColor: "#000",
        borderWidth: 1,
    },
    checkbox: {
        color: '#000',
        marginBottom: Platform.OS == 'web' ? -12 : -24,
        alignSelf: "flex-start",
        paddingLeft: 12
    },
    error: {
        color: "#f00",
        marginTop: Platform.OS == 'web' ? -8 : -20,
        alignSelf: "flex-start",
        paddingLeft: 12
    }
});

export default CustomerStaff;
