import React, { useState } from "react";
import { StyleSheet, Text, View, ScrollView } from 'react-native';
import { Dialog } from 'react-native-elements';

import { Header } from '../../components/header';

import { ButtonSearch } from '../../components/mybuttons'
import { MyPaging, MySelectCalendarSearch, MyDisplayFromToDate, BoxOpenShortMenu, MyListingCustomerThemSelf } from "../../components/myboxs";
import { IsLoadingData } from "../../components/myother";

import { MyContext } from '../../context/mycontext';
import { MyControlShortMenuCustomer } from "../../components/mycontrolinputs";

import { PaymentModel } from "../../models/models"
import { MyInputSearchName } from "../../components/myinputs";
import { CUSTOMER_ID_THEMSELF, LANG } from "../../config/config";
const dialogTitle = LANG.Deposit;


function PaymentCustomer({ route, navigation }) {
    const [isLoading, setLoading] = useState<boolean>(false);

    const [taskItems, setTaskItems] = useState([]);

    const [pagingParams, setPagingParams] = useState({});
    const [currentPage, setCurrentPage] = React.useState(1);

    const [searchName, setSearchName] = React.useState('');
    const [visibleSearchCalendar, setVisibleSearchCalendar] = React.useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const [openList, setOpenList] = React.useState(true);
    const [openForm, setOpenForm] = React.useState(false);
    const [openButton, setOpenButton] = React.useState(true);

    const [visible, setVisible] = React.useState(true);
    const [visibleDialog, setVisibleDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');

    const [visibleShortMenu, setVisibleShortMenu] = React.useState(false);

    // fields of table
    const [id, setID] = useState('');
    // same as birthday

    function Paging(page = 1) {
        setLoading(true);
        PaymentModel.gets(customerID, userFamilyID, page)
            .then((results) => {
                const data: any = results["data"] || [];
                setTaskItems(data["posts"] || []);
                setPagingParams(data['paging'])
            })
            .catch(err => {
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false));
    }

    function Search(search_name = '', from_date = '', to_date = '') {
        setLoading(true);
        PaymentModel.gets(customerID, userFamilyID, 1, search_name, from_date, to_date)
            .then((results) => {
                const data: any = results["data"] || [];
                setTaskItems(data["posts"] || []);
                setPagingParams(data['paging'])
                setCurrentPage(1);
            })
            .catch(err => {
                console.log(err);
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false));
    };

    async function gets_init(_customer_id = '', _user_family_id = '') {
        const p_posts = PaymentModel.gets(_customer_id, _user_family_id, 1, '');
        setLoading(true);
        Promise.all([p_posts]).then((values) => {
            const data_posts = values[0]["data"] || [];

            if (__DEV__) {
                console.log('data_posts:', data_posts);
            }

            setTaskItems(data_posts["posts"] || []);
            setPagingParams(data_posts["paging"]);
        })
            .catch(err => {
                console.log(`${err.message}`);

                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false))
    }

    React.useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            console.log('Init: Screen is focused buy order: ' + Math.random());

            gets_init(CUSTOMER_ID_THEMSELF);
            setCustomerID(CUSTOMER_ID_THEMSELF);

            setOpenForm(false);
            setOpenButton(true);
            setOpenList(true);
        });

        return () => {
            // cleanup
            // Execute before the next effect or unmount
        };
    }, []);

    const [customerID, setCustomerID] = React.useState('');
    const [customerName, setCustomerName] = React.useState('');
    const [userFamilyID, setUserFamilyID] = React.useState('');

    React.useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            // if screen is focused then reload
            console.log('Screen is focused buy order: ' + Math.random());

            const { _customer_id, _customer_name, _user_family_id } = route.params || [CUSTOMER_ID_THEMSELF, '', ''];
            if (_customer_id != undefined) {
                console.log('_customer_id: ', _customer_id);
                console.log('_customer_name: ', _customer_name);
                console.log('_user_family_id: ', _user_family_id);

                gets_init(_customer_id, _user_family_id);

                setCustomerID(_customer_id);
                setCustomerName(_customer_name);
                setUserFamilyID(_user_family_id);
            }
        });

        // cleanup
        return unsubscribe;
    }, [route]);

    return (
        <View style={styles.container}>
            <Header title={dialogTitle} view="chat" navigation={navigation} />

            <Dialog
                isVisible={visibleDialog}
                onBackdropPress={() => setVisibleDialog(!visibleDialog)}
            >
                <Dialog.Title title={dialogTitle} />
                <Text>{dialogMessage}</Text>
            </Dialog>

            {visibleShortMenu && (
                <MyContext.Provider value={
                    {
                        visibleShortMenu,
                        setVisibleShortMenu,

                    }}>
                    <MyControlShortMenuCustomer
                        navigation={navigation}
                        customer_id={customerID}
                        customer_name={customerName}
                    />
                </MyContext.Provider>
            )}

            <ScrollView
                contentContainerStyle={{
                    flexGrow: 1,
                }}
                keyboardShouldPersistTaps="handled"
            >
                {openList && (
                    <>
                        <MyContext.Provider value={{
                            setVisibleShortMenu,
                        }}>
                            <BoxOpenShortMenu
                                customerName={customerName}
                                customerID={customerID}
                                navigation={navigation}
                            />
                        </MyContext.Provider>
                        <View style={{ flexDirection: "row", alignSelf: "center", marginTop: 12 }}>
                            <MyContext.Provider value={{
                                Search,
                                searchName,
                                setSearchName,
                            }}>
                                <MyInputSearchName />
                            </MyContext.Provider>

                            <MyContext.Provider value={{
                                visibleSearchCalendar,
                                setVisibleSearchCalendar,

                                fromDate,
                                setFromDate,

                                toDate,
                                setToDate,
                            }}>
                                <MySelectCalendarSearch />
                            </MyContext.Provider>

                            <ButtonSearch onPress={() => Search(searchName, fromDate, toDate)} />
                        </View>

                        <MyContext.Provider value={{
                            fromDate,
                            setFromDate,

                            toDate,
                            setToDate,
                        }}>
                            <MyDisplayFromToDate />
                        </MyContext.Provider>

                        {isLoading ? (
                            <IsLoadingData />
                        ) : (
                            <MyContext.Provider value={
                                {
                                    taskItems,

                                    pagingParams,
                                    Paging,

                                    currentPage,
                                    setCurrentPage,
                                }}>
                                <MyListingCustomerThemSelf />
                                <MyPaging />
                            </MyContext.Provider>
                        )}

                    </>
                )}

            </ScrollView >
        </View >
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#E8EAED',
    }
});

export default PaymentCustomer;
