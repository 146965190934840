import * as React from "react";
import { StyleSheet, Text, View, AppState } from "react-native";

import { NavigationContainer } from "@react-navigation/native";

import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItemList,
} from '@react-navigation/drawer';


import AsyncStorage from "@react-native-async-storage/async-storage";

import LoginScreen3 from "./src/views/login/screen3";
import Logout from "./src/views/login/logout";
import ChangePassword from "./src/views/login/changepassword";

import ListNews from "./src/views/common/listnews";
import ShowNews from "./src/views/common/shownews";
import ClassRoom from "./src/views/common/classroom";
import ListCustomer from "./src/views/common/listcustomer";
import ListSupplier from "./src/views/common/listsupplier";
import ListNewMessage from "./src/views/common/listNewMessage";
import ListNewChat from "./src/views/common/listNewChat";

import ReportInternal from "./src/views/common/report.internal";
import ReportListPayment from "./src/views/common/reportListPayment";
import ReportListSaleService from "./src/views/common/reportListSaleService";
import ReportListSaleOrder from "./src/views/common/reportListSaleOrder";

import SaleService from "./src/views/common/listSaleService";
import ServiceCategory from "./src/views/common/listservicecategory";
import Service from "./src/views/common/listservice";

import ProductCategory from "./src/views/common/listproductcategory";
import Product from "./src/views/common/listproduct";
import BuyOrder from "./src/views/common/listBuyOrder";
import BuyOrderItem from "./src/views/common/listBuyOrderItem";
import SaleOrder from "./src/views/common/listSaleOrder";
import SaleOrderItem from "./src/views/common/listSaleOrderItem";
import Payment from "./src/views/common/listPayment";

import HomeSuperadmin from "./src/views/superadmin/home.superadmin";
import MemberSuperAdmin from "./src/views/superadmin/admin.superadmin";
import TestSuperAdmin from "./src/views/superadmin/test.hookform.superadmin";

import HomeAdmin from "./src/views/admin/home.admin";
import ListDoctor from "./src/views/admin/listdoctor";
import ListStaff from "./src/views/admin/liststaff";
import ListManager from "./src/views/admin/listmanager";

import HomeManager from "./src/views/manager/home.manager";

import HomeDoctor from "./src/views/doctor/home.doctor";
import BookingDoctor from "./src/views/doctor/booking.doctor";

import HomeStaff from "./src/views/staff/home.staff";
import BookingStaff from "./src/views/staff/booking.staff";
import CustomerStaff from "./src/views/staff/customer.staff";
import FamilyCustomerStaff from "./src/views/staff/familyCustomer.staff";
import ChatStaff from "./src/views/staff/chat.staff";

import HomeCustomer from "./src/views/customer/home.customer";
import BookingCustomer from "./src/views/customer/booking.customer";
import SaleServiceCustomer from "./src/views/customer/saleService.customer";
import SaleOrderCustomer from "./src/views/customer/saleOrder.customer";
import PaymentCustomer from "./src/views/customer/payment.customer";
import FamilyCustomerCustomer from "./src/views/customer/familyCustomer.customer";
import ChatCustomer from "./src/views/customer/chat.customer";
import ReportCustomer from "./src/views/common/report.customer";

import { AuthContext, ThemeContext } from "./src/context/mycontext";

import { COLLECTION_CHAT_READ, COLLECTION_SCHEDULE_READ, firebaseConfig, LANG } from "./src/config/config";

import * as firebase from 'firebase'
import 'firebase/firestore'
import IntroScreen from "./src/views/login/intro";

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig)
}
const db = firebase.firestore()

function SplashScreen() {
  return (
    <View>
      <Text>Loading...</Text>
    </View>
  );
}

const Drawer = createDrawerNavigator();

function reducer(prevState, action) {
  switch (action.type) {
    case "RESTORE_TOKEN":
      return {
        ...prevState,
        isLoading: false,
        jwt_token: action.token,
        userRole: action.role,
      };
    case "SIGN_IN":
      return {
        ...prevState,
        isSignout: false,
        jwt_token: action.token,
        userRole: action.role,
      };
    case "SIGN_OUT":
      return {
        ...prevState,
        isSignout: true,
        jwt_token: null,
        userRole: "",
      };
  }
}

export default function App({ navigation }) {
  const [state, dispatch] = React.useReducer(reducer, {
    isLoading: true,
    isSignout: false,
    jwt_token: null,
    role: "",
  });

  React.useEffect(() => {
    // Fetch the token from storage then navigate to our appropriate place
    const bootstrapAsync = async () => {
      let jwt_token = null;
      let user_role = null;

      await AsyncStorage.getItem("jwt_token").then((value) => {
        if (value == null || value == "") {
          jwt_token = "";
        } else {
          jwt_token = value;
        }
      });

      await AsyncStorage.getItem("user_role").then((value) => {
        if (value == null || value == "") {
          user_role = "";
        } else {
          user_role = value;
        }
      });

      // After restoring token, we may need to validate it in production apps

      // This will switch to the App screen or Auth screen and this loading
      // screen will be unmounted and thrown away.
      dispatch({ type: "RESTORE_TOKEN", token: jwt_token, role: user_role });
    };

    bootstrapAsync();

    return () => {
      // cleanup
      // Execute before the next effect or unmount
    };
  }, []);

  const authContext = React.useMemo(
    () => ({
      signIn: async (data) => {
        // In a production app, we need to send some data (usually username, password) to server and get a token
        // We will also need to handle errors if sign in failed
        // After getting token, we need to persist the token using `SecureStore` or any other encrypted storage
        // In the example, we'll use a dummy token

        dispatch({
          type: "SIGN_IN",
          // token: "dummy-auth-token",
          token: data.token,
          role: data.role,
        });
      },
      signOut: () => dispatch({ type: "SIGN_OUT" }),
      signUp: async (data) => {
        // In a production app, we need to send user data to server and get a token
        // We will also need to handle errors if sign up failed
        // After getting token, we need to persist the token using `SecureStore` or any other encrypted storage
        // In the example, we'll use a dummy token

        dispatch({ type: "SIGN_IN", token: "dummy-auth-token" });
      },
    }),
    []
  );

  const [theme, setTheme] = React.useState("light");
  const [numMessage, setNumMessage] = React.useState(0);
  const [numSchedule, setNumSchedule] = React.useState(0);

  // Begin: api gets message
  // const appState = React.useRef(AppState.currentState);
  // const _handleAppStateChange = nextAppState => {
  //   if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
  //     // API call only the first
  //     if (__DEV__) {
  //       console.log('call only the first');
  //     }
  //   }

  //   appState.current = nextAppState;
  //   if (__DEV__) {
  //     console.log('AppState', appState.current);
  //   }
  // };

  // React.useEffect(() => {
  //   AppState.addEventListener('change', _handleAppStateChange);
  //   return () => {
  //     AppState.removeEventListener('change', _handleAppStateChange);
  //   };
  // }, []);

  // React.useEffect(() => {
  //   if (appState.current == 'active') {
  //     const timerRefresh = 15000;
  //     const countTimer = setInterval(() => {
  //       setNumMessage((prevCount) => prevCount + 1);
  //       // API call after timerRefresh
  //     }, timerRefresh);

  //     return function cleanup() {
  //       clearInterval(countTimer);
  //     };
  //   }
  // }, []);
  // // End: api gets message

  // Begin: firebase get news message
  const chatsRef = db.collection(COLLECTION_CHAT_READ);
  const schedulesRef = db.collection(COLLECTION_SCHEDULE_READ);

  React.useEffect(() => {
    Promise.all([AsyncStorage.getItem('user_role'), AsyncStorage.getItem('user_id')]).then((values) => {
      const _user_role = values[0];
      const _user_id = values[1];
      let unsubscribe, unschedule;

      switch (_user_role) {
        case 'customer':
          unsubscribe = chatsRef.onSnapshot((querySnapshot) => {
            querySnapshot
              .docChanges()
              .map(({ doc }) => {
                const obj_data = doc.data();
                let count = 0;
                for (var key in obj_data) {
                  if (`room_${_user_id}` == key && (obj_data[key]['customer_read'] || 0) == 0) {
                    count++;
                  }
                }
                setNumMessage(count);
              })
          });

          unschedule = schedulesRef.onSnapshot((querySnapshot) => {
            querySnapshot
              .docChanges()
              .map(({ doc }) => {
                const obj_data = doc.data();
                let count = 0;
                for (var key in obj_data) {
                  if (`room_${_user_id}` == key && (obj_data[key]['customer_read'] || 0) == 0) {
                    count++;
                  }
                }
                setNumSchedule(count);
              })
          });

          return () => { unsubscribe(); unschedule(); };
          break;
        default:
          unsubscribe = chatsRef.onSnapshot((querySnapshot) => {
            querySnapshot
              .docChanges()
              .map(({ doc }) => {
                const obj_data = doc.data();
                let count = 0;
                for (var key in obj_data) {
                  if ((obj_data[key]['staff_read'] || 0) == 0) {
                    count++;
                  }
                }
                setNumMessage(count);
              })
          });

          unschedule = schedulesRef.onSnapshot((querySnapshot) => {
            querySnapshot
              .docChanges()
              .map(({ doc }) => {
                const obj_data = doc.data();
                let count = 0;
                for (var key in obj_data) {
                  if ((obj_data[key]['staff_read'] || 0) == 0) {
                    count++;
                  }
                }
                setNumSchedule(count);
              })
          });

          return () => { unsubscribe(); unschedule(); };
      }
    });
  }, []);
  // End: firebase get news message

  const [isActivated, setIsActivated] = React.useState(false);
  React.useEffect(() => {
      const asyncParamsForIsActivate = async () => {
        await AsyncStorage.getItem('is_activated').then((value) => {
          if(value=='true'){
              setIsActivated(true);
          } else {
              setIsActivated(false);
          }
        });
      }

      asyncParamsForIsActivate();
  }, []);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <AuthContext.Provider
        value={{
          ...authContext, token: state.jwt_token, role: state.userRole, isActivated, setIsActivated, numMessage: (numMessage + numSchedule)
        }}
      >
        <NavigationContainer>
          <Drawer.Navigator
            drawerContentOptions={{
              activeTintColor: '#000000',
              itemStyle: { padding: 0 },
            }}
            drawerContent={(props) => {
              const filteredProps = {
                ...props,
                state: {
                  ...props.state,
                  routeNames: props.state.routeNames.filter(
                    // To hide single option
                    // (routeName) => routeName !== 'BuyOrderItem',
                    // To hide multiple options you can add & condition
                    (routeName) => {
                      !['BuyOrderItem', 'SaleService', 'SaleOrderItem', 'SaleOrder', 'Payment', 'BookingDoctor', 'BookingStaff', 'FamilyCustomerStaff', 'BuyOrder', 'ReportListPayment', 'ReportListSaleService', 'ReportListSaleOrder', 'ListNewMessage', 'ListNewChat', 'ChatStaff', 'ChatCustomer'].includes(routeName);
                    }
                  ),
                  routes: props.state.routes.filter(
                    (route) => !['BuyOrderItem', 'SaleService', 'SaleOrderItem', 'SaleOrder', 'Payment', 'BookingDoctor', 'BookingStaff', 'FamilyCustomerStaff', 'BuyOrder', 'ReportListPayment', 'ReportListSaleService', 'ReportListSaleOrder', 'ListNewMessage', 'ListNewChat', 'ChatStaff', 'ChatCustomer'].includes(route.name)
                  ),
                },
              };
              return (
                <DrawerContentScrollView {...filteredProps}>
                  <DrawerItemList {...filteredProps} />
                </DrawerContentScrollView>
              );
            }}>
            {state.isLoading ? (
              // We haven't finished checking for the token yet
              <Drawer.Screen name="Splash" component={SplashScreen} />
            ) : state.jwt_token == null || state.jwt_token == "" ? (
              // No token found, user isn't signed in
              <>
                <Drawer.Screen name="Intro" component={IntroScreen} />
                <Drawer.Screen
                  name="Login"
                  component={LoginScreen3}
                  options={{
                    headerShown: false,
                    title: LANG.Login,
                    // When logging out, a pop animation feels intuitive
                    animationTypeForReplace: state.isSignout ? "pop" : "push",
                  }}
                />
              </>
            ) : // User is signed in
              state.userRole == "superadmin" ? (
                <>
                  <Drawer.Screen
                    name="HomeSuperadmin"
                    component={HomeSuperadmin}
                    options={{
                      headerShown: false,
                      title: "Trang Chủ Superadmin",
                    }}
                  />
                  <Drawer.Screen
                    name="MemberSuperAdmin"
                    component={MemberSuperAdmin}
                    options={{
                      headerShown: false,
                      title: "Quản Lý Admin",
                    }}
                  />
                  <Drawer.Screen
                    name="TestSuperAdmin"
                    component={TestSuperAdmin}
                    options={{
                      headerShown: false,
                      title: "supperadmin test",
                    }}
                  />
                </>
              ) : state.userRole == "admin" ? (
                <>
                  <Drawer.Screen
                    name="HomeAdmin"
                    component={HomeAdmin}
                    options={{
                      headerShown: false,
                      title: LANG.HomePage,
                    }}
                  />
                  <Drawer.Screen
                    name="ListManager"
                    component={ListManager}
                    options={{
                      headerShown: false,
                      title: LANG.Manager,
                    }}
                  />
                  <Drawer.Screen
                    name="ListDoctor"
                    component={ListDoctor}
                    options={{
                      headerShown: false,
                      title: LANG.Doctor,
                    }}
                  />
                  <Drawer.Screen
                    name="ListStaff"
                    component={ListStaff}
                    options={{
                      headerShown: false,
                      title: LANG.Staff,
                    }}
                  />
                  <Drawer.Screen
                    name="ListCustomer"
                    component={ListCustomer}
                    options={{
                      headerShown: false,
                      title: LANG.Customer,
                    }}
                  />
                  <Drawer.Screen
                    name="ClassRoom"
                    component={ClassRoom}
                    options={{
                      headerShown: false,
                      title: LANG.Department,
                    }}
                  />
                </>
              ) : state.userRole == "manager" ? (
                <>
                  <Drawer.Screen
                    name="HomeManager"
                    component={HomeManager}
                    options={{
                      headerShown: false,
                      title: LANG.HomePage,
                    }}
                  />
                  <Drawer.Screen
                    name="CustomerStaff"
                    component={CustomerStaff}
                    options={{
                      headerShown: false,
                      title: LANG.Customer,
                    }}
                  />
                  <Drawer.Screen
                    name="ListSupplier"
                    component={ListSupplier}
                    options={{
                      headerShown: false,
                      title: LANG.Supplier,
                    }}
                  />
                  <Drawer.Screen
                    name="Service"
                    component={Service}
                    options={{
                      headerShown: false,
                      title: LANG.Service,
                    }}
                  />
                  <Drawer.Screen
                    name="Product"
                    component={Product}
                    options={{
                      headerShown: false,
                      title: LANG.Product,
                    }}
                  />
                  <Drawer.Screen
                    name="ServiceCategory"
                    component={ServiceCategory}
                    options={{
                      headerShown: false,
                      title: LANG.ServiceCategory,
                    }}
                  />
                  <Drawer.Screen
                    name="ProductCategory"
                    component={ProductCategory}
                    options={{
                      headerShown: false,
                      title: LANG.ProductCategory,
                    }}
                  />
                </>
              ) : state.userRole == "doctor" ? (
                <>
                  <Drawer.Screen
                    name="HomeDoctor"
                    component={HomeDoctor}
                    options={{
                      headerShown: false,
                      title: LANG.HomePage,
                    }}
                  />
                  <Drawer.Screen
                    name="ListCustomer"
                    component={ListCustomer}
                    options={{
                      headerShown: false,
                      title: LANG.Customer,
                    }}
                  />
                </>
              ) : state.userRole == "staff" ? (
                <>
                  <Drawer.Screen
                    name="HomeStaff"
                    component={HomeStaff}
                    options={{
                      headerShown: false,
                      title: LANG.HomePage,
                    }}
                  />
                  <Drawer.Screen
                    name="CustomerStaff"
                    component={CustomerStaff}
                    options={{
                      headerShown: false,
                      title: LANG.Customer,
                    }}
                  />
                  <Drawer.Screen
                    name="ListSupplier"
                    component={ListSupplier}
                    options={{
                      headerShown: false,
                      title: LANG.Supplier,
                    }}
                  />
                  <Drawer.Screen
                    name="Service"
                    component={Service}
                    options={{
                      headerShown: false,
                      title: LANG.Service,
                    }}
                  />
                  <Drawer.Screen
                    name="Product"
                    component={Product}
                    options={{
                      headerShown: false,
                      title: LANG.Product,
                    }}
                  />
                  <Drawer.Screen
                    name="ServiceCategory"
                    component={ServiceCategory}
                    options={{
                      headerShown: false,
                      title: LANG.ServiceCategory,
                    }}
                  />
                  <Drawer.Screen
                    name="ProductCategory"
                    component={ProductCategory}
                    options={{
                      headerShown: false,
                      title: LANG.ProductCategory,
                    }}
                  />
                </>
              ) : (
                state.userRole == "customer" && (
                  <>
                    <Drawer.Screen
                      name="HomeCustomer"
                      component={HomeCustomer}
                      options={{
                        headerShown: false,
                        title: LANG.HomePage,
                      }}
                    />
                    {isActivated && <Drawer.Screen
                      name="SaleServiceCustomer"
                      component={SaleServiceCustomer}
                      options={{
                        headerShown: false,
                        title: LANG.HealthCheck,
                      }}
                    />}
                    <Drawer.Screen
                      name="BookingCustomer"
                      component={BookingCustomer}
                      options={{
                        headerShown: false,
                        title: LANG.CalendarAppointment,
                      }}
                    />
                    {isActivated && <Drawer.Screen
                      name="SaleOrderCustomer"
                      component={SaleOrderCustomer}
                      options={{
                        headerShown: false,
                        title: LANG.BuyMedicine,
                      }}
                    />}
                    {isActivated && <Drawer.Screen
                      name="PaymentCustomer"
                      component={PaymentCustomer}
                      options={{
                        headerShown: false,
                        title: LANG.Deposit,
                      }}
                    />}
                    <Drawer.Screen
                      name="FamilyCustomerCustomer"
                      component={FamilyCustomerCustomer}
                      options={{
                        headerShown: false,
                        title: LANG.HomeFamily,
                      }}
                    />
                  </>
                )
              )}

            {state.userRole != "customer" && (
              <>
                <Drawer.Screen
                  name="ListNews"
                  component={ListNews}
                  options={{
                    headerShown: false,
                    title: LANG.PostNews,
                  }}
                />
              </>
            )}

            <Drawer.Screen
              name="ShowNews"
              component={ShowNews}
              options={{
                headerShown: false,
                title: LANG.ReadNews,
              }}
            />
            {state.userRole == "customer" ? (
              isActivated && <Drawer.Screen
                name="ReportCustomer"
                component={ReportCustomer}
                options={{
                  headerShown: false,
                  title: LANG.Report,
                }}
              />              
            ) : (
              <Drawer.Screen
                name="ReportInternal"
                component={ReportInternal}
                options={{
                  headerShown: false,
                  title: LANG.Report,
                }}
              />
            )}
            <Drawer.Screen
              name="ChangePassword"
              component={ChangePassword}
              options={{
                headerShown: false,
                title: LANG.ChangePassword,
              }}
            />
            <Drawer.Screen
              name="Logout"
              component={Logout}
              options={{
                headerShown: false,
                title: LANG.Logout,
              }}
            />
            {/* Begin: hidden menu */}
            <Drawer.Screen
              name="SaleService"
              component={SaleService}
              options={{
                headerShown: false,
                title: LANG.SellService,
              }}
            />
            <Drawer.Screen
              name="SaleOrder"
              component={SaleOrder}
              options={{
                headerShown: false,
                title: LANG.SellProduct,
              }}
            />
            <Drawer.Screen
              name="SaleOrderItem"
              component={SaleOrderItem}
              options={{
                headerShown: false,
                title: "Chọn sản phẩm để bán",
              }}
            />
            <Drawer.Screen
              name="BuyOrderItem"
              component={BuyOrderItem}
              options={{
                headerShown: false,
                title: "Chọn sản phẩm để nhập",
              }}
            />
            <Drawer.Screen
              name="Payment"
              component={Payment}
              options={{
                headerShown: false,
                title: "Thành Viên Ký Quỹ",
              }}
            />
            <Drawer.Screen
              name="BookingDoctor"
              component={BookingDoctor}
              options={{
                headerShown: false,
                title: "Lịch Khám",
              }}
            />
            <Drawer.Screen
              name="BookingStaff"
              component={BookingStaff}
              options={{
                headerShown: false,
                title: "Lịch Khám",
              }}
            />
            <Drawer.Screen
              name="FamilyCustomerStaff"
              component={FamilyCustomerStaff}
              options={{
                headerShown: false,
                title: "Người nhà",
              }}
            />
            <Drawer.Screen
              name="BuyOrder"
              component={BuyOrder}
              options={{
                headerShown: false,
                title: "Nhập hàng",
              }}
            />
            <Drawer.Screen
              name="ReportListPayment"
              component={ReportListPayment}
              options={{
                headerShown: false,
                title: LANG.Report,
              }}
            />
            <Drawer.Screen
              name="ReportListSaleService"
              component={ReportListSaleService}
              options={{
                headerShown: false,
                title: LANG.Report,
              }}
            />
            <Drawer.Screen
              name="ReportListSaleOrder"
              component={ReportListSaleOrder}
              options={{
                headerShown: false,
                title: LANG.Report,
              }}
            />
            <Drawer.Screen
              name="ListNewMessage"
              component={ListNewMessage}
              options={{
                headerShown: false,
                title: 'List thông báo',
              }}
            />
            <Drawer.Screen
              name="ListNewChat"
              component={ListNewChat}
              options={{
                headerShown: false,
                title: 'List chat mới nhất',
              }}
            />
            <Drawer.Screen
              name="ChatStaff"
              component={ChatStaff}
              options={{
                headerShown: false,
                title: LANG.Chat,
              }}
            />
            <Drawer.Screen
              name="ChatCustomer"
              component={ChatCustomer}
              options={{
                headerShown: false,
                title: LANG.Chat,
              }}
            />
            {/* End: hidden menu */}
          </Drawer.Navigator>
        </NavigationContainer>
      </AuthContext.Provider>
    </ThemeContext.Provider>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    backgroundColor: "red",
  },
});
