import React, { useState } from "react";
import { StyleSheet, Text, View, ScrollView, Platform } from 'react-native';
import { CheckBox, Dialog } from 'react-native-elements';

import moment from 'moment'

import { Header } from '../../components/header';

import { ButtonAdd, ButtonCancel, ButtonDelete, ButtonEdit, ButtonSave, ButtonSearch, CloseButtons, OpenButtons } from '../../components/mybuttons'
import { stylesButtons, stylesColumns } from "../../config/mystyles";
import { checkSingleItem, getsMyItemChecked } from "../../config/myfunc";
import { MyPaging, MyListingMember } from "../../components/myboxs";
import { IsLoadingData } from "../../components/myother";

import { MyContext } from '../../context/mycontext';
import { arr_pattern, MyControlCheckbox, MyControlInputText } from "../../components/mycontrolinputs";
import { LANG, windowWidth } from "../../config/config";

import { useForm } from "react-hook-form";

import { PersonModel } from "../../models/models"
import { MyInputSearchName } from "../../components/myinputs";
let objPersonModel = new PersonModel('manager');
const dialogTitle = LANG.Manager;

function ListManager({ route, navigation }) {
    const [isLoading, setLoading] = useState<boolean>(false);

    const [taskItems, setTaskItems] = useState([]);

    const [pagingParams, setPagingParams] = useState({});
    const [currentPage, setCurrentPage] = React.useState(1);

    const [searchName, setSearchName] = React.useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const [openList, setOpenList] = React.useState(true);
    const [openForm, setOpenForm] = React.useState(false);
    const [openButton, setOpenButton] = React.useState(true);

    const [visible, setVisible] = React.useState(true);
    const [visibleDialog, setVisibleDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');

    const [isChangePassword, setChangePassword] = React.useState(false);

    // fields of table
    const [id, setID] = useState('');
    // same as birthday

    function checkItem(item: any) {
        const arr = checkSingleItem(taskItems, item);
        setTaskItems(arr);

        setOpenButton(true);
        setVisible(true);
    }

    function deleteItems() {
        let arr: any = getsMyItemChecked(taskItems);

        if (arr.length == 0) {
            setVisibleDialog(true);
            setDialogMessage(LANG.ChooseAtLeastOneToDelete);
            return;
        }

        objPersonModel.delete_arr({ 'ids': arr })
            .then((results) => {
                const posts_reload = results.data.posts_reload || {};
                if (posts_reload.posts != null) {
                    setTaskItems(posts_reload.posts);
                } else if (results.data.posts != undefined) {
                    setTaskItems(results.data.posts);
                }

                setVisibleDialog(true);
                setDialogMessage(LANG.DeleteSuccessfully);
            })
            .catch(err => {
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            });
    }

    function editItem() {
        let arr: any = getsMyItemChecked(taskItems);
        const lastItem = arr.pop();

        if (lastItem == undefined) {
            setVisibleDialog(true);
            setDialogMessage(LANG.ChooseAtLeastOne);
        } else {
            objPersonModel.get(lastItem)
                .then((results) => {
                    const post: any = results.data.post || {};

                    setID(post.id);
                    fields.forEach(field => {
                        if (field.Type == 'calendar') {
                            if (field.subFieldHour != undefined) {
                                setValue(`${field.name}`, moment(post[`${field.name}`]).format('YYYY/MM/DD hh:mm:ss'));
                            } else {
                                setValue(`${field.name}`, moment(post[`${field.name}`]).format('YYYY/MM/DD'));
                            }
                        } else {
                            setValue(`${field.name}`, post[`${field.name}`]);
                        }
                    });

                    setOpenForm(true);
                    setOpenList(false);
                    setOpenButton(false);
                })
                .catch(err => {
                    setVisibleDialog(true);
                    setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
                });
        }
    }

    function Paging(page = 1) {
        setLoading(true);
        objPersonModel.gets(page)
            .then((results) => {
                const data: any = results["data"] || [];
                setTaskItems(data["posts"] || []);
                setPagingParams(data['paging'])
            })
            .catch(err => {
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false));
    }

    function Search(search_name = '', from_date = '') {
        setLoading(true);
        objPersonModel.gets(1, search_name, from_date)
            .then((results) => {
                const data: any = results["data"] || [];
                setTaskItems(data["posts"] || []);
                setPagingParams(data['paging'])
                setCurrentPage(1);
            })
            .catch(err => {
                console.log(err);
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false));
    };

    async function gets_init() {
        let p_posts = objPersonModel.gets(1);

        setLoading(true);
        Promise.all([p_posts]).then((values) => {
            const data_posts = values[0]["data"] || [];

            setTaskItems(data_posts["posts"] || []);
            setPagingParams(data_posts['paging'])
        })
            .catch(err => {
                console.log(`${err.message}`);

                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false))
    }


    /***
     * begin: react hook form
    */
    const { control, handleSubmit, reset, formState: { errors }, getValues, setValue } = useForm();

    const onSubmit = data => {
        const data_submit = data;

        if (id != '') {
            objPersonModel.update(id, data_submit)
                .then((results) => {
                    const data: any = results.data || {};
                    if (data.post != undefined && data.post.id != undefined) {
                        const obj_task: any = {
                            'id': data.post.id,
                            'display_name': data.post.display_name,
                            'birthday': data.post.birthday,
                            'name': data.post.name,
                            'checked': false
                        };
                        let arr: any = [];
                        taskItems.forEach(el => {
                            let el_tg: any = el;
                            if (el_tg['id'] == obj_task.id) {
                                arr.push(obj_task);
                            } else {
                                arr.push(el);
                            }
                        });
                        setTaskItems(arr);

                        setVisibleDialog(true);
                        setDialogMessage(`${data.message}`);
                    } else {
                        setVisibleDialog(true);
                        setDialogMessage(`${data.message}`);
                    }

                    setOpenForm(false);
                    setOpenList(true);
                    setOpenButton(true);
                    setVisibleDialog(true);

                    reset();
                })
                .catch(err => {
                    setVisibleDialog(true);
                    setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
                })
                .finally(() => {
                    setID('');
                    setLoading(false);
                });
        } else {
            objPersonModel.create(data_submit)
                .then((results) => {
                    const data: any = results.data || {};
                    if (data.post != undefined && data.post.id != undefined) {
                        const obj_task: any = {
                            'id': data.post.id,
                            'display_name': data.post.display_name,
                            'birthday': data.post.birthday,
                            'name': data.post.name
                        };
                        setTaskItems([obj_task, ...taskItems]);

                        setVisibleDialog(true);
                        setDialogMessage(`${data.message}`);
                    } else {
                        setVisibleDialog(true);
                        setDialogMessage(`${data.message}`);
                    }

                    setOpenForm(false);
                    setOpenList(true);
                    setOpenButton(true);
                    setVisibleDialog(true);

                    reset();
                })
                .catch(err => {
                    setVisibleDialog(true);
                    setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
                    setLoading(false);
                })
                .finally(() => {
                    setID('');
                    setLoading(false);
                });
        }
    };

    const myHandleSubmit = () => {
        // convert before submit
        fields.forEach(field => {
            if (field.Type == "calendar") {
                // do anything
            }
            if (field.Type == "image") {
                // do anything
            }
        });

        handleSubmit(onSubmit)();
    }

    const api_fields = [
        {
            name: "display_name",
            lable: LANG.Field.ManagerName,
            placeholder: LANG.Field.EnterFullname,
            rules: {
                required: true,
                pattern: false,
            },
            icon: {
                name: "info-circle",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
        },
        {
            name: "name",
            lable: LANG.Field.Phone,
            placeholder: LANG.Field.EnterPhone,
            rules: {
                required: LANG.Field.PhoneRequired,
                minLength: 9,
                maxLength: 11,
                pattern: "phone"
            },
            icon: {
                name: "phone",
                size: 25
            },
            keyboardType: "numeric",
            isPassword: false,
        },
        {
            name: "password",
            lable: LANG.Field.Password,
            placeholder: LANG.Field.EnterPassword,
            rules: {
                required: LANG.Field.PasswordRequired,
            },
            icon: {
                name: "lock",
                size: 25
            },
            keyboardType: "default",
            isPassword: true,
            confirmField: {
                name: "confirm_password",
                lable: LANG.Field.ConfirmPassword,
                placeholder: "",
                rules: {
                    required: LANG.Field.ConfirmPasswordRequired,
                    validate: "validate_password"
                },
                icon: {
                    name: "lock",
                    size: 25
                },
                keyboardType: "default",
                isPassword: true,
            },
        },
        {
            name: "permission",
            lable: LANG.Field.Permission,
            rules: {
                required: true,
                pattern: false,
            },
            icon: {
                name: "info-circle",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
            Type: "checkbox",
        },
        {
            name: "other_info",
            lable: LANG.Field.OtherInfo,
            placeholder: LANG.Field.EnterOtherInfo,
            rules: {
                pattern: false,
            },
            icon: {
                name: "info-circle",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
            multiline: true
        },
    ];

    const arr_validate = {
        validate_password: {
            matchesPreviousPassword: (value) => {
                const { password } = getValues();
                return password === value || LANG.PasswordRequiredValidate;
            }
        }
    };

    let column = {};
    let row = {};
    if (windowWidth <= 768) {
        column = stylesColumns.columnSmall;
        row = stylesColumns.rowSmall;
    } else if (windowWidth >= 769) {
        // column = stylesColumns.columnMedium;
        // row = stylesColumns.rowMedium;
    }

    const [fields, setFields] = React.useState([]);

    /***
      * end: react hook form
    */

    React.useEffect(() => {
        setFields(api_fields);

        const unsubscribe = navigation.addListener('focus', () => {
            console.log('Screen is focused member ' + Math.random());
            gets_init();
        });

        return () => {
            // cleanup
        };
    }, []);


    return (
        <View style={styles.container}>
            <Header title={dialogTitle} view="chat" navigation={navigation} />

            <Dialog
                isVisible={visibleDialog}
                onBackdropPress={() => setVisibleDialog(!visibleDialog)}
            >
                <Dialog.Title title={dialogTitle} />
                <Text>{dialogMessage}</Text>
            </Dialog>

            <ScrollView
                contentContainerStyle={{
                    flexGrow: 1,
                }}
                keyboardShouldPersistTaps="handled"
            >
                {openForm && (
                    <View style={styles.box}>
                        <View style={row}>
                            {fields.map((field, key) => {
                                switch (field.Type) {
                                    case "checkbox":
                                        return (
                                            <View key={key} style={column}>
                                                <Text style={styles.text}>{field.lable}:</Text>
                                                <MyContext.Provider value={{ setValue }}>
                                                    <MyControlCheckbox
                                                        value={getValues(`${field.name}`)}
                                                        field={field}
                                                        control={control}
                                                    />
                                                </MyContext.Provider>
                                            </View>
                                        );
                                        break;
                                    default:
                                        return (
                                            field.confirmField != null ? (
                                                <View key={key}>
                                                    <View style={column}>
                                                        <CheckBox
                                                            title={'Thay đổi mật khẩu'}
                                                            containerStyle={{ marginLeft: 3, backgroundColor: "transfarent" }}
                                                            checked={isChangePassword}
                                                            onPress={() => setChangePassword(!isChangePassword)}
                                                        />
                                                    </View>
                                                    {isChangePassword && (
                                                        <View style={column}>
                                                            <Text style={styles.text}> {field.lable}:</Text>
                                                            <MyControlInputText
                                                                action={id != '' ? 'edit' : 'add'}
                                                                field={field}
                                                                control={control}
                                                                errors={errors}
                                                                pattern={field.rules.pattern != undefined ? arr_pattern[field.rules.pattern] : ''}
                                                                validate={field.rules.validate != undefined ? arr_validate[field.rules.validate] : ''}
                                                            />
                                                            <Text style={styles.text}>{field.confirmField.lable}:</Text>
                                                            <MyControlInputText
                                                                action={id != '' ? 'edit' : 'add'}
                                                                field={field.confirmField}
                                                                control={control}
                                                                errors={errors}
                                                                pattern={field.confirmField.rules.pattern != undefined ? arr_pattern[field.confirmField.rules.pattern] : ''}
                                                                validate={field.confirmField.rules.validate != undefined ? arr_validate[field.confirmField.rules.validate] : ''}
                                                            />
                                                        </View>
                                                    )}
                                                </View>
                                            ) : (
                                                <View key={key} style={column}>
                                                    <Text style={styles.text}> {field.lable}:</Text>
                                                    <MyControlInputText
                                                        action={id != '' ? 'edit' : 'add'}
                                                        field={field}
                                                        control={control}
                                                        errors={errors}
                                                        pattern={field.rules.pattern != undefined ? arr_pattern[field.rules.pattern] : ''}
                                                        validate={field.rules.validate != undefined ? arr_validate[field.rules.validate] : ''}
                                                    />
                                                </View>
                                            )
                                        )
                                }
                            })}
                        </View>

                        <View style={{ flexDirection: "row", alignSelf: "center", marginTop: 30 }}>
                            {isLoading == true ? (
                                <IsLoadingData title={LANG.Updating} />
                            ) : (
                                <>
                                    <ButtonCancel onPress={() => { setID(''); setOpenForm(false); setOpenButton(true); setOpenList(true); }} />
                                    <ButtonSave onPress={() => myHandleSubmit()} />
                                </>
                            )}
                        </View>
                    </View>
                )}

                {openList && (
                    <>
                        <View style={{ flexDirection: "row", alignSelf: "center", marginTop: 12 }}>
                            <MyContext.Provider value={{
                                Search,
                                searchName,
                                setSearchName,
                            }}>
                                <MyInputSearchName />
                            </MyContext.Provider>

                            <ButtonSearch onPress={() => Search(searchName, fromDate)} />
                        </View>

                        {isLoading ? (
                            <IsLoadingData />
                        ) : (
                            <MyContext.Provider value={
                                {
                                    taskItems,
                                    checkItem,

                                    pagingParams,
                                    Paging,

                                    currentPage,
                                    setCurrentPage,
                                }}>
                                <MyListingMember />
                                <MyPaging />
                            </MyContext.Provider>
                        )}
                    </>
                )}
            </ScrollView >

            {openButton && (
                <View style={{ flexDirection: "row", alignSelf: "center", "position": "absolute", bottom: 5 }}>
                    <ButtonAdd onPress={() => {
                        reset();
                        setOpenForm(true); setOpenButton(false); setOpenList(false);
                    }}
                    />
                    <ButtonEdit onPress={() => editItem()} />
                    <ButtonDelete onPress={() => deleteItems()} />
                </View>
            )}

            <View style={stylesButtons.open_close}>
                {visible == true ? (
                    <OpenButtons onPress={() => { setOpenButton(false); setVisible(false); }} />
                ) : (
                    <CloseButtons onPress={() => { setOpenButton(true); setVisible(true); }} />
                )}
            </View>

        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#E8EAED',
    },
    box: {
        flex: 1,
        margin: 12,
        marginTop: 6,
        marginBottom: 6,
        padding: 20
    },
    text: {
        marginTop: 18,
        marginBottom: Platform.OS == 'web' ? -12 : -24,
        color: '#000',
        alignSelf: "flex-start",
        paddingLeft: 12
    },
    error: {
        color: "#f00",
        marginTop: Platform.OS == 'web' ? -8 : -20,
        alignSelf: "flex-start",
        paddingLeft: 12
    }
});

export default ListManager;
