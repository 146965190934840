import React from "react";
import { View, Text, StyleSheet, ScrollView, Platform } from 'react-native';
import { Dialog } from 'react-native-elements';

import { Header } from '../../components/header';
import { mycolors } from "../../config/mycolors";

import { MyContext } from '../../context/mycontext';
import { DownloadModel, ReportModel } from "../../models/models";
import { MyDisplayFromToDate, MySelectCalendarSearch, SelectOneReportModal } from "../../components/myboxs";
import { ButtonDownload, ButtonSearch } from "../../components/mybuttons";
import { IsLoadingData } from "../../components/myother";

import * as WebBrowser from 'expo-web-browser';
import { BarChart, PieChart } from "react-native-chart-kit";
import { LANG, windowWidth } from "../../config/config";
import { formatCurrenCy } from "../../config/myfunc";

function ReportInternal({ navigation }) {
    // const id = 1;
    const [id, setID] = React.useState('');

    const dialogTitle = 'Thống Kê';

    const [isLoading, setLoading] = React.useState<boolean>(false);

    const [searchName, setSearchName] = React.useState('');
    const [visibleSearchCalendar, setVisibleSearchCalendar] = React.useState(false);
    const [fromDate, setFromDate] = React.useState('');
    const [toDate, setToDate] = React.useState('');

    const [visibleDialog, setVisibleDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');

    const [columnsNote, setColumnsNote] = React.useState(null);
    const [dataBarchart, setDataBarchart] = React.useState(null);
    const [dataPiechart, setDataPiechart] = React.useState(null);

    const fontSize = Platform.OS == 'web' ? 15 : 13;

    const chartConfig = {
        backgroundColor: mycolors.primary,
        backgroundGradientFrom: mycolors.primary,
        backgroundGradientTo: mycolors.primary,
        decimalPlaces: 0, // optional, defaults to 2dp
        color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
        labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
        style: {
            borderRadius: 3,
        },
        propsForDots: {
            r: "6",
            strokeWidth: "55",
            stroke: "#ffa726"
        }
    };

    let colors_chart = [];
    colors_chart.push('blue');
    colors_chart.push('green');
    colors_chart.push('orange');
    colors_chart.push('chocolate');
    colors_chart.push('red');
    colors_chart.push('gray');
    colors_chart.push('goldenrod');
    colors_chart.push('darkcyan');

    function buildDataChart(_posts: any) {
        // BEGIN: barchar
        let total_profit = 0;
        const columns_note = _posts.map((item, key) => {
            if (item['field_name'] == 'payment') {
                total_profit += item['total'];
            } else {
                total_profit -= item['total'];
            }

            let arr = {};
            arr['name'] = item['field_label'];
            arr['total'] = item['total'];
            return arr;
        });
        const barchart = {
            labels: columns_note.map(item => item['name']),
            datasets: [
                {
                    data: _posts.map(item => item['total'])
                }
            ]
        };

        columns_note.push({ 'name': 'Tổng lãi', 'total': total_profit });
        setColumnsNote(columns_note);
        setDataBarchart(barchart);
        // END: barchar

        // BEGIN piochart
        let data_piechart = _posts.map((item, key) => {
            return {
                name: item['field_label'],
                population: item['total'],
                color: `${colors_chart[key] ? colors_chart[key] : 'rgba(0, 0, 0, 1)'}`,
                legendFontColor: "#7F7F7F",
                legendFontSize: fontSize
            }
        });
        setDataPiechart(data_piechart);
        // END piochart
    }

    function Search(search_name = '', from_date = '', to_date = '') {
        setLoading(true);
        ReportModel.gets(1, search_name, from_date, to_date)
            .then((results) => {
                const data: any = results["data"] || [];
                const posts = data['posts'] || [];

                if (__DEV__) {
                    console.log('data search:', data);
                }

                buildDataChart(posts);
            })
            .catch(err => {
                if (__DEV__) {
                    console.log('err:', err);
                }
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false));
    };

    function downloadFile() {
        DownloadModel.create_file()
            .then((results) => {
                const data: any = results["data"] || [];
                let file_download = data.file || '';
                if (__DEV__) {
                    console.log('create file: ', data)
                }

                if (file_download != '') {
                    const full_file = DownloadModel.full_file(file_download)

                    if (Platform.OS == 'web') {
                        var link = document.createElement('a');
                        link.href = full_file;
                        link.download = full_file.substr(full_file.lastIndexOf('/') + 1);
                        link.click();
                    } else {
                        WebBrowser.openBrowserAsync(full_file);
                    }
                } else {
                    setVisibleDialog(true);
                    setDialogMessage(`${data.message} `);
                }
            })
            .catch(err => {
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            });
    }

    async function gets_init() {
        const p_report = ReportModel.gets(1);

        setLoading(true);
        Promise.all([p_report]).then((values) => {
            const data_report = values[0]["data"] || [];
            const posts = data_report['posts'] || [];

            if (__DEV__) {
                console.log('init data report: ', data_report);
            }

            buildDataChart(posts);
        })
            .catch(err => {
                console.log(`${err.message} \n${LANG.CanNotConnect}`);

                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false))
    }

    React.useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            if (__DEV__) {
                // if screen is focused then reload
                console.log('Screen is focused parent' + Math.random());
            }
            gets_init();

            setDataBarchart(null);
            setDataPiechart(null);
        });

        return () => {
            // cleanup
            return unsubscribe();
        };
    }, []);

    const mainWith = Platform.OS == 'web' ? 450 : windowWidth;
    return (
        <View style={styles.container}>
            <Header title={dialogTitle} view="chat" navigation={navigation} />

            <Dialog
                isVisible={visibleDialog}
                onBackdropPress={() => setVisibleDialog(!visibleDialog)}
            >
                <Dialog.Title title={dialogTitle} />
                <Text>{dialogMessage}</Text>
            </Dialog>

            <ScrollView
                contentContainerStyle={{
                    flexGrow: 1,
                }}
                keyboardShouldPersistTaps="handled"
            >
                <View style={{ flexDirection: "row", alignSelf: "center", marginTop: 12, marginLeft: 20, marginRight: 20 }}>
                    <MyContext.Provider value={{}}>
                        <SelectOneReportModal navigation={navigation} />
                    </MyContext.Provider>
                </View>

                <View style={{ flexDirection: "row", alignSelf: "center", marginTop: 12 }}>
                    <MyContext.Provider value={{
                        visibleSearchCalendar,
                        setVisibleSearchCalendar,

                        fromDate,
                        setFromDate,

                        toDate,
                        setToDate,
                    }}>
                        <MySelectCalendarSearch />
                    </MyContext.Provider>
                    <ButtonSearch onPress={() => Search(searchName, fromDate, toDate)} title="Lọc" />
                    <View style={{ marginLeft: 6 }}>
                        <ButtonDownload
                            onPress={downloadFile}
                        />
                    </View>
                </View>

                <MyContext.Provider value={{
                    fromDate,
                    setFromDate,

                    toDate,
                    setToDate,
                }}>
                    <MyDisplayFromToDate />
                </MyContext.Provider>

                {isLoading ? (
                    <IsLoadingData />
                ) : (
                    <>
                        {dataBarchart != null &&
                            <ScrollView
                                horizontal={true}
                                showsHorizontalScrollIndicator={true}
                            >
                                <View style={{ width: mainWith }}>
                                    <BarChart
                                        style={{
                                            marginVertical: 8,
                                            borderRadius: 5,
                                            margin: 12,
                                        }}
                                        data={dataBarchart}
                                        width={mainWith - 20}
                                        height={220}
                                        yAxisLabel=""
                                        yAxisSuffix=" đ"
                                        chartConfig={chartConfig}
                                        verticalLabelRotation={0}
                                    />
                                </View>
                            </ScrollView>
                        }


                        {columnsNote != null && <View style={{ paddingLeft: 12, paddingRight: 12, marginTop: 12 }}>
                            <Text style={{ fontWeight: "bold" }}>Ghi chú:</Text>
                            {columnsNote.map((name, key) =>
                                <View key={key} style={{ display: "flex", flexDirection: "row" }}>
                                    {Platform.OS == 'web'
                                        ? <>
                                            <Text style={{ width: 120, fontSize }}>
                                                {`${name['name']}:`}
                                            </Text>
                                            <Text style={{ width: 180, textAlign: "right", fontSize, color: "red" }}>
                                                {`${formatCurrenCy(name['total'])} `}
                                            </Text>
                                        </>
                                        : <>
                                            <Text style={{ fontSize }}>
                                                {`${name['name']}:`}
                                            </Text>
                                            <Text style={{ flex: 1, textAlign: "right", fontSize, color: "red" }}>
                                                {`${formatCurrenCy(name['total'])} `}
                                            </Text>
                                        </>
                                    }
                                </View>
                            )}
                        </View>}

                        {dataPiechart != null &&
                            <ScrollView
                                horizontal={true}
                                showsHorizontalScrollIndicator={true}
                            >
                                <View style={{ width: "auto" }}>
                                    <PieChart
                                        data={dataPiechart}
                                        width={mainWith - 12}
                                        height={220}
                                        chartConfig={chartConfig}
                                        accessor={"population"}
                                        backgroundColor={"transparent"}
                                        paddingLeft={"15"}
                                        center={[0, 0]}
                                    />
                                </View>
                            </ScrollView>
                        }
                    </>
                )}
            </ScrollView>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#E8EAED',
    },
    rowSelector: {
        height: 20,
        flexDirection: 'row',
        alignItems: 'center',
    },
    box: {
        backgroundColor: mycolors.primary2,
        margin: 12,
        marginTop: 6,
        marginBottom: 6,
        padding: 20
    },
    text: {
        color: '#fff'
    }
});


export default ReportInternal;