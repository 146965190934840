import React, { useState } from "react";
import { Platform, Text, View, ScrollView, Pressable, TextInput } from 'react-native';
import { CheckBox, Button, Dialog, Overlay, Input, Icon, InputProps } from 'react-native-elements';

import moment from 'moment'

import { fontSizeBaseCalendar, windowHeight, windowWidth } from "../config/config";

import { mycolors } from "../config/mycolors";
import DatePicker from 'react-native-modern-datepicker';
import { ButtonCancel } from './mybuttons'
import { stylesCalendar } from "../config/mystyles";
import { convertDateForMoment } from "../config/myfunc";
import { IsLoadingData, NoDataLoading } from "../components/myother";

import { MyContext } from '../context/mycontext';

const inputProps = {};
const InputFieldsStyle = {
    borderWidth: 0,
};

export const MyCalendar = (props) => {
    let mContext: any = React.useContext(MyContext);
    let [childDate, setChildDate] = useState('');

    const changeValue = (date: any) => {
        switch (props.index) {
            case 'fromDate':
                mContext.setFromDate(date);
                break;
            case 'toDate':
                mContext.setToDate(date);
                break;
            case 'startDate':
                mContext.setStartDate(date);
                break;
            default:
                break;
        }

        setChildDate(date);
    };
    return (
        <View style={Platform.OS == 'web' && { width: 350 }}>
            <DatePicker
                options={{
                    backgroundColor: mycolors.primary,
                    textHeaderColor: '#FFA25B',
                    textDefaultColor: '#F6E7C1',
                    selectedTextColor: '#fff',
                    mainColor: '#F4722B',
                    textSecondaryColor: '#D6C7A1',
                    textFontSize: fontSizeBaseCalendar,
                    textHeaderFontSize: fontSizeBaseCalendar + 2,
                    borderColor: 'rgba(122, 146, 165, 0.75)',
                }}
                current={mContext[`${props.index}`]}
                selected={mContext[`${props.index}`]}
                mode="calendar"
                minuteInterval={30}
                style={{ borderRadius: 0 }}
                onSelectedChange={date => {
                    changeValue(date);
                }}
            />
        </View>
    );
};

export const MyInputPermission = () => {
    let mContext: any = React.useContext(MyContext);

    return (
        <View>
            <Dialog
                isVisible={mContext.visiblePermission}
                onBackdropPress={mContext.toggleDialogPermission}
            >
                <Dialog.Title title="Chọn phân quyền" />
                {mContext.permission.map((arr) => (
                    <CheckBox
                        checkedIcon="dot-circle-o"
                        uncheckedIcon="circle-o"
                        key={arr['key']}
                        title={arr['label']}
                        containerStyle={{ backgroundColor: 'white', borderWidth: 0 }}
                        checked={arr['checked']}
                        onPress={() => { mContext.changePermission(arr['key']); }}
                    />
                ))}
            </Dialog>

            <View style={{ minHeight: 36 }}>
                <Input
                    {...(inputProps as InputProps)}
                    leftIcon={
                        <Pressable onPress={mContext.toggleDialogPermission}>
                            <Icon
                                name="user"
                                type="font-awesome"
                                color="#86939e"
                                size={25}
                            />
                        </Pressable>
                    }
                    editable={false}
                    value={`Phân quyền: ${mContext.permission.map(arr => arr['checked'] == true ? arr['label'] : '').filter(x => x != '').join(', ')}`}
                    leftIconContainerStyle={{ marginLeft: 0, marginRight: 10 }}
                    containerStyle={{ marginTop: 6, width: '100%' }}
                    style={InputFieldsStyle}
                    onFocus={mContext.toggleDialogPermission}
                />
            </View>
        </View>
    )
}

export const MyInputMultiPermission = () => {
    let mContext: any = React.useContext(MyContext);

    return (
        <>
            <Dialog
                isVisible={mContext.visiblePermission}
                onBackdropPress={mContext.toggleDialogPermission}
            >
                <Dialog.Title title="Chọn đối tượng" />
                {mContext.permission.map((arr) => (
                    <CheckBox
                        key={arr['key']}
                        title={arr['label']}
                        containerStyle={{ backgroundColor: 'white', borderWidth: 0 }}
                        checked={arr['checked']}
                        onPress={() => { mContext.changePermission(arr['key']); }}
                    />
                ))}
            </Dialog>

            <View style={{ minHeight: 36 }}>
                <Input
                    {...(inputProps as InputProps)}
                    leftIcon={
                        <Icon
                            name="user"
                            type="font-awesome"
                            color="#86939e"
                            size={28}
                            onPress={mContext.toggleDialogPermission}
                        />
                    }
                    leftIconContainerStyle={{ marginLeft: 0, marginRight: 10 }}
                    editable={false}
                    value={mContext.permission.map(arr => arr['checked'] == true ? arr['label'] : '').filter(x => x != '').join(', ')}
                    containerStyle={{ marginTop: 6, width: '100%' }}
                    style={{ ...InputFieldsStyle, minHeight: 60 }}
                />
            </View>
        </>
    )
}

export const MyInputPhone = (props) => {
    let mContext: any = React.useContext(MyContext);

    return (
        <View style={{ minHeight: 36 }}>
            <Input
                {...(inputProps as InputProps)}
                leftIcon={
                    <Icon
                        name="phone"
                        type="font-awesome"
                        color="#86939e"
                        size={25}
                    />
                }
                leftIconContainerStyle={{ marginLeft: 0, marginRight: 10 }}
                containerStyle={{ marginTop: 6, width: '100%' }}
                placeholder={props.placeholder != undefined ? props.placeholder : "Số di động"}
                maxLength={11}
                keyboardType="numeric"
                style={InputFieldsStyle}
                value={mContext.name}
                onChangeText={text => mContext.setName(text)}
                errorMessage={
                    mContext.isNameValid == true ? '' : 'Vui lòng nhập số điện thoại!'
                }
            />
        </View>
    )
}

export const MyInputPassword = (props) => {
    let mContext: any = React.useContext(MyContext);

    return (
        <View style={{ minHeight: 36 }}>
            <Input
                {...(inputProps as InputProps)}
                leftIcon={
                    <Icon
                        name="lock"
                        type="font-awesome"
                        color="#86939e"
                        size={25}
                    />
                }
                leftIconContainerStyle={{ marginLeft: 0, marginRight: 10 }}
                containerStyle={{ marginTop: 6, width: '100%' }}
                placeholder={props.placeholder != undefined ? props.placeholder : "Mật khẩu"}
                autoCapitalize="none"
                autoCorrect={false}
                secureTextEntry={true}
                style={InputFieldsStyle}
                value={mContext.password}
                onChangeText={text => mContext.setPassword(text)}
                errorMessage={
                    mContext.isPasswordValid == true ? '' : 'Vui lòng nhập mật khẩu!'
                }
            />
        </View>
    )
}

export const MyInputConfirmPassword = (props) => {
    let mContext: any = React.useContext(MyContext);

    return (
        <View style={{ minHeight: 36 }}>
            <Input
                {...(inputProps as InputProps)}
                leftIcon={
                    <Icon
                        name="lock"
                        type="font-awesome"
                        color="#86939e"
                        size={25}
                    />
                }
                leftIconContainerStyle={{ marginLeft: 0, marginRight: 10 }}
                containerStyle={{ marginTop: 6, width: '100%' }}
                placeholder={props.placeholder != undefined ? props.placeholder : "Xác nhận mật khẩu"}
                autoCapitalize="none"
                autoCorrect={false}
                secureTextEntry={true}
                style={InputFieldsStyle}
                value={mContext.confirm_password}
                onChangeText={text => mContext.setConfirmPassword(text)}
                errorMessage={
                    mContext.isConfirmPasswordValid == true ? '' : 'Vui lòng xác nhận mật khẩu!'
                }
            />
        </View>
    )
}

export const MyInputAddress = () => {
    let mContext: any = React.useContext(MyContext);

    return (
        <View style={{ minHeight: 36 }}>
            <Input
                {...(inputProps as InputProps)}
                leftIcon={
                    <Icon
                        name="address-card"
                        type="font-awesome"
                        color="#86939e"
                        size={25}
                    />
                }
                leftIconContainerStyle={{ marginLeft: 0, marginRight: 10 }}
                containerStyle={{ marginTop: 6, width: '100%' }}
                placeholder="Quê quán"
                style={InputFieldsStyle}
                value={mContext.address}
                onChangeText={text => mContext.setAddress(text)}
            />
        </View>
    )
}

export const MyInputBirthday = () => {
    let mContext: any = React.useContext(MyContext);

    return (
        <>
            <Overlay
                fullScreen={Platform.OS == 'web' ? false : true}
                isVisible={mContext.visibleCalendar}
                onBackdropPress={() => mContext.setVisibleCalendar(!mContext.visibleCalendar)}
            >
                <ScrollView>
                    <View style={{ maxWidth: 350 }}>
                        <MyCalendar index="startDate" />
                    </View>
                    <Button
                        icon={
                            <Icon
                                name="check"
                                type="font-awesome"
                                color="white"
                                size={25}
                                iconStyle={{ marginRight: 10 }}
                            />
                        }
                        title="OK"
                        onPress={() => mContext.setVisibleCalendar(!mContext.visibleCalendar)}
                        buttonStyle={{ marginTop: 20 }}
                    />
                </ScrollView>
            </Overlay>

            <View style={{ minHeight: 36 }}>
                <Pressable style={{ width: windowWidth - 30 }} onPress={() => mContext.setVisibleCalendar(true)}>
                    <Input
                        {...(inputProps as InputProps)}
                        leftIcon={
                            <Icon
                                name="calendar"
                                type="font-awesome"
                                color="#86939e"
                                size={25}
                            />
                        }
                        value={`${moment(convertDateForMoment(mContext.startDate)).format('DD/MM/YYYY')}`}
                        editable={false}
                        leftIconContainerStyle={{ marginLeft: 0, marginRight: 10 }}
                        containerStyle={{ marginTop: 6, width: '100%' }}
                        placeholder="Ngày sinh"
                        style={InputFieldsStyle}
                    />
                </Pressable>
            </View>
        </>
    )
}

export const MyInputStartDate = () => {
    let mContext: any = React.useContext(MyContext);

    return (
        <>
            <Overlay
                fullScreen={Platform.OS == 'web' ? false : true}
                isVisible={mContext.visibleCalendar}
                onBackdropPress={() => mContext.setVisibleCalendar(!mContext.visibleCalendar)}
            >
                <ScrollView>
                    <View style={{ maxWidth: 350 }}>
                        <MyCalendar index="startDate" />
                    </View>

                    <Text style={{ marginTop: 12 }}>Chọn giờ:</Text>
                    <ScrollView horizontal={true} style={{ maxWidth: 350, flexDirection: "row" }}>
                        {mContext.hours.map(index => (
                            <View key={index}>
                                {mContext.startHour == index ? (
                                    <Text style={stylesCalendar.itemActive} onPress={() => { mContext.setStartHour(index) }}>
                                        {index}
                                    </Text>
                                ) : (
                                    <Text style={stylesCalendar.item} onPress={() => { mContext.setStartHour(index) }}>
                                        {index}
                                    </Text>
                                )}
                            </View>
                        ))}
                    </ScrollView>

                    <Text style={{ marginTop: 12 }}>Chọn phút:</Text>
                    <ScrollView horizontal={true} style={{ maxWidth: 350, flexDirection: "row" }}>
                        {mContext.minutes.map(index => (
                            <View key={index}>
                                {mContext.startMinute == index ? (
                                    <Text style={stylesCalendar.itemActive} onPress={() => { mContext.setStartMinute(index) }}>
                                        {index}
                                    </Text>
                                ) : (
                                    <Text style={stylesCalendar.item} onPress={() => { mContext.setStartMinute(index) }}>
                                        {index}
                                    </Text>
                                )}
                            </View>
                        ))}
                    </ScrollView>

                    <Button
                        icon={
                            <Icon
                                name="check"
                                type="font-awesome"
                                color="white"
                                size={25}
                                iconStyle={{ marginRight: 10 }}
                            />
                        }
                        title="OK"
                        onPress={() => mContext.setVisibleCalendar(!mContext.visibleCalendar)}
                        buttonStyle={{ marginTop: 20 }}
                    />
                </ScrollView>
            </Overlay>

            <View style={{ minHeight: 36 }}>
                <Pressable style={{ width: 280 }} onPress={() => mContext.setVisibleCalendar(true)}>
                    <Input
                        {...(inputProps as InputProps)}
                        leftIconContainerStyle={{ marginLeft: 0, marginRight: 10 }}
                        leftIcon={
                            <Icon
                                name="calendar"
                                type="font-awesome"
                                color="#86939e"
                                size={25}
                            />
                        }
                        value={`${moment(convertDateForMoment(mContext.startDate)).format('DD/MM/YYYY')} ${mContext.startHour}h${mContext.startMinute} `}
                        editable={false}
                        containerStyle={{ marginTop: 6, width: '100%' }}
                        placeholder="Ngày hiển thị"
                        style={InputFieldsStyle}
                    />
                </Pressable>
            </View>
        </>
    )
}

export const MyInputDisplayName = (props) => {
    let mContext: any = React.useContext(MyContext);

    return (
        <View style={{ minHeight: 36 }}>
            <Input
                {...(inputProps as InputProps)}
                leftIcon={
                    <Icon
                        name="info-circle"
                        type="font-awesome"
                        color="#86939e"
                        size={25}
                    />
                }
                leftIconContainerStyle={{ marginLeft: 0, marginRight: 10 }}
                containerStyle={{ marginTop: 6, width: '100%' }}
                placeholder={props.placeholder != undefined ? props.placeholder : 'Họ tên đầy đủ'}
                style={InputFieldsStyle}
                value={mContext.display_name}
                onChangeText={text => mContext.setDisplayName(text)}
                errorMessage={
                    mContext.isDisplayNameValid == true ? '' : (props.errorMessage != undefined ? props.errorMessage : 'Vui lòng nhập họ tên đầy đủ!')
                }
            />
        </View>
    )
}

export const MyInputUniqueName = (props) => {
    let mContext: any = React.useContext(MyContext);

    return (
        <View style={{ minHeight: 36 }}>
            <Input
                {...(inputProps as InputProps)}
                leftIcon={
                    <Icon
                        name="info-circle"
                        type="font-awesome"
                        color="#86939e"
                        size={25}
                    />
                }
                leftIconContainerStyle={{ marginLeft: 0, marginRight: 10 }}
                containerStyle={{ marginTop: 6, width: '100%' }}
                placeholder={props.placeholder != undefined ? props.placeholder : 'a-zA-Z0-9'}
                style={InputFieldsStyle}
                value={mContext.unique_name}
                onChangeText={text => mContext.setUniqueName(text)}
                errorMessage={
                    mContext.isUniqueNameValid == true ? '' : (props.errorMessage != undefined ? props.errorMessage : 'Chỉ bao gồm a-zA-Z0-9!')
                }
            />
        </View>
    )
}

export const MyInputMaxAccount = (props) => {
    let mContext: any = React.useContext(MyContext);

    return (
        <View style={{ minHeight: 36 }}>
            <Input
                {...(inputProps as InputProps)}
                leftIcon={
                    <Icon
                        name="info-circle"
                        type="font-awesome"
                        color="#86939e"
                        size={25}
                    />
                }
                leftIconContainerStyle={{ marginLeft: 0, marginRight: 10 }}
                containerStyle={{ marginTop: 6, width: '100%' }}
                placeholder={props.placeholder != undefined ? props.placeholder : 'Ví dụ: 1500'}
                style={InputFieldsStyle}
                keyboardType="numeric"
                value={mContext.max_account}
                onChangeText={text => mContext.setMaxAccount(text)}
                errorMessage={
                    mContext.isMaxAccountValid == true ? '' : (props.errorMessage != undefined ? props.errorMessage : 'Chỉ bao gồm 0-9!')
                }
            />
        </View>
    )
}

export const MyInputCard = (props) => {
    let mContext: any = React.useContext(MyContext);

    return (
        <View style={{ minHeight: 36 }}>
            <Input
                {...(inputProps as InputProps)}
                leftIcon={
                    <Icon
                        name="id-card-o"
                        type="font-awesome"
                        color="#86939e"
                        size={25}
                    />
                }
                leftIconContainerStyle={{ marginLeft: 0, marginRight: 10 }}
                containerStyle={{ marginTop: 6, width: '100%' }}
                placeholder={props.placeholder != undefined ? props.placeholder : 'CMND/Thẻ căn cước'}
                keyboardType="numeric"
                style={InputFieldsStyle}
                value={mContext.card}
                onChangeText={text => mContext.setCard(text)}
            />
        </View>
    )
}

export const MyInputOtherInfo = (props) => {
    let mContext: any = React.useContext(MyContext);

    return (
        <View style={{ minHeight: 36 }}>
            <Input
                {...(inputProps as InputProps)}
                leftIcon={
                    <Icon
                        name="info-circle"
                        type="font-awesome"
                        color="#86939e"
                        size={32}
                    />
                }
                leftIconContainerStyle={{ marginLeft: 0, marginRight: 10 }}
                containerStyle={{ marginTop: 6, width: '100%' }}
                placeholder={props.placeholder != undefined ? props.placeholder : 'Thông tin khác'}
                style={{ ...InputFieldsStyle, minHeight: 100 }}
                multiline={true}
                value={mContext.other_info}
                onChangeText={text => mContext.setOtherInfo(text)}
            />
        </View>
    )
}

export const MyInputContent = (props) => {
    let mContext: any = React.useContext(MyContext);

    return (
        <View style={{ minHeight: 36 }}>
            <Input
                {...(inputProps as InputProps)}
                leftIcon={
                    <Icon
                        name="info-circle"
                        type="font-awesome"
                        color="#86939e"
                        size={25}
                    />
                }
                leftIconContainerStyle={{ marginLeft: 0, marginRight: 10 }}
                containerStyle={{ marginTop: 6, width: '100%' }}
                placeholder={props.placeholder != undefined ? props.placeholder : 'Nội dung'}
                style={{ ...InputFieldsStyle, minHeight: 50 }}
                multiline={true}
                value={mContext.content}
                onChangeText={text => mContext.setContent(text)}
                errorMessage={
                    mContext.isContentValid == true ? '' : 'Vui lòng nhập nội dung!'
                }
            />
        </View>
    )
}

export const MyInputTitle = (props) => {
    let mContext: any = React.useContext(MyContext);

    return (
        <View style={{ minHeight: 36 }}>
            <Input
                {...(inputProps as InputProps)}
                leftIcon={
                    <Icon
                        name="info-circle"
                        type="font-awesome"
                        color="#86939e"
                        size={25}
                    />
                }
                leftIconContainerStyle={{ marginLeft: 0, marginRight: 10 }}
                containerStyle={{ marginTop: 6, width: '100%' }}
                placeholder={props.placeholder != undefined ? props.placeholder : 'Tiêu đề'}
                style={InputFieldsStyle}
                value={mContext.title}
                onChangeText={text => mContext.setTitle(text)}
                errorMessage={
                    mContext.isTitleValid == true ? '' : 'Vui lòng nhập tiêu đề!'
                }
            />
        </View>
    )
}

export const MyInputParent = () => {
    let mContext: any = React.useContext(MyContext);

    return (
        <>
            <Overlay
                fullScreen={Platform.OS == 'web' ? false : true}
                isVisible={mContext.visibleBoxSearch}
                onBackdropPress={() => mContext.setVisibleBoxSearch(!mContext.visibleBoxSearch)}
            >
                <Input
                    {...(inputProps as InputProps)}
                    rightIcon={
                        <Icon
                            name="search"
                            type="font-awesome"
                            color="#86939e"
                            size={25}
                            onPress={() => {
                                mContext.setVisibleBoxSearch(true);
                                if (mContext.parent_id == 0)
                                    mContext.searchParent(mContext.parent_name);
                            }}
                        />
                    }
                    leftIconContainerStyle={{ marginLeft: 0, marginRight: 10 }}
                    containerStyle={{ marginTop: 6, width: '100%', maxWidth: 350, paddingHorizontal: 20 }}
                    placeholder="Chuyên mục cha"
                    style={InputFieldsStyle}
                    value={mContext.parent_name}
                    onChangeText={text => { mContext.setParentName(text); mContext.setParentID(0); }}
                />
                <ScrollView style={{ maxHeight: windowHeight - 80, maxWidth: 450 }}>
                    {mContext.isLoading ? (
                        <IsLoadingData />
                    ) : (
                        <>
                            <CheckBox
                                checkedIcon="dot-circle-o"
                                uncheckedIcon="circle-o"
                                key={0}
                                title={'--- Không thuộc chuyên mục nào ---'}
                                containerStyle={{ marginLeft: 0, backgroundColor: 'white', borderWidth: 0 }}
                                checked={0 == mContext.parent_id ? true : false}
                                onPress={() => {
                                    mContext.setParentID(0);
                                    mContext.setParentName('');
                                    mContext.setVisibleBoxSearch(false);
                                    mContext.chooseParent(0);
                                }}
                            />
                            {
                                mContext.listParent.map((arr) => (
                                    <CheckBox
                                        checkedIcon="dot-circle-o"
                                        uncheckedIcon="circle-o"
                                        key={arr['id']}
                                        title={arr['parent_title'] != '' ? `${arr['parent_title']} --> ${arr['title']}` : arr['title']}
                                        containerStyle={{ marginLeft: 0, backgroundColor: 'white', borderWidth: 0 }}
                                        checked={arr['id'] == mContext.parent_id ? true : false}
                                        onPress={() => {
                                            mContext.setParentID(arr['id']);
                                            mContext.setParentName(arr['title']);
                                            mContext.setVisibleBoxSearch(false);
                                            mContext.chooseParent(arr['id']);
                                        }}
                                    />
                                ))
                            }
                        </>
                    )}
                </ScrollView>
            </Overlay>

            <View style={{ minHeight: 36 }}>
                <Text style={stylesCalendar.label}>parent_id: {mContext.parent_id}</Text>
                <Input
                    {...(inputProps as InputProps)}
                    leftIcon={
                        <Icon
                            name="info-circle"
                            type="font-awesome"
                            color="#86939e"
                            size={25}
                        />
                    }
                    leftIconContainerStyle={{ marginLeft: 0, marginRight: 10 }}
                    rightIcon={
                        <Icon
                            name="arrow-down"
                            type="font-awesome"
                            color="#86939e"
                            size={25}
                            onPress={() => {
                                mContext.setVisibleBoxSearch(true);
                                if (mContext.parent_id == 0)
                                    mContext.searchParent(mContext.parent_name);
                            }}
                        />
                    }
                    containerStyle={{ marginTop: 6, width: '100%', maxWidth: 350 }}
                    placeholder="Tên chuyên mục cha"
                    style={InputFieldsStyle}
                    value={mContext.parent_name}
                    onChangeText={text => { mContext.setParentName(text); mContext.setParentID(0); }}
                    onBlur={() => {
                        mContext.setVisibleBoxSearch(true);
                        if (mContext.parent_id == 0)
                            mContext.searchParent(mContext.parent_name);
                    }}
                />
            </View>
        </>
    )
}


export const MyInputSearchName = (props) => {
    let mContext: any = React.useContext(MyContext);

    const placeholder = props.placeholder || 'Từ cần tìm';
    return (
        <TextInput
            value={mContext.searchName}
            onChangeText={text => mContext.setSearchName(text)}
            style={{
                paddingVertical: 5,
                paddingHorizontal: 5,
                marginHorizontal: 10,
                marginVertical: 5,
                backgroundColor: '#FFF',
                borderRadius: 5,
                borderColor: '#C0C0C0',
                borderWidth: 1,
                alignItems: "flex-start",
                minWidth: 100,
                width: Platform.OS == 'web' && windowWidth <= 414 ? 100 : 'auto',
            }}
            placeholder={placeholder}
        />
    )
}