import React, { useState, useEffect, useCallback } from 'react'
import { StyleSheet, View, LogBox } from 'react-native'
import { GiftedChat } from 'react-native-gifted-chat'

import { COLLECTION_CHAT, COLLECTION_CHAT_READ, firebaseConfig, HEAD_PUSH_NOTIFICATION, LANG } from "../../config/config";

import * as firebase from 'firebase'
import 'firebase/firestore'

if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig)
}
LogBox.ignoreLogs(['Setting a timer for a long period of time'])
const db = firebase.firestore()

import { Header } from '../../components/header';
import { PushnotificationModel } from '../../models/models';
const dialogTitle = LANG.Chat;

function ChatCustomer({ route, navigation }) {
    const [userChat, setUserChat] = useState(null);
    const [messages, setMessages] = useState([]);

    const { _user_id, _user_name } = route.params || ['random_' + (new Date()).getMilliseconds(), ''];

    const chatsRef = db.collection(COLLECTION_CHAT).doc('rooms').collection(`room_${_user_id}`);

    async function readChatForStaff(_user_id) {
        await db.collection(COLLECTION_CHAT_READ).doc('read').update({
            [`room_${_user_id}.customer_read`]: 1,
            [`room_${_user_id}.staff_read`]: 0,
        });
    };

    useEffect(() => {
        // read all
        db.collection(COLLECTION_CHAT_READ).doc('read').update({
            [`room_${_user_id}.customer_read`]: 1,
            [`room_${_user_id}.customer_id`]: _user_id,
            [`room_${_user_id}.customer_name`]: _user_name,
        });
    }, [route]);

    useEffect(() => {
        setUserChat({ '_id': _user_id, 'name': _user_name });
        setMessages([]);

        const unsubscribe = chatsRef.onSnapshot((querySnapshot) => {
            const messagesFirestore = querySnapshot
                .docChanges()
                .filter(({ type }) => type === 'added')
                .map(({ doc }) => {
                    const message = doc.data()
                    //createdAt is firebase.firestore.Timestamp instance
                    //https://firebase.google.com/docs/reference/js/firebase.firestore.Timestamp
                    return { ...message, createdAt: message.createdAt.toDate() }
                })
                .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
            appendMessages(messagesFirestore)
        })

        return () => unsubscribe()
    }, [route]);

    const appendMessages = useCallback(
        (messages) => {
            setMessages((previousMessages) => GiftedChat.append(previousMessages, messages))
        },
        [messages]
    )

    async function handleSend(messages) {
        readChatForStaff(_user_id);
        console.log(messages);

        const title = messages[0]['user']['name'] + ' nhắn tin';
        const content = messages[0]['text'];
        PushnotificationModel.add_message_all_user({
            title, content, 'user_id': 'staff'
        })
            .then((result) => {
                if (__DEV__) {
                    console.log('push notification when chat', result['data']['message']);
                }

                PushnotificationModel.run_schedule();
            });

        const writes = messages.map((m) => chatsRef.add(m))
        await Promise.all(writes)
    }

    return (
        <View style={styles.container}>
            <Header title={dialogTitle} view="chat" navigation={navigation} />
            {_user_id != ''
                && <GiftedChat messages={messages} user={userChat} onSend={handleSend} placeholder="Nhập nội dung" label="Gửi" />
            }
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#E8EAED',
    }
});

export default ChatCustomer;