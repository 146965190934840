import React, { useEffect } from "react";
import { ScrollView, StyleSheet, View, Image, Text, Platform } from "react-native";
import * as WebBrowser from "expo-web-browser";
import { WebAPIModel } from "../../models/models";
import MySlider from "../../components/myslider";
import {
  COLORS_BRAND,
  DOMAIN_DATA,
  windowHeight,
  windowWidth,
} from "../../config/config";
import { ButtonRegister } from "../../components/mybuttons";
import { Button } from "react-native-elements";
import { IsLoadingData } from "../../components/myother";

export default function Intro({ navigation }) {
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const [info, setInfo] = React.useState(null);
  const [listNews, setListNews] = React.useState([]);
  const [listServices, setListServices] = React.useState([]);
  const [about, setAbout] = React.useState(null);

  useEffect(() => {
    setLoading(true);
    WebAPIModel.get_all()
      .then((res) => {
        const data = res.data || {};
        setInfo(data.info);
        setListNews(data.news);
        console.log("data.news: ", data.news);
        setListServices(data.service || data.services);
        setAbout(data.about);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <View style={styles.container}>
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          marginTop: 6,
        }}
        keyboardShouldPersistTaps="handled"
        nestedScrollEnabled={true}
      >
        {isLoading ? (
          <View style={{ marginTop: 12 }}>
            <IsLoadingData />
          </View>
        ) : (
          <>
            <MySlider items={listServices} />
            <About about={about} />
            <News listNews={listNews} />
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                borderRadius: 5,
                alignItems: "center",
                marginBottom: 12,
              }}
            >
              <ButtonRegister
                onPress={() => {
                  navigation.navigate({
                    name: "Login",
                    params: { screen: "signin" },
                  });
                }}
              />
              <ButtonRegister
                title="Đăng ký"
                onPress={() =>
                  navigation.navigate({
                    name: "Login",
                    params: { screen: "signup" },
                  })
                }
              />
            </View>
            <Address info={info} />
          </>
        )}
      </ScrollView>
    </View>
  );
}

function Address(props) {
  const { info } = props;
  return (
    <View style={styles.address}>
      <Text>{info && info.room_name}</Text>
      <Text style={styles.address_text}>{info && info.address}</Text>
      <Text style={styles.address_text}>
        <Text style={{ fontWeight: "bold" }}>Điện thoại:</Text>
        {info &&
          info.phone.map((item, index) => {
            const phoneFormat = item.phone.replace(/[^0-9]/g, "");
            const last = index === info.phone.length - 1 ? "" : " -";
            return (
              <Text
                key={index}
                onPress={() => {
                  WebBrowser.openBrowserAsync(`tel:${phoneFormat}`);
                }}
              >
                {` ${item.phone}${last}`}
              </Text>
            );
          })}
      </Text>
      <Text style={styles.address_text}>
        <Text style={{ fontWeight: "bold" }}>Website:</Text>{" "}
        <Text
          onPress={() => {
            WebBrowser.openBrowserAsync(info && info.website);
          }}
        >
          {info && info.website}
        </Text>
      </Text>
    </View>
  );
}

function About(props) {
  const { about } = props;
  return (
    <View style={styles.about}>
      <Text style={styles.about_text}>
        <Text style={{ color: COLORS_BRAND.primary, fontWeight: "500" }}>
          [Giới Thiệu]
        </Text>{" "}
        {about && `${about.text}`}{" "}
      </Text>
      <Button
        title="Chi tiết"
        buttonStyle={{
          backgroundColor: "transparent",
          alignSelf: "flex-start",
          padding: 0,
        }}
        titleStyle={styles.title}
        iconPosition="right"
        icon={{
          name: "arrow-right",
          type: "font-awesome",
          size: 11,
          color: COLORS_BRAND.primary,
        }}
        onPress={() => {
          WebBrowser.openBrowserAsync(about && about.link);
        }}
      />
    </View>
  );
}

function News(props) {
  return (
    <View
      style={{
        marginTop: 12,
        padding: 12,
        borderTopColor: "#ccc",
        borderTopWidth: 1,
      }}
    >
      {props.listNews.map((item, index) => {
        let title = item.title;
        if (title.length > 60) {
          title = title.substring(0, 60) + "...";
        }
        return (
          <View key={index} style={styles.newsItem}>
            {item.img != "" && (
              <Image
                style={styles.img}
                source={{
                  uri: item.img,
                }}
              />
            )}
            <View style={styles.right_img_news}>
              <Text style={styles.title}>{title}</Text>
              <Button
                title="Chi tiết"
                buttonStyle={{
                  backgroundColor: "transparent",
                  alignSelf: "flex-start",
                  padding: 0,
                  marginTop: 4,
                }}
                titleStyle={styles.title}
                iconPosition="right"
                icon={{
                  name: "arrow-right",
                  type: "font-awesome",
                  size: 11,
                  color: COLORS_BRAND.primary,
                }}
                onPress={() => {
                  WebBrowser.openBrowserAsync(item.link);
                }}
              />
            </View>
          </View>
        );
      })}
      <View
        style={{
          alignSelf: "center",
        }}
      >
        <Button
          title="Xem Toàn Bộ"
          buttonStyle={{
            backgroundColor: "transparent",
            alignSelf: "flex-start",
            padding: 0,
          }}
          titleStyle={styles.title}
          iconPosition="right"
          icon={{
            name: "arrow-right",
            type: "font-awesome",
            size: 11,
            color: COLORS_BRAND.primary,
          }}
          onPress={() => {
            WebBrowser.openBrowserAsync(`${DOMAIN_DATA}/tin-tuc/`);
          }}
        />
      </View>
    </View>
  );
}

const fontSize = Platform.OS === "ios" ? 12 : 13;
const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 25,
  },
  header: {
    fontWeight: "bold",
    fontSize: 18,
    padding: 0,
    paddingBottom: 4,
    marginTop: 8,
  },
  newsItem: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 12,
    width: windowWidth,
  },
  img: {
    width: 60,
    height: 50,
    marginRight: 6,
    borderColor: "gray",
    borderWidth: 1,
    padding: 2,
    borderRadius: 3,
  },
  serviceItem: {
    marginBottom: 2,
  },
  img_service: {
    width: Platform.OS === "web" ? "150px" : windowWidth / 3.1 - 12,
    height: Platform.OS === "web" ? "120px" : (0.65 * windowWidth) / 3,
    marginRight: 12,
    borderColor: "gray",
    borderWidth: 1,
    padding: 2,
    borderRadius: 3,
  },
  right_img: {
    marginTop: 6,
  },
  right_img_news: {
    marginTop: -2,
    width: windowWidth - windowWidth / 3.1 + 24,
  },
  title: {
    color: "#000",
    fontSize: fontSize,
  },
  title_service: {
    color: "#000",
    fontSize: fontSize,
  },
  address: {
    backgroundColor: COLORS_BRAND.primary,
    paddingBottom: 12,
    paddingLeft: 12,
    paddingRight: 12,
    textAlign: "left",
    alignItems: "flex-start",
  },
  address_text: {
    color: "#fff",
    fontSize: fontSize,
  },

  about: {
    padding: 12,
    marginTop: Platform.OS === "web" ? 6 : -12,
    textAlign: "justify",
  },
  about_text: {
    marginTop: 12,
    fontSize: fontSize,
    lineHeight: 18,
    textAlign: "justify",
  },
});
