import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, ScrollView, Pressable } from "react-native";
import { Button, CheckBox, Dialog, Icon } from "react-native-elements";
import { PostModel, WebAPIModel } from "../../models/models";

import { Header } from "../../components/header";

import { mycolors } from "../../config/mycolors";
import { MyPaging, MyListingShowPost } from "../../components/myboxs";
import { IsLoadingData } from "../../components/myother";

import { MyContext } from "../../context/mycontext";
import { MyInputSearchName } from "../../components/myinputs";
import { ButtonSearch } from "../../components/mybuttons";
import { LANG } from "../../config/config";

function ShowNews({ navigation }) {
  const [isLoading, setLoading] = useState<boolean>(false);

  const [categories, setCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState({
    title: LANG.All,
    id: "",
  });

  const [taskItems, setTaskItems] = useState([]);

  const [pagingParams, setPagingParams] = useState({});
  const [currentPage, setCurrentPage] = React.useState(1);

  const [searchName, setSearchName] = React.useState("");
  const [fromDate, setFromDate] = useState("");

  const [openList, setOpenList] = React.useState(true);

  const [visibleDialog, setVisibleDialog] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState("");

  const [visibleDialogSearch, setVisibleDialogSearch] = React.useState(false);

  const dialogTitle = LANG.News;

  function Paging(page = 1) {
    setLoading(true);
    PostModel.gets_to_show(currentCategory.id, page)
      .then((results) => {
        const data: any = results["data"] || [];
        setTaskItems(data["posts"] || []);
        setPagingParams(data["paging"]);
      })
      .catch((err) => {
        setVisibleDialog(true);
        setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
      })
      .finally(() => setLoading(false));
  }

  function Search(category_id, search_name = "", from_date = "") {
    setLoading(true);
    PostModel.gets_to_show(category_id, 1, search_name, from_date)
      .then((results) => {
        const data: any = results["data"] || [];
        setTaskItems(data["posts"] || []);
        setPagingParams(data["paging"]);
        setCurrentPage(1);
      })
      .catch((err) => {
        console.log(err);
        setVisibleDialog(true);
        setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
      })
      .finally(() => setLoading(false));
  }

  async function gets_init() {
    // and gets from website
    const p_posts = PostModel.gets_to_show("", 1);
    const p_categories = WebAPIModel.get_categories();

    setLoading(true);
    Promise.all([p_posts, p_categories])
      .then((values) => {
        const data_posts = values[0]["data"] || [];
        console.log("data_posts: ", data_posts);
        setTaskItems(data_posts["posts"] || []);
        setPagingParams(data_posts["paging"]);

        const data_categories = values[1]["data"] || [];
        console.log("data_categories: ", data_categories["categories"]);
        setCategories(data_categories["categories"] || []);
      })
      .catch((err) => {
        console.log(`${err.message} \n${LANG.CanNotConnect}`);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      // if screen is focused then reload
      console.log("Screen is focused shownews " + Math.random());
      gets_init();
    });

    return () => {
      // cleanup
      // Execute before the next effect or unmount
    };
  }, []);

  return (
    <View style={styles.container}>
      <Header title={dialogTitle} view="chat" navigation={navigation} />

      <Dialog
        isVisible={visibleDialog}
        onBackdropPress={() => setVisibleDialog(!visibleDialog)}
      >
        <Dialog.Title title={dialogTitle} />
        <Text>{dialogMessage}</Text>
      </Dialog>

      <Dialog
        isVisible={visibleDialogSearch}
        onBackdropPress={() => setVisibleDialogSearch(!visibleDialogSearch)}
      >
        <Dialog.Title title={LANG.All} />

        <ScrollView showsHorizontalScrollIndicator={true}>
          <View style={{ width: "auto", alignItems: "flex-start" }}>
            <CheckBox
              center
              title={LANG.All}
              checkedIcon="dot-circle-o"
              uncheckedIcon="circle-o"
              onPress={() => {
                setCurrentCategory({ title: LANG.All, id: "" });
                setVisibleDialogSearch(!visibleDialogSearch);
                Search("", searchName, fromDate);
              }}
              containerStyle={{
                backgroundColor: "transparent",
                borderWidth: 0,
                padding: 0,
                marginTop: 4,
                marginLeft: 0,
                marginRight: 0,
              }}
            />
            {categories.map((item, index) => (
              <CheckBox
                key={index}
                center
                title={item.title}
                checkedIcon="dot-circle-o"
                uncheckedIcon="circle-o"
                checked={currentCategory && currentCategory.id === item.id}
                onPress={() => {
                  setCurrentCategory(item);
                  setVisibleDialogSearch(!visibleDialogSearch);
                  Search(item.id, searchName, fromDate);
                }}
                containerStyle={{
                  backgroundColor: "transparent",
                  borderWidth: 0,
                  padding: 0,
                  marginTop: 4,
                  marginLeft: 0,
                  marginRight: 0,
                }}
              />
            ))}
          </View>
        </ScrollView>
      </Dialog>

      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
        }}
        keyboardShouldPersistTaps="handled"
      >
        {openList && (
          <>
            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                marginTop: 12,
              }}
            >
              <Pressable
                onPress={() => {
                  setVisibleDialogSearch(true);
                }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                }}
              >
                {currentCategory ? (
                  <Text style={{ maxWidth: 130 }}>{currentCategory.title}</Text>
                ) : (
                  <Text>{LANG.ChooseCategory}</Text>
                )}
                <Icon
                  name="chevron-down"
                  type="font-awesome"
                  size={20}
                  color={mycolors.primary}
                  style={{
                    marginLeft: 10,
                  }}
                />
              </Pressable>
              <MyContext.Provider
                value={{
                  Search,
                  searchName,
                  setSearchName,
                }}
              >
                <MyInputSearchName />
              </MyContext.Provider>

              <ButtonSearch
                onPress={() => Search(currentCategory.id, searchName, fromDate)}
              />
            </View>

            {isLoading ? (
              <IsLoadingData />
            ) : (
              <MyContext.Provider
                value={{
                  taskItems,

                  pagingParams,
                  Paging,

                  currentPage,
                  setCurrentPage,
                }}
              >
                <MyListingShowPost />
                <MyPaging />
              </MyContext.Provider>
            )}
          </>
        )}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#E8EAED",
  },
  box: {
    flex: 1,
    // backgroundColor: mycolors.primary2,
    margin: 12,
    marginTop: 6,
    marginBottom: 6,
    padding: 20,
  },
  box2: {
    backgroundColor: mycolors.secondary,
    margin: 12,
    marginTop: 6,
    marginBottom: 6,
    padding: 20,
  },
});

export default ShowNews;
