import React, { useState } from "react";
import { StyleSheet, Text, View, ScrollView, Platform } from 'react-native';
import { Dialog } from 'react-native-elements';

import moment from 'moment'

import { Header } from '../../components/header';

import { ButtonAdd, ButtonCancel, ButtonDelete, ButtonEdit, ButtonSave, ButtonSearch, CloseButtons, OpenButtons } from '../../components/mybuttons'
import { stylesButtons, stylesColumns } from "../../config/mystyles";
import { checkSingleItem, fieldPriceBuy, getsMyItemChecked } from "../../config/myfunc";
import { MyPaging, MyListingBuyOrder } from "../../components/myboxs";
import { IsLoadingData } from "../../components/myother";

import { MyContext } from '../../context/mycontext';
import { arr_pattern, MyControlBuyItem, MyControlInputText, MyControlSparateCalendar } from "../../components/mycontrolinputs";
import { LANG, windowWidth } from "../../config/config";

import { useForm } from "react-hook-form";

import { BuyModel } from "../../models/models"
import { MyInputSearchName } from "../../components/myinputs";
const dialogTitle = LANG.OrderImport;


function BuyOrder({ route, navigation }) {
    const [isLoading, setLoading] = useState<boolean>(false);

    const [taskItems, setTaskItems] = useState([]);

    const [pagingParams, setPagingParams] = useState({});
    const [currentPage, setCurrentPage] = React.useState(1);

    const [searchName, setSearchName] = React.useState('');
    const [fromDate, setFromDate] = useState('');

    const [openList, setOpenList] = React.useState(true);
    const [openForm, setOpenForm] = React.useState(false);
    const [openButton, setOpenButton] = React.useState(true);

    const [visible, setVisible] = React.useState(true);
    const [visibleDialog, setVisibleDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');

    const [permission, setPermission] = React.useState(false);

    // fields of table
    const [id, setID] = useState('');
    // same as birthday

    function checkItem(item: any) {
        const arr = checkSingleItem(taskItems, item);
        setTaskItems(arr);

        setOpenButton(true);
        setVisible(true);
    }

    function deleteItems() {
        let arr: any = getsMyItemChecked(taskItems);

        if (arr.length == 0) {
            setVisibleDialog(true);
            setDialogMessage(LANG.ChooseAtLeastOneToDelete);
            return;
        }

        BuyModel.delete_arr({ 'ids': arr, supplier_id: supplierID })
            .then((results) => {
                const posts_reload = results.data.posts_reload || {};
                if (posts_reload.posts != null) {
                    setTaskItems(posts_reload.posts);
                } else if (results.data.posts != undefined) {
                    setTaskItems(results.data.posts);
                }

                setVisibleDialog(true);
                setDialogMessage(LANG.DeleteSuccessfully);
            })
            .catch(err => {
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            });
    }

    function editItem() {
        let arr: any = getsMyItemChecked(taskItems);
        const lastItem = arr.pop();

        if (lastItem == undefined) {
            setVisibleDialog(true);
            setDialogMessage(LANG.ChooseAtLeastOne);
        } else {
            BuyModel.get(lastItem)
                .then((results) => {
                    const data: any = results.data || {};
                    const post: any = data.post || {};
                    let list_item: any = data.list_item || {};

                    if (__DEV__) {
                        console.log('Get data: ', data);
                        console.log('list item: ', list_item);
                    }

                    let all_items = [];
                    list_item.forEach((ele) => {
                        const fieldName = `${PRODUCT_ITEMS}[${ele["product_id"]}]`;
                        const fieldPrice = fieldPriceBuy(fieldName);

                        const value = ele["quatity"];
                        all_items.push({
                            name: fieldName,
                            id: ele["product_id"],
                            title: ele["product_name"],
                            value: value,
                            price_buy: ele["price"],
                        });
                        setValue(fieldName, value);
                        setValue(fieldPrice, ele["price"]);
                    });
                    setAllItemsChecked(all_items);


                    setID(post.id);
                    fields.forEach(field => {
                        switch (field.Type) {
                            case 'content_object':
                                setValue(`${field.name}`, post[`${field.name}`].info);
                                break;
                            case 'calendar':
                            default:
                                setValue(`${field.name}`, post[`${field.name}`]);
                                break;
                        }
                    });

                    setOpenForm(true);
                    setOpenList(false);
                    setOpenButton(false);
                })
                .catch(err => {
                    setVisibleDialog(true);
                    setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
                });
        }
    }

    function Paging(page = 1) {
        setLoading(true);
        BuyModel.gets(supplierID, page)
            .then((results) => {
                const data: any = results["data"] || [];
                setTaskItems(data["posts"] || []);
                setPagingParams(data['paging'])
            })
            .catch(err => {
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false));
    }

    function Search(search_name = '', from_date = '') {
        setLoading(true);
        BuyModel.gets(supplierID, 1, search_name, from_date)
            .then((results) => {
                const data: any = results["data"] || [];

                if (__DEV__) {
                    console.log('Data search: ', data);
                }

                setTaskItems(data["posts"] || []);
                setPagingParams(data['paging'])
                setCurrentPage(1);
            })
            .catch(err => {
                console.log(err);
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false));
    };

    async function gets_init(_supplier_id = '') {
        const p_posts = BuyModel.gets(_supplier_id, 1, '');

        setLoading(true);
        Promise.all([p_posts]).then((values) => {
            const data_posts = values[0]["data"] || [];

            if (__DEV__) {
                console.log('init data:', data_posts);
            }

            setPermission(data_posts["permission"] || false);

            setTaskItems(data_posts["posts"] || []);
            setPagingParams(data_posts['paging']);
        })
            .catch(err => {
                console.log(`${err.message}`);

                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false))
    }


    /***
     * begin: react hook form
    */
    const { control, handleSubmit, reset, formState: { errors }, getValues, setValue, setFocus, register, unregister } = useForm();

    const onSubmit = data => {
        const data_submit = data;

        if (id != '') {
            BuyModel.update(id, data_submit)
                .then((results) => {
                    const data: any = results.data || {};

                    if (__DEV__) {
                        console.log('Data update: ', data);
                    }

                    if (data.post != undefined && data.post.id != undefined) {
                        const obj_task: any = { ...data.post, 'checked': false };
                        let arr: any = [];
                        taskItems.forEach(el => {
                            let el_tg: any = el;
                            if (el_tg['id'] == obj_task.id) {
                                arr.push(obj_task);
                            } else {
                                arr.push(el);
                            }
                        });
                        setTaskItems(arr);

                        setVisibleDialog(true);
                        setDialogMessage(`${data.message}`);
                    } else {
                        setVisibleDialog(true);
                        setDialogMessage(`${data.message}`);
                    }

                    setOpenForm(false);
                    setOpenList(true);
                    setOpenButton(true);
                    setVisibleDialog(true);

                    reset();
                })
                .catch(err => {
                    setVisibleDialog(true);
                    setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
                })
                .finally(() => {
                    setID('');
                    setLoading(false);
                });
        } else {
            BuyModel.create(data_submit)
                .then((results) => {
                    const data: any = results.data || {};
                    if (data.post != undefined && data.post.id != undefined) {
                        const obj_task: any = { ...data.post };
                        setTaskItems([obj_task, ...taskItems]);

                        setVisibleDialog(true);
                        setDialogMessage(`${data.message}`);
                    } else {
                        setVisibleDialog(true);
                        setDialogMessage(`${data.message}`);
                    }

                    setOpenForm(false);
                    setOpenList(true);
                    setOpenButton(true);
                    setVisibleDialog(true);

                    reset();
                })
                .catch(err => {
                    setVisibleDialog(true);
                    setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
                    setLoading(false);
                })
                .finally(() => {
                    setID('');
                    setLoading(false);
                });
        }
    };

    const myHandleSubmit = () => {
        // convert before submit
        setValue("supplier_id", supplierID);
        setValue("supplier_name", supplierName);

        let other_validate = true;

        if (allItemsChecked.length <= 0) {
            setDialogMessage(`Hãy chọn ít nhất 1 sản phẩm!`)
            setVisibleDialog(true);

            other_validate = false;
        }

        fields.forEach((field) => {
            switch (field.Type) {
                case "calendar":
                    if (field.name == 'date_created') {
                        let d = getValues(field.child_date['date']);
                        if (parseInt(d) < 10) {
                            d = `0${parseInt(d)}`;
                        }
                        let m = getValues(field.child_date['month']);
                        if (parseInt(m) < 10) {
                            m = `0${parseInt(m)}`;
                        }
                        const y = getValues(field.child_date['year']);
                        const date_created = `${y}/${m}/${d} 00:00:00`;
                        setValue(field.name, date_created);

                        const validate = moment(`${m}/${d}/${y}`, 'MM/DD/YYYY', true).isValid();
                        if (!validate && d != '' && m != '' && y != '') {
                            setDialogMessage(`Tháng ${m}/${y} không có ngày ${d}!`)
                            setVisibleDialog(true);
                            setFocus(field.child_date['date']);

                            other_validate = false;
                        }
                    }
                    break;
                case "image":
                default:
                    break;
            }
        });

        if (other_validate) {
            handleSubmit(onSubmit)();
        }
    };

    const api_fields = [
        {
            name: "title",
            lable: LANG.Field.OrderName,
            placeholder: LANG.Field.EnterOrderName,
            rules: {
                required: true,
                pattern: false,
            },
            icon: {
                name: "info-circle",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
        },
        {
            name: "date_created",
            child_date: {
                'date': 'date_of_month',
                'month': 'month_of_year',
                'year': 'year_of_calendar'
            },
            lable: LANG.Field.OrderDate,
            placeholder: LANG.Field.EnterOrderDate,
            rules: {
                required: true,
                pattern: false,
            },
            icon: {
                name: "calendar",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
            Type: "calendar",
        },
        {
            name: "content",
            lable: LANG.Field.OtherInfo,
            placeholder: LANG.Field.EnterOtherInfo,
            rules: {
                pattern: false,
            },
            icon: {
                name: "info-circle",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
            Type: "content_object",
            multiline: true
        },
    ];

    const arr_validate = {
        validate_password: {
            matchesPreviousPassword: (value) => {
                const { password } = getValues();
                return password === value || LANG.PasswordRequiredValidate;
            }
        }
    };

    let column = {};
    let row = {};
    if (windowWidth <= 768) {
        column = stylesColumns.columnSmall;
        row = stylesColumns.rowSmall;
    } else if (windowWidth >= 769) {
        // column = stylesColumns.columnMedium;
        // row = stylesColumns.rowMedium;
    }

    const [fields, setFields] = React.useState([]);

    /***
      * end: react hook form
    */

    const [supplierID, setSupplierID] = React.useState('');
    const [supplierName, setSupplierName] = React.useState('');

    React.useEffect(() => {
        setFields(api_fields);

        const unsubscribe = navigation.addListener('focus', () => {

            const { _supplier_id, _supplier_name } = route.params || ['', ''];
            if (_supplier_id != undefined) {
                console.log('_supplier_id: ', _supplier_id);
                console.log('_supplier_name: ', _supplier_name);

                gets_init(_supplier_id);

                setSupplierID(_supplier_id);
                setSupplierName(_supplier_name);
            }

            setOpenForm(false);
            setOpenButton(true);
            setOpenList(true);
        });

        // cleanup
        return unsubscribe;
    }, [route]);


    const PRODUCT_ITEMS = 'product_items';
    const [allItemsChecked, setAllItemsChecked] = React.useState([]);

    React.useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            // if screen is focused then reload
            console.log('Screen is focused buy order: ' + Math.random());

            const { _order_id, _items_checked } = route.params || ['', ''];

            if (_order_id != undefined) {
                console.log('order_id: ', _order_id);

                // push item to array if not exist
                const old_ids_checked = allItemsChecked.map(item => item.id);
                let all_items = allItemsChecked;
                _items_checked.forEach(ele => {
                    const fieldName = `${PRODUCT_ITEMS}[${ele['id']}]`
                    ele['name'] = fieldName;
                    if (old_ids_checked.indexOf(ele['id']) < 0) {
                        all_items.push(ele);
                    }
                });
                setAllItemsChecked(all_items);
                console.log('all_items: ', all_items);

                // set value of field item
                all_items.forEach(field => {
                    const fieldName = `${field.name}`;
                    const fieldPrice = fieldPriceBuy(fieldName);

                    let old_value = getValues(fieldName) || 0;
                    let old_price_value = getValues(fieldPrice) || 0;
                    if (old_value != 0) {
                        setValue(fieldName, parseInt(old_value));
                        setValue(fieldPrice, parseInt(old_price_value));
                    } else {
                        setValue(fieldName, parseInt(field.value));
                        setValue(fieldPrice, parseInt(field.price_buy));
                    }
                });

                setOpenForm(true); setOpenButton(false); setOpenList(false);
            }
        });

        // cleanup
        return unsubscribe;
    }, [route]);


    function removeBuyItem(_item) {
        const currentFieldName = `${PRODUCT_ITEMS}[${_item.id}]`;
        unregister(currentFieldName);
        unregister(fieldPriceBuy(currentFieldName));

        const all_items = allItemsChecked.filter(item => item.id != _item.id);
        setAllItemsChecked(all_items);

        all_items.forEach(field => {
            let fieldName = `${PRODUCT_ITEMS}[${field.id}]`;
            let old_value = getValues(fieldName) || 0;
            if (old_value != 0) {
                setValue(fieldName, parseInt(old_value));
            } else {
                setValue(fieldName, parseInt(field.value));
            }
        });
    };

    return (
        <View style={styles.container}>
            <Header title={dialogTitle} view="chat" navigation={navigation} />

            <Dialog
                isVisible={visibleDialog}
                onBackdropPress={() => setVisibleDialog(!visibleDialog)}
            >
                <Dialog.Title title={dialogTitle} />
                <Text>{dialogMessage}</Text>
            </Dialog>

            <ScrollView
                contentContainerStyle={{
                    flexGrow: 1,
                }}
                keyboardShouldPersistTaps="handled"
            >
                {openForm && (
                    <View style={styles.box}>
                        <View style={row}>
                            {fields.map((field, key) => {
                                switch (field.Type) {
                                    case "calendar":
                                        return (
                                            <View key={key} style={column}>
                                                <View style={{ marginBottom: 20 }}>
                                                    <Text style={styles.textCalendar}>{field.lable}:</Text>
                                                </View>

                                                <MyContext.Provider value={{
                                                    setValue,
                                                    getValues,
                                                    reset,
                                                }}>
                                                    <MyControlSparateCalendar
                                                        child_date={field.child_date}
                                                        control={control}
                                                        errors={errors}
                                                        default_fulldate={getValues(field.name)}
                                                    />
                                                </MyContext.Provider>
                                            </View>
                                        );
                                        break;
                                    default:
                                        return (
                                            <View key={key} style={column}>
                                                <Text style={styles.text}> {field.lable}:</Text>
                                                <MyControlInputText
                                                    action={id != '' ? 'edit' : 'add'}
                                                    field={field}
                                                    control={control}
                                                    errors={errors}
                                                    pattern={field.rules.pattern != undefined ? arr_pattern[field.rules.pattern] : ''}
                                                    validate={field.rules.validate != undefined ? arr_validate[field.rules.validate] : ''}
                                                />
                                                {field.confirmField != null && (
                                                    <>
                                                        <Text style={styles.text}>{field.confirmField.lable}:</Text>
                                                        <MyControlInputText
                                                            action={id != '' ? 'edit' : 'add'}
                                                            field={field.confirmField}
                                                            control={control}
                                                            errors={errors}
                                                            pattern={field.confirmField.rules.pattern != undefined ? arr_pattern[field.confirmField.rules.pattern] : ''}
                                                            validate={field.confirmField.rules.validate != undefined ? arr_validate[field.confirmField.rules.validate] : ''}
                                                        />
                                                    </>
                                                )}
                                            </View>
                                        );
                                        break;
                                }
                            })}
                        </View>


                        {isLoading == true ? (
                            <IsLoadingData title={LANG.Updating} />
                        ) : (
                            <>
                                <MyContext.Provider value={
                                    {
                                        removeItem: removeBuyItem
                                    }}>
                                    {allItemsChecked.map((item: any, index) => {
                                        return <MyControlBuyItem
                                            key={item.id}
                                            field={item}
                                            control={control}
                                            errors={errors}
                                        />
                                    })}
                                </MyContext.Provider>

                                <ButtonAdd
                                    title="Chọn sản phẩm"
                                    onPress={() => {
                                        navigation.navigate('BuyOrderItem', {
                                            _order_id: id,
                                        });
                                    }}
                                />

                                <View style={{ flexDirection: "row", alignSelf: "center", marginTop: 12 }}>
                                    <ButtonCancel onPress={() => {
                                        setID('');
                                        setOpenForm(false);
                                        setOpenButton(true);
                                        setOpenList(true);
                                    }}
                                    />
                                    <ButtonSave onPress={() => myHandleSubmit()} />
                                </View>
                            </>
                        )}
                    </View>
                )}

                {openList && (
                    <>
                        <View style={{ flexDirection: "row", alignSelf: "center", marginTop: 12 }}>
                            <MyContext.Provider value={{
                                Search,
                                searchName,
                                setSearchName,
                            }}>
                                <MyInputSearchName />
                            </MyContext.Provider>

                            <ButtonSearch onPress={() => Search(searchName, fromDate)} />
                        </View>

                        {isLoading ? (
                            <IsLoadingData />
                        ) : (
                            <MyContext.Provider value={
                                {
                                    taskItems,
                                    checkItem,

                                    pagingParams,
                                    Paging,

                                    currentPage,
                                    setCurrentPage,
                                }}>
                                <MyListingBuyOrder />
                                <MyPaging />
                            </MyContext.Provider>
                        )}

                    </>
                )}

            </ScrollView >

            {permission == true && <>
                {openButton && (
                    <View style={{ flexDirection: "row", alignSelf: "center", "position": "absolute", bottom: 5 }}>
                        <ButtonAdd onPress={() => {
                            // begin reset all
                            reset();
                            setAllItemsChecked([]);

                            setOpenForm(true); setOpenButton(false); setOpenList(false);
                        }}
                        />
                        <ButtonEdit onPress={() => editItem()} />
                        <ButtonDelete onPress={() => deleteItems()} />
                    </View>
                )}

                <View style={stylesButtons.open_close}>
                    {visible == true ? (
                        <OpenButtons onPress={() => { setOpenButton(false); setVisible(false); }} />
                    ) : (
                        <CloseButtons onPress={() => { setOpenButton(true); setVisible(true); }} />
                    )}
                </View>
            </>}

        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#E8EAED',
    },
    box: {
        flex: 1,
        margin: 12,
        marginTop: 6,
        marginBottom: 6,
        padding: 20
    },
    text: {
        marginTop: 18,
        marginBottom: Platform.OS == 'web' ? -12 : -24,
        color: '#000',
        alignSelf: "flex-start",
        paddingLeft: 12
    },
    textCalendar: {
        marginTop: 18,
        marginBottom: Platform.OS == 'web' ? -12 : -12,
        color: '#000',
        alignSelf: "flex-start",
        paddingLeft: 12
    }
});

export default BuyOrder;
