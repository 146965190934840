import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, ScrollView, Platform, LogBox } from 'react-native';
import { Dialog, Icon } from 'react-native-elements';
import { ButtonAdd, ButtonCancel, ButtonDelete, ButtonEdit, ButtonSave, ButtonSearch, CloseButtons, OpenButtons } from '../../components/mybuttons'

import moment from "moment";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { BookingModel, PushnotificationModel } from "../../models/models"

import { Header } from '../../components/header';

import { stylesButtons, stylesColumns } from "../../config/mystyles";
import { getsMyItemChecked } from "../../config/myfunc";
import { MyPaging, MySelectCalendarSearch, MyDisplayFromToDate, MyListingBook, BoxOpenShortMenu } from "../../components/myboxs";
import { IsLoadingData } from "../../components/myother";

import { MyContext } from '../../context/mycontext';
import { arr_pattern, MyControlInputText, MyControlShortMenuCustomer, MyControlSparateCalendar } from "../../components/mycontrolinputs";
import { COLLECTION_CHAT_READ, COLLECTION_SCHEDULE_READ, CUSTOMER_ID_THEMSELF, firebaseConfig, HEAD_PUSH_NOTIFICATION, LANG, windowWidth } from "../../config/config";

import { useForm } from "react-hook-form";
import { MyInputSearchName } from "../../components/myinputs";

import * as firebase from 'firebase'
import 'firebase/firestore'

if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig)
}
LogBox.ignoreLogs(['Setting a timer for a long period of time'])
const db = firebase.firestore()

function BookingCustomer({ route, navigation }) {
    const [isLoading, setLoading] = useState<boolean>(false);

    const [taskItems, setTaskItems] = useState([]);

    const [pagingParams, setPagingParams] = useState({});
    const [currentPage, setCurrentPage] = React.useState(1);

    const [searchName, setSearchName] = React.useState('');
    const [visibleSearchCalendar, setVisibleSearchCalendar] = React.useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const [openList, setOpenList] = React.useState(true);
    const [openForm, setOpenForm] = React.useState(false);
    const [openButton, setOpenButton] = React.useState(true);

    const [visible, setVisible] = React.useState(true);
    const [visibleDialog, setVisibleDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');

    const [visibleShortMenu, setVisibleShortMenu] = React.useState(false);

    const [id, setID] = useState('');

    const dialogTitle = LANG.CalendarAppointment;

    function checkItem(item: any) {
        let arr: any = [];
        taskItems.forEach(el => {
            let el_tg: any = el;
            if (el_tg.id == item.id) {
                if (!el_tg['checked'])
                    el_tg['checked'] = true;
                else
                    el_tg['checked'] = false;
            } else {
                el_tg['checked'] = false;
            }
            arr.push(el_tg);
        });

        setTaskItems(arr);
    };

    function deleteItems() {
        const arr = getsMyItemChecked(taskItems);

        if (arr.length == 0) {
            setVisibleDialog(true);
            setDialogMessage(LANG.ChooseAtLeastOneToDelete);
            return;
        }

        BookingModel.delete_arr({ 'ids': arr, customer_id: customerID })
            .then((results) => {
                const data: any = results.data || {};
                if (data['posts'] != undefined) {
                    setTaskItems(data['posts'] || []);
                    setPagingParams(data['paging'])
                }

                setVisibleDialog(true);
                setDialogMessage(LANG.DeleteSuccessfully);
            })
            .catch(err => {
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            });
    };

    function editItem() {
        let arr: any = getsMyItemChecked(taskItems);
        const lastItem = arr.pop();

        if (lastItem == undefined) {
            setVisibleDialog(true);
            setDialogMessage(LANG.ChooseAtLeastOne);
        } else {
            BookingModel.get(lastItem)
                .then((results) => {
                    if (__DEV__) {
                        console.log('customer book:', results);
                    }
                    const post: any = results.data.post || {};

                    setID(post.id);
                    fields.forEach(field => {
                        if (field.Type == 'calendar') {
                            setValue(`${field.name}`, post[`${field.name}`]);
                        } else {
                            setValue(`${field.name}`, post[`${field.name}`]);
                        }
                    });

                    setOpenForm(true);
                    setOpenList(false);
                    setOpenButton(false);
                })
                .catch(err => {
                    setVisibleDialog(true);
                    setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
                });
        }
    };

    function Paging(page = 1) {
        setLoading(true);
        BookingModel.gets(customerID, userFamilyID, page)
            .then((results) => {
                const data: any = results["data"] || [];
                setTaskItems(data["posts"] || []);
                setPagingParams(data['paging'])
            })
            .catch(err => {
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false));
    };

    function Search(search_name = '', from_date = '') {
        setLoading(true);
        BookingModel.gets(customerID, userFamilyID, 1, search_name, from_date)
            .then((results) => {
                const data: any = results["data"] || [];

                if (__DEV__) {
                    console.log('data: ', data)
                }

                setTaskItems(data["posts"] || []);
                setPagingParams(data['paging'])
                setCurrentPage(1);
            })
            .catch(err => {
                console.log(err);
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false));
    };

    async function gets_init(_customer_id = '', _user_family_id = '') {
        let p_posts = BookingModel.gets(_customer_id, _user_family_id, 1);

        setLoading(true);
        Promise.all([p_posts]).then((values) => {
            if (__DEV__) {
                // console.log('init customer book:', values);
            }
            const data_posts = values[0]["data"] || [];

            setTaskItems(data_posts["posts"] || []);
            setPagingParams(data_posts['paging'])
        })
            .catch(err => {
                console.log(`${err.message}`);

                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false));
    }

    /***
     * begin: react hook form
    */
    const { control, handleSubmit, reset, formState: { errors }, getValues, setValue, setFocus } = useForm();

    const onSubmit = data => {
        const data_submit = data;

        if (id != '') {
            BookingModel.update(id, data_submit)
                .then((results) => {
                    if (__DEV__) {
                        console.log('customer update book:', results);
                    }

                    const data: any = results.data || {};
                    if (data.post != undefined && data.post.id != undefined) {
                        const obj_task: any = {
                            'id': data.post.id,
                            'title': data.post.title,
                            'content': data.post.content,
                            'diagnose': data.post.diagnose,
                            'date_created': data.post.date_created,
                            'checked': false,
                            'customer': data.post.customer,
                        };
                        let arr: any = [];
                        taskItems.forEach(el => {
                            let el_tg: any = el;
                            if (el_tg['id'] == obj_task.id) {
                                arr.push(obj_task);
                            } else {
                                arr.push(el);
                            }
                        });
                        setTaskItems(arr);

                        setDialogMessage(LANG.UpdateSuccessfully);
                    } else {
                        setVisibleDialog(true);
                        setDialogMessage(LANG.CanNotUpdate);
                    }

                    setOpenForm(false);
                    setOpenList(true);
                    setOpenButton(true);
                    setVisibleDialog(true);

                    reset();
                })
                .catch(err => {
                    setVisibleDialog(true);
                    setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
                })
                .finally(() => {
                    setID('');
                    setLoading(false);
                });
        } else {
            BookingModel.create(data_submit)
                .then((results) => {
                    const data: any = results.data || {};
                    if (data['posts'] != undefined) {
                        const the_post = data['post'] || {};

                        setTaskItems(data['posts'] || []);
                        setPagingParams(data['paging'])

                        setDialogMessage(LANG.AddNewSuccessfully);

                        Promise.all([AsyncStorage.getItem('user_id'), AsyncStorage.getItem('display_name')])
                            .then((values) => {
                                const _user_id = values[0];
                                const _user_name = values[1];

                                db.collection(COLLECTION_SCHEDULE_READ).doc('read').update({
                                    [`room_${_user_id}.staff_read`]: 0,
                                });

                                const title = _user_name + ' đặt lịch hẹn';
                                const content = the_post['content'] || '';
                                PushnotificationModel.add_message_all_user({
                                    title, content, 'user_id': 'staff'
                                })
                                    .then((result) => {
                                        if (__DEV__) {
                                            console.log('push notification when customer book', result['data']['message']);
                                        }

                                        PushnotificationModel.run_schedule();
                                    });
                            });
                    } else {
                        setVisibleDialog(true);
                        setDialogMessage(LANG.CanNotAddNew);
                    }

                    setOpenForm(false);
                    setOpenList(true);
                    setOpenButton(true);
                    setVisibleDialog(true);

                    reset();
                })
                .catch(err => {
                    setVisibleDialog(true);
                    setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
                })
                .finally(() => {
                    setID('');
                    setLoading(false);
                });
        }
    };

    const myHandleSubmit = () => {
        // convert before submit
        setValue('customer_id', customerID);

        let other_validate = true;
        fields.forEach((field) => {
            switch (field.Type) {
                case "calendar":
                    if (field.name == 'date_publish' || field.name == 'date_health_check') {
                        let d = getValues(field.child_date['date']);
                        if (parseInt(d) < 10) {
                            d = `0${parseInt(d)}`;
                        }
                        let m = getValues(field.child_date['month']);
                        if (parseInt(m) < 10) {
                            m = `0${parseInt(m)}`;
                        }

                        let hour = getValues(field.child_date['hour']);
                        if (parseInt(hour) < 10) {
                            hour = `0${parseInt(hour)}`;
                        }
                        let minute = getValues(field.child_date['minute']);
                        if (parseInt(minute) < 10) {
                            minute = `0${parseInt(minute)}`;
                        }

                        const y = getValues(field.child_date['year']);
                        const date_tg = `${y}/${m}/${d} ${hour}:${minute}:00`;
                        setValue(field.name, date_tg);

                        const validate = moment(`${m}/${d}/${y}`, 'MM/DD/YYYY', true).isValid();
                        if (!validate && d != '' && m != '' && y != '') {
                            setDialogMessage(`Tháng ${m}/${y} không có ngày ${d}!`)
                            setVisibleDialog(true);
                            setFocus(field.child_date['date']);

                            other_validate = false;
                        }
                    }
                    break;
                case "image":
                default:
                    break;
            }
        });

        if (other_validate) {
            handleSubmit(onSubmit)();
        }
    }


    const api_fields = [
        {
            name: "date_health_check",
            child_date: {
                'date': 'date_of_month',
                'month': 'month_of_year',
                'year': 'year_of_calendar',
                'hour': 'hour_of_datetime',
                'minute': 'minute_of_datetime'
            },
            visible_hour_minute: true,
            lable: LANG.Field.HealthDate,
            placeholder: LANG.Field.EnterHealthDate,
            rules: {
                required: true,
                pattern: false,
            },
            icon: {
                name: "calendar",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
            Type: "calendar",
        },
        {
            name: "title",
            lable: LANG.Field.Title,
            placeholder: LANG.Field.EnterTitle,
            rules: {
                required: true,
                pattern: false,
            },
            icon: {
                name: "info-circle",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
        },
        {
            name: "content",
            lable: LANG.Field.Content,
            placeholder: LANG.Field.EnterContent,
            rules: {
                required: true,
                pattern: false,
            },
            icon: {
                name: "info-circle",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
            multiline: true,
        },
    ];

    const arr_validate = {
        validate_password: {
            matchesPreviousPassword: (value) => {
                const { password } = getValues();
                return password === value || LANG.PasswordRequiredValidate;
            }
        }
    };

    let column = {};
    let row = {};
    if (windowWidth <= 768) {
        column = stylesColumns.columnSmall;
        row = stylesColumns.rowSmall;
    } else if (windowWidth >= 769) {
        // column = stylesColumns.columnMedium;
        // row = stylesColumns.rowMedium;
    }

    const [fields, setFields] = React.useState([]);

    /***
     * end: react hook form
    */


    useEffect(() => {
        setFields(api_fields);

        const unsubscribe = navigation.addListener('focus', () => {
            // if screen is focused then reload
            console.log('Screen is focused post.admin ' + Math.random());

            gets_init(CUSTOMER_ID_THEMSELF);
            setCustomerID(CUSTOMER_ID_THEMSELF);
        });

        return () => {
            // cleanup
            // Execute before the next effect or unmount
        };
    }, []);

    const [customerID, setCustomerID] = React.useState('');
    const [customerName, setCustomerName] = React.useState('');
    const [userFamilyID, setUserFamilyID] = React.useState('');
    const [userFamilyName, setUserFamilyName] = React.useState('');

    React.useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            // if screen is focused then reload

            const { _customer_id, _customer_name, _user_family_id, _user_family_name } = route.params || [CUSTOMER_ID_THEMSELF, '', '', ''];
            if (_user_family_id != undefined) {
                console.log('_customer_id: ', _customer_id);
                console.log('_customer_name: ', _customer_name);
                console.log('_user_family_id: ', _user_family_id);
                console.log('_user_family_name: ', _user_family_name);

                gets_init(_customer_id, _user_family_id);

                setCustomerID(_customer_id);
                setCustomerName(_customer_name);
                setUserFamilyID(_user_family_id);
                setUserFamilyName(_user_family_name);
            }
        });

        // cleanup
        return unsubscribe;
    }, [route]);

    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            // read all
            Promise.all([AsyncStorage.getItem('user_id'), AsyncStorage.getItem('display_name')])
                .then((values) => {
                    const _user_id = values[0];
                    const _user_name = values[1];

                    const schedulesRead = db.collection(COLLECTION_SCHEDULE_READ).doc('read');
                    schedulesRead.get().then((res) => {
                        const obj_data = res.data();
                        let check_exist = false;
                        for (let key in obj_data) {
                            if (`room_${_user_id}` == key) {
                                check_exist = true;
                            }
                        }
                        schedulesRead.update({
                            [`room_${_user_id}.customer_read`]: 1,
                            [`room_${_user_id}.customer_id`]: _user_id,
                            [`room_${_user_id}.customer_name`]: _user_name,
                            [`room_${_user_id}.staff_read`]: check_exist ? 0 : 1,
                        });
                    });
                });
        });

        // cleanup
        return unsubscribe;
    }, []);

    return (
        <View style={styles.container}>
            <Header title={dialogTitle} view="chat" navigation={navigation} />

            <Dialog
                isVisible={visibleDialog}
                onBackdropPress={() => setVisibleDialog(!visibleDialog)}
            >
                <Dialog.Title title={dialogTitle} />
                <Text>{dialogMessage}</Text>
            </Dialog>

            {visibleShortMenu && (
                <MyContext.Provider value={
                    {
                        visibleShortMenu,
                        setVisibleShortMenu,

                    }}>
                    <MyControlShortMenuCustomer
                        navigation={navigation}
                        customer_id={customerID}
                        customer_name={customerName}
                    />
                </MyContext.Provider>
            )}

            <ScrollView
                contentContainerStyle={{
                    flexGrow: 1,
                }}
                keyboardShouldPersistTaps="handled"
            >
                {openForm && (
                    <View style={styles.box}>

                        <View style={row}>
                            {fields.map((field, key) => {
                                switch (field.Type) {
                                    case "calendar":
                                        return (
                                            <View key={key} style={column}>
                                                <View style={{ marginBottom: 20 }}>
                                                    <Text style={styles.textCalendar}>{field.lable}:</Text>
                                                </View>

                                                <View style={{ display: "flex", flexDirection: "row" }}>
                                                    <Icon
                                                        name={field.icon.name}
                                                        type="font-awesome"
                                                        color="#86939e"
                                                        size={25}
                                                        style={{ marginTop: 12, marginLeft: 12 }}
                                                    />
                                                    <MyContext.Provider value={{
                                                        setValue,
                                                        getValues,
                                                        reset,
                                                    }}>
                                                        <MyControlSparateCalendar
                                                            child_date={field.child_date}
                                                            visible_hour_minute={field.visible_hour_minute}
                                                            control={control}
                                                            errors={errors}
                                                            default_fulldate={getValues(field.name)}
                                                        />
                                                    </MyContext.Provider>
                                                </View>
                                            </View>
                                        );
                                        break;
                                    default:
                                        return (
                                            <View key={key} style={column}>
                                                <Text style={styles.text}>{field.lable}:</Text>
                                                <MyControlInputText
                                                    field={field}
                                                    control={control}
                                                    errors={errors}
                                                    pattern={field.rules.pattern != undefined ? arr_pattern[field.rules.pattern] : ''}
                                                    validate={field.rules.validate != undefined ? arr_validate[field.rules.validate] : ''}
                                                />
                                            </View>
                                        );
                                        break;
                                }
                            })}
                        </View>

                        <View style={{ flexDirection: "row", alignSelf: "center", marginTop: 30 }}>
                            {isLoading == true ? (
                                <IsLoadingData title={LANG.Updating} />
                            ) : (
                                <>
                                    <ButtonCancel onPress={() => { setOpenForm(false); setOpenButton(true); setOpenList(true); }} />
                                    <ButtonSave onPress={() => myHandleSubmit()} />
                                </>
                            )}
                        </View>
                    </View>
                )}

                {openList && (
                    <>
                        <MyContext.Provider value={{
                            setVisibleShortMenu,
                        }}>
                            <BoxOpenShortMenu
                                customerName={customerName}
                                customerID={customerID}
                                userFamilyName={userFamilyName}
                                navigation={navigation}
                            />
                        </MyContext.Provider>

                        <View style={{ flexDirection: "row", alignSelf: "center", marginTop: 12 }}>
                            <MyContext.Provider value={{
                                Search,
                                searchName,
                                setSearchName,
                            }}>
                                <MyInputSearchName />
                            </MyContext.Provider>

                            <MyContext.Provider value={{
                                visibleSearchCalendar,
                                setVisibleSearchCalendar,

                                fromDate,
                                setFromDate,

                                toDate,
                                setToDate,
                            }}>
                                <MySelectCalendarSearch />
                            </MyContext.Provider>

                            <ButtonSearch onPress={() => Search(searchName, fromDate)} />
                        </View>

                        <MyContext.Provider value={{
                            fromDate,
                            setFromDate,

                            toDate,
                            setToDate,
                        }}>
                            <MyDisplayFromToDate />
                        </MyContext.Provider>

                        {isLoading ? (
                            <IsLoadingData />
                        ) : (
                            <MyContext.Provider value={
                                {
                                    taskItems,
                                    checkItem,

                                    pagingParams,
                                    Paging,

                                    currentPage,
                                    setCurrentPage,
                                }}>
                                <MyListingBook />
                                <MyPaging />
                            </MyContext.Provider>
                        )}
                    </>
                )}

            </ScrollView>

            {openButton && (
                <View style={{ flexDirection: "row", alignSelf: "center", "position": "absolute", bottom: 5 }}>
                    <ButtonAdd onPress={() => { setOpenForm(true); setOpenButton(false); setOpenList(false); }} />
                    <ButtonEdit onPress={() => editItem()} />
                    <ButtonDelete onPress={() => deleteItems()} />
                </View>
            )}

            <View style={stylesButtons.open_close}>
                {visible == true ? (
                    <OpenButtons onPress={() => { setOpenButton(false); setVisible(false); }} />
                ) : (
                    <CloseButtons onPress={() => { setOpenButton(true); setVisible(true); }} />
                )}
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#E8EAED',
    },
    box: {
        flex: 1,
        margin: 12,
        marginTop: 6,
        marginBottom: 6,
        padding: 20
    },
    text: {
        marginTop: 18,
        marginBottom: Platform.OS == 'web' ? -12 : -24,
        color: '#000',
        alignSelf: "flex-start",
        paddingLeft: 12
    },
    textCalendar: {
        marginTop: 18,
        marginBottom: Platform.OS == 'web' ? -12 : -12,
        color: '#000',
        alignSelf: "flex-start",
        paddingLeft: 12
    },
    error: {
        color: "#f00",
        marginTop: Platform.OS == 'web' ? -8 : -20,
        alignSelf: "flex-start",
        paddingLeft: 12
    }
});

export default BookingCustomer;
