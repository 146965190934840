import React from "react";
import { Text, View, StyleSheet, ScrollView, Platform, Pressable } from 'react-native';
import { Button, CheckBox } from 'react-native-elements';
import { useForm, Controller } from "react-hook-form";

import { Header } from '../../components/header';
import { windowWidth, windowHeight, convertMyDatePicker } from "../../config/config";
import { MyControlInputText, arr_pattern, MyControlDisplayCalendar, MyControlCalendar, MyControlCheckbox, MyControlMultiCheckbox, MyControlRadioCheck, MyControlModalListSelect } from "../../components/mycontrolinputs";

import { MyContext } from '../../context/mycontext';
import { ButtonAdd, ButtonCancel } from "../../components/mybuttons";
import { Overlay } from "react-native-elements/dist/Overlay";
import { stylesColumns } from "../../config/mystyles";


function TestSuperadmin({ navigation }) {
    const id = 1;
    let initalValues = {};
    if (id > 0) {
        initalValues = {
            defaultValues: {
                phone: "",
                first_name: "Van",
                last_name: "Bien",
                email: "biencoder@gmail.com",
                password: "",
                address: "",
                confirm_password: "",

                multicheckn: [
                    { id: "1", lbl: "Giá trị 1" },
                    { id: "2", lbl: "Giá trị 2" },
                    { id: "3", lbl: "Giá trị 3" },
                    { id: "4", lbl: "Giá trị 4" },
                ],
                radiocheck1: [
                    { id: "1", lbl: "Giá trị 1" },
                    { id: "2", lbl: "Giá trị 2" },
                ],
            }
        };
    } else {
        initalValues = {}
    }

    const { control, handleSubmit, formState: { errors }, getValues, setValue } = useForm(initalValues);
    const onSubmit = data => {
        console.log(data)
    };

    const myHandleSubmit = () => {
        // convert before submit
        fields.forEach(field => {
            if (field.Type == "calendar") {
                if (getValues(`${field.name}`) != undefined && getValues(`${field.name}_hour`) != undefined) {
                    const fulldate = convertMyDatePicker(
                        getValues(`${field.name}`),
                        getValues(`${field.name}_hour`),
                        getValues(`${field.name}_minute`)
                    );
                    setValue(`${field.name}`, fulldate);
                }
            }
        });

        // other field 
        setValue("listChildren", listChildren);

        handleSubmit(onSubmit)();
    }

    const api_fields = [
        {
            name: "birthday",
            lable: "Ngày sinh",
            placeholder: "Hãy chọn ngày sinh",
            rules: {
                required: true,
                pattern: false,
            },
            icon: {
                name: "calendar",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
            Type: "calendar",
            subFieldHour: true,
            subFieldMinute: true,
        },
        // {
        //     name: "startDate",
        //     lable: "Ngày bắt đầu",
        //     placeholder: "Hãy chọn ngày bắt đầu",
        //     rules: {
        //         required: true,
        //         pattern: false,
        //     },
        //     icon: {
        //         name: "calendar",
        //         size: 25
        //     },
        //     keyboardType: "default",
        //     isPassword: false,
        //     Type: "calendar",
        //     subFieldHour: true
        // },
        // {
        //     name: "finishDate",
        //     lable: "Ngày kết thúc",
        //     placeholder: "Hãy chọn ngày kết thúc",
        //     rules: {
        //         required: true,
        //         pattern: false,
        //     },
        //     icon: {
        //         name: "calendar",
        //         size: 25
        //     },
        //     keyboardType: "default",
        //     isPassword: false,
        //     Type: "calendar"
        // },
        {
            name: "first_name",
            lable: "First name",
            placeholder: "Hãy nhập tên",
            rules: {
                required: true,
                pattern: false,
            },
            icon: {
                name: "info-circle",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
        },
        {
            name: "checkbox",
            lable: "Bạn có muốn không?",
            rules: {
                required: true,
                pattern: false,
            },
            icon: {
                name: "info-circle",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
            Type: "checkbox",
        },
        // {
        //     name: "multicheckn",
        //     lable: "Bạn có chọn nhiều không?",
        //     rules: {
        //         required: true,
        //         pattern: false,
        //     },
        //     icon: {
        //         name: "info-circle",
        //         size: 25
        //     },
        //     keyboardType: "default",
        //     isPassword: false,
        //     Type: "multicheckbox"
        // },
        // {
        //     name: "radiocheck1",
        //     lable: "Chọn 1 option sau nhé?",
        //     rules: {
        //         required: true,
        //         pattern: false,
        //     },
        //     icon: {
        //         name: "info-circle",
        //         size: 25
        //     },
        //     keyboardType: "default",
        //     isPassword: false,
        //     Type: "radiocheck"
        // },
        // {
        //     name: "last_name",
        //     lable: "Last name",
        //     placeholder: "Hãy nhập họ",
        //     rules: {
        //         required: true,
        //         pattern: false,
        //     },
        //     icon: {
        //         name: "info-circle",
        //         size: 25
        //     },
        //     keyboardType: "default",
        //     isPassword: false,
        // },
        // {
        //     name: "address",
        //     lable: "Địa chỉ",
        //     placeholder: "Hãy nhập địa chỉ",
        //     rules: {
        //         pattern: false,
        //     },
        //     icon: {
        //         name: "address-card",
        //         size: 25
        //     },
        //     keyboardType: "default",
        //     isPassword: false,
        // },
        // {
        //     name: "email",
        //     lable: "Email",
        //     placeholder: "Hãy nhập email",
        //     rules: {
        //         required: "Địa chỉ email là bắt buộc!",
        //         pattern: "email",
        //     },
        //     icon: {
        //         name: "envelope",
        //         size: 25
        //     },
        //     keyboardType: "email-address",
        //     isPassword: false,
        // }
        // ,
        // {
        //     name: "phone",
        //     lable: "Số di động",
        //     placeholder: "Hãy nhập số di động",
        //     rules: {
        //         required: "Di động bắt đầu bởi 0 và có độ dài 9-11 số!",
        //         minLength: 9,
        //         maxLength: 11,
        //         pattern: "phone"
        //     },
        //     icon: {
        //         name: "phone",
        //         size: 25
        //     },
        //     keyboardType: "numeric",
        //     isPassword: false,
        // },
        // {
        //     name: "password",
        //     lable: "Mật khẩu",
        //     placeholder: "Hãy nhập mật khẩu",
        //     rules: {
        //         required: "Mật khẩu là bắt buộc",
        //     },
        //     icon: {
        //         name: "lock",
        //         size: 25
        //     },
        //     keyboardType: "default",
        //     isPassword: true,
        // },
        // {
        //     name: "confirm_password",
        //     lable: "Xác nhận mật khẩu",
        //     placeholder: "",
        //     rules: {
        //         required: "Vui lòng xác nhận mật khẩu!",
        //         validate: "validate_password"
        //     },
        //     icon: {
        //         name: "lock",
        //         size: 25
        //     },
        //     keyboardType: "default",
        //     isPassword: true,
        // }
    ];

    const arr_validate = {
        validate_password: {
            matchesPreviousPassword: (value) => {
                const { password } = getValues();
                return password === value || "Mật khẩu phải khớp!";
            }
        }
    };

    let column = {};
    let row = {};
    if (windowWidth <= 768) {
        column = stylesColumns.columnSmall;
        row = stylesColumns.rowSmall;
    } else if (windowWidth >= 769) {
        // column = stylesColumns.columnMedium;
        // row = stylesColumns.rowMedium;
    }

    const [fields, setFields] = React.useState([]);
    const [visibleCalendar, setVisibleCalendar] = React.useState(false);
    const [subFieldHour, setSubFieldHour] = React.useState(false);
    const [subFieldMinute, setSubFieldMinute] = React.useState(false);

    const [currentCalendar, setCurrentCalendar] = React.useState('');
    const changeCalendarFieldValue = (_fieldname, _value) => {
        setValue(_fieldname, _value);
    };

    React.useEffect(() => {
        setFields(api_fields);

        return () => {
            // cleanup
            // Execute before the next effect or unmount
        };
    }, []);

    const arrStudent = [
        {
            "id": 1,
            "name": "Nguyễn Văn Biển",
            "send": true,
            "done": 1,
            "error": 1,
        },
        {
            "id": 2,
            "name": "Nguyễn Văn Bảo An",
            "send": true,
            "done": 2,
            "error": 2,
        },
        {
            "id": 3,
            "name": "Nguyễn Văn Đông",
            "send": true,
            "done": 3,
            "error": 3,
        },
        {
            "id": 4,
            "name": "Nguyễn Văn Đông",
            "send": true,
            "done": 1,
            "error": 3,
        },
        {
            "id": 5,
            "name": "Nguyễn Văn Đông",
            "send": true,
            "done": 1,
            "error": 3,
        }
    ];

    const listDone = [
        {
            "id": 1,
            "lbl": "Khám lưng"
        },
        {
            "id": 2,
            "lbl": "Khám cổ"
        },
        {
            "id": 3,
            "lbl": "Khám chân"
        },
        {
            "id": 4,
            "lbl": "Khám đầu"
        },
    ];

    const listError = [
        {
            "id": 1,
            "lbl": "Châm kim ngắn"
        },
        {
            "id": 2,
            "lbl": "Châm kim ngắn",
        },
        {
            "id": 3,
            "lbl": "Không châm",
        }
    ];

    const [listChildren, setListChildren] = React.useState(arrStudent);

    const [currentPerson, setCurrentPerson] = React.useState({});
    const [visible, setVisible] = React.useState(false);
    const toggleOverlay = () => {
        setVisible(!visible);
    };

    const [visibleError, setVisibleError] = React.useState(false);
    const toggleOverlayError = () => {
        setVisibleError(!visibleError);
    };

    return (
        <View style={styles.container}>
            <Header title="react hook form" view="chat" />

            <MyContext.Provider value={{
                fieldOption: "done",
                listSelection: listDone,
                visible,
                toggleOverlay,
                currentPerson,
                listChildren,
                setListChildren,
            }}>
                <MyControlModalListSelect />
            </MyContext.Provider>

            <MyContext.Provider value={{
                fieldOption: "error",
                listSelection: listError,
                visible: visibleError,
                toggleOverlay: toggleOverlayError,
                currentPerson,
                listChildren,
                setListChildren,
            }}>
                <MyControlModalListSelect />
            </MyContext.Provider>


            {visibleCalendar && (
                <MyContext.Provider value={
                    {
                        changeCalendarFieldValue,
                        visibleCalendar,
                        setVisibleCalendar,

                    }}>
                    <MyControlCalendar
                        currentCalendar={currentCalendar}
                        currentValueCalendar={getValues(currentCalendar)}
                        subFieldHour={subFieldHour}
                        subFieldMinute={subFieldMinute}
                    />
                </MyContext.Provider>
            )}

            <ScrollView
                contentContainerStyle={{
                    flexGrow: 1,
                    marginTop: 6,
                }}
                keyboardShouldPersistTaps="handled"
            >
                <View style={row}>
                    {fields.map((field, key) => {
                        switch (field.Type) {
                            case "calendar":
                                return (
                                    <View key={key} style={column}>
                                        <Controller
                                            control={control}
                                            render={({ field: { value } }) => (
                                                <Pressable
                                                    style={{ width: 280 }}
                                                    onPress={() => {
                                                        setVisibleCalendar(true);
                                                        setCurrentCalendar(field.name);
                                                        if (field.subFieldHour != undefined) {
                                                            setSubFieldHour(true);
                                                        } else {
                                                            setSubFieldHour(null);
                                                        }
                                                        if (field.subFieldMinute != undefined) {
                                                            setSubFieldMinute(true);
                                                        } else {
                                                            setSubFieldMinute(null);
                                                        }
                                                    }}
                                                >
                                                    <MyControlDisplayCalendar
                                                        value={getValues(`${field.name}`)}
                                                        placeholder={field.placeholder}
                                                    />
                                                </Pressable>
                                            )}
                                            name={field.name}
                                        />
                                        {errors[field.name] && <Text style={styles.error}>
                                            {field.rules.required == true ? "Trường này là bắt buộc" : field.rules.required}
                                        </Text>}
                                    </View>
                                );
                                break;
                            case "checkbox":
                                return (
                                    <View key={key} style={column}>
                                        <Text style={styles.checkbox}>{field.lable}:</Text>
                                        <MyContext.Provider value={{ setValue }}>
                                            <MyControlCheckbox
                                                value={getValues(`${field.name}`)}
                                                field={field}
                                                control={control}
                                            />
                                        </MyContext.Provider>
                                    </View>
                                );
                                break;
                            case "multicheckbox":
                                return (
                                    <View key={key} style={column}>
                                        <Text style={styles.checkbox}>{field.lable}:</Text>
                                        <MyContext.Provider value={{ setValue }}>
                                            <MyControlMultiCheckbox
                                                value={getValues(`${field.name}`)}
                                                field={field}
                                                control={control}
                                            />
                                        </MyContext.Provider>
                                    </View>
                                );
                                break;
                            case "radiocheck":
                                return (
                                    <View key={key} style={column}>
                                        <Text style={styles.checkbox}>{field.lable}:</Text>
                                        <MyContext.Provider value={{ setValue }}>
                                            <MyControlRadioCheck
                                                value={getValues(`${field.name}`)}
                                                field={field}
                                                control={control}
                                            />
                                        </MyContext.Provider>
                                    </View>
                                );
                                break;
                            default:
                                return (
                                    <View key={key} style={column}>
                                        <Text style={styles.text}>{field.lable}:</Text>
                                        <MyControlInputText
                                            field={field}
                                            control={control}
                                            errors={errors}
                                            pattern={field.rules.pattern != undefined ? arr_pattern[field.rules.pattern] : ''}
                                            validate={field.rules.validate != undefined ? arr_validate[field.rules.validate] : ''}
                                        />
                                    </View>
                                );
                                break;
                        }
                    })}
                </View>

                <ScrollView
                    horizontal={true}
                    showsHorizontalScrollIndicator={true}
                >
                    <View style={{ width: "auto" }}>
                        <View style={stylesTable.header}>
                            <Text style={stylesTable.headerColumn1}>TT</Text>
                            <Text style={stylesTable.headerColumn2}>Họ và tên</Text>
                            <Text style={stylesTable.headerColumn3}>Nội dung</Text>
                            <Text style={stylesTable.headerColumn4}>Chọn gửi</Text>
                        </View>

                        {listChildren.map((item, index) =>
                            <View key={index} style={index % 2 == 0 ? stylesTable.headerOld : stylesTable.headerEvent}>
                                <Text style={stylesTable.column1}>{index + 1}</Text>
                                <Text style={stylesTable.column2}>{item.name}</Text>
                                <View style={stylesTable.column3}>
                                    <View style={stylesTable.subRow}>
                                        <Pressable onPress={() => { setCurrentPerson(item); toggleOverlay() }}>
                                            <View style={{ flexDirection: "row" }}>
                                                <Text style={{ width: 110 }}>
                                                    {listDone.map(option =>
                                                        option.id == item.done && <Text>{option.lbl}</Text>
                                                    )}
                                                </Text>
                                                <Icon
                                                    name="caret-down"
                                                    type="font-awesome"
                                                    color="#000"
                                                    size={25}
                                                    iconStyle={{ marginLeft: 6 }}
                                                />
                                            </View>
                                        </Pressable>
                                        <Pressable onPress={() => { setCurrentPerson(item); toggleOverlayError() }}>
                                            <View style={{ flexDirection: "row", marginLeft: 20 }}>
                                                <Text style={{ width: 110 }}>
                                                    {listError.map(option =>
                                                        option.id == item.error && <Text>{option.lbl}</Text>
                                                    )}
                                                </Text>
                                                <Icon
                                                    name="caret-down"
                                                    type="font-awesome"
                                                    color="#000"
                                                    size={25}
                                                    iconStyle={{ marginLeft: 6 }}
                                                />
                                            </View>
                                        </Pressable>
                                    </View>
                                </View>
                                <View style={stylesTable.column4}>
                                    <View style={stylesTable.subRow2}>
                                        <View>
                                            <Button title="Gửi" />
                                        </View>
                                        <View>
                                            <Button title="Chờ" buttonStyle={{ backgroundColor: "#000", marginLeft: 10 }} />
                                        </View>
                                    </View>
                                </View>
                            </View>
                        )}

                        <View style={stylesTable.headerOld}>
                            <Text style={stylesTable.headerColumn1}></Text>
                            <Text style={stylesTable.headerColumn2}></Text>
                            <Text style={stylesTable.headerColumn3}></Text>
                            <View style={{ marginRight: 50, marginTop: 12, marginBottom: 20, alignSelf: "flex-end" }}>
                                <Button title="Gửi tất cả" onPress={myHandleSubmit} />
                            </View>
                        </View>

                    </View>
                </ScrollView>

                <View style={{ height: 30 }}></View>
                <Button title="Submit" onPress={myHandleSubmit} />
                <View style={{ height: 80 }}></View>
            </ScrollView >
        </View >
    );
};

const styColumn = {
    padding: 6,
    paddingLeft: 0,
    borderBottomColor: "#ccc",
    borderBottomWidth: 1,
}
const styleHeader = {
    marginLeft: 12,
    marginRight: 12,
}

const stylesTable = StyleSheet.create({
    header: {
        ...styleHeader,
        flexDirection: "row",
    },
    headerEvent: {
        ...styleHeader,
        flexDirection: "row",
        backgroundColor: "#fff"
    },
    headerOld: {
        ...styleHeader,
        flexDirection: "row",
        backgroundColor: "#D5D8DC"
    },

    subRow: {
        flexDirection: "row"
    },
    subRow2: {
        flexDirection: "row"
    },

    headerColumn1: {
        ...styColumn,
        width: 35,
        fontWeight: "bold",
    },
    column1: {
        ...styColumn,
        width: 35,
        textAlign: "center",
    },

    headerColumn2: {
        ...styColumn,
        width: 120,
        fontWeight: "bold",
    },
    column2: {
        ...styColumn,
        width: 120,
    },

    headerColumn3: {
        ...styColumn,
        width: 300,
        fontWeight: "bold",
    },
    column3: {
        ...styColumn,
        width: 300,
    },


    headerColumn4: {
        ...styColumn,
        width: 140,
        fontWeight: "bold",
    },
    column4: {
        ...styColumn,
        width: 140,
    }
});

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#E8EAED',
    },
    leftIconContainerStyle: {
        marginLeft: 0,
        marginRight: 10,
    },
    containerStyle:
    {
        marginTop: 6,
        width: '100%',
    },
    text: {
        marginTop: 18,
        marginBottom: Platform.OS == 'web' ? -12 : -24,
        color: '#000',
        alignSelf: "flex-start",
        paddingLeft: 12
    },
    checkbox: {
        color: '#000',
        marginBottom: Platform.OS == 'web' ? -12 : -24,
        alignSelf: "flex-start",
        paddingLeft: 12
    },
    input: {
        maxWidth: 500,
        alignSelf: "center",
        borderColor: "#000",
        borderWidth: 1,
    },
    error: {
        color: "#f00",
        marginTop: Platform.OS == 'web' ? -8 : -20,
        alignSelf: "flex-start",
        paddingLeft: 12
    }
});

export default TestSuperadmin;
