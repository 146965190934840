import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, ScrollView } from 'react-native';

import { Header } from '../../components/header';

import { mycolors } from "../../config/mycolors";

import { COLLECTION_SCHEDULE_READ, firebaseConfig } from "../../config/config";

import * as firebase from 'firebase'
import 'firebase/firestore'
import { ButtonStaffFunc } from "../../components/mybuttons";

if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig)
}
const db = firebase.firestore()

function ListNewMessage({ navigation, route }) {
    const dialogTitle = 'Lịch hẹn mới nhất';

    const [newChats, setNewChats] = React.useState(null);

    const chatsRef = db.collection(COLLECTION_SCHEDULE_READ);

    useEffect(() => {
        const unsubscribe = chatsRef.onSnapshot((querySnapshot) => {
            querySnapshot
                .docChanges()
                .map(({ doc }) => {
                    const obj_data = doc.data();
                    let arr = [];
                    for (let key in obj_data) {
                        if ((obj_data[key]['staff_read'] || 0) == 0) {
                            arr.push({ 'key': key, 'value': obj_data[key] });
                        }
                    }
                    setNewChats(arr);
                })
        })

        return () => unsubscribe()
    }, []);

    return (
        <View style={styles.container}>
            <Header title={dialogTitle} view="chat" navigation={navigation} />
            <ScrollView
                contentContainerStyle={{
                    flexGrow: 1,
                    marginTop: 6,
                }}
                keyboardShouldPersistTaps="handled"
            >
                {newChats != null && newChats.map((item, index) =>
                    <View style={styles.box} key={index}>
                        <ButtonStaffFunc
                            icon="calendar"
                            title={item.value.customer_name}
                            onPress={() => {
                                navigation.navigate('BookingStaff', {
                                    _customer_id: item.value.customer_id,
                                    _customer_name: item.value.customer_name,
                                });
                            }}
                        />
                    </View>
                )}
            </ScrollView>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#E8EAED',
    },
    box: {
        backgroundColor: mycolors.primary,
        alignContent: "center",
        marginTop: 6,
        marginBottom: 6,
        marginLeft: 12,
        marginRight: 12,
        padding: 10
    },
});

export default ListNewMessage;