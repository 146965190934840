import { trim } from "lodash";
import { PixelRatio, Dimensions } from "react-native";

var configURL = {
  apiUrl: "http://amazonapi.trilieutunhien.vn",
  // apiUrl: "http://3.1.150.116:5000",
  // apiUrl: "http://127.0.0.1:5000",
};

export const config = configURL;

export const APP_CONST = {
  levels: "levels",
  conversion_rate: "conversion_rate",
};

export const  CUSTOMER_ID_THEMSELF = 'my_self';

export const firebaseConfig = {
  apiKey: "AIzaSyDW5sFKq3StG3-iL2s2_BrK-UyLaOJYpsU",
  authDomain: "phongkhamdt3.firebaseapp.com",
  databaseURL: "https://phongkhamdt3-default-rtdb.firebaseio.com",
  projectId: "phongkhamdt3",
  storageBucket: "phongkhamdt3.appspot.com",
  messagingSenderId: "39898147791",
  appId: "1:39898147791:web:4b62a2397d8d21ea75c0ba",
  measurementId: "G-H707X1YHCF"
};

export const COLLECTION_CHAT = 'chats';
export const COLLECTION_CHAT_READ = 'chats_read';
export const COLLECTION_NEWS_READ = 'news_read';
export const COLLECTION_SCHEDULE_READ = 'schedule_read';

export const HEAD_PUSH_NOTIFICATION = 'Phòng Khám ĐT';

export const LANG = {
  'Manager': 'Quản Lý',
  'Doctor': 'Bác Sỹ',
  'Staff': 'Nhân Viên',
  'Customer': 'Khách Hàng',
  'Supplier': 'Nhà Cung Cấp',

  'HomePage': 'Trang Chủ',
  'Department': 'Khoa Khám',

  'OrderImport': 'Đơn Hàng Nhập',
  'OrderExport': 'Đơn Hàng Bán',

  'Service': 'Dịch Vụ',
  'SellService': 'Bán Dịch Vụ',
  'ServiceCategory': 'Chuyên Mục Dịch Vụ',
  
  'Product': 'Sản Phẩm',
  'SellProduct': 'Bán Sản Phẩm',
  'ProductCategory': 'Chuyên Mục Sản Phẩm',
  
  'News': 'Tin tức',
  'ReadNews': 'Xem Tin Tức',
  'PostNews': 'Đăng Tin',

  'HealthCheck': 'Khám',
  'CalendarAppointment': 'Lịch & Nhắc hẹn',
  'BuyMedicine': 'Mua thuốc',
  'Deposit': 'Ký quỹ',
  'Chat': 'Chat',
  'HomeFamily': 'Người nhà',
  'Family': 'Người nhà',
  'Report': 'Thống Kê',

  'Field': {
    'HealthDate': 'Ngày khám(ngày/tháng/năm)',
    'EnterHealthDate': 'Hãy nhập ngày khám',
    'ScheduleDate': 'Ngày gửi lời nhắc(ngày/tháng/năm)',
    'Title': 'Tiêu đề',
    'EnterTitle': 'Hãy nhập tiêu đề',
    'Content': 'Nội dung',
    'EnterContent': 'Hãy nhập nội dung',

    'Symptom': 'Triệu chứng',
    'EnterSymptom': 'Hãy nhập triệu chứng',

    'CustomerName': 'Họ tên(khách hàng)',
    'CustomerNameFamily': 'Họ tên(người nhà)',
    'StaffName': 'Họ tên(nhân viên)',
    'DoctorName': 'Họ tên(bác sỹ)',
    'ManagerName': 'Họ tên(quản lý)',
    'EnterFullname': 'Hãy nhập họ tên',
    'Birthday': 'Ngày sinh(ngày/tháng/năm)',
    'EnterBirthday': 'Hãy chọn ngày sinh',
    'Sex': 'Giới tính(nam tích)',
    'IsActivated': 'Kích hoạt(mở toàn bộ chức năng)',
    'ResetPassword': 'Đặt lại mật khẩu(123456)',

    'DepartmentName': 'Tên khoa',
    'EnterDepartmentName': 'Hãy nhập tên khoa',

    'Phone': 'Số di động',
    'EnterPhone': 'Hãy nhập số di động',
    'PhoneRequired': 'Di động bắt đầu bởi 0 và có độ dài 9-11 số!',

    'Address': 'Địa chỉ',
    'EnterAddress': 'Hãy nhập địa chỉ',
    
    'Card': 'CMND/Thẻ căn cước',
    'EnterCard': 'Hãy nhập cmnd hoặc thẻ căn cước',
    
    'Email': 'Hãy nhập email',
    'EmailRequired': 'Địa chỉ email là bắt buộc!',

    'OtherInfo': 'Thông tin khác',
    'EnterOtherInfo': 'Hãy nhập thông tin khác',

    'Password': 'Mật khẩu',
    'EnterPassword': 'Hãy nhập mật khẩu',
    'PasswordRequired': 'Mật khẩu là bắt buộc',
    'ConfirmPassword': 'Xác nhận mật khẩu',
    'ConfirmPasswordRequired': 'Vui lòng xác nhận mật khẩu!',
    'Permission': 'Ghi dữ liệu(tích)',

    'OrderName': 'Tên đơn hàng',
    'EnterOrderName': 'Hãy nhập tên',
    'OrderDate': 'Ngày nhập(ngày/tháng/năm)',
    'EnterOrderDate': 'Hãy nhập ngày nhập hàng',

    'SaleDate': 'Ngày bán hàng(ngày/tháng/năm)',
    'EnterSaleDate': 'Hãy nhập ngày bán',

    'PaymentDate': 'Ngày ký quỹ(ngày/tháng/năm)',
    'EnterPaymentDate': 'Hãy nhập ngày ký quỹ',
    'TotalPayment': 'Số tiền ký quỹ',
    'EnterTotalPayment': 'Hãy nhập số tiền',

    'NewsTitle': 'Tiêu đề',
    'EnterNewsTitle': 'Hãy nhập tiêu đề',
    'NewsContent': 'Nội dung',
    'EnterNewsContent': 'Hãy nhập nội dung',
  },

  'Login': 'Đăng Nhập',
  'Logout': 'Đăng Xuất',
  'ChangePassword': 'Đổi mật khẩu',

  'PasswordRequiredValidate': 'Mật khẩu phải khớp!',

  'AddNewSuccessfully': 'Thêm mới thành công!',
  'CanNotAddNew': 'Không có bản ghi được thêm!',

  'UpdateSuccessfully': 'Cập nhật thành công!',
  'CanNotUpdate': 'Bản ghi không được cập nhật!',

  'ChooseAtLeastOne': 'Hãy chọn ít nhất 1 mục!',
  'ChooseAtLeastOneToDelete': 'Chọn ít nhất 1 mục để xóa!',
  'DeleteSuccessfully': 'Xóa thành công các mục đã chọn!',
  'CanNotConnect': `Không thể  kết nối, hãy bấm CTRL + F5 để thử lại!`,

  'FieldRequired': 'Trường này là bắt buộc',
  'Updating': 'Đang cập nhật nội dung',

  'ChooseCategory': 'Chọn danh mục',
  'All': 'Tất cả',
}

export const ROLE_VIEW_NEWS = [
  { 'id': 'internal', 'title': 'Nội bộ' },
  { 'id': 'customer', 'title': 'Khách hàng' },
  { 'id': 'all', 'title': 'Tất cả' }
]

export const LIST_REPORT_MENU = [
  { 'id': 'all', 'component': 'ReportInternal', 'title': 'Tổng hợp', 'icon': 'bar-chart' },
  { 'id': 'list_payment', 'component': 'ReportListPayment', 'title': 'Danh sách ký quỹ(ngày)', 'icon': 'bars' },
  { 'id': 'list_health_check', 'component': 'ReportListSaleService', 'title': 'Danh sách khám(ngày)', 'icon': 'bars' },
  { 'id': 'list_buy_medicine', 'component': 'ReportListSaleOrder', 'title': 'Danh sách mua thuốc(ngày)', 'icon': 'bars' },
  // { 'id': 'chart_payment', 'component': 'ReportChartPayment', 'title': 'Biểu đồ  ký quỹ(tháng)', 'icon': 'bar-chart' },
  // { 'id': 'chart_health_check', 'component': 'ReportChartHealthCheck', 'title': 'Biểu đồ khám(tháng)', 'icon': 'bar-chart' },
  // { 'id': 'chart_buy_medicine', 'component': 'ReportChartBuyMedicine', 'title': 'Biểu đồ  mua thuốc(tháng)', 'icon': 'bar-chart' },
];

const chars =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
export const Base64 = {
  btoa: (input: string = "") => {
    let str = input;
    let output = "";

    for (
      let block = 0, charCode, i = 0, map = chars;
      str.charAt(i | 0) || ((map = "="), i % 1);
      output += map.charAt(63 & (block >> (8 - (i % 1) * 8)))
    ) {
      charCode = str.charCodeAt((i += 3 / 4));

      if (charCode > 0xff) {
        throw new Error(
          "'btoa' failed: The string to be encoded contains characters outside of the Latin1 range."
        );
      }

      block = (block << 8) | charCode;
    }

    return output;
  },

  atob: (input: string = "") => {
    let str = input.replace(/=+$/, "");
    let output = "";

    if (str.length % 4 == 1) {
      throw new Error(
        "'atob' failed: The string to be decoded is not correctly encoded."
      );
    }
    for (
      let bc = 0, bs = 0, buffer, i = 0;
      (buffer = str.charAt(i++));
      ~buffer && ((bs = bc % 4 ? bs * 64 + buffer : buffer), bc++ % 4)
        ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
        : 0
    ) {
      buffer = chars.indexOf(buffer);
    }

    return output;
  },
};

export function convertDatePicker(str) {
  // str : Sat Jul 25 2020 00:00:00 GMT+0700 (Giờ Đông Dương)
  // to: dd/MM/yyyy
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  var date_month_day = [day, mnth, date.getFullYear()].join("/");

  var hours = str.getHours();
  var minus = str.getMinutes();

  return `${date_month_day} : ${hours}h${minus}'`;
}

export function convertMyDatePicker(_day, _hour, _minute) {
  if (_day != undefined) {
    if (_hour != undefined) {
      let day = _day.split(" ")[0];
      let h = _hour != undefined ? trim(_hour) : "00";
      let m = _minute != undefined ? trim(_minute) : "00";
      const fulldate = `${day} ${h}:${m}:00`;
      return fulldate;
    } else {
      return _day;
    }
  }
  return "";
}

export const windowWidth = Dimensions.get("window").width;
export const windowHeight = Dimensions.get("window").height;

export const HEIGHT_FOR_KEYBOARD = 80;

// for react-native-modern-datepicker: when overlay
let fontSizeBaseCalendar = 15;
if (windowWidth >= 320 && PixelRatio.get() > 2 && PixelRatio.get() < 3) {
  fontSizeBaseCalendar = 9;
}
export { fontSizeBaseCalendar };

export const COLORS_BRAND = {
  primary: "#284F8C",
  secondary: "#4FBE95",
};

export const DOMAIN_DATA = 'https://trilieutunhien.vn';