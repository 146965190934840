import React, { useState } from "react";
import { StyleSheet, Text, View, ScrollView, Platform } from 'react-native';
import { Dialog } from 'react-native-elements';

import moment from 'moment'

import { Header } from '../../components/header';

import { ButtonAdd, ButtonCancel, ButtonDelete, ButtonEdit, ButtonSave, ButtonSearch, CloseButtons, OpenButtons } from '../../components/mybuttons'
import { stylesButtons, stylesColumns } from "../../config/mystyles";
import { checkSingleItem, getsMyItemChecked } from "../../config/myfunc";
import { MyPaging, MySelectCalendarSearch, MyDisplayFromToDate, MyListingPayment, BoxOpenShortMenu } from "../../components/myboxs";
import { IsLoadingData } from "../../components/myother";

import { MyContext } from '../../context/mycontext';
import { arr_pattern, MyControlInputText, MyControlShortMenu, MyControlSparateCalendar } from "../../components/mycontrolinputs";
import { LANG, windowWidth } from "../../config/config";

import { useForm } from "react-hook-form";

import { PaymentModel } from "../../models/models"
import { MyInputSearchName } from "../../components/myinputs";
const dialogTitle = LANG.Deposit;


function Payment({ route, navigation }) {
    const [isLoading, setLoading] = useState<boolean>(false);

    const [taskItems, setTaskItems] = useState([]);

    const [pagingParams, setPagingParams] = useState({});
    const [currentPage, setCurrentPage] = React.useState(1);

    const [searchName, setSearchName] = React.useState('');
    const [visibleSearchCalendar, setVisibleSearchCalendar] = React.useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const [openList, setOpenList] = React.useState(true);
    const [openForm, setOpenForm] = React.useState(false);
    const [openButton, setOpenButton] = React.useState(true);

    const [visible, setVisible] = React.useState(true);
    const [visibleDialog, setVisibleDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');

    const [visibleShortMenu, setVisibleShortMenu] = React.useState(false);

    const [permission, setPermission] = React.useState(false);

    // fields of table
    const [id, setID] = useState('');
    // same as birthday

    function checkItem(item: any) {
        const arr = checkSingleItem(taskItems, item);
        setTaskItems(arr);

        setOpenButton(true);
        setVisible(true);
    }

    function deleteItems() {
        let arr: any = getsMyItemChecked(taskItems);

        if (arr.length == 0) {
            setVisibleDialog(true);
            setDialogMessage(LANG.ChooseAtLeastOneToDelete);
            return;
        }

        PaymentModel.delete_arr({ 'ids': arr, customer_id: customerID })
            .then((results) => {
                const posts_reload = results.data.posts_reload || {};
                if (posts_reload.posts != null) {
                    setTaskItems(posts_reload.posts);
                } else if (results.data.posts != undefined) {
                    setTaskItems(results.data.posts);
                }

                setVisibleDialog(true);
                setDialogMessage(LANG.DeleteSuccessfully);
            })
            .catch(err => {
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            });
    }

    function editItem() {
        let arr: any = getsMyItemChecked(taskItems);
        const lastItem = arr.pop();

        if (lastItem == undefined) {
            setVisibleDialog(true);
            setDialogMessage(LANG.ChooseAtLeastOne);
        } else {
            PaymentModel.get(lastItem)
                .then((results) => {
                    const data: any = results.data || {};
                    const post: any = data.post || {};

                    if (__DEV__) {
                        console.log('Get data: ', data);
                    }

                    setID(post.id);
                    fields.forEach(field => {
                        switch (field.Type) {
                            case 'calendar':
                                setValue(`${field.name}`, post[`${field.name}`]);
                                break;
                            default:
                                setValue(`${field.name}`, post[`${field.name}`]);
                                break;
                        }
                    });

                    setOpenForm(true);
                    setOpenList(false);
                    setOpenButton(false);
                })
                .catch(err => {
                    setVisibleDialog(true);
                    setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
                });
        }
    }

    function Paging(page = 1) {
        setLoading(true);
        PaymentModel.gets(customerID, userFamilyID, page)
            .then((results) => {
                const data: any = results["data"] || [];
                setTaskItems(data["posts"] || []);
                setPagingParams(data['paging'])
            })
            .catch(err => {
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false));
    }

    function Search(search_name = '', from_date = '', to_date = '') {
        setLoading(true);
        PaymentModel.gets(customerID, userFamilyID, 1, search_name, from_date, to_date)
            .then((results) => {
                const data: any = results["data"] || [];
                setTaskItems(data["posts"] || []);
                setPagingParams(data['paging'])
                setCurrentPage(1);
            })
            .catch(err => {
                console.log(err);
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false));
    };

    async function gets_init(_customer_id = '', _user_family_id = '') {
        const p_posts = PaymentModel.gets(_customer_id, _user_family_id, 1, '');
        setLoading(true);
        Promise.all([p_posts]).then((values) => {
            const data_posts = values[0]["data"] || [];

            if (__DEV__) {
                console.log('data_posts:', data_posts);
            }

            setPermission(data_posts["permission"] || false);

            setTaskItems(data_posts["posts"] || []);
            setPagingParams(data_posts["paging"]);
        })
            .catch(err => {
                console.log(`${err.message}`);

                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false))
    }

    /***
     * begin: react hook form
    */
    const { control, handleSubmit, reset, formState: { errors }, getValues, setValue, unregister, setFocus } = useForm();

    const onSubmit = data => {
        const data_submit = data;

        if (id != '') {
            PaymentModel.update(id, data_submit)
                .then((results) => {
                    const data: any = results.data || {};

                    if (__DEV__) {
                        console.log('Data update: ', data);
                    }

                    if (data.post != undefined && data.post.id != undefined) {
                        const obj_task: any = {
                            'id': data.post.id,
                            'title': data.post.title,
                            'customer_name': data.post.customer_name,
                            'content': data.post.content,
                            'date_created': data.post.date_created,
                            'code': data.post.code,
                            'total': data.post.total,
                            'checked': false
                        };
                        let arr: any = [];
                        taskItems.forEach(el => {
                            let el_tg: any = el;
                            if (el_tg['id'] == obj_task.id) {
                                arr.push(obj_task);
                            } else {
                                arr.push(el);
                            }
                        });
                        setTaskItems(arr);

                        setVisibleDialog(true);
                        setDialogMessage(`${data.message}`);
                    } else {
                        setVisibleDialog(true);
                        setDialogMessage(`${data.message}`);
                    }

                    setOpenForm(false);
                    setOpenList(true);
                    setOpenButton(true);
                    setVisibleDialog(true);

                    reset();
                })
                .catch(err => {
                    setVisibleDialog(true);
                    setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
                })
                .finally(() => {
                    setID('');
                    setLoading(false);
                });
        } else {
            PaymentModel.create(data_submit)
                .then((results) => {
                    const data: any = results.data || {};
                    if (data.post != undefined && data.post.id != undefined) {
                        const obj_task: any = {
                            'id': data.post.id,
                            'title': data.post.title,
                            'customer_name': data.post.customer_name,
                            'content': data.post.content,
                            'date_created': data.post.date_created,
                            'code': data.post.code,
                            'total': data.post.total,
                        };
                        setTaskItems([obj_task, ...taskItems]);

                        setVisibleDialog(true);
                        setDialogMessage(`${data.message}`);
                    } else {
                        setVisibleDialog(true);
                        setDialogMessage(`${data.message}`);
                    }

                    setOpenForm(false);
                    setOpenList(true);
                    setOpenButton(true);
                    setVisibleDialog(true);

                    reset();
                })
                .catch(err => {
                    setVisibleDialog(true);
                    setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
                    setLoading(false);
                })
                .finally(() => {
                    setID('');
                    setLoading(false);
                });
        }
    };

    const myHandleSubmit = () => {
        // convert before submit
        setValue('customer_id', customerID);
        setValue('customer_name', customerName);
        setValue('user_family_id', userFamilyID);

        let other_validate = true;
        fields.forEach((field) => {
            switch (field.Type) {
                case "calendar":
                    if (field.name == 'date_created') {
                        let d = getValues(field.child_date['date']);
                        if (parseInt(d) < 10) {
                            d = `0${parseInt(d)}`;
                        }
                        let m = getValues(field.child_date['month']);
                        if (parseInt(m) < 10) {
                            m = `0${parseInt(m)}`;
                        }
                        const y = getValues(field.child_date['year']);
                        const date_created = `${y}/${m}/${d} 00:00:00`;
                        setValue(field.name, date_created);

                        const validate = moment(`${m}/${d}/${y}`, 'MM/DD/YYYY', true).isValid();
                        if (!validate && d != '' && m != '' && y != '') {
                            setDialogMessage(`Tháng ${m}/${y} không có ngày ${d}!`)
                            setVisibleDialog(true);
                            setFocus(field.child_date['date']);

                            other_validate = false;
                        }
                    }
                    break;
                case "image":
                default:
                    break;
            }
        });

        if (other_validate) {
            handleSubmit(onSubmit)();
        }
    };

    const api_fields = [
        {
            name: "date_created",
            child_date: {
                'date': 'date_of_month',
                'month': 'month_of_year',
                'year': 'year_of_calendar'
            },
            lable: LANG.Field.PaymentDate,
            placeholder: LANG.Field.EnterPaymentDate,
            rules: {
                required: true,
                pattern: false,
            },
            icon: {
                name: "calendar",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
            Type: "calendar",
        },
        {
            name: "total",
            lable: LANG.Field.TotalPayment,
            placeholder: LANG.Field.EnterTotalPayment,
            rules: {
                pattern: false,
            },
            icon: {
                name: "info-circle",
                size: 25
            },
            keyboardType: "numeric",
            isCurrency: true,
            isPassword: false,
        },
        {
            name: "content",
            lable: LANG.Field.OtherInfo,
            placeholder: LANG.Field.EnterOtherInfo,
            rules: {
                pattern: false,
            },
            icon: {
                name: "info-circle",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
            multiline: true
        },
    ];

    const arr_validate = {
        validate_password: {
            matchesPreviousPassword: (value) => {
                const { password } = getValues();
                return password === value || LANG.PasswordRequiredValidate;
            }
        }
    };

    let column = {};
    let row = {};
    if (windowWidth <= 768) {
        column = stylesColumns.columnSmall;
        row = stylesColumns.rowSmall;
    } else if (windowWidth >= 769) {
        // column = stylesColumns.columnMedium;
        // row = stylesColumns.rowMedium;
    }

    const [fields, setFields] = React.useState([]);

    /***
      * end: react hook form
    */

    React.useEffect(() => {
        setFields(api_fields);

        const unsubscribe = navigation.addListener('focus', () => {
            console.log('Init: Screen is focused buy order: ' + Math.random());

            setOpenForm(false);
            setOpenButton(true);
            setOpenList(true);
        });

        return () => {
            // cleanup
            // Execute before the next effect or unmount
        };
    }, []);

    const [customerID, setCustomerID] = React.useState('');
    const [customerName, setCustomerName] = React.useState('');
    const [userFamilyID, setUserFamilyID] = React.useState('');

    React.useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            // if screen is focused then reload
            console.log('Screen is focused buy order: ' + Math.random());

            const { _customer_id, _customer_name, _user_family_id } = route.params || ['', '', ''];
            if (_customer_id != undefined) {
                console.log('_customer_id: ', _customer_id);
                console.log('_customer_name: ', _customer_name);
                console.log('_user_family_id: ', _user_family_id);

                gets_init(_customer_id, _user_family_id);

                setCustomerID(_customer_id);
                setCustomerName(_customer_name);
                setUserFamilyID(_user_family_id);
            }
        });

        // cleanup
        return unsubscribe;
    }, [route]);

    return (
        <View style={styles.container}>
            <Header title={dialogTitle} view="chat" navigation={navigation} />

            <Dialog
                isVisible={visibleDialog}
                onBackdropPress={() => setVisibleDialog(!visibleDialog)}
            >
                <Dialog.Title title={dialogTitle} />
                <Text>{dialogMessage}</Text>
            </Dialog>

            {visibleShortMenu && (
                <MyContext.Provider value={
                    {
                        visibleShortMenu,
                        setVisibleShortMenu,

                    }}>
                    <MyControlShortMenu
                        navigation={navigation}
                        customer_id={customerID}
                        customer_name={customerName}
                    />
                </MyContext.Provider>
            )}

            <ScrollView
                contentContainerStyle={{
                    flexGrow: 1,
                }}
                keyboardShouldPersistTaps="handled"
            >
                {openForm && (
                    <View style={styles.box}>
                        <View style={{ alignSelf: "flex-start", marginTop: 12, marginLeft: 12 }}>
                            <Text style={{ fontWeight: "bold" }}>{customerName}</Text>
                        </View>
                        <View style={row}>
                            {fields.map((field, key) => {
                                switch (field.Type) {
                                    case "calendar":
                                        return (
                                            <View key={key} style={column}>
                                                <View style={{ marginBottom: 20 }}>
                                                    <Text style={styles.textCalendar}>{field.lable}:</Text>
                                                </View>

                                                <MyContext.Provider value={{
                                                    setValue,
                                                    getValues,
                                                    reset,
                                                }}>
                                                    <MyControlSparateCalendar
                                                        child_date={field.child_date}
                                                        control={control}
                                                        errors={errors}
                                                        default_fulldate={getValues(field.name)}
                                                    />
                                                </MyContext.Provider>
                                            </View>
                                        );
                                        break;
                                    default:
                                        return (
                                            <View key={key} style={column}>
                                                <Text style={styles.text}> {field.lable}:</Text>
                                                <MyControlInputText
                                                    action={id != '' ? 'edit' : 'add'}
                                                    field={field}
                                                    control={control}
                                                    errors={errors}
                                                    pattern={field.rules.pattern != undefined ? arr_pattern[field.rules.pattern] : ''}
                                                    validate={field.rules.validate != undefined ? arr_validate[field.rules.validate] : ''}
                                                />
                                                {field.confirmField != null && (
                                                    <>
                                                        <Text style={styles.text}>{field.confirmField.lable}:</Text>
                                                        <MyControlInputText
                                                            action={id != '' ? 'edit' : 'add'}
                                                            field={field.confirmField}
                                                            control={control}
                                                            errors={errors}
                                                            pattern={field.confirmField.rules.pattern != undefined ? arr_pattern[field.confirmField.rules.pattern] : ''}
                                                            validate={field.confirmField.rules.validate != undefined ? arr_validate[field.confirmField.rules.validate] : ''}
                                                        />
                                                    </>
                                                )}
                                            </View>
                                        );
                                        break;
                                }
                            })}
                        </View>


                        {isLoading == true ? (
                            <IsLoadingData title={LANG.Updating} />
                        ) : (
                            <>
                                <View style={{ flexDirection: "row", alignSelf: "center", marginTop: 12 }}>
                                    <ButtonCancel onPress={() => {
                                        setID('');
                                        setOpenForm(false);
                                        setOpenButton(true);
                                        setOpenList(true);
                                    }}
                                    />
                                    <ButtonSave onPress={() => myHandleSubmit()} />
                                </View>
                            </>
                        )}
                    </View>
                )}

                {openList && (
                    <>
                        <MyContext.Provider value={{
                            setVisibleShortMenu,
                        }}>
                            <BoxOpenShortMenu
                                customerName={customerName}
                                customerID={customerID}
                                navigation={navigation}
                            />
                        </MyContext.Provider>
                        <View style={{ flexDirection: "row", alignSelf: "center", marginTop: 12 }}>
                            <MyContext.Provider value={{
                                Search,
                                searchName,
                                setSearchName,
                            }}>
                                <MyInputSearchName />
                            </MyContext.Provider>

                            <MyContext.Provider value={{
                                visibleSearchCalendar,
                                setVisibleSearchCalendar,

                                fromDate,
                                setFromDate,

                                toDate,
                                setToDate,
                            }}>
                                <MySelectCalendarSearch />
                            </MyContext.Provider>

                            <ButtonSearch onPress={() => Search(searchName, fromDate, toDate)} />
                        </View>

                        <MyContext.Provider value={{
                            fromDate,
                            setFromDate,

                            toDate,
                            setToDate,
                        }}>
                            <MyDisplayFromToDate />
                        </MyContext.Provider>

                        {isLoading ? (
                            <IsLoadingData />
                        ) : (
                            <MyContext.Provider value={
                                {
                                    taskItems,
                                    checkItem,

                                    pagingParams,
                                    Paging,

                                    currentPage,
                                    setCurrentPage,
                                }}>
                                <MyListingPayment />
                                <MyPaging />
                            </MyContext.Provider>
                        )}

                    </>
                )}

            </ScrollView>

            {permission == true && <>
                {openButton && (
                    <View style={{ flexDirection: "row", alignSelf: "center", "position": "absolute", bottom: 5 }}>
                        <ButtonAdd onPress={() => {
                            // begin reset all
                            reset();
                            setOpenForm(true); setOpenButton(false); setOpenList(false);
                        }}
                        />
                        <ButtonEdit onPress={() => editItem()} />
                        <ButtonDelete onPress={() => deleteItems()} />
                    </View>
                )}

                <View style={stylesButtons.open_close}>
                    {visible == true ? (
                        <OpenButtons onPress={() => { setOpenButton(false); setVisible(false); }} />
                    ) : (
                        <CloseButtons onPress={() => { setOpenButton(true); setVisible(true); }} />
                    )}
                </View>
            </>}

        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#E8EAED',
    },
    box: {
        flex: 1,
        margin: 12,
        marginTop: 6,
        marginBottom: 6,
        padding: 20
    },
    text: {
        marginTop: 18,
        marginBottom: Platform.OS == 'web' ? -12 : -24,
        color: '#000',
        alignSelf: "flex-start",
        paddingLeft: 12
    },
    textCalendar: {
        marginTop: 18,
        marginBottom: Platform.OS == 'web' ? -12 : -12,
        color: '#000',
        alignSelf: "flex-start",
        paddingLeft: 12
    }
});

export default Payment;
