import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, ScrollView } from 'react-native';
import { Dialog } from 'react-native-elements';

import { UserMainModel } from "../../models/models"

import moment from 'moment'

import { Header } from '../../components/header';

import { ButtonAdd, ButtonCancel, ButtonDelete, ButtonEdit, ButtonSave, ButtonSearch, CloseButtons, OpenButtons } from '../../components/mybuttons'
import { stylesButtons, stylesCalendar, stylesTextNote } from "../../config/mystyles";
import { mycolors } from "../../config/mycolors";
import { checkMyItem, convertDateForMoment, getsMyItemChecked, validatePhone } from "../../config/myfunc";
import { MyPaging, MyListingMember, MySelectCalendarSearch, MyDisplayFromToDate } from "../../components/myboxs";
import { MyInputPhone, MyInputBirthday, MyInputDisplayName, MyInputOtherInfo, MyInputUniqueName, MyInputMaxAccount, MyInputPassword, MyInputConfirmPassword, MyInputSearchName } from "../../components/myinputs";
import { IsLoadingData } from "../../components/myother";

import { MyContext } from '../../context/mycontext';


function MemberSuperAdmin({ navigation }) {
    const [isLoading, setLoading] = useState<boolean>(false);

    const [taskItems, setTaskItems] = useState([]);

    const [pagingParams, setPagingParams] = useState({});
    const [currentPage, setCurrentPage] = React.useState(1);

    const [searchName, setSearchName] = React.useState('');
    const [visibleSearchCalendar, setVisibleSearchCalendar] = React.useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const [openList, setOpenList] = React.useState(true);
    const [openForm, setOpenForm] = React.useState(false);
    const [openButton, setOpenButton] = React.useState(true);

    const [visible, setVisible] = React.useState(true);
    const [visibleDialog, setVisibleDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');
    const [visibleCalendar, setVisibleCalendar] = React.useState(false);

    // validate
    const [isDisplayNameValid, setDisplayNameValid] = useState<boolean>(true);
    const [isNameValid, setNameValid] = useState<boolean>(true);
    const [isUniqueNameValid, setUniqueNameValid] = useState<boolean>(true);
    const [isMaxAccountValid, setMaxAccountValid] = useState<boolean>(true);
    const [isPasswordValid, setPasswordValid] = useState<boolean>(true);
    const [isConfirmPasswordValid, setConfirmPasswordValid] = useState<boolean>(true);

    // fields of table
    const [id, setID] = useState('');
    const [display_name, setDisplayName] = useState('');
    const [unique_name, setUniqueName] = useState('');
    const [max_account, setMaxAccount] = useState('');
    const [password, setPassword] = useState('');
    const [confirm_password, setConfirmPassword] = useState('');
    const [name, setName] = useState('');
    const [other_info, setOtherInfo] = useState('');
    // same as birthday
    const [startDate, setStartDate] = useState(moment().format('YYYY/MM/DD'));

    const dialogTitle = 'Quản trị thành viên admin';

    function handleSubmit() {
        if (display_name == '') {
            setVisibleDialog(true);
            setDialogMessage(`Hãy nhập họ tên đầy đủ!`);
            setDisplayNameValid(false);
            return;
        } else {
            setDisplayNameValid(true);
        }

        if (!validatePhone(name)) {
            setVisibleDialog(true);
            setDialogMessage(`Số điện thoại không hợp lệ. Số hợp lệ phải bắt đầu từ 0 và có độ dài 9-11 số!`);
            setNameValid(false);
            return;
        } else {
            setNameValid(true);
        }

        const resetInputs = () => {
            setID('');
            setName('');
            setDisplayName('');
            setStartDate('');
            setOtherInfo('');

            setOpenForm(false);
            setOpenList(true);
            setOpenButton(true);
        }

        const data_submit = {
            'name': name
            , 'password': password
            , 'confirm_password': confirm_password
            , 'display_name': display_name
            , 'birthday': convertFullDate(startDate)
            , 'other_info': other_info
            , 'table_prefix': unique_name
            , 'max_account': max_account
        };

        setLoading(true);
        if (id != '') {
            UserMainModel.update(id, data_submit)
                .then((results) => {
                    const data: any = results.data || {};
                    if (data.post != undefined && data.post.id != undefined) {
                        const obj_task: any = { 'id': data.post.id, 'display_name': display_name, 'checked': false };
                        let arr: any = [];
                        taskItems.forEach(el => {
                            let el_tg: any = el;
                            if (el_tg['id'] == obj_task.id) {
                                arr.push(obj_task);
                            } else {
                                arr.push(el);
                            }
                        });
                        setTaskItems(arr);

                        resetInputs();
                        setVisibleDialog(true);
                        setDialogMessage('Cập nhật thành công!');
                    } else {
                        setVisibleDialog(true);
                        setDialogMessage(`Bản ghi không được cập nhật!\n${data.message}`);
                    }
                })
                .catch(err => {
                    setVisibleDialog(true);
                    setDialogMessage(`${err.message} \nOr Can not connect API, Please login again!`);
                })
                .finally(() => {
                    setID('');
                    setLoading(false);
                });
        } else {
            UserMainModel.create(data_submit)
                .then((results) => {
                    const data: any = results.data || {};
                    if (data.post != undefined && data.post.id != undefined) {
                        const obj_task: any = { 'id': data.post.id, 'display_name': display_name };
                        setTaskItems([obj_task, ...taskItems]);

                        resetInputs();
                        setVisibleDialog(true);
                        setDialogMessage('Thêm mới thành công!');
                    } else {
                        setVisibleDialog(true);
                        setDialogMessage(`Không có bản ghi được thêm!\n${data.message}`);
                    }
                })
                .catch(err => {
                    setVisibleDialog(true);
                    setDialogMessage(`${err.message} \nOr Can not connect API, Please login again!`);
                    setLoading(false);
                });
        }
    }

    function convertFullDate(_startDate: string) {
        // same as 2021-07-28 06:39:07
        return `${convertDateForMoment(_startDate)} 00:00:00`;
    }

    function checkItem(item: any) {
        const arr = checkMyItem(taskItems, item);
        setTaskItems(arr);

        setOpenButton(true);
        setVisible(true);
    }

    function deleteItems() {
        let arr: any = getsMyItemChecked(taskItems);

        if (arr.length == 0) {
            setVisibleDialog(true);
            setDialogMessage('Chọn ít nhất 1 mục để xóa!');
            return;
        }

        setVisibleDialog(true);
        setDialogMessage('Chức năng này bị đóng lại bởi biencoder!');
    }

    function editItem() {
        let arr: any = getsMyItemChecked(taskItems);
        const lastItem = arr.pop();

        if (lastItem == undefined) {
            setVisibleDialog(true);
            setDialogMessage('Hãy chọn ít nhất 1 mục!');
        } else {
            UserMainModel.get(lastItem)
                .then((results) => {
                    const post: any = results.data.post || {};

                    setID(post.id);
                    setName(post.name);
                    setDisplayName(post.display_name);
                    setStartDate(moment(post.birthday).format('YYYY/MM/DD'));
                    setOtherInfo(post.other_info);
                    setUniqueName(post.prefix);
                    setMaxAccount(`${post.max_account}`);

                    setOpenForm(true);
                    setOpenList(false);
                    setOpenButton(false);
                })
                .catch(err => {
                    setVisibleDialog(true);
                    setDialogMessage(`${err.message} \nOr Can not connect API, Please login again!`);
                });
        }
    }

    function Paging(page = 1) {
        setLoading(true);
        UserMainModel.gets(page)
            .then((results) => {
                const data: any = results["data"] || [];
                setTaskItems(data["posts"] || []);
                setPagingParams(data['paging'])
            })
            .catch(err => {
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \nOr Can not connect API, Please login again!`);
            })
            .finally(() => setLoading(false));
    }

    function Search(search_name = '', from_date = '') {
        setLoading(true);
        UserMainModel.gets(1, search_name, from_date)
            .then((results) => {
                const data: any = results["data"] || [];
                setTaskItems(data["posts"] || []);
                setPagingParams(data['paging'])
                setCurrentPage(1);
            })
            .catch(err => {
                console.log(err);
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \nOr Can not connect API, Please login again!`);
            })
            .finally(() => setLoading(false));
    };

    async function gets_init() {
        let p_posts = UserMainModel.gets(1);

        setLoading(true);
        Promise.all([p_posts]).then((values) => {
            const data_posts = values[0]["data"] || [];
            setTaskItems(data_posts["posts"] || []);

            setPagingParams(data_posts['paging'])
        })
            .catch(err => {
                console.log(`${err.message} \nOr Can not connect API, Please login again!`);
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            // if screen is focused then reload
            console.log('Screen is focused member.superadmin ' + Math.random());
            gets_init();
        });

        return () => {
            // cleanup
            // Execute before the next effect or unmount
        };
    }, []);

    return (
        <View style={styles.container}>
            <Header title="Quản Lý Admin" view="chat" navigation={navigation} />

            <Dialog
                isVisible={visibleDialog}
                onBackdropPress={() => setVisibleDialog(!visibleDialog)}
            >
                <Dialog.Title title={dialogTitle} />
                <Text>{dialogMessage}</Text>
            </Dialog>

            <ScrollView
                contentContainerStyle={{
                    flexGrow: 1,
                }}
                keyboardShouldPersistTaps="handled"
            >
                {openForm && (
                    <View style={styles.box}>
                        <MyContext.Provider value={{
                            display_name,
                            setDisplayName,
                            isDisplayNameValid,

                            unique_name,
                            setUniqueName,
                            isUniqueNameValid,

                            max_account,
                            setMaxAccount,
                            isMaxAccountValid,

                            password,
                            setPassword,
                            isPasswordValid,

                            confirm_password,
                            setConfirmPassword,
                            isConfirmPasswordValid,

                            visibleCalendar,
                            setVisibleCalendar,
                            startDate,
                            setStartDate,

                            name,
                            setName,
                            isNameValid,

                            other_info,
                            setOtherInfo,
                        }}>
                            <Text style={stylesCalendar.label}>Tên phòng khám:</Text>
                            <MyInputDisplayName placeholder="Ví dụ: Phòng khám ĐT" />

                            {id != '' ? (
                                <View style={{ minHeight: 36 }}>
                                    <Text style={stylesCalendar.label}>Bí danh: {unique_name}</Text>
                                </View>
                            ) : (
                                <>
                                    <Text style={stylesCalendar.label}>Bí danh(chỉ nhập 1 lần):</Text>
                                    <MyInputUniqueName />
                                </>
                            )}

                            <Text style={stylesCalendar.label}>Ngày lập tài khoản:</Text>
                            <MyInputBirthday />

                            <Text style={stylesCalendar.label}>Tên đăng nhập(số di động):</Text>
                            <MyInputPhone placeholder="Số di động" />

                            <Text style={stylesCalendar.label}>Mật khẩu:{id != '' && '(để trống nếu không thay đổi)'}</Text>
                            <MyInputPassword />
                            <Text style={stylesCalendar.label}>Xác nhận mật khẩu:</Text>
                            <MyInputConfirmPassword />

                            <Text style={stylesCalendar.label}>Số tài khoản được phép khởi tạo:</Text>
                            <MyInputMaxAccount />

                            <Text style={stylesCalendar.label}>Thông tin khác:</Text>
                            <MyInputOtherInfo />
                        </MyContext.Provider>

                        <View style={{ minHeight: 36, marginBottom: 20 }}>
                            <Text style={stylesTextNote.label}>
                                Chú ý: Tài khoản được tạo tự động với số di động và mật khẩu là 123456
                            </Text>
                        </View>

                        <View style={{ flexDirection: "row", alignSelf: "center" }}>
                            {isLoading == true ? (
                                <IsLoadingData title="Đang cập nhật nội dung" />
                            ) : (
                                <>
                                    <ButtonCancel onPress={() => { setOpenForm(false); setOpenButton(true); setOpenList(true); }} />
                                    <ButtonSave onPress={() => handleSubmit()} />
                                </>
                            )}
                        </View>
                    </View>
                )}

                {openList && (
                    <>
                        <View style={{ flexDirection: "row", alignSelf: "center", marginTop: 12 }}>
                            <MyContext.Provider value={{
                                Search,
                                searchName,
                                setSearchName,
                            }}>
                                <MyInputSearchName />
                            </MyContext.Provider>

                            <MyContext.Provider value={{
                                visibleSearchCalendar,
                                setVisibleSearchCalendar,

                                fromDate,
                                setFromDate,

                                toDate,
                                setToDate,
                            }}>
                                <MySelectCalendarSearch />
                            </MyContext.Provider>

                            <ButtonSearch onPress={() => Search(searchName, fromDate)} />
                        </View>

                        <MyContext.Provider value={{
                            fromDate,
                            setFromDate,

                            toDate,
                            setToDate,
                        }}>
                            <MyDisplayFromToDate />
                        </MyContext.Provider>

                        {isLoading ? (
                            <IsLoadingData />
                        ) : (
                            <MyContext.Provider value={
                                {
                                    taskItems,
                                    checkItem,

                                    pagingParams,
                                    Paging,

                                    currentPage,
                                    setCurrentPage,
                                }}>
                                <MyListingMember />
                                <MyPaging />
                            </MyContext.Provider>
                        )}

                    </>
                )}

            </ScrollView>

            {openButton && (
                <View style={{ flexDirection: "row", alignSelf: "center", "position": "absolute", bottom: 5 }}>
                    <ButtonAdd onPress={() => {
                        setOpenForm(true); setOpenButton(false); setOpenList(false);
                    }}
                    />
                    <ButtonEdit onPress={() => editItem()} />
                    <ButtonDelete onPress={() => deleteItems()} />
                </View>
            )}

            <View style={stylesButtons.open_close}>
                {visible == true ? (
                    <OpenButtons onPress={() => { setOpenButton(false); setVisible(false); }} />
                ) : (
                    <CloseButtons onPress={() => { setOpenButton(true); setVisible(true); }} />
                )}
            </View>

        </View >
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#E8EAED',
    },
    box: {
        flex: 1,
        margin: 12,
        marginTop: 6,
        marginBottom: 6,
        padding: 20
    },
    box2: {
        backgroundColor: mycolors.secondary,
        margin: 12,
        marginTop: 6,
        marginBottom: 6,
        padding: 20
    },
});

export default MemberSuperAdmin;
