import React, { useState } from "react";
import { Pressable } from 'react-native';
import { Icon, Button } from 'react-native-elements';
import { mycolors } from '../config/mycolors';
import { stylesButtons } from "../config/mystyles";

export const OpenButtons = (props: any) => (
    <Pressable onPress={props.onPress}>
        <Icon
            raised
            name='minus'
            type='font-awesome'
            color={mycolors.primary}
        />
    </Pressable>
);

export const CloseButtons = (props: any) => (
    <Pressable onPress={props.onPress}>
        <Icon
            raised
            name='plus'
            type='font-awesome'
            color={mycolors.primary}
        />
    </Pressable>
);

export const ButtonShortMenu = (props: any) => {
    const [isFocused, setIsFocused] = useState(false);

    const icon = props.icon || 'plus';
    const color_icon = props.color_icon || 'white';
    const color_bg = props.color_bg || mycolors.primary2;

    const style = {
        backgroundColor: color_bg,
        paddingLeft: 7,
        paddingRight: 14,
        borderRadius: 3,
    }

    return (
        <Button
            onPress={props.onPress}
            onBlur={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            title={props.title != undefined ? props.title : 'Thêm'}
            titleStyle={{ fontSize: 14 }}
            icon={{
                name: icon,
                type: 'font-awesome',
                size: 15,
                color: color_icon,
            }}
            iconContainerStyle={{ marginRight: 10 }}
            buttonStyle={[style, isFocused && { opacity: 0.85 }]}
            containerStyle={{
                minWidth: 154,
                alignContent:"flex-start",
                alignSelf: "center",
                marginLeft: 4,
                marginRight :4,
            }}
        />
    );
}

export const ButtonStaffFunc = (props: any) => {
    const [isFocused, setIsFocused] = useState(false);

    const style = {
        backgroundColor: mycolors.primary2,
        paddingLeft: 7,
        paddingRight: 14,
        borderRadius: 3,
    }

    const icon = props.icon || 'plus';
    const color_icon = props.color_icon || 'white';
    
    return (
        <Button
            onPress={props.onPress}
            onBlur={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            title={props.title != undefined ? props.title : 'Thêm'}
            titleStyle={{ fontSize: 14 }}
            icon={{
                name: icon,
                type: 'font-awesome',
                size: 15,
                color: color_icon,
            }}
            iconContainerStyle={{ marginRight: 10 }}
            buttonStyle={[style, isFocused && { opacity: 0.85 }]}
            containerStyle={{
                alignSelf: "flex-start",
                marginLeft: 4,
                marginRight :4,
            }}
        />
    );
}

export const ButtonCustomerFunc = (props: any) => {
    const [isFocused, setIsFocused] = useState(false);

    const style = {
        backgroundColor: mycolors.primary2,
        paddingLeft: 7,
        paddingRight: 14,
        borderRadius: 3,
    }

    const icon = props.icon || 'plus';
    const color_icon = props.color_icon || 'white';
    
    return (
        <Button
            onPress={props.onPress}
            onBlur={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            title={props.title != undefined ? props.title : 'Thêm'}
            titleStyle={{ fontSize: 14 }}
            icon={{
                name: icon,
                type: 'font-awesome',
                size: 15,
                color: color_icon,
            }}
            iconContainerStyle={{ marginRight: 10 }}
            buttonStyle={[style, isFocused && { opacity: 0.85 }]}
            containerStyle={{
                alignSelf: "flex-start",
                marginLeft: 4,
                marginRight :4,
            }}
        />
    );
}

export const ButtonFunc = (props: any) => {
    const [isFocused, setIsFocused] = useState(false);

    const style = {
        backgroundColor: mycolors.primary2,
        paddingLeft: 7,
        paddingRight: 14,
        borderRadius: 3,
    }

    const icon = props.icon || 'plus';
    const color_icon = props.color_icon || 'white';
    
    return (
        <Button
            onPress={props.onPress}
            onBlur={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            title={props.title != undefined ? props.title : 'Thêm'}
            titleStyle={{ fontSize: 14 }}
            icon={{
                name: icon,
                type: 'font-awesome',
                size: 15,
                color: color_icon,
            }}
            iconContainerStyle={{ marginRight: 10 }}
            buttonStyle={[style, isFocused && { opacity: 0.85 }]}
            containerStyle={{
                alignSelf: "center",
                marginLeft: 4,
                marginRight :4,
            }}
        />
    );
}

export const ButtonAdd = (props: any) => {
    const [isFocused, setIsFocused] = useState(false);

    const style = {
        backgroundColor: mycolors.gray,
        paddingLeft: 7,
        paddingRight: 14,
        borderRadius: 3,
    }

    return (
        <Button
            onPress={props.onPress}
            onBlur={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            title={props.title != undefined ? props.title : 'Thêm'}
            titleStyle={{ fontSize: 14 }}
            icon={{
                name: 'plus',
                type: 'font-awesome',
                size: 15,
                color: 'white',
            }}
            iconContainerStyle={{ marginRight: 10 }}
            buttonStyle={[style, isFocused && { opacity: 0.85 }]}
            containerStyle={{
                alignSelf: "center",
            }}
        />
    );
}

export const ButtonEdit = (props: any) => {
    const [isFocused, setIsFocused] = useState(false);

    const style = {
        backgroundColor: mycolors.primary,
        paddingLeft: 7,
        paddingRight: 14,
        borderRadius: 3,
    }

    return (
        <Button
            onPress={props.onPress}
            onBlur={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            title={props.title != undefined ? props.title : 'Sửa'}
            titleStyle={{ fontSize: 14 }}
            icon={{
                name: 'edit',
                type: 'font-awesome',
                size: 15,
                color: 'white',
            }}
            iconContainerStyle={{ marginRight: 10 }}
            buttonStyle={[style, isFocused && { opacity: 0.85 }]}
            containerStyle={{
                marginHorizontal: 5,
                alignSelf: "center",
            }}
        />
    );
}

export const ButtonDelete = (props: any) => {
    const [isFocused, setIsFocused] = useState(false);

    const style = {
        backgroundColor: mycolors.red,
        paddingLeft: 7,
        paddingRight: 14,
        borderRadius: 3,
    }

    return (
        <Button
            onPress={props.onPress}
            onBlur={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            title={props.title != undefined ? props.title : 'Xóa'}
            titleStyle={{ fontSize: 14 }}
            icon={{
                name: 'remove',
                type: 'font-awesome',
                size: 15,
                color: 'white',
            }}
            iconContainerStyle={{ marginRight: 10 }}
            buttonStyle={[style, isFocused && { opacity: 0.85 }]}
            containerStyle={{
                alignSelf: "center",
            }}
        />
    );
}

export const ButtonReport = (props: any) => {
    const [isFocused, setIsFocused] = useState(false);

    const style = {
        backgroundColor: mycolors.primary,
        borderRadius: 3,
        paddingLeft: 15,
        paddingRight: 25,
    }

    return (
        <Button
            onPress={props.onPress}
            onBlur={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            title={props.title != undefined ? props.title : 'Biểu đồ'}
            titleStyle={{ fontSize: 14 }}
            icon={{
                name: 'bar-chart',
                type: 'font-awesome',
                size: 15,
                color: 'white',
            }}
            iconContainerStyle={{ marginRight: 10 }}
            buttonStyle={[style, isFocused && { opacity: 0.85 }]}
            containerStyle={{
                marginHorizontal: 3,
                alignSelf: "center",
            }}
        />
    );
}

export const ButtonSave = (props: any) => {
    const [isFocused, setIsFocused] = useState(false);

    const style = {
        backgroundColor: mycolors.primary,
        borderRadius: 3,
        paddingLeft: 15,
        paddingRight: 25,
    }

    return (
        <Button
            onPress={props.onPress}
            onBlur={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            title={props.title != undefined ? props.title : 'Lưu lại'}
            titleStyle={{ fontSize: 14 }}
            icon={{
                name: 'save',
                type: 'font-awesome',
                size: 15,
                color: 'white',
            }}
            iconContainerStyle={{ marginRight: 10 }}
            buttonStyle={[style, isFocused && { opacity: 0.85 }]}
            containerStyle={{
                marginHorizontal: 3,
                alignSelf: "center",
            }}
        />
    );
}

export const ButtonDownload = (props: any) => {
    const [isFocused, setIsFocused] = useState(false);

    const style = {
        backgroundColor: mycolors.primary,
        borderRadius: 3,
        paddingLeft: 15,
        paddingRight: 25,
    }

    return (
        <Button
            onPress={props.onPress}
            onBlur={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            title={props.title != undefined ? props.title : 'Tải file excel'}
            titleStyle={{ fontSize: 14 }}
            icon={{
                name: 'download',
                type: 'font-awesome',
                size: 15,
                color: 'white',
            }}
            iconContainerStyle={{ marginRight: 10 }}
            buttonStyle={[style, isFocused && { opacity: 0.85 }]}
            containerStyle={{
                marginHorizontal: 3,
                alignSelf: "center",
            }}
        />
    );
}

export const ButtonCancel = (props: any) => {
    const [isFocused, setIsFocused] = useState(false);

    const style = {
        backgroundColor: mycolors.gray,
        borderRadius: 3,
        paddingLeft: 15,
        paddingRight: 25,
    }

    const containerStyle = props.containerStyle || {};
    return (
        <Button
            onPress={props.onPress}
            onBlur={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            title={props.title != undefined ? props.title : 'Thoát'}
            titleStyle={{ fontSize: 14, color: "#fff" }}
            icon={{
                name: 'arrow-left',
                type: 'font-awesome',
                size: 15,
                color: '#fff',
            }}
            iconContainerStyle={{ marginRight: 10 }}
            buttonStyle={[style, isFocused && { opacity: 0.85 }]}
            containerStyle={{
                ...containerStyle,
                marginHorizontal: 3,
                alignSelf: "center",
            }}
        />
    );
}


export const ButtonSearch = (props: any) => {
    const [isFocused, setIsFocused] = useState(false);

    const style = {
        backgroundColor: mycolors.primary,
        paddingLeft: 7,
        paddingRight: 14,
        borderRadius: 3,
    }

    return (
        <Button
            onPress={props.onPress}
            onBlur={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            title={props.title != undefined ? props.title : 'Tìm'}
            titleStyle={{ fontSize: 14 }}
            icon={{
                name: 'search',
                type: 'font-awesome',
                size: 15,
                color: 'white',
            }}
            iconContainerStyle={{ marginRight: 10 }}
            buttonStyle={[style, isFocused && { opacity: 0.85 }]}
            containerStyle={{
                alignSelf: "center",
            }}
        />
    );
}

export const ButtonMore = (props: any) => {
    const [isFocused, setIsFocused] = useState(false);

    const style = {
        backgroundColor: mycolors.primary,
        paddingLeft: 7,
        paddingRight: 14,
        borderRadius: 3,
    }

    return (
        <Button
            onPress={props.onPress}
            onBlur={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            title={props.title != undefined ? props.title : 'Chi tiết'}
            titleStyle={{ fontSize: 13 }}
            icon={{
                name: 'arrow-right',
                type: 'font-awesome',
                size: 15,
                color: 'white',
            }}
            iconContainerStyle={{ marginRight: 10 }}
            buttonStyle={[style, isFocused && { opacity: 0.85 }]}
            containerStyle={{
                marginHorizontal: 5,
                alignSelf: "center",
            }}
        />
    );
}

export const ButtonRefresh = (props: any) => {
    const [isFocused, setIsFocused] = useState(false);

    const style = {
        backgroundColor: mycolors.primary,
        paddingLeft: 7,
        paddingRight: 14,
        borderRadius: 3,
    }

    return (
        <Button
            onPress={props.onPress}
            onBlur={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            title={props.title != undefined ? props.title : 'Làm mới'}
            titleStyle={{ fontSize: 14 }}
            icon={{
                name: 'refresh',
                type: 'font-awesome',
                size: 15,
                color: 'white',
            }}
            iconContainerStyle={{ marginRight: 10 }}
            buttonStyle={[style, isFocused && { opacity: 0.85 }]}
            containerStyle={{
                alignSelf: "center",
                marginLeft: 6,
            }}
        />
    );
}

export const ButtonPrimary = (props: any) => {
    let buttonStyle = { ...stylesButtons.buttonStyle }
    if (props.buttonStyle != undefined) {
        buttonStyle = { ...buttonStyle, ...props.buttonStyle }
    }
    return (
        <Button
            buttonStyle={buttonStyle}
            titleStyle={{ ...stylesButtons.titleStyle, fontSize: 14 }}
            activeOpacity={0.8}
            title={props.title != undefined ? props.title : 'Button'}
            icon={{
                name: 'arrow-right',
                type: 'font-awesome',
                size: 15,
                color: 'white',
            }}
            onPress={props.onPress}
        />
    );
}


export const ButtonSecondary = (props: any) => {
    let buttonStyle = { ...stylesButtons.buttonStyle, backgroundColor: mycolors.secondary }
    if (props.buttonStyle != undefined) {
        buttonStyle = { ...buttonStyle, ...props.buttonStyle }
    }
    return (
        <Button
            buttonStyle={buttonStyle}
            titleStyle={stylesButtons.titleStyle}
            activeOpacity={0.8}
            title={props.title != undefined ? props.title : 'Button'}
            icon={{
                name: 'arrow-right',
                type: 'font-awesome',
                size: 15,
                color: 'white',
            }}
            onPress={() => { }}
        />
    );
}


export const ButtonPageLast = (props: any) => {
    const [isFocused, setIsFocused] = useState(false);

    const style = {
        backgroundColor: props.bgColorActive != undefined ? props.bgColorActive : mycolors.primary2,
        paddingLeft: 7,
        paddingRight: 14,
        borderRadius: 3,
    }

    return (
        <Button
            onPress={props.onPress}
            onBlur={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            title={props.title != undefined ? props.title : ''}
            icon={{
                name: 'step-forward',
                type: 'font-awesome',
                size: 15,
                color: 'white',
            }}
            iconContainerStyle={{ marginRight: 0 }}
            buttonStyle={[style, isFocused && { opacity: 0.85 }]}
            containerStyle={{
                alignSelf: "center",
                marginRight: 10,
            }}
        />
    );
}

export const ButtonPageFirst = (props: any) => {
    const [isFocused, setIsFocused] = useState(false);

    const style = {
        backgroundColor: props.bgColorActive != undefined ? props.bgColorActive : mycolors.primary2,
        paddingLeft: 7,
        paddingRight: 14,
        borderRadius: 3,
    }

    return (
        <Button
            onPress={props.onPress}
            onBlur={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            title={props.title != undefined ? props.title : ''}
            icon={{
                name: 'step-backward',
                type: 'font-awesome',
                size: 15,
                color: 'white',
            }}
            iconContainerStyle={{ marginRight: 0 }}
            buttonStyle={[style, isFocused && { opacity: 0.85 }]}
            containerStyle={{
                alignSelf: "center",
                marginRight: 10,
            }}
        />
    );
}

export const ButtonPagePrev = (props: any) => {
    const [isFocused, setIsFocused] = useState(false);

    const style = {
        backgroundColor: props.bgColorActive != undefined ? props.bgColorActive : mycolors.primary2,
        paddingLeft: 7,
        paddingRight: 14,
        borderRadius: 3,
    }

    return (
        <Button
            onPress={props.onPress}
            onBlur={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            title={props.title != undefined ? props.title : ''}
            icon={{
                name: 'chevron-left',
                type: 'font-awesome',
                size: 15,
                color: 'white',
            }}
            iconContainerStyle={{ marginRight: 0 }}
            buttonStyle={[style, isFocused && { opacity: 0.85 }]}
            containerStyle={{
                alignSelf: "center",
                marginRight: 10,
            }}
        />
    );
}

export const ButtonPageNext = (props: any) => {
    const [isFocused, setIsFocused] = useState(false);

    const style = {
        backgroundColor: props.bgColorActive != undefined ? props.bgColorActive : mycolors.primary2,
        paddingLeft: 7,
        paddingRight: 14,
        borderRadius: 3,
    }

    return (
        <Button
            onPress={props.onPress}
            onBlur={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            title={props.title != undefined ? props.title : ''}
            icon={{
                name: 'chevron-right',
                type: 'font-awesome',
                size: 15,
                color: 'white',
            }}
            iconContainerStyle={{ marginRight: 0 }}
            buttonStyle={[style, isFocused && { opacity: 0.85 }]}
            containerStyle={{
                alignSelf: "center",
                marginRight: 10,
            }}
        />
    );
}

export const ButtonRegister = (props: any) => {
  return (
    <Button
      buttonStyle={stylesButtons.loginButton}
      containerStyle={{ marginTop: 6 }}
      activeOpacity={0.8}
      title={props.title != undefined ? props.title : "Đăng nhập"}
      icon={{
        name: "arrow-right",
        type: "font-awesome",
        size: 15,
        color: "white",
      }}
      onPress={() => props.onPress()}
      titleStyle={stylesButtons.loginButton}
    />
  );
};