import React from "react";
import { Text, View, StyleSheet, ScrollView } from 'react-native';

import { Header } from '../../components/header';
import { ButtonStaffFunc } from "../../components/mybuttons";
import { LANG } from "../../config/config";
import { mycolors } from "../../config/mycolors";

function HomeAdmin({ navigation }) {
    return (
        <View style={styles.container}>
            <Header title={LANG.HomePage} view="chat" isHome="true" navigation={navigation} />
            <ScrollView
                contentContainerStyle={{
                    flexGrow: 1,
                }}
                keyboardShouldPersistTaps="handled"
            >
                <View style={styles.boxTop}>
                    <Text style={styles.textTop}>Trang Chủ Admin</Text>
                </View>
                <View style={styles.box}>
                    <ButtonStaffFunc
                        icon="user-times"
                        title={LANG.Manager}
                        onPress={() => {
                            navigation.navigate('ListManager');
                        }}
                    />
                </View>
                <View style={styles.box}>
                    <ButtonStaffFunc
                        icon="user-secret"
                        title={LANG.Doctor}
                        onPress={() => {
                            navigation.navigate('ListDoctor');
                        }}
                    />
                </View>
                <View style={styles.box}>
                    <ButtonStaffFunc
                        icon="user"
                        title={LANG.Staff}
                        onPress={() => {
                            navigation.navigate('ListStaff');
                        }}
                    />
                </View>
                <View style={styles.box}>
                    <ButtonStaffFunc
                        icon="users"
                        title={LANG.Customer}
                        onPress={() => {
                            navigation.navigate('ListCustomer');
                        }}
                    />
                </View>
                <View style={styles.box}>
                    <ButtonStaffFunc
                        icon="pencil-square-o"
                        title={LANG.PostNews}
                        onPress={() => {
                            navigation.navigate('ListNews');
                        }}
                    />
                </View>
                <View style={styles.box}>
                    <ButtonStaffFunc
                        icon="newspaper-o"
                        title={LANG.News}
                        onPress={() => {
                            navigation.navigate('ShowNews');
                        }}
                    />
                </View>
                <View style={styles.box}>
                    <ButtonStaffFunc
                        icon="bar-chart"
                        title={LANG.Report}
                        onPress={() => {
                            navigation.navigate('ReportInternal');
                        }}
                    />
                </View>
            </ScrollView>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#E8EAED',
    },
    box: {
        backgroundColor: mycolors.primary,
        alignContent: "center",
        marginTop: 6,
        marginBottom: 6,
        marginLeft: 12,
        marginRight: 12,
        padding: 10
    },
    text: {
        color: '#fff'
    },
    boxTop: {
        padding: 12,
        alignItems: "center",
    },
    textTop: {
        color: "#000",
    }
});

export default HomeAdmin;
