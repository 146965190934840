import React, { useState, useRef, useEffect } from 'react';
import { StyleSheet, View, Text, LayoutAnimation, UIManager, Alert, Linking } from 'react-native';
import { Input, Button, Icon, Dialog } from 'react-native-elements';

import { UserModel, WebAPIModel } from "../../models/models"

import AsyncStorage from '@react-native-async-storage/async-storage';
import { mycolors } from '../../config/mycolors';
import { validatePhone } from '../../config/myfunc';
import { stylesInput } from '../../config/mystyles';
import { windowHeight, windowWidth } from '../../config/config';

import { AuthContext } from "../../context/mycontext"

import { useNetInfo } from "@react-native-community/netinfo";

// Enable LayoutAnimation on Android
UIManager.setLayoutAnimationEnabledExperimental &&
  UIManager.setLayoutAnimationEnabledExperimental(true);

type TabSelectorProps = {
  selected: boolean;
};

const TabSelector: React.FunctionComponent<TabSelectorProps> = ({
  selected,
}) => {
  return (
    <View style={styles.selectorContainer}>
      <View style={selected && styles.selected} />
    </View>
  );
};

type LoginScreen3State = {};
type LoginScreen3Props = {};

const LoginScreen3: React.FunctionComponent<LoginScreen3State> = (
  props: LoginScreen3Props
) => {
  const netInfo = useNetInfo();

  const { } = props;
  const route = props.route;
  const navigation = props.navigation;
 
  const [isLoading, setLoading] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>('');
  const [isPhoneValid, setPhoneValid] = useState<boolean>(true);
  const [password, setPassword] = useState<string>('');
  const [isPasswordValid, setPasswordValid] = useState<boolean>(true);
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [isConfirmPasswordValid, setConfirmPasswordValid] = useState<boolean>(
    true
  );
  const [selectedCategory, setSelectedCategory] = useState<number>(0);
  const phoneInput = useRef<InputProps>(null);
  const passwordInput = useRef<InputProps>(null);
  const confirmationInput = useRef<InputProps>(null);

  const isLoginPage = selectedCategory === 0;
  const isSignUpPage = selectedCategory === 1;

  const { screen } = route.params || '';
  useEffect(() => {
    // clean up
    return () => {
      if (screen === 'signup') {
        setSelectedCategory(1);
      } else {
        setSelectedCategory(0);
      }
    };
  }, [screen]);

  const [forgotPassword, setForgotPassword] = useState<boolean>(false);
  const [contactPhone, setContactPhone] = useState<array>([]);
  useEffect(() => {
    WebAPIModel.get_all()
      .then((res) => {
        const data = res.data || {};
        setContactPhone(data.info.phone);
      })
      .finally(() => {
      });
  }, []);

  const [visibleDialog, setVisibleDialog] = React.useState(false);
  const [visibleRegisterDialog, setVisibleRegisterDialog] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState<any>('');

  const dialogTitle = 'Thành Viên';

  const authContext = React.useContext(AuthContext);

  const selectCategory = (selectedCategoryIndex: number) => {
    LayoutAnimation.easeInEaseOut();
    setLoading(false);
    setSelectedCategory(selectedCategoryIndex);
  };

  function login(){
    if (netInfo.isConnected != undefined && !netInfo.isConnected) {
      setDialogMessage('Mạng internet bị mất kết nối, bạn không thể đăng nhập lúc này!');
      setVisibleDialog(true);
      return;
    }

    const isPhoneValidFlag =
        validatePhone(phone) || phoneInput.current.shake();
      const isPasswordValidFlag =
        password.length >= 5 || passwordInput.current.shake();

      LayoutAnimation.easeInEaseOut();
      setPhoneValid(isPhoneValidFlag);
      setPasswordValid(isPasswordValidFlag);

      if (isPhoneValidFlag && isPasswordValidFlag) {
        setLoading(true);

        try {
          UserModel.login({ 'username': phone, 'password': password })
          .then((results) => {
            const data = results.data || {};
            if (__DEV__) {
              console.log('data login:', data);
            }
            const token = data.token;
            const role = data.role || '';
            const display_name = data.display_name || '';
            const user_id = data.id || '';
            const is_activated = data.is_activated || false;
            if (token) {

              AsyncStorage.setItem('jwt_token', token);
              AsyncStorage.setItem('user_role', role);
              AsyncStorage.setItem('display_name', display_name);
              AsyncStorage.setItem('user_id', user_id);
              AsyncStorage.setItem('is_activated', is_activated.toString());
              
              authContext.signIn({ phone, password, token, role });
              authContext.setIsActivated(is_activated);

              setVisibleDialog(true);
              setDialogMessage('Đăng nhập thành công!');
            }
          })
          .catch(err => {
            AsyncStorage.setItem('jwt_token', '');
            setVisibleDialog(true);
            setDialogMessage(`Có lỗi xảy ra khi đăng nhập!\nSố di động hoặc mật khẩu không đúng.`);
          })
          .finally(() => {
            setLoading(false);
          });
        } catch (error) {
          setDialogMessage(`Có lỗi: ${error}`);
          setVisibleDialog(true);
        }
      }
  };

  function signUp(){
    if (netInfo.isConnected != undefined && !netInfo.isConnected) {
      setDialogMessage('Mạng internet bị mất kết nối, bạn không thể đăng ký lúc này!');
      setVisibleDialog(true);
      return;
    }

    const isPhoneValidFlag =
        validatePhone(phone) || phoneInput.current.shake();
      const isPasswordValidFlag =
        password.length >= 5 || passwordInput.current.shake();
      const isConfirmPasswordValidFlag =
        password === confirmPassword || confirmationInput.current.shake();

      LayoutAnimation.easeInEaseOut();
      setPhoneValid(validatePhone(phone) || phoneInput.current.shake());
      setPasswordValid(password.length >= 5 || passwordInput.current.shake());
      setConfirmPasswordValid(
        password === confirmPassword || confirmationInput.current.shake()
      );

      if (
        isPhoneValidFlag &&
        isPasswordValidFlag &&
        isConfirmPasswordValidFlag
      ) {
        UserModel.register({
          'name': phone, 'password': password, 'confirm_password': confirmPassword
        })
          .then((results) => {
            const data = results.data || {};
            if (__DEV__) {
              console.log('data register:', data);
            }

            if (data.post != undefined) {
              selectCategory(0);

              setVisibleRegisterDialog(true);
              setDialogMessage('Đăng ký thành công!');
            } else {
              setVisibleRegisterDialog(true);
              setDialogMessage(`Có lỗi xảy ra khi đăng ký!\n${data.message}`);
            }
          })
          .catch(err => {
            setVisibleRegisterDialog(true);
            setDialogMessage(`${err.message} \nOr Can not connect API, Please login again!`);
          })
          .finally(() => {
            setLoading(false);
          });
      }
  };

  return (
    <View style={styles.container}>
      <Dialog
        isVisible={visibleDialog}
        onBackdropPress={() => {
          setVisibleDialog(!visibleDialog);
        }}
        overlayStyle={{ width: windowWidth - 30 }}
      >
        <Dialog.Title title={dialogTitle} />
        <Text>{dialogMessage}</Text>
      </Dialog>

      <Dialog
        isVisible={visibleRegisterDialog}
        onBackdropPress={() => {
          setVisibleRegisterDialog(!visibleRegisterDialog);
        }}
      >
        <Dialog.Title title={dialogTitle} />
        <Text>{dialogMessage}</Text>
      </Dialog>

      <View style={styles.bgImage}>
        <View style={{ flexDirection: "row" }}>
          <Button
            disabled={isLoading}
            type="clear"
            activeOpacity={0.7}
            onPress={() => selectCategory(0)}
            containerStyle={{ flex: 1 }}
            titleStyle={[
              styles.categoryText,
              isLoginPage && styles.selectedCategoryText,
            ]}
            title="Đăng nhập"
          />
          <Button
            disabled={isLoading}
            type="clear"
            activeOpacity={0.7}
            onPress={() => selectCategory(1)}
            containerStyle={{ flex: 1 }}
            titleStyle={[
              styles.categoryText,
              isSignUpPage && styles.selectedCategoryText,
            ]}
            title="Đăng ký"
          />
        </View>
        <View style={styles.rowSelector}>
          <TabSelector selected={isLoginPage} />
          <TabSelector selected={isSignUpPage} />
        </View>
        <View style={styles.formContainer}>
          <Input
            leftIcon={
              <Icon
                name="phone"
                type="font-awesome"
                color={stylesInput.icon.color}
                size={25}
                style={{ backgroundColor: "transparent", marginRight: 5 }}
              />
            }
            value={phone}
            placeholder={"Số di động"}
            maxLength={11}
            keyboardAppearance="light"
            autoFocus={false}
            autoCapitalize="none"
            autoCorrect={false}
            keyboardType="numeric"
            returnKeyType="next"
            inputStyle={stylesInput.inputStyle}
            containerStyle={stylesInput.containerStyle}
            ref={phoneInput}
            onSubmitEditing={() => passwordInput.current.focus()}
            onChangeText={(text) => setPhone(text)}
            errorMessage={
              isPhoneValid
                ? ""
                : "Số di động không hợp lệ. Số hợp lệ phải bắt đầu từ 0 và có độ dài 9-11 số!"
            }
          />
          <Input
            leftIcon={
              <Icon
                name="lock"
                type="simple-line-icon"
                color={stylesInput.icon.color}
                size={25}
                style={{ backgroundColor: "transparent" }}
              />
            }
            value={password}
            placeholder={"Mật khẩu"}
            keyboardAppearance="light"
            autoCapitalize="none"
            autoCorrect={false}
            secureTextEntry={true}
            returnKeyType={isSignUpPage ? "next" : "done"}
            blurOnSubmit={true}
            containerStyle={stylesInput.containerStyle}
            inputStyle={stylesInput.inputStyle}
            ref={passwordInput}
            onSubmitEditing={() => {
              isSignUpPage ? confirmationInput.current.focus() : login();
            }}
            onChangeText={(text) => setPassword(text)}
            errorMessage={isPasswordValid ? "" : "Mật khẩu ít nhất 5 ký tự"}
          />
          {isSignUpPage && (
            <Input
              leftIcon={
                <Icon
                  name="lock"
                  type="simple-line-icon"
                  color={stylesInput.icon.color}
                  size={25}
                  style={{ backgroundColor: "transparent" }}
                />
              }
              value={confirmPassword}
              placeholder={"Xác nhận mật khẩu"}
              secureTextEntry={true}
              keyboardAppearance="light"
              autoCapitalize="none"
              autoCorrect={false}
              keyboardType="default"
              returnKeyType={"done"}
              blurOnSubmit={true}
              containerStyle={stylesInput.containerStyle}
              inputStyle={stylesInput.inputStyle}
              ref={confirmationInput}
              onSubmitEditing={signUp}
              onChangeText={(text) => setConfirmPassword(text)}
              errorMessage={
                isConfirmPasswordValid ? "" : "Vui lòng xác nhận mật khẩu"
              }
            />
          )}
          <Button
            buttonStyle={styles.loginButton}
            containerStyle={{ marginTop: 12 }}
            activeOpacity={0.8}
            title={isLoginPage ? "Đăng nhập" : "Đăng ký"}
            icon={{
              name: "arrow-right",
              type: "font-awesome",
              size: 15,
              color: "white",
            }}
            onPress={isLoginPage ? login : signUp}
            titleStyle={styles.loginTextButton}
            loading={isLoading}
            disabled={isLoading}
          />
        </View>
        <View style={styles.helpContainer}>
          {!forgotPassword ? (
            <View style={{
              flexDirection: "row",
            }}>
              <Button
                title={"Quên mật khẩu ?"}
                titleStyle={{ color: mycolors.gray, fontSize: 14 }}
                buttonStyle={{ backgroundColor: "transparent" }}
                onPress={() => {
                  setForgotPassword(true);
                }}
              />
              <Button
                title={"Trang giới thiệu"}
                titleStyle={{ color: mycolors.gray, fontSize: 14 }}
                buttonStyle={{ backgroundColor: "transparent" }}
                onPress={() => {
                  navigation.navigate("Intro");
                }}
              />
            </View>
          ) : (
            <View style={{ marginTop: 36 }}>
              <Text style={{ fontWeight: "bold" }}>
                Vui lòng liên hệ số phone(Zalo) để lấy lại mật khẩu:
              </Text>
              {contactPhone.map((item, index) => (
                <Button
                  key={index}
                  title={`Hotline ${index + 1}: ${item.phone}`}
                  titleStyle={{ color: mycolors.gray, fontSize: 14 }}
                  buttonStyle={{
                    backgroundColor: "transparent",
                    marginBottom: -8,
                  }}
                  onPress={() => {
                    Linking.openURL(`tel:${item}`);
                  }}
                />
              ))}
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    paddingBottom: 0,
    width: '100%',
    height: windowHeight - 80,
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  rowSelector: {
    height: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  selectorContainer: {
    flex: 1,
    alignItems: 'center',
  },
  selected: {
    position: 'absolute',
    borderRadius: 50,
    height: 0,
    width: 0,
    top: -5,
    borderRightWidth: 70,
    borderBottomWidth: 70,
    borderColor: 'white',
    backgroundColor: 'white',
  },
  loginTextButton: {
    fontSize: 16,
    color: 'white',
    fontWeight: 'bold',
  },
  loginButton: {
    backgroundColor: mycolors.primary,
    borderRadius: 5,
    height: 50,
    paddingLeft: 20,
    paddingRight: 20
  },
  formContainer: {
    backgroundColor: 'white',
    width: windowWidth - 30,
    borderRadius: 5,
    paddingTop: 32,
    paddingBottom: 32,
    alignItems: 'center',
  },
  loginText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
  },
  bgImage: {
    flex: 1,
    backgroundColor: mycolors.secondary,
    top: 0,
    left: 0,
    width: '100%',
    height: windowHeight,
    justifyContent: 'center',
    alignItems: 'center',
  },
  categoryText: {
    textAlign: 'center',
    color: 'black',
    fontSize: 16,
    backgroundColor: 'transparent',
    opacity: 0.54,
    width: 120,
  },
  selectedCategoryText: {
    opacity: 1,
  },
  helpContainer: {
    height: 64,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default LoginScreen3;
