import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { useState, useEffect } from "react";
import * as WebBrowser from "expo-web-browser";
import { Platform, Text, View, ScrollView, Pressable, Image } from 'react-native';
import { CheckBox, Button, Dialog, Overlay, Icon, Divider } from 'react-native-elements';
import { WebView } from 'react-native-webview';

import moment from 'moment'

import DatePicker from 'react-native-modern-datepicker';
import { mycolors } from "../config/mycolors";
import { config, fontSizeBaseCalendar, LANG, LIST_REPORT_MENU, windowHeight, windowWidth } from "../config/config";

import { ButtonCancel, ButtonFunc, ButtonPageFirst, ButtonPageLast, ButtonPageNext, ButtonPagePrev, ButtonPrimary, ButtonSave } from '../components/mybuttons'
import { stylesItem, stylesTextNote, stylesClassRoom, stylesChildrensTableDoing, stylesTheChildrenTableDoing, stylesICustomer, stylesI22Customer, stylesNews } from "../config/mystyles";
import { convertDateForMoment, formatCurrenCy } from "../config/myfunc";

import { BookingModel, PostModel } from "../models/models";
import { MyInputContent } from "./myinputs";
import { IsLoadingData, NoDataChecking, NoDataLoading, NoDataUnChecking } from "../components/myother";

import { MyContext } from '../context/mycontext';

export const MyCalendar = (props) => {
    let mContext: any = React.useContext(MyContext);
    let [childDate, setChildDate] = useState('');

    const changeValue = (date: any) => {
        switch (props.index) {
            case 'fromDate':
                mContext.setFromDate(date);
                break;
            case 'toDate':
                mContext.setToDate(date);
                break;
            case 'startDate':
                mContext.setStartDate(date);
                break;
            default:
                break;
        }

        setChildDate(date);
    };
    return (
        <View style={Platform.OS == 'web' && { width: 350 }}>
            <DatePicker
                options={{
                    backgroundColor: mycolors.primary,
                    textHeaderColor: '#FFA25B',
                    textDefaultColor: '#F6E7C1',
                    selectedTextColor: '#fff',
                    mainColor: '#F4722B',
                    textSecondaryColor: '#D6C7A1',
                    textFontSize: fontSizeBaseCalendar,
                    textHeaderFontSize: fontSizeBaseCalendar + 2,
                    borderColor: 'rgba(122, 146, 165, 0.75)',
                }}
                current={mContext[`${props.index}`]}
                selected={mContext[`${props.index}`]}
                mode="calendar"
                minuteInterval={30}
                style={{ borderRadius: 0 }}
                onSelectedChange={date => {
                    changeValue(date);
                }}
            />
        </View>
    );
};

export const MyPaging = () => {
    let mContext: any = React.useContext(MyContext);

    const currentPage = mContext.currentPage || 1;
    const pageParams = mContext.pagingParams || {};
    const searchName = mContext.searchName || '';
    const Paging = (page: any) => {
        mContext.Paging(page);
    };
    const setCurrentPage = (page: any) => {
        mContext.setCurrentPage(page);
    };

    return (
        <>
            {pageParams.total_records > 0 && (
                <Text style={{ alignSelf: "center", marginBottom: 12 }}>
                    {currentPage}/{pageParams.pages} trang, {pageParams.total_records} bản ghi
                    {searchName != '' ? `. Tìm theo: "${searchName}"` : ''}
                </Text>
            )}
            <View style={{ alignSelf: "center", flexDirection: "row" }}>
                {pageParams.pages > 1 && (
                    <ButtonPageFirst
                        bgColorActive={pageParams.has_prev == true ? mycolors.primary : undefined}
                        onPress={() => {
                            if (pageParams.has_prev == true) {
                                Paging(1);
                                setCurrentPage(1);
                            }
                        }}
                    />
                )}
                {pageParams.has_prev == true && (
                    <ButtonPagePrev
                        bgColorActive={mycolors.primary}
                        onPress={() => { Paging(currentPage - 1); setCurrentPage(currentPage - 1); }}
                    />
                )}
                {pageParams.has_next == true && (
                    <ButtonPageNext
                        bgColorActive={mycolors.primary}
                        onPress={() => { Paging(currentPage + 1); setCurrentPage(currentPage + 1); }}
                    />
                )}
                {pageParams.pages > 1 && (
                    <ButtonPageLast
                        bgColorActive={pageParams.has_next == true ? mycolors.primary : undefined}
                        onPress={() => {
                            if (pageParams.has_next == true) {
                                Paging(pageParams.pages);
                                setCurrentPage(pageParams.pages);
                            }
                        }}
                    />
                )}
            </View>
            <View style={{ height: 60 }}></View>
        </>
    );
};

export const MyListingSupplier = (props: any) => {
    let mContext: any = React.useContext(MyContext);

    const taskItems = mContext.taskItems || [];
    const checkItem = (item: any) => {
        mContext.checkItem(item);
    };

    const navigation = props.navigation;
    return (
        <View style={{ marginTop: 30 }}>
            {taskItems.length == 0 ? (
                <NoDataLoading />
            ) : (
                taskItems.map((item: any) => {
                    return (
                        <View key={item.id} style={stylesItem.item}>
                            <View style={stylesItem.itemLeft}>
                                <CheckBox
                                    center
                                    title=""
                                    checkedIcon='dot-circle-o'
                                    uncheckedIcon='circle-o'
                                    checked={item.checked}
                                    containerStyle={stylesItem.checkbox}
                                    onPress={() => checkItem(item)}
                                />
                                <Pressable
                                    style={stylesItem.itemText}
                                    onPress={() => checkItem(item)}
                                >
                                    <Text style={{ lineHeight: 22 }}>
                                        <Text style={{ fontWeight: "bold" }}>{item.display_name}</Text>
                                        {`\nTổng chi: ${formatCurrenCy(item.payment)}`}
                                        {`\nDi động: ${item.phone}`}
                                        {item.birthday != undefined && (
                                            `\nNgày sinh: ` + moment(convertDateForMoment(item.birthday)).format('DD/MM/YYYY')
                                        )}
                                        {item.address != '' && `\nĐịa chỉ: ${item.address}`}
                                        {item.card != '' && `\nCMND(Thẻ căn cước): ${item.card}`}
                                        {item.email != '' && `\nEmail: ${item.email}`}
                                        {item.other_info != '' && `\nThông tin khác: ${item.other_info}`}
                                    </Text>
                                </Pressable>
                            </View>
                            <View style={{ alignSelf: "flex-start", marginTop: 8, marginLeft: 40 }}>
                                <ButtonFunc
                                    icon="plus"
                                    color_icon="#fff"
                                    title="Nhập hàng"
                                    onPress={() => {
                                        navigation.navigate('BuyOrder', {
                                            _supplier_id: item.id,
                                            _supplier_name: item.display_name,
                                        });
                                    }}
                                />
                            </View>
                        </View>
                    );
                })
            )}
        </View>
    );
};

export const MyListingMember = (props: any) => {
    let mContext: any = React.useContext(MyContext);

    const taskItems = mContext.taskItems || [];
    const checkItem = (item: any) => {
        mContext.checkItem(item);
    };

    return (
        <View style={{ marginTop: 30 }}>
            {taskItems.length == 0 ? (
                <NoDataLoading />
            ) : (
                taskItems.map((item: any) => {
                    return (
                        <View key={item.id} style={stylesItem.item}>
                            <View style={stylesItem.itemLeft}>
                                <CheckBox
                                    center
                                    title=""
                                    checkedIcon='dot-circle-o'
                                    uncheckedIcon='circle-o'
                                    checked={item.checked}
                                    containerStyle={stylesItem.checkbox}
                                    onPress={() => checkItem(item)}
                                />
                                <Pressable
                                    style={stylesItem.itemText}
                                    onPress={() => checkItem(item)}
                                >
                                    <Text style={{ fontWeight: "bold" }}>{item.display_name}</Text>
                                    <Text>{`\nDi động: ${item.name}`}</Text>
                                </Pressable>
                                <View style={{ marginRight: 10 }}>
                                    {item.birthday != undefined && (
                                        <Text style={{ fontSize: 12 }}>
                                            {`Ngày sinh:\n`}
                                            {moment(convertDateForMoment(item.birthday)).format('DD/MM/YYYY')}
                                        </Text>
                                    )}
                                </View>
                            </View>
                        </View>
                    );
                })
            )}
        </View>
    );
};

export const BoxOpenShortMenu = (props: any) => {
    let mContext: any = React.useContext(MyContext);

    const userFamilyName = props.userFamilyName || '';
    const customerName = props.customerName || '';
    const customerID = props.customerID || '';
    const inChat = props.inChat || false;

    const navigation = props.navigation;
    return (
        <View style={{ flexDirection: "row", alignSelf: "center", marginTop: 12, marginLeft: 20, marginRight: 20 }}>
            {userFamilyName == '' ?
                <View style={{ flexDirection: "row", alignSelf: "center" }}>
                    <Text>{customerName}</Text>
                </View>
                :
                <View style={{ flexDirection: "row", alignSelf: "center" }}>
                    <ButtonFunc
                        icon="user"
                        title={customerName}
                        onPress={() => {
                            navigation.navigate('FamilyCustomerStaff', {
                                _customer_id: customerID,
                                _customer_name: customerName,
                            });
                        }}
                    />
                    <Icon
                        name='arrow-right'
                        type='font-awesome'
                        color={mycolors.gray}
                        size={15}
                        style={{
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginLeft: 6,
                            marginRight: 6,
                        }}
                    />
                    <View
                        style={{
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                        }}
                    >
                        <Text>{userFamilyName}</Text>
                    </View>
                </View>
            }
            <Icon
                name='bars'
                type='font-awesome'
                color='#303030'
                onPress={() => {
                    mContext.setVisibleShortMenu(true);
                }}
                style={{
                    flex: 1,
                    marginTop: userFamilyName == '' ? (inChat ? -3 : 3) : 6,
                    marginLeft: 8
                }}
            />
        </View>
    )
};

export const BoxNameCustomerToUserFamily = (props: any) => {
    const customerName = props.customerName || '';
    const userFamilyName = props.userFamilyName || '';

    return (
        <View style={{ flexDirection: "row", alignSelf: "flex-start", marginTop: 12, marginLeft: 12 }}>
            <Text style={{ fontWeight: "bold" }}>{customerName}</Text>
            {userFamilyName != '' && <>
                <Icon
                    name='arrow-right'
                    type='font-awesome'
                    color={mycolors.gray}
                    size={15}
                    style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginLeft: 6,
                        marginRight: 6,
                    }}
                />
                <View
                    style={{
                        flex: 1,
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start'
                    }}
                >
                    <Text>{userFamilyName}</Text>
                </View>
            </>
            }
        </View>
    )
};

export const MyListingCustomerFamilyCustomer = (props: any) => {
    let mContext: any = React.useContext(MyContext);

    const taskItems = mContext.taskItems || [];
    const checkItem = (item: any) => {
        mContext.checkItem(item);
    };

    const navigation = props.navigation;
    const customerName = props.customerName || '';
    return (
        <View style={{ marginTop: 30 }}>
            {taskItems.length == 0 ? (
                <NoDataLoading />
            ) : (
                taskItems.map((item: any) => {
                    return (
                        <View key={item.id} style={stylesI22Customer.item}>
                            <View style={stylesI22Customer.itemLeft}>
                                <Icon
                                    name='arrows'
                                    type='font-awesome'
                                    color={mycolors.gray}
                                    size={22}
                                    style={stylesI22Customer.iconStyle}
                                    onPress={() => { }}
                                />
                                <Pressable
                                    style={stylesI22Customer.itemText}
                                    onPress={() => { }}
                                >
                                    <Text style={{ lineHeight: 22 }}>
                                        <Text style={{ fontWeight: "bold" }}>{item.display_name}</Text>
                                        {item.sex == 1 ? '\nGiới tính: Nam' : '\nGiới tính: Nữ'}
                                        {item.birthday != undefined && (
                                            `\nNgày sinh:` + moment(convertDateForMoment(item.birthday)).format('DD/MM/YYYY')
                                        )}
                                        {item.card != '' && `\nCMND(Thẻ căn cước): ${item.card}`}
                                        {item.address != '' && `\nĐịa chỉ: ${item.address}`}
                                        {item.other_info != '' && `\nThông tin khác: ${item.other_info}`}
                                        {`\nTổng chi: ${formatCurrenCy(item.money_service_sale + item.money_product_sale)}`}
                                        {`\n(Khám: ${formatCurrenCy(item.money_service_sale)}, Mua thuốc: ${formatCurrenCy(item.money_product_sale)} )`}
                                    </Text>
                                </Pressable>
                            </View>

                            <ScrollView horizontal={true} style={{ flexDirection: "row" }}>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'flex-start',
                                        marginTop: 12,
                                        width: 530
                                    }}
                                >
                                    <ButtonFunc
                                        icon="heart"
                                        color_icon="#f7e608"
                                        title={LANG.HealthCheck}
                                        onPress={() => {
                                            navigation.navigate('SaleServiceCustomer', {
                                                _customer_id: item.user_id,
                                                _customer_name: customerName,
                                                _user_family_id: item.id,
                                                _user_family_name: item.display_name,
                                            });
                                        }}
                                    />
                                    <ButtonFunc
                                        icon="shopping-cart"
                                        title={LANG.BuyMedicine}
                                        onPress={() => {
                                            navigation.navigate('SaleOrderCustomer', {
                                                _customer_id: item.user_id,
                                                _customer_name: customerName,
                                                _user_family_id: item.id,
                                                _user_family_name: item.display_name,
                                            });
                                        }}
                                    />
                                    <ButtonFunc
                                        icon="calendar"
                                        title={LANG.CalendarAppointment}
                                        onPress={() => {
                                            navigation.navigate('BookingCustomer', {
                                                _customer_id: item.user_id,
                                                _customer_name: customerName,
                                                _user_family_id: item.id,
                                                _user_family_name: item.display_name,
                                            });
                                        }}
                                    />
                                    <ButtonFunc
                                        icon="comments"
                                        title={LANG.Chat}
                                        onPress={() => {
                                            // navigation.navigate('', {
                                            //     _customer_id: item.user_id,
                                            //     _customer_name: customerName,
                                            //     _user_family_id: item.id,
                                            //     _user_family_name: item.display_name,
                                            // });
                                        }}
                                    />
                                </View>
                            </ScrollView>
                        </View>
                    );
                })
            )}
        </View>
    );
};

export const MyListingCustomerFamilyStaff = (props: any) => {
    let mContext: any = React.useContext(MyContext);

    const taskItems = mContext.taskItems || [];
    const checkItem = (item: any) => {
        mContext.checkItem(item);
    };

    const navigation = props.navigation;
    const customerName = props.customerName || '';
    return (
        <View style={{ marginTop: 30 }}>
            {taskItems.length == 0 ? (
                <NoDataLoading />
            ) : (
                taskItems.map((item: any) => {
                    return (
                        <View key={item.id} style={stylesICustomer.item}>
                            <View style={stylesICustomer.itemLeft}>
                                <CheckBox
                                    center
                                    title=""
                                    checkedIcon='dot-circle-o'
                                    uncheckedIcon='circle-o'
                                    checked={item.checked}
                                    containerStyle={stylesICustomer.checkbox}
                                    onPress={() => checkItem(item)}
                                />
                                <Pressable
                                    onPress={() => checkItem(item)}
                                >
                                    <Text style={{ fontWeight: "bold" }}>{item.display_name}</Text>
                                    <Text style={{ lineHeight: 22 }}>
                                        {item.sex == 1 ? '\Giới tính: Nam' : '\Giới tính: Nữ'}
                                        {item.birthday != undefined && (
                                            `\nNgày sinh: ` + moment(convertDateForMoment(item.birthday)).format('DD/MM/YYYY')
                                        )}
                                        {item.card != '' && `\nCMND(Thẻ căn cước): ${item.card}`}
                                        {item.address != '' && `\nĐịa chỉ: ${item.address}`}
                                        {item.other_info != '' && `\nThông tin khác: ${item.other_info}`}
                                        {`\nTổng chi: ${formatCurrenCy(item.money_service_sale + item.money_product_sale)}`}
                                        {`\n(Khám: ${formatCurrenCy(item.money_service_sale)}, Mua thuốc: ${formatCurrenCy(item.money_product_sale)} )`}
                                    </Text>
                                </Pressable>
                            </View>

                            <ScrollView horizontal={true} style={{ flexDirection: "row" }}>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'flex-start',
                                        marginTop: 12,
                                        width: 530
                                    }}
                                >
                                    <ButtonFunc
                                        icon="heart"
                                        color_icon="#f7e608"
                                        title={LANG.HealthCheck}
                                        onPress={() => {
                                            navigation.navigate('SaleService', {
                                                _customer_id: item.user_id,
                                                _customer_name: customerName,
                                                _user_family_id: item.id,
                                                _user_family_name: item.display_name,
                                            });
                                        }}
                                    />
                                    <ButtonFunc
                                        icon="shopping-cart"
                                        title={LANG.BuyMedicine}
                                        onPress={() => {
                                            navigation.navigate('SaleOrder', {
                                                _customer_id: item.user_id,
                                                _customer_name: customerName,
                                                _user_family_id: item.id,
                                                _user_family_name: item.display_name,
                                            });
                                        }}
                                    />
                                    <ButtonFunc
                                        icon="calendar"
                                        title={LANG.CalendarAppointment}
                                        onPress={() => {
                                            navigation.navigate('BookingStaff', {
                                                _customer_id: item.user_id,
                                                _customer_name: customerName,
                                                _user_family_id: item.id,
                                                _user_family_name: item.display_name,
                                            });
                                        }}
                                    />
                                    <ButtonFunc
                                        icon="comments"
                                        title={LANG.Chat}
                                        onPress={() => {
                                            // navigation.navigate('', {
                                            //     _customer_id: item.user_id,
                                            //     _customer_name: customerName,
                                            //     _user_family_id: item.id,
                                            //     _user_family_name: item.display_name,
                                            // });
                                        }}
                                    />
                                </View>
                            </ScrollView>
                        </View>
                    );
                })
            )}
        </View>
    );
};

export const MyListingCustomerStaff = (props: any) => {
    let mContext: any = React.useContext(MyContext);

    const taskItems = mContext.taskItems || [];
    const checkItem = (item: any) => {
        mContext.checkItem(item);
    };

    const navigation = props.navigation;

    const handleActivated = (item: any) => {
        mContext.handleActivated(item);
    };

    return (
        <View style={{ marginTop: 30 }}>
            {taskItems.length == 0 ? (
                <NoDataLoading />
            ) : (
                taskItems.map((item: any) => {
                    const user_family = item.user_family || '';
                    let user_family_obj = [];
                    if(user_family != '' && user_family != undefined){
                        // convert to array
                        const user_family_arr = user_family.split('\n');
                        // loop array and convert to object
                        user_family_obj = user_family_arr.map((item: any) => {
                            const arr = item.split('|');
                            let birthday = arr[5];
                            if (birthday != '') {
                                birthday = moment(convertDateForMoment(birthday)).format('DD/MM/YYYY');
                            }

                            return {
                                id: arr[0],
                                display_name: arr[1],
                                address: arr[2],
                                other_info: arr[3],
                                card: arr[4],
                                birthday,
                            };
                        });
                    }
                    
                    return (
                        <View key={item.id} style={stylesICustomer.item}>
                            <View style={stylesICustomer.itemLeft}>
                                <CheckBox
                                    center
                                    title=""
                                    checkedIcon='dot-circle-o'
                                    uncheckedIcon='circle-o'
                                    checked={item.checked}
                                    containerStyle={stylesICustomer.checkbox}
                                    onPress={() => checkItem(item)}
                                />
                                <Pressable
                                    onPress={() => checkItem(item)}
                                >
                                    <Text style={{ fontWeight: "bold" }}>{item.display_name}</Text>
                                    <Text style={{ lineHeight: 22 }}>
                                        {`\nDi động: ${item.name}`}
                                        {item.sex == 1 || item.sex == true ? '\nGiới tính: Nam' : '\nGiới tính: Nữ'}
                                        {item.birthday != undefined && (
                                            `\nNgày sinh: ` + moment(convertDateForMoment(item.birthday)).format('DD/MM/YYYY')
                                        )}
                                        {item.card != '' && `\nCMND(thẻ căn cước): ${item.card}`}
                                        {item.address != '' && `\nĐịa chỉ: ${item.address}`}
                                        {item.other_info != '' && `\nThông tin khác: ${item.other_info}`}
                                        {`\nKý quỹ: ${formatCurrenCy(item.payment)}`}
                                        {`\nTổng chi: ${formatCurrenCy(item.money_service_sale + item.money_product_sale)}`}
                                        {`\n(Khám: ${formatCurrenCy(item.money_service_sale)}, Mua thuốc: ${formatCurrenCy(item.money_product_sale)} )`}
                                        <Text style={{ color: "red" }}>
                                            {`\nSố dư: ${formatCurrenCy(item.payment - item.money_service_sale - item.money_product_sale)}`}
                                        </Text>
                                    </Text>
                                    {user_family_obj.length > 0 && (
                                        <View style={{ marginTop: 10 }}>
                                            <Text style={{ fontWeight: "bold" }}>{`${LANG.Family}:`}</Text>
                                        <Text>
                                            {user_family_obj.map((child_item: any) => {
                                                let info = child_item.display_name;
                                                if (child_item.birthday != '') {
                                                    info += `, ${child_item.birthday}`;
                                                }
                                                if (child_item.card != '') {
                                                    info += `, ${child_item.card}`;
                                                }
                                                if (child_item.address != '') {
                                                    info += `, ${child_item.address}`;
                                                }
                                                if (child_item.other_info != '') {
                                                    info += `, ${child_item.other_info}`;
                                                }                                              
                                                info += '\n';
                                                return info;
                                            })}
                                        </Text>
                                    </View>
                                    )}
                                </Pressable>
                            </View>

                            {item.is_activated == true ? (
                                <ScrollView horizontal={true} style={{ flexDirection: "row" }}>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'flex-start',
                                        marginTop: 12,
                                        width: 830
                                    }}
                                >
                                    <ButtonFunc
                                        icon="heart"
                                        color_icon="#f7e608"
                                        title={LANG.HealthCheck}
                                        onPress={() => {
                                            navigation.navigate('SaleService', {
                                                _customer_id: item.id,
                                                _customer_name: item.display_name,
                                                _user_family_id: '',
                                                _user_family_name: '',
                                            });
                                        }}
                                    />
                                    <ButtonFunc
                                        icon="shopping-cart"
                                        title={LANG.BuyMedicine}
                                        onPress={() => {
                                            navigation.navigate('SaleOrder', {
                                                _customer_id: item.id,
                                                _customer_name: item.display_name,
                                            });
                                        }}
                                    />
                                    <ButtonFunc
                                        icon="money"
                                        title={LANG.Deposit}
                                        onPress={() => {
                                            navigation.navigate('Payment', {
                                                _customer_id: item.id,
                                                _customer_name: item.display_name,
                                            });
                                        }}
                                    />
                                    <ButtonFunc
                                        icon="calendar"
                                        title={LANG.CalendarAppointment}
                                        onPress={() => {
                                            navigation.navigate('BookingStaff', {
                                                _customer_id: item.id,
                                                _customer_name: item.display_name,
                                            });
                                        }}
                                    />
                                    <ButtonFunc
                                        icon="comments"
                                        title={LANG.Chat}
                                        onPress={() => {
                                            navigation.navigate('ChatStaff', {
                                                _customer_id: item.id,
                                                _customer_name: item.display_name,
                                            });
                                        }}
                                    />
                                    <ButtonFunc
                                        icon="users"
                                        title={LANG.HomeFamily}
                                        onPress={() => {
                                            navigation.navigate('FamilyCustomerStaff', {
                                                _customer_id: item.id,
                                                _customer_name: item.display_name,
                                            });
                                        }}
                                    />
                                </View>
                            </ScrollView>
                            ):(
                                <View style={{ marginTop: 12}}>
                                    <ButtonSave 
                                        title="Kích hoạt thành viên"
                                        onPress={() => {
                                            handleActivated(item.id)
                                        }}
                                    />    
                                </View>
                            )}
                        </View>
                    );
                })
            )}
        </View>
    );
};


export const MyListingClassRoom = () => {
    let mContext: any = React.useContext(MyContext);

    const taskItems = mContext.taskItems || [];
    const checkItem = (item: any) => {
        mContext.checkItem(item);
    };

    return (
        <View style={{ marginTop: 30 }}>
            {taskItems.length == 0 ? (
                <NoDataLoading />
            ) : (
                taskItems.map((item: any) => {
                    return (
                        <View key={item.id} style={stylesItem.item}>
                            <View style={stylesItem.itemLeft}>
                                <CheckBox
                                    center
                                    title=""
                                    checkedIcon='dot-circle-o'
                                    uncheckedIcon='circle-o'
                                    checked={item.checked}
                                    containerStyle={stylesItem.checkbox}
                                    onPress={() => checkItem(item)}
                                />
                                <Text style={{ ...stylesItem.itemText, width: "35%" }}>
                                    <Text style={{ fontWeight: "bold" }}>{item.title}</Text>
                                </Text>
                            </View>
                        </View>
                    );
                })
            )}
        </View>
    );
};

export const ListingApprovalLessson = () => {
    let mContext: any = React.useContext(MyContext);

    const taskItems = mContext.taskItems || [];
    const checkItem = (item: any) => {
        mContext.checkItem(item);
    };

    return (
        <View style={{ marginTop: 30 }}>
            {taskItems.length == 0 ? (
                <NoDataLoading />
            ) : (
                taskItems.map((item: any, index) => {
                    let checkColor = '#ccc';
                    if (item.status) {
                        checkColor = mycolors.primary;
                    }

                    return (
                        <View key={index} style={stylesItem.item}>
                            <View style={stylesItem.itemLeft}>
                                <CheckBox
                                    center
                                    title=""
                                    checked={item.status}
                                    containerStyle={stylesItem.checkbox}
                                    checkedColor={checkColor}
                                    onPress={() => checkItem(item)}
                                />
                                <Text style={stylesItem.itemText}>
                                    {(item.parent_title != undefined && item.parent_title != '')
                                        ? `${item.parent_title} --> ${item.title}`
                                        : item.title
                                    }
                                    <Text style={{ fontSize: 12 }}>(duyệt: {item.approval_percentage})</Text>
                                </Text>
                                <View style={{ marginRight: 10 }}>
                                    {item.date_approval != undefined && (
                                        <Text style={{ fontSize: 12 }}>
                                            {moment(convertDateForMoment(item.date_approval)).format('DD/MM/YYYY')}
                                        </Text>
                                    )}
                                </View>
                            </View>
                        </View>
                    );
                })
            )}
        </View>
    );
};

export const MyListingLesson = () => {
    let mContext: any = React.useContext(MyContext);

    const taskItems = mContext.taskItems || [];
    const checkItem = (item: any) => {
        mContext.checkItem(item);
    };

    return (
        <View style={{ marginTop: 30 }}>
            {taskItems.length == 0 ? (
                <NoDataLoading />
            ) : (
                taskItems.map((item: any, index) => {
                    return (
                        <View key={index} style={stylesItem.item}>
                            <View style={stylesItem.itemLeft}>
                                <CheckBox
                                    center
                                    title=""
                                    checkedIcon='dot-circle-o'
                                    uncheckedIcon='circle-o'
                                    checked={item.checked}
                                    containerStyle={stylesItem.checkbox}
                                    onPress={() => checkItem(item)}
                                />
                                <Text style={stylesItem.itemText}>
                                    {(item.parent_title != undefined && item.parent_title != '')
                                        ? `${item.parent_title} --> ${item.title}`
                                        : item.title
                                    }
                                </Text>
                                <View style={{ marginRight: 10 }}>
                                    {item.date_created != undefined && (
                                        <Text style={{ fontSize: 12 }}>
                                            {moment(convertDateForMoment(item.date_created)).format('DD/MM/YYYY')}
                                        </Text>
                                    )}
                                </View>
                            </View>
                        </View>
                    );
                })
            )}
        </View>
    );
};

export const MyListingDoingChildrens = (props) => {
    let mContext: any = React.useContext(MyContext);

    const taskItems = mContext.taskItems || [];

    const styles = stylesChildrensTableDoing;
    return (
        <View style={{ marginTop: 30 }}>
            {taskItems.length == 0 ? (
                <NoDataLoading />
            ) : (
                <>
                    <View style={{ marginLeft: 12 }}>
                        <View style={styles.header}>
                            <Text style={styles.column1}>TT</Text>
                            <Text style={styles.column2}>Họ và tên</Text>
                            <Text style={styles.column3}>Nội dung</Text>
                        </View>
                    </View>
                    <ScrollView
                        horizontal={true}
                        showsHorizontalScrollIndicator={true}
                    >
                        <View style={{ width: "auto" }}>
                            {taskItems.map((item: any, index) => {
                                return (
                                    <View key={index} style={{ marginLeft: 12 }}>
                                        <View style={index % 2 == 0 ? styles.headerEvent : styles.headerOld}>
                                            <Text style={styles.column1}>
                                                {index + 1}
                                            </Text>
                                            <Text style={styles.column2}>
                                                {item.display_name}
                                            </Text>
                                            <Text style={styles.column3}>
                                                {item.going.map(itemGoing =>
                                                    `(${itemGoing.count} lần)${itemGoing.title}\n`
                                                )}
                                                {item.behavior.map(itemBehavior =>
                                                    `(${itemBehavior.count} lần)${itemBehavior.title}\n`
                                                )}
                                            </Text>
                                        </View>
                                    </View>
                                );
                            })}
                        </View>
                    </ScrollView>
                </>
            )}
        </View>
    );
};

export const MyListingDoingTheChildren = (props) => {
    let mContext: any = React.useContext(MyContext);

    const taskItems = mContext.taskItems || [];

    const listGoing = props.listGoing || [];
    const listBehavior = props.listBehavior || [];

    const styles = stylesTheChildrenTableDoing;
    return (
        <View style={{ marginTop: 30 }}>
            {taskItems.length == 0 ? (
                <NoDataLoading />
            ) : (
                <>
                    <View style={{ marginLeft: 12 }}>
                        <View style={styles.header}>
                            <Text style={styles.column1}>Thời Gian</Text>
                            <Text style={styles.column2}>Nội Dung</Text>
                            <Text style={styles.column3}>Tình Trạng</Text>
                        </View>
                    </View>
                    {taskItems.map((item: any, index) => {
                        return (
                            <View key={index} style={{ marginLeft: 12 }}>
                                <View style={index % 2 == 0 ? styles.headerEvent : styles.headerOld}>
                                    <Text style={styles.column1}>
                                        {item.date_created != undefined && (
                                            <Text style={{ fontSize: 12 }}>
                                                {moment(convertDateForMoment(item.date_created)).format('DD/MM/YYYY')}
                                            </Text>
                                        )}
                                    </Text>
                                    <Text style={styles.column2}>
                                        {listGoing.map(itemGoing => item.going_id == itemGoing.id && itemGoing.title)}
                                        {listBehavior.map(itemBehavior => `${JSON.stringify(item.behavior)}`.indexOf(itemBehavior.id) >= 0
                                            && `\n${itemBehavior.title}`)}
                                    </Text>
                                    <Text style={styles.column3}>Đã gửi</Text>
                                </View>
                            </View>
                        );
                    })
                    }
                </>
            )}
        </View>
    );
};

export const MyListingBook = () => {
    let mContext: any = React.useContext(MyContext);

    const taskItems = mContext.taskItems || [];
    const checkItem = (item: any) => {
        mContext.checkItem(item);
    };

    return (
        <View style={{ marginTop: 30 }}>
            {taskItems.length == 0 ? (
                <NoDataLoading />
            ) : (
                taskItems.map((item: any, index) => {
                    return (
                        <View key={index} style={stylesItem.item}>
                            <View style={stylesItem.itemLeft}>
                                <CheckBox
                                    center
                                    title=""
                                    checked={item.checked}
                                    checkedIcon='dot-circle-o'
                                    uncheckedIcon='circle-o'
                                    containerStyle={stylesItem.checkbox}
                                    onPress={() => checkItem(item)}
                                />
                                <Pressable
                                    style={stylesItem.itemText}
                                    onPress={() => checkItem(item)}
                                >
                                    <Text style={{ fontSize: 13, lineHeight: 20 }}>
                                        <Text style={{ fontWeight: "bold" }}>
                                            {item.user_family_name != '' ?
                                                `${item.user_family_name}\n`
                                                : item.customer != undefined && `${item.customer.display_name}\n`
                                            }
                                        </Text>
                                        {(item.parent_title != undefined && item.parent_title != '')
                                            ? `${item.parent_title} --> ${item.title}`
                                            : item.title
                                        }
                                        {item.date_health_check != undefined ?
                                            <Text style={{ color: "red" }}>
                                                {`\nNgày khám: ${moment(convertDateForMoment(item.date_health_check.replace('GMT', ''))).format('DD/MM/YYYY, HH giờ mm\'')}`}
                                            </Text>
                                            : ''
                                        }
                                        {item.customer != undefined && `\n${item.customer.name}`}
                                    </Text>
                                </Pressable>
                            </View>
                            <View style={{ marginLeft: 45 }}>
                                <Text>{item.content}</Text>
                                <Text style={{ marginTop: 4 }}>
                                    <Text style={{ fontWeight: "bold", fontStyle: "italic" }}>Chẩn đoán: </Text>
                                    {`${item.diagnose}`}
                                </Text>
                            </View>
                        </View>
                    );
                })
            )}
        </View>
    );
};

export const MyListProductToStore = () => {
    let mContext: any = React.useContext(MyContext);

    const taskItems = mContext.taskItems || [];
    const checkItem = (item: any) => {
        mContext.checkItem(item);
    };

    return (
        <View style={{ marginTop: 30 }}>
            {taskItems.length == 0 ? (
                <NoDataLoading />
            ) : (
                taskItems.map((item: any, index) => {
                    return (
                        <View key={index} style={stylesItem.item}>
                            <View style={stylesItem.itemLeft}>
                                <CheckBox
                                    center
                                    title=""
                                    checked={item.checked}
                                    containerStyle={stylesItem.checkbox}
                                    onPress={() => checkItem(item)}
                                />
                                <Pressable
                                    style={stylesItem.itemText}
                                    onPress={() => checkItem(item)}
                                >
                                    <Text>
                                        {item.title}
                                        {`\nGiá nhập: ${formatCurrenCy(item.price_buy)}`}
                                        {`\nGiá bán: ${formatCurrenCy(item.price_sell)}`}
                                    </Text>
                                </Pressable>
                                <View style={{ marginRight: 10 }}>
                                    <Text>
                                        {item.code}
                                    </Text>
                                    {item.date_created != undefined && (
                                        <Text style={{ fontSize: 12 }}>
                                            {moment(convertDateForMoment(item.date_created)).format('DD/MM/YYYY')}
                                        </Text>
                                    )}
                                </View>
                            </View>
                        </View>
                    );
                })
            )}
        </View>
    );
};

export const MyListingBuyOrder = () => {
    let mContext: any = React.useContext(MyContext);

    const taskItems = mContext.taskItems || [];
    const checkItem = (item: any) => {
        mContext.checkItem(item);
    };

    return (
        <View style={{ marginTop: 30 }}>
            {taskItems.length == 0 ? (
                <NoDataLoading />
            ) : (
                taskItems.map((item: any, index) => {
                    return (
                        <View key={index} style={stylesItem.item}>
                            <View style={stylesItem.itemLeft}>
                                <CheckBox
                                    center
                                    title=""
                                    checkedIcon='dot-circle-o'
                                    uncheckedIcon='circle-o'
                                    checked={item.checked}
                                    containerStyle={stylesItem.checkbox}
                                    onPress={() => checkItem(item)}
                                />
                                <Pressable
                                    style={stylesItem.itemText}
                                    onPress={() => checkItem(item)}
                                >
                                    <Text>
                                        {item.title}
                                    </Text>
                                </Pressable>
                                <View style={{ marginRight: 10 }}>
                                    {item.date_created != undefined && (
                                        <Text style={{ fontSize: 12 }}>
                                            {moment(convertDateForMoment(item.date_created)).format('DD/MM/YYYY')}
                                        </Text>
                                    )}
                                </View>
                            </View>
                            <Pressable
                                style={{ marginLeft: 45 }}
                                onPress={() => checkItem(item)}
                            >
                                <Text style={{ marginTop: 4 }}>
                                    {`${item.supplier_name}`}
                                    {`\nMã đơn: ${item.code}`}
                                </Text>
                                {item.content != '' && <Text>{item.content.info}</Text>}
                                <Text style={{ fontWeight: "bold" }}>
                                    {`\nTổng tiền: ${formatCurrenCy(item.total)}`}
                                </Text>
                                {item.content.items != undefined && item.content.items.map((child_item: any, child_index) => {
                                    return <Text key={child_index}>{child_index + 1}. <Text style={{ color: "red" }}>{child_item.title}</Text> {`: SL ${child_item.quatity}, ĐG ${formatCurrenCy(parseInt(child_item.price))}`}</Text>
                                })}
                            </Pressable>
                        </View>
                    );
                })
            )}
        </View>
    );
};

export const MyListServiceToStore = (props: any) => {
    let mContext: any = React.useContext(MyContext);

    let taskItems = mContext.taskItems || [];
    const checkItem = (item: any) => {
        mContext.checkItem(item);
    };

    const checked = props.checked || false;
    taskItems = taskItems.filter(item => item.checked == checked);

    return (
        <View style={{ marginTop: 0 }}>
            {taskItems.length == 0 ? (
                checked ? <NoDataChecking /> : <NoDataUnChecking />
            ) : (
                taskItems.map((item: any, index) => {
                    return (
                        <View key={index} style={stylesItem.item}>
                            <View style={stylesItem.itemLeft}>
                                <CheckBox
                                    center
                                    title=""
                                    checked={item.checked}
                                    containerStyle={stylesItem.checkbox}
                                    onPress={() => checkItem(item)}
                                />
                                <Pressable
                                    style={stylesItem.itemText}
                                    onPress={() => checkItem(item)}
                                >
                                    <Text>
                                        {item.title}
                                        {`\nĐơn giá: ${formatCurrenCy(item.price)}`}
                                    </Text>
                                </Pressable>
                                <View style={{ marginRight: 10 }}>
                                    <Text>
                                        {item.code}
                                    </Text>
                                    {item.date_created != undefined && (
                                        <Text style={{ fontSize: 12 }}>
                                            {moment(convertDateForMoment(item.date_created)).format('DD/MM/YYYY')}
                                        </Text>
                                    )}
                                </View>
                            </View>
                        </View>
                    );
                })
            )}
        </View>
    );
};

export const MyListingPayment = () => {
    let mContext: any = React.useContext(MyContext);

    const taskItems = mContext.taskItems || [];
    const checkItem = (item: any) => {
        mContext.checkItem(item);
    };

    return (
        <View style={{ marginTop: 30 }}>
            {taskItems.length == 0 ? (
                <NoDataLoading />
            ) : (
                taskItems.map((item: any, index) => {
                    return (
                        <View key={index} style={stylesItem.item}>
                            <View style={stylesItem.itemLeft}>
                                <CheckBox
                                    center
                                    title=""
                                    checkedIcon='dot-circle-o'
                                    uncheckedIcon='circle-o'
                                    checked={item.checked}
                                    containerStyle={stylesItem.checkbox}
                                    onPress={() => checkItem(item)}
                                />
                                <Pressable
                                    style={stylesItem.itemText}
                                    onPress={() => checkItem(item)}
                                >
                                    {item.date_created != undefined && (
                                        <Text>
                                            {`Ngày: `}
                                            {moment(convertDateForMoment(item.date_created)).format('DD/MM/YYYY')}
                                        </Text>
                                    )}
                                </Pressable>
                            </View>
                            <Pressable
                                style={{ marginLeft: 45 }}
                                onPress={() => checkItem(item)}
                            >
                                {item.content != '' && <Text>{item.content}</Text>}
                                <Text style={{ marginTop: 4 }}>
                                    <Text style={{ color: "red" }}>
                                        {`${item.customer_name != '' ? `${item.customer_name}\n` : ''}`}
                                    </Text>
                                    {`${item.birthday != null ? `Ngày sinh: ${moment(convertDateForMoment(item.birthday)).format('DD/MM/YYYY')}\n` : ''}`}
                                    {`${item.phone != null ? `Di động: ${item.phone}\n` : ''}`}
                                    {`${item.address != null ? `Quê quán: ${item.address}\n` : ''}`}
                                    {`Mã đơn: ${item.code}, Tiền ký quỹ: ${formatCurrenCy(item.total)}`}
                                </Text>
                            </Pressable>
                        </View>
                    );
                })
            )}
        </View>
    );
};

export const MyListingCustomerThemSelf = () => {
    let mContext: any = React.useContext(MyContext);

    const taskItems = mContext.taskItems || [];
    return (
        <View style={{ marginTop: 30 }}>
            {taskItems.length == 0 ? (
                <NoDataLoading />
            ) : (
                taskItems.map((item: any, index) => {
                    return (
                        <View key={index} style={stylesI22Customer.item}>
                            <View style={stylesI22Customer.itemLeft}>
                                <Text style={{ marginLeft: 10, marginRight: 10, fontWeight: "900", color: "red" }}>
                                    {index + 1}.
                                </Text>
                                <View
                                    style={stylesI22Customer.itemText}
                                >
                                    <Text>
                                        {item.date_created != undefined && (
                                            `Ngày: ` + moment(convertDateForMoment(item.date_created)).format('DD/MM/YYYY')
                                        )}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ marginLeft: 32 }}>
                                <Text>
                                    {`${item.user_family_name != undefined && item.user_family_name != '' ? item.user_family_name : item.customer_name}`}
                                    {`\nMã đơn: ${item.code}`}
                                </Text>
                                {item.content != '' && <Text>{item.content.info}</Text>}
                                <Text style={{ fontWeight: "bold" }}>
                                    {`\nTổng tiền: ${formatCurrenCy(item.total)}`}
                                </Text>
                                {item.content.items != undefined && item.content.items.map((child_item: any, child_index) => {
                                    return <Text key={child_index}>{child_index + 1}. {`${child_item.title}: ${formatCurrenCy(parseInt(child_item.price))}`}</Text>
                                })}
                            </View>
                        </View>
                    );
                })
            )}
        </View>
    );
};

export const MyListingSellService = () => {
    let mContext: any = React.useContext(MyContext);

    const taskItems = mContext.taskItems || [];
    const checkItem = (item: any) => {
        mContext.checkItem(item);
    };

    return (
        <View style={{ marginTop: 30 }}>
            {taskItems.length == 0 ? (
                <NoDataLoading />
            ) : (
                taskItems.map((item: any, index) => {
                    return (
                        <View key={index} style={stylesItem.item}>
                            <View style={stylesItem.itemLeft}>
                                <CheckBox
                                    center
                                    title=""
                                    checkedIcon='dot-circle-o'
                                    uncheckedIcon='circle-o'
                                    checked={item.checked}
                                    containerStyle={stylesItem.checkbox}
                                    onPress={() => checkItem(item)}
                                />
                                <Pressable
                                    style={stylesItem.itemText}
                                    onPress={() => checkItem(item)}
                                >
                                    {item.date_created != undefined && (
                                        <Text>
                                            {`Ngày: `}
                                            {moment(convertDateForMoment(item.date_created)).format('DD/MM/YYYY')}
                                        </Text>
                                    )}
                                </Pressable>
                            </View>
                            <Pressable
                                style={{ marginLeft: 45 }}
                                onPress={() => checkItem(item)}
                            >
                                <Text style={{ marginTop: 4 }}>
                                    <Text style={{ color: "red" }}>
                                        {`${item.user_family_name != '' ? item.user_family_name : item.customer_name}`}
                                    </Text>
                                    {`${item.birthday != null ? `\nNgày sinh: ${moment(convertDateForMoment(item.birthday)).format('DD/MM/YYYY')}` : ''}`}
                                    {`${item.phone != null ? `\nDi động: ${item.phone}` : ''}`}
                                    {`${item.address != null ? `\nQuê quán: ${item.address}` : ''}`}
                                    {`\nMã đơn: ${item.code}\n`}
                                    {item.content && item.content.info}
                                </Text>
                                <Text style={{ fontWeight: "bold" }}>
                                    {`\nTổng tiền: ${formatCurrenCy(item.total)}`}
                                </Text>
                                {item.content.items != undefined && item.content.items.map((child_item: any, child_index) => {
                                    return <Text key={child_index}>{child_index + 1}. {`${child_item.title}: ${formatCurrenCy(parseInt(child_item.price))}`}</Text>
                                })}
                            </Pressable>
                        </View>
                    );
                })
            )}
        </View>
    );
};

export const MyListingService = () => {
    let mContext: any = React.useContext(MyContext);

    const taskItems = mContext.taskItems || [];
    const checkItem = (item: any) => {
        mContext.checkItem(item);
    };

    return (
        <View style={{ marginTop: 30 }}>
            {taskItems.length == 0 ? (
                <NoDataLoading />
            ) : (
                taskItems.map((item: any, index) => {
                    return (
                        <View key={index} style={stylesItem.item}>
                            <View style={stylesItem.itemLeft}>
                                <CheckBox
                                    center
                                    title=""
                                    checkedIcon='dot-circle-o'
                                    uncheckedIcon='circle-o'
                                    checked={item.checked}
                                    containerStyle={stylesItem.checkbox}
                                    onPress={() => checkItem(item)}
                                />
                                <Pressable
                                    style={stylesItem.itemText}
                                    onPress={() => checkItem(item)}>
                                    <Text>
                                        {item.title}
                                        {`\nGía: ${formatCurrenCy(item.price)}`}
                                    </Text>
                                </Pressable>
                                <View style={{ marginRight: 10 }}>
                                    <Text>
                                        {`Mã: ${item.code}`}
                                    </Text>
                                    {item.date_created != undefined && (
                                        <Text style={{ fontSize: 12 }}>
                                            {moment(convertDateForMoment(item.date_created)).format('DD/MM/YYYY')}
                                        </Text>
                                    )}
                                </View>
                            </View>
                        </View>
                    );
                })
            )}
        </View>
    );
};


export const MyListingSellOrder = () => {
    let mContext: any = React.useContext(MyContext);

    const taskItems = mContext.taskItems || [];
    const checkItem = (item: any) => {
        mContext.checkItem(item);
    };

    return (
        <View style={{ marginTop: 30 }}>
            {taskItems.length == 0 ? (
                <NoDataLoading />
            ) : (
                taskItems.map((item: any, index) => {
                    return (
                        <View key={index} style={stylesItem.item}>
                            <View style={stylesItem.itemLeft}>
                                <CheckBox
                                    center
                                    title=""
                                    checkedIcon='dot-circle-o'
                                    uncheckedIcon='circle-o'
                                    checked={item.checked}
                                    containerStyle={stylesItem.checkbox}
                                    onPress={() => checkItem(item)}
                                />
                                <Pressable
                                    style={stylesItem.itemText}
                                    onPress={() => checkItem(item)}
                                >
                                    <Text>
                                        {item.title}
                                    </Text>
                                </Pressable>
                                <View style={{ marginRight: 10 }}>
                                    {item.date_created != undefined && (
                                        <Text style={{ fontSize: 12 }}>
                                            {moment(convertDateForMoment(item.date_created)).format('DD/MM/YYYY')}
                                        </Text>
                                    )}
                                </View>
                            </View>
                            <Pressable
                                style={{ marginLeft: 45 }}
                                onPress={() => checkItem(item)}
                            >
                                <Text style={{ marginTop: 4 }}>
                                    <Text style={{ color: "red" }}>
                                        {`${item.user_family_name != '' ? item.user_family_name : item.customer_name}`}
                                    </Text>
                                    {`${item.birthday != null ? `\nNgày sinh: ${moment(convertDateForMoment(item.birthday)).format('DD/MM/YYYY')}` : ''}`}
                                    {`${item.phone != null ? `\nDi động: ${item.phone}` : ''}`}
                                    {`${item.address != null ? `\nQuê quán: ${item.address}` : ''}`}
                                    {`\nMã đơn: ${item.code}`}
                                    {item.content != '' ? `\n${item.content.info}` : ''}
                                </Text>
                                <Text style={{ fontWeight: "bold" }}>
                                    {`Tổng tiền: ${formatCurrenCy(item.total)}`}
                                </Text>
                                {item.content.items != undefined && item.content.items.map((child_item: any, child_index) => {
                                    return <Text key={child_index}>{child_index + 1}. <Text style={{ color: "#e13131" }}>{child_item.title}</Text> {`: SL ${child_item.quatity}, ĐG ${formatCurrenCy(parseInt(child_item.price))}`}</Text>
                                })}
                            </Pressable>
                        </View>
                    );
                })
            )}
        </View>
    );
};

export const MyListingProduct = () => {
    let mContext: any = React.useContext(MyContext);

    const taskItems = mContext.taskItems || [];
    const checkItem = (item: any) => {
        mContext.checkItem(item);
    };

    return (
        <View style={{ marginTop: 30 }}>
            {taskItems.length == 0 ? (
                <NoDataLoading />
            ) : (
                taskItems.map((item: any, index) => {
                    return (
                        <View key={index} style={stylesItem.item}>
                            <View style={stylesItem.itemLeft}>
                                <CheckBox
                                    center
                                    title=""
                                    checkedIcon='dot-circle-o'
                                    uncheckedIcon='circle-o'
                                    checked={item.checked}
                                    containerStyle={stylesItem.checkbox}
                                    onPress={() => checkItem(item)}
                                />
                                <Pressable
                                    style={stylesItem.itemText}
                                    onPress={() => checkItem(item)}>
                                    <Text>
                                        {item.title}
                                        {`\nGía nhập: ${formatCurrenCy(item.price_buy)}`}
                                        {`\nGía bán: `}<Text style={{ marginLeft: 8 }}>{formatCurrenCy(item.price_sell)}</Text>
                                    </Text>
                                </Pressable>
                                <View style={{ marginRight: 10 }}>
                                    <Text>
                                        {`Trong kho: ${item.quatity_in_stock}`}
                                        {`\nMã: ${item.code}`}
                                    </Text>
                                    {item.date_created != undefined && (
                                        <Text style={{ fontSize: 12 }}>
                                            {moment(convertDateForMoment(item.date_created)).format('DD/MM/YYYY')}
                                        </Text>
                                    )}
                                </View>
                            </View>
                        </View>
                    );
                })
            )}
        </View>
    );
};

export const MyListingProductCategory = () => {
    let mContext: any = React.useContext(MyContext);

    const taskItems = mContext.taskItems || [];
    const checkItem = (item: any) => {
        mContext.checkItem(item);
    };

    return (
        <View style={{ marginTop: 30 }}>
            {taskItems.length == 0 ? (
                <NoDataLoading />
            ) : (
                taskItems.map((item: any, index) => {
                    return (
                        <View key={index} style={stylesItem.item}>
                            <View style={stylesItem.itemLeft}>
                                <CheckBox
                                    center
                                    title=""
                                    checkedIcon='dot-circle-o'
                                    uncheckedIcon='circle-o'
                                    checked={item.checked}
                                    containerStyle={stylesItem.checkbox}
                                    onPress={() => checkItem(item)}
                                />
                                <Pressable
                                    style={stylesItem.itemText}
                                    onPress={() => checkItem(item)}>
                                    <Text>
                                        {item.title}
                                    </Text>
                                </Pressable>
                                <View style={{ marginRight: 10 }}>
                                    <Text>
                                        {item.code}
                                    </Text>
                                    {item.date_created != undefined && (
                                        <Text style={{ fontSize: 12 }}>
                                            {moment(convertDateForMoment(item.date_created)).format('DD/MM/YYYY')}
                                        </Text>
                                    )}
                                </View>
                            </View>
                        </View>
                    );
                })
            )}
        </View>
    );
};

export const MyListingPost = () => {
    let mContext: any = React.useContext(MyContext);

    const taskItems = mContext.taskItems || [];
    const checkItem = (item: any) => {
        mContext.checkItem(item);
    };

    return (
        <View style={{ marginTop: 30 }}>
            {taskItems.length == 0 ? (
                <NoDataLoading />
            ) : (
                taskItems.map((item: any, index) => {
                    return (
                        <View key={index} style={stylesItem.item}>
                            <View style={stylesItem.itemLeft}>
                                <CheckBox
                                    center
                                    title=""
                                    checkedIcon='dot-circle-o'
                                    uncheckedIcon='circle-o'
                                    checked={item.checked}
                                    containerStyle={stylesItem.checkbox}
                                    onPress={() => checkItem(item)}
                                />
                                <Pressable
                                    onPress={() => checkItem(item)}
                                >
                                    <Text style={{ lineHeight: 18 }}>
                                        {(item.parent_title != undefined && item.parent_title != '')
                                            ? `${item.parent_title} --> ${item.title}`
                                            : item.title
                                        }
                                        {item.date_created != undefined && (
                                            `\nNgày đăng: ${moment(convertDateForMoment(item.date_created)).format('DD/MM/YYYY')}`
                                            + `\nNgày gửi: ${moment(convertDateForMoment(item.date_publish)).format('DD/MM/YYYY')}`
                                        )}
                                    </Text>
                                </Pressable>
                            </View>
                        </View>
                    );
                })
            )}
        </View>
    );
};

export const MyListingShowPost = () => {
    let mContext: any = React.useContext(MyContext);

    const taskItems = mContext.taskItems || [];

    const [isLoading, setLoading] = useState<boolean>(false);
    const [visibleDialog, setVisibleDialog] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState('');
    const [dialogMessage, setDialogMessage] = React.useState('');
    const [idYoutube, setIDYoutube] = React.useState('');
    const [postDetail, setPostDetail] = React.useState(null);

    const widthModalWeb = 650;

    function get_detail(_id = 0) {
        setLoading(true);
        PostModel.get_to_show(_id)
            .then((results) => {
                const post: any = results.data.post || {};
                if (__DEV__) {
                    console.log('data model news: ', post);
                }

                let link = post.link || '';
                let arr: any;
                if (link.indexOf('youtube.com') > -1) {
                    arr = link.split('youtube.com/watch?v=');
                    setIDYoutube(arr[1]);
                } else if (link.indexOf('youtu.be') > -1) {
                    arr = link.split('//youtu.be');
                    setIDYoutube(arr[1]);
                }

                setDialogTitle(post.title);
                setPostDetail(post);
            })
            .catch(err => {
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <View style={{ marginTop: 30 }}>

            <Overlay
                fullScreen={Platform.OS == 'web' ? false : true}
                isVisible={visibleDialog}
                onBackdropPress={() => {
                    setVisibleDialog(false);
                    setIDYoutube('');
                }}
            >
                <Pressable
                    onPress={() => {
                        setVisibleDialog(false);
                        setIDYoutube('');
                    }}
                    style={{ flexDirection: "row", alignSelf: "center", marginTop: 15, marginBottom: 12 }}>
                    <Icon
                        name="close"
                        type="font-awesome"
                        color={mycolors.gray}
                        size={22}
                        iconStyle={{ marginRight: 2 }}
                    />
                    <Text style={{ fontSize: 14 }}>(Đóng lại)</Text>
                </Pressable>
                {isLoading == true ? (
                    <View style={{ alignSelf: "flex-start" }}>
                        <IsLoadingData title="Đang tải dữ liệu" />
                    </View>
                ) : (
                    <>
                        <Dialog.Title title={dialogTitle} />
                        {postDetail == null ? (
                            <Text>{dialogMessage}</Text>
                        ) : (
                            Platform.OS == 'web' ? (
                                <div style={{ maxWidth: widthModalWeb, height: windowHeight - 60, overflowY: "auto" }}>
                                    <p>{postDetail['content']}</p>
                                    {idYoutube != '' ? <div style={{ marginBottom: "12px" }}>
                                        <iframe width={widthModalWeb} height={widthModalWeb * 315 / 560} src={`https://www.youtube.com/embed/${idYoutube}`} title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    </div> : ''}
                                    {postDetail['image'].length != undefined ? postDetail['image'].map((item: any) => <img src={`${config.apiUrl}/${item['src']}`} style={{ width: widthModalWeb / 2 - 10 }} />) : ''}
                                    {postDetail['link'] != '' ? <p><strong>Liên kết:</strong> <a href={postDetail['link']} target="_blank">{postDetail['link']}</a></p> : ''}
                                </div>
                            ) : (
                                <WebView
                                    style={{ flex: 1 }}
                                    source={{
                                        html: `<!DOCTYPE html>
                                <html>
                                    <head>
                                    <title></title>
                                    <meta http-equiv="content-type" content="text/html; charset=utf-8">
                                    <meta name="viewport" content="width=320, user-scalable=no">
                                    <style type="text/css">
                                        body {
                                        margin: 0;
                                        padding: 0;
                                        background: #fff;
                                        }
                                        img{
                                            width: 100%;
                                            height: auto;
                                            margin-bottom: 12px;
                                        }
                                    </style>
                                    </head>
                                    <body>
                                    <p>${postDetail['content']}</p>
                                    ${idYoutube != '' ? `<div style="margin-bottom:12px;"><iframe width="${windowWidth}" height="${windowWidth * 315 / 560}" src="https://www.youtube.com/embed/${idYoutube}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>` : ''}
                                    ${postDetail['image'].length != undefined ? postDetail['image'].map((item: any) => `<img src="${config.apiUrl}/${item['src']}" />`) : ''}
                                    ${postDetail['link'] != '' ? `<p><strong>Liên kết:</strong> <a href="${postDetail['link']}" target="_blank">${postDetail['link']}</a></p>` : ''}
                                    </body>
                                </html>` }} />
                            ))}
                    </>
                )}
            </Overlay>

            {taskItems.length == 0 ? (
                <NoDataLoading />
            ) : (
                taskItems.map((item: any) => {
                    return (
                      <View key={item.id} style={stylesNews.item}>
                        <Pressable
                          onPress={() => {
                            if (
                              item.web_post_id != "" &&
                              item.web_post_id != null &&
                              item.web_post_id != undefined
                            ) {
                              WebBrowser.openBrowserAsync(item.link);
                            } else {
                              get_detail(item.id);
                              setVisibleDialog(true);
                            }
                          }}
                        >
                          <View style={stylesNews.itemLeft}>
                            {item.web_img != "" && (
                              <Image
                                style={stylesNews.img}
                                source={{ uri: item.web_img }}
                              />
                            )}
                            <View style={stylesNews.itemText}>
                              {item.date_created != undefined && (
                                <Text style={{ fontSize: 12 }}>
                                  {moment(
                                    convertDateForMoment(item.date_created)
                                  ).format("DD/MM/YYYY")}
                                </Text>
                              )}
                              <Text>
                                {item.parent_title != undefined &&
                                item.parent_title != ""
                                  ? `${item.parent_title} --> ${item.title}`
                                  : item.title}
                              </Text>
                            </View>
                          </View>
                        </Pressable>
                      </View>
                    );
                })
            )}
        </View >
    );
};

export const MyListingForCustomer = () => {
    let mContext: any = React.useContext(MyContext);

    const taskItems = mContext.taskItems || [];

    const [isLoading, setLoading] = useState<boolean>(false);
    const [visibleDialog, setVisibleDialog] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState('');
    const [dialogMessage, setDialogMessage] = React.useState('');

    const [id, setID] = useState('');

    function get_detail(_id = '0') {
        setLoading(true);
        BookingModel.get(_id)
            .then((results) => {
                const post: any = results.data.post || {};
                setID(post.id);
                setDialogTitle(post.title);
                setDialogMessage(post.content);
            })
            .catch(err => {
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \nOr Can not connect API, Please login again!`);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <View style={{ marginTop: 30 }}>

            <Overlay
                fullScreen={Platform.OS == 'web' ? false : true}
                isVisible={visibleDialog}
                onBackdropPress={() => setVisibleDialog(false)}
            >
                <Pressable
                    onPress={() => setVisibleDialog(false)}
                    style={{ flexDirection: "row", alignSelf: "center", marginBottom: 12 }}>
                    <Icon
                        name="close"
                        type="font-awesome"
                        color={mycolors.gray}
                        size={22}
                        iconStyle={{ marginRight: 2 }}
                    />
                    <Text style={{ fontSize: 16 }}>(Đóng lại)</Text>
                </Pressable>
                {isLoading == true ? (
                    <View style={{ alignSelf: "flex-start" }}>
                        <IsLoadingData title="Đang tải dữ liệu" />
                    </View>
                ) : (
                    <>
                        <Dialog.Title title={dialogTitle} />
                        <ScrollView
                            style={{ height: windowHeight / 2 - 120 }}
                        >
                            <View style={Platform.OS == 'web' ? { maxWidth: 650 } : { maxWidth: 350 }}>
                                <Text>{dialogMessage}</Text>
                            </View>

                            <Divider style={{ marginTop: 20, marginBottom: 12 }} />
                            <Dialog.Title title="Kết quá khám:" />
                            <MyInputContent placeholder="Mô tả chi tiết" />
                            <ButtonSave onPress={() => mContext.replyBooking(id)} />
                        </ScrollView>
                    </>
                )}
            </Overlay>

            {taskItems.length == 0 ? (
                <NoDataLoading />
            ) : (
                taskItems.map((item: any) => {
                    return (
                        <View key={item.id} style={stylesItem.item}>
                            <Pressable onPress={() => { get_detail(item.id); setVisibleDialog(true); }} >
                                <View style={stylesItem.itemLeft}>
                                    <Icon
                                        name="arrows"
                                        type="font-awesome"
                                        color={mycolors.gray}
                                        size={22}
                                        iconStyle={{ marginRight: 10 }}
                                    />
                                    <Text style={stylesItem.itemText}>
                                        {(item.parent_title != undefined && item.parent_title != '')
                                            ? `${item.parent_title} --> ${item.title}`
                                            : item.title
                                        }
                                    </Text>
                                    <View style={{ marginRight: 10 }}>
                                        {item.date_created != undefined && (
                                            <Text style={{ fontSize: 12 }}>
                                                {moment(convertDateForMoment(item.date_created)).format('DD/MM/YYYY')}
                                            </Text>
                                        )}
                                    </View>
                                </View>
                                <View style={stylesItem.itemLeft}>
                                    <Text style={stylesTextNote.label}>Lê thị B, 20/12/1983, Hà Nội</Text>
                                </View>
                            </Pressable>
                        </View>
                    );
                })
            )}
        </View >
    );
};

export const MySelectCalendarSearch = (props) => {
    let mContext: any = React.useContext(MyContext);

    const display_calendar = props.display_calendar != undefined ? props.display_calendar : true;

    const dropPress = () => {
        mContext.setVisibleSearchCalendar(!mContext.visibleSearchCalendar);
    }
    return (
        display_calendar && (
            <>
                <Overlay
                    fullScreen={Platform.OS == 'web' ? false : true}
                    isVisible={mContext.visibleSearchCalendar}
                    onBackdropPress={() => dropPress()}
                >
                    <ScrollView
                        contentContainerStyle={{
                            flexGrow: 1,
                        }}
                        keyboardShouldPersistTaps="handled"
                    >
                        <View style={{ maxWidth: 350 }}>
                            <Text style={{ fontWeight: "700" }}>Tìm từ ngày:</Text>
                            <MyCalendar index="fromDate" />
                            {mContext.toDate != undefined && (
                                <>
                                    <Text style={{ fontWeight: "700" }}>Tìm đến ngày:</Text>
                                    <MyCalendar index="toDate" />
                                </>
                            )}

                            <Button
                                icon={
                                    <Icon
                                        name="check"
                                        type="font-awesome"
                                        color="white"
                                        size={25}
                                        iconStyle={{ marginRight: 10 }}
                                    />
                                }
                                title="OK"
                                onPress={() => dropPress()}
                                buttonStyle={{ marginTop: 20 }}
                            />
                        </View>
                    </ScrollView>
                </Overlay>

                <Icon
                    name="calendar"
                    type="font-awesome"
                    color="#86939e"
                    size={25}
                    containerStyle={{ marginTop: 8, marginRight: 10 }}
                    onPress={() => mContext.setVisibleSearchCalendar(true)}
                />
            </>
        )
    );
};

export const MyDisplayFromToDate = (props) => {
    let mContext: any = React.useContext(MyContext);

    const fromDate = mContext.fromDate || '';
    const toDate = mContext.toDate || '';
    return (
        (fromDate != '' || toDate != '') && (
            <View style={{ flexDirection: "row", alignSelf: "center", marginTop: 6 }}>
                {fromDate != '' && (
                    <Text>Từ ngày: {`${moment(convertDateForMoment(fromDate)).format('DD/MM/YYYY')}`}</Text>
                )}
                {toDate != '' && (
                    <Text> đến {`${moment(convertDateForMoment(toDate)).format('DD/MM/YYYY')}`}</Text>
                )}
                {(fromDate != '' || toDate != '') && (
                    <Icon
                        name="remove"
                        type="font-awesome"
                        color={mycolors.red}
                        size={20}
                        onPress={() => {
                            mContext.setFromDate('');
                            if (toDate != '') {
                                mContext.setToDate('');
                            }
                        }}
                    />
                )}
            </View>
        )
    );
};

export const SelectOneOptionModal = (props) => {
    let mContext: any = React.useContext(MyContext);

    const [visible, setVisible] = useState(false);
    const toggleOverlay = () => {
        setVisible(!visible);
    };

    const listOptions = mContext.listOptions || [];
    const currentType = mContext.currentType || listOptions[0];

    let dropTitle = currentType.title != "" ? currentType.title : "";
    if (dropTitle == "") {
        dropTitle = props.dropTitle != undefined ? props.dropTitle : "Chọn";
    }

    const heightWindow = Platform.OS == 'web' ? windowHeight - 240 : windowHeight - 80
    return (
        <>
            {visible && (
                <Overlay
                    fullScreen={Platform.OS == 'web' ? false : true}
                    isVisible={visible}
                    onBackdropPress={() => setVisible(!visible)}
                >
                    <Text style={{ marginTop: 22, marginLeft: 20, marginBottom: 6, fontWeight: "bold" }}>Bấm chọn 1 mục :</Text>
                    <ScrollView style={{ maxHeight: heightWindow, maxWidth: 450 }}>
                        <View style={Platform.OS == 'web' && { width: 350 }}>
                            <View style={{ ...stylesClassRoom.row, width: "auto" }}>
                                {listOptions.map((item, index) =>
                                    <CheckBox
                                        key={index}
                                        title={item['title']}
                                        checkedIcon='dot-circle-o'
                                        uncheckedIcon='circle-o'
                                        checked={currentType.id == item.id ? true : false}
                                        onPress={() => {
                                            mContext.selectOption(item);
                                            setVisible(!visible);
                                        }}
                                    />
                                )}
                            </View>
                        </View>
                    </ScrollView>
                    <View style={{ marginTop: 12 }}>
                        <ButtonCancel onPress={() => setVisible(!visible)} />
                    </View>
                </Overlay>
            )}

            <View style={{ flexDirection: "row", alignSelf: "center" }}>
                <Pressable onPress={toggleOverlay}  >
                    <Icon
                        name="caret-down"
                        type="font-awesome"
                        color="#000"
                        size={22}
                        iconStyle={{ marginBottom: -6 }}
                    />
                    <Text style={{ maxWidth: 80, fontSize: 13 }}>{dropTitle}</Text>
                </Pressable>
            </View>
        </>
    );
};

export const SelectOneReportModal = (props) => {
    let mContext: any = React.useContext(MyContext);

    const navigation = props.navigation || {};

    let listOptions = LIST_REPORT_MENU;
    const currentType = mContext.currentType || listOptions[0];

    const [visible, setVisible] = useState(false);
    const toggleOverlay = () => {
        setVisible(!visible);
    };

    let dropTitle = currentType.title != "" ? currentType.title : "";
    if (dropTitle == "") {
        dropTitle = props.dropTitle != undefined ? props.dropTitle : "Chọn";
    }

    const heightWindow = Platform.OS == 'web' ? windowHeight - 240 : windowHeight - 80
    return (
        <>
            {visible && (
                <Overlay
                    fullScreen={Platform.OS == 'web' ? false : true}
                    isVisible={visible}
                    onBackdropPress={() => setVisible(!visible)}
                >
                    <ScrollView style={{ maxHeight: heightWindow, maxWidth: 450 }}>
                        <View style={Platform.OS == 'web' && { width: 350 }}>
                            <View style={{ ...stylesClassRoom.row, width: "auto" }}>
                                {listOptions.map((item, index) =>
                                    item.id == currentType.id ?
                                        <CheckBox
                                            key={index}
                                            title={item['title']}
                                            uncheckedIcon={item['icon']}
                                            containerStyle={{ backgroundColor: mycolors.primary }}
                                            textStyle={{ color: "#fff" }}
                                            onPress={() => {
                                                setVisible(false);
                                                navigation.navigate(item.component);
                                            }}
                                        />
                                        :
                                        <CheckBox
                                            key={index}
                                            title={item['title']}
                                            uncheckedIcon={item['icon']}
                                            onPress={() => {
                                                setVisible(false);
                                                navigation.navigate(item.component);
                                            }}
                                        />
                                )}
                            </View>
                            <View style={{ marginTop: 12 }}>
                                <ButtonCancel onPress={() => setVisible(!visible)} />
                            </View>
                        </View>
                    </ScrollView>
                </Overlay>
            )}

            <Pressable
                style={{ flexDirection: "row", alignSelf: "center", alignContent: "center" }}
                onPress={toggleOverlay}  >
                <View style={{ marginTop: 3 }}>
                    <Text style={{ fontWeight: "bold" }}>{dropTitle}</Text>
                </View>
                <Icon
                    name='bars'
                    type='font-awesome'
                    color='#303030'
                    style={{ flex: 1, marginLeft: 8 }}
                />
            </Pressable>
        </>
    );
};

export const LogoutDialog = ({ navigation }) => {
    const [isVisible, setVisible] = useState(true);

    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', async () => {
            await AsyncStorage.getItem('jwt_token').then((value) => {
                if (value == null || value == '') {
                    setVisible(true);
                } else {
                    setVisible(false);
                }
            });
        });

        return () => {
            // cleanup
            // Execute before the next effect or unmount
        };
    }, []);

    return (
        <Dialog
            isVisible={isVisible}
            onBackdropPress={() => {
                setVisible(false);
                navigation.navigate('Login');
            }}
        >
            <View style={{ maxWidth: windowWidth, alignSelf: "center" }}>
                <Dialog.Title title='Bạn Đã Đăng Xuất!' />
                <ButtonPrimary title="Đăng nhập lại"
                    onPress={() => {
                        setVisible(false);
                        navigation.navigate('Login');
                    }}
                />
            </View>
        </Dialog>
    )
}