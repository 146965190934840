import React from "react";
import { Text, View, StyleSheet, ScrollView, Platform } from 'react-native';
import { Input, Icon, Overlay, Button, CheckBox } from 'react-native-elements';
import { Controller } from "react-hook-form";
import { convertDateForMoment, displayMomentWithFormat, fieldPriceBuy, fieldPriceSell, getHoursOfDay, getMinutesOfHour,maskInputNumber,removeCharFromStr } from "../config/myfunc";

import { mycolors } from "../../src/config/mycolors";
import DatePicker from 'react-native-modern-datepicker';

import { fontSizeBaseCalendar, convertMyDatePicker, windowHeight, windowWidth, LANG } from "../../src/config/config";

import { AuthContext, MyContext } from '../../src/context/mycontext';
import { stylesCalendar } from "../../src/config/mystyles";
import { ButtonAdd, ButtonCancel, ButtonSave, ButtonShortMenu } from "./mybuttons";
import { isArray, isEmpty } from "lodash";
import { BehaviorModel } from "../models/models";

import AsyncStorage from "@react-native-async-storage/async-storage";

export const arr_pattern = {
    "email": /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    "phone": /^((0)[0-9])/
}

export function MyControlDisplayText(props) {
    const field = props.field;
    return <>
        <View style={{ flexDirection: "row", marginLeft: 12, marginBottom: 20 }}>
            <Icon
                name={field.icon.name}
                type="font-awesome"
                color="#86939e"
                size={field.icon.size}
                containerStyle={{ marginRight: 12 }}
            />
            <Text style={{ marginTop: 2 }}>
                {props.value}
            </Text>
        </View>
    </>
}

export function MyControlInputText(props) {
    const field = props.field;
    const control = props.control;
    const errors = props.errors;
    const action = props.action;

    let rules = { ...field.rules };
    if (props.pattern) {
        rules = { ...rules, pattern: props.pattern };
    }
    if (props.validate) {
        rules = { ...rules, validate: props.validate };
    }

    const keyboardType = field.keyboardType || `default`;
    const isCurrency = field.isCurrency || false;

    return (
      <>
        <Text style={styles.text}>{field.label}</Text>
        {/* {field.isPassword && (
            <Text style={styles.textPassword}>
                {action == 'edit' ? `(để trống nếu không muốn thay đổi)` : `(mặc định 123456 nếu để trống)`}
            </Text>
        )} */}
        <Controller
          control={control}
          rules={rules}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Input
              leftIcon={
                <Icon
                  name={field.icon.name}
                  type="font-awesome"
                  color="#86939e"
                  size={field.icon.size}
                />
              }
              leftIconContainerStyle={styles.leftIconContainerStyle}
              containerStyle={styles.containerStyle}
              keyboardType={keyboardType}
              onBlur={onBlur}
              onChangeText={(value) => {
                if (keyboardType == "numeric") {
                  value = removeCharFromStr(value);
                }
                onChange(value);
              }}
              value={
                value == null
                  ? ""
                  : `${
                      keyboardType == "numeric" && isCurrency ? maskInputNumber(value) : value
                    }`
              }
              ref={ref}
              maxLength={
                field.rules.maxLength != undefined
                  ? field.rules.maxLength
                  : 99999999
              }
              placeholder={field.placeholder}
              secureTextEntry={field.isPassword}
              multiline={field.multiline != undefined ? true : false}
            />
          )}
          name={field.name}
        />
        {errors[field.name] && (
          <Text style={styles.error}>
            {field.rules.required == true
              ? LANG.FieldRequired
              : field.rules.required}
          </Text>
        )}
      </>
    );
}

export function MyControlBuyItem(props) {
    let mContext: any = React.useContext(MyContext);

    const field = props.field;
    const control = props.control;
    const errors = props.errors;

    const price_default = field['price_buy'] || 0;

    let rules = { ...field.rules };
    // if (props.pattern) {
        rules = { ...rules, pattern: /^[0-9\/]+$/i };
    // }
    if (props.validate) {
        rules = { ...rules, validate: props.validate };
    }

    return (
        <>
            <Text style={{ marginLeft: 10, fontWeight:"bold" }}>
                {field.title}
            </Text>
            <View style={{ flexDirection: "row" }}>
                <Controller
                    control={control}
                    rules={rules}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                            leftIcon={
                                <Text>Số lượng:</Text>
                            }
                            leftIconContainerStyle={styles.leftIconContainerStyle}
                            containerStyle={{ width: "45%" }}
                            keyboardType='numeric'
                            onBlur={onBlur}
                            onChangeText={value => {
                                value = removeCharFromStr(value);
                                onChange(value);
                            }}
                            value={value == null ? '1' : `${value}`}
                            maxLength={10}
                        />
                    )}
                    name={field.name}
                />
                <Controller
                    control={control}
                    rules={rules}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                            leftIcon={
                                <Text>Đơn giá:</Text>
                            }
                            rightIcon={
                                <Icon
                                    name="remove"
                                    type="font-awesome"
                                    color="#f00"
                                    size={30}
                                    onPress={() => mContext.removeItem(field)}
                                />
                            }
                            leftIconContainerStyle={styles.leftIconContainerStyle}
                            containerStyle={{ width: "55%" }}
                            keyboardType='numeric'
                            onBlur={onBlur}
                            onChangeText={value => {
                                value = removeCharFromStr(value);
                                onChange(value);
                            }}
                            value={value == null ? `${price_default}` : `${value}`}
                            maxLength={10}
                        />
                    )}
                    name={fieldPriceBuy(field.name)}
                />
            </View>
        </>
    )
}

export function MyControlSelltem(props) {
    let mContext: any = React.useContext(MyContext);

    const field = props.field;
    const control = props.control;
    const errors = props.errors;

    const price_default = field['price_sell'] || 0;

    let rules = { ...field.rules };
    if (props.pattern) {
        rules = { ...rules, pattern: props.pattern };
    }
    if (props.validate) {
        rules = { ...rules, validate: props.validate };
    }

    return (
        <>
            <Text style={{ marginLeft: 10, fontWeight:"bold" }}>
                {field.title}
            </Text>
            <View style={{ flexDirection: "row" }}>
                <Controller
                    control={control}
                    rules={rules}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                            leftIcon={
                                <Text>Số lượng:</Text>
                            }
                            leftIconContainerStyle={styles.leftIconContainerStyle}
                            containerStyle={{ width: "35%" }}
                            keyboardType='numeric'
                            onBlur={onBlur}
                            onChangeText={value => {
                                value = removeCharFromStr(value);
                                onChange(value);
                            }}
                            value={value == null ? '1' : `${value}`}
                            maxLength={10}
                        />
                    )}
                    name={field.name}
                />
                <Controller
                    control={control}
                    rules={rules}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                            leftIcon={
                                <Text>Đơn giá:</Text>
                            }
                            rightIcon={
                                <Icon
                                    name="remove"
                                    type="font-awesome"
                                    color="#f00"
                                    size={30}
                                    onPress={() => mContext.removeItem(field)}
                                />
                            }
                            leftIconContainerStyle={styles.leftIconContainerStyle}
                            containerStyle={{ width: "65%" }}
                            keyboardType='numeric'
                            onBlur={onBlur}
                            onChangeText={value => {
                                value = removeCharFromStr(value);
                                onChange(value);
                            }}
                            value={value == null ? `${maskInputNumber(price_default)}` : `${maskInputNumber(value)}`}
                            maxLength={10}
                        />
                    )}
                    name={fieldPriceSell(field.name)}
                />
            </View>
        </>
    )
}


export function MyControlSellServicetem(props) {
    let mContext: any = React.useContext(MyContext);

    const field = props.field;
    const control = props.control;
    const errors = props.errors;

    const price_default = field['price'] || 0;

    let rules = { ...field.rules };
    if (props.pattern) {
        rules = { ...rules, pattern: props.pattern };
    }
    if (props.validate) {
        rules = { ...rules, validate: props.validate };
    }

    return (
        <>
            <Text style={{ marginLeft: 10, fontWeight:"bold" }}>
                {field.title}
            </Text>
            <View style={{ flexDirection: "row" }}>
                <Controller
                    control={control}
                    rules={rules}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                            leftIcon={
                                <Text>Số lượng:</Text>
                            }
                            leftIconContainerStyle={styles.leftIconContainerStyle}
                            containerStyle={{ width: "45%" }}
                            keyboardType='numeric'
                            onBlur={onBlur}
                            onChangeText={onChange}
                            value={value == null ? '1' : `${value}`}
                            maxLength={10}
                        />
                    )}
                    name={field.name}
                />
                <Controller
                    control={control}
                    rules={rules}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                            leftIcon={
                                <Text>Đơn giá:</Text>
                            }
                            rightIcon={
                                <Icon
                                    name="remove"
                                    type="font-awesome"
                                    color="#f00"
                                    size={30}
                                    onPress={() => mContext.removeItem(field)}
                                />
                            }
                            leftIconContainerStyle={styles.leftIconContainerStyle}
                            containerStyle={{ width: "55%" }}
                            keyboardType='numeric'
                            onBlur={onBlur}
                            onChangeText={onChange}
                            value={value == null ? `${price_default}` : `${value}`}
                            maxLength={10}
                        />
                    )}
                    name={fieldPriceSell(field.name)}
                />
            </View>
        </>
    )
}

export function MyControlCheckbox(props) {
    let mContext: any = React.useContext(MyContext);

    const field = props.field;
    const control = props.control;
    const value = props.value != undefined ? props.value : false;

    const [checked, setChecked] = React.useState(value);

    const changeValue = () => {
        mContext.setValue(`${field.name}`, !checked);
        setChecked(!checked);
    }

    return <>
        <Text style={styles.checkbox}>{field.label}</Text>
        <Controller
            control={control}
            render={() => (
                <CheckBox
                    containerStyle={{ width: 30, marginTop: -10, marginLeft: 3 }}
                    checked={checked}
                    onPress={changeValue}
                />
            )}
            name={field.name}
        />
    </>
}

export function MyControlMultiCheckbox(props) {
    let mContext: any = React.useContext(MyContext);

    const field = props.field;
    const control = props.control;
    const value = props.value != undefined ? props.value : [];

    const [checkList, setCheckList] = React.useState(value);

    const changeValue = (_item) => {
        let newCheckList = checkList.map(item => {
            if (item.id == _item.id) {
                if (item.checked == undefined || item.checked == false) {
                    item.checked = true;
                } else {
                    item.checked = false;
                }
            }
            return item;
        });

        setCheckList(newCheckList);
        mContext.setValue(`${field.name}`, newCheckList);
    }

    return <>
        <Text style={styles.checkbox}>{field.label}</Text>
        <ScrollView
            style={{ width: 220, maxWidth: "auto" }} >
            <Controller
                control={control}
                render={() =>
                    checkList.map((item, index) =>
                        <CheckBox
                            key={index}
                            title={item.lbl}
                            containerStyle={{ marginTop: -10, marginLeft: 3, backgroundColor: "none" }}
                            checked={item.checked}
                            onPress={() => { changeValue(item) }}
                        />
                    )
                }
                name={field.name}
            />
        </ScrollView>
    </>
}

export function MyControlMultiCheckboxModal(props) {
    let mContext: any = React.useContext(MyContext);

    const field = props.field;
    const [checkList, setCheckList] = React.useState(mContext.listSelect);

    const changeValue = (_item) => {
        let newCheckList = checkList.map(item => {
            if (item.id == _item.id) {
                if (item.checked == undefined || item.checked == false) {
                    item.checked = true;
                } else {
                    item.checked = false;
                }
            }
            return item;
        });

        setCheckList(newCheckList);

        mContext.setValue(`${field.name}`, newCheckList);
    }

    const maxHeightWindow = Platform.OS == 'web' ? windowHeight - 240 : windowHeight - 80;
    return <>
        <Text style={styles.checkbox}>{field.label}</Text>
        <Overlay
            fullScreen={Platform.OS == 'web' ? false : true}
            isVisible={mContext.visibleMultiSelect}
            onBackdropPress={() => mContext.setVisibleMultiSelect(!mContext.visibleMultiSelect)}
        >
            <Text style={{ margin: 12, marginBottom: 6, fontWeight: "bold" }}>{props.title != undefined ? props.title : 'Chọn các mục sau:'}</Text>
            <ScrollView style={{ maxHeight: maxHeightWindow, width: windowWidth - 60, maxWidth: 450 }}>
                {checkList.map(item =>
                    <CheckBox
                        key={item.id}
                        title={item.title}
                        checked={item.checked}
                        onPress={() => {
                            changeValue(item);
                        }}
                    />
                )}
                <View style={{ height: 30 }}></View>
            </ScrollView>
            <ButtonSave onPress={() => mContext.setVisibleMultiSelect(!mContext.visibleMultiSelect)} />
        </Overlay>
    </>
}

export function MyControlRadioCheck(props) {
    let mContext: any = React.useContext(MyContext);

    const field = props.field;
    const control = props.control;
    const value = props.value != undefined ? props.value : [];

    const [checkList, setCheckList] = React.useState(value);

    const changeValue = (_item) => {
        let newCheckList = checkList.map(item => {
            if (item.id == _item.id) {
                if (item.checked == undefined || item.checked == false) {
                    item.checked = true;
                } else {
                    item.checked = false;
                }
            } else {
                item.checked = false;
            }

            return item;
        });

        setCheckList(newCheckList);
        mContext.setValue(`${field.name}`, newCheckList);
    }

    return <>
        <Text style={styles.checkbox}>{field.label}</Text>
        <Controller
            control={control}
            render={() =>
                checkList.map((item, index) =>
                    <CheckBox
                        key={index}
                        title={item.lbl}
                        containerStyle={{ marginTop: -10, marginLeft: 3, backgroundColor: "none" }}
                        checked={item.checked}
                        checkedIcon='dot-circle-o'
                        uncheckedIcon='circle-o'
                        onPress={() => { changeValue(item) }}
                    />
                )
            }
            name={field.name}
        />
    </>
}

export function MyControlDisplayCalendar(props) {
    const value = props.value != undefined ? props.value : '';
    const placeholder = props.placeholder != undefined ? props.placeholder : '';
    return (
        <Input
            leftIconContainerStyle={{ marginLeft: 0, marginRight: 10 }}
            leftIcon={
                <Icon
                    name="calendar"
                    type="font-awesome"
                    color="#86939e"
                    size={25}
                />
            }
            value={displayMomentWithFormat(value)}
            editable={false}
            containerStyle={{ marginTop: 6, width: '100%' }}
            placeholder={placeholder}
        />
    )
}

export function MyControlSparateCalendar(props){
    let mContext: any = React.useContext(MyContext);
    
    const control = props.control;
    const errors = props.errors || {};
    const visible_hour_minute = props.visible_hour_minute || false;

    const child_date = props.child_date || {
        'date': 'date_of_month',
        'month': 'month_of_year',
        'year': 'year_of_calendar',
        'hour': 'hour_of_datetime',
        'minute': 'minute_of_datetime'
    };
    const field_date = child_date['date'];
    const field_month = child_date['month'];
    const field_year = child_date['year'];
    const field_hour = child_date['hour'];
    const field_minute = child_date['minute'];

    // the same: Mon, 27 Dec 2021 14:31:27 GMT
    let fulldate = props.default_fulldate || '';
    if (fulldate == '') {
        fulldate = new Date();
    } else {
        fulldate = fulldate.replace('GMT','');
        fulldate = new Date(fulldate);
    }
    let date_default = fulldate.getDate();
    if (parseInt(date_default) < 10) {
        date_default = `0${parseInt(date_default)}`;
    }
    let month_default = fulldate.getMonth() + 1;
    if (parseInt(month_default) < 10) {
        month_default = `0${parseInt(month_default)}`;
    }
    const year_default = fulldate.getFullYear();

    // hour, minute
    let hour_default = fulldate.getHours();
    if (parseInt(hour_default) < 10) {
        hour_default = `0${parseInt(hour_default)}`;
    }

    let minute_default = fulldate.getMinutes();
    if (parseInt(minute_default) < 10) {
        minute_default = `0${parseInt(minute_default)}`;
    }

    React.useEffect(() => {
        mContext.setValue(field_date, date_default);
        mContext.setValue(field_month, month_default);
        mContext.setValue(field_year, year_default);

        mContext.setValue(field_hour, hour_default);
        mContext.setValue(field_minute, minute_default);
    }, []);

    const styles = StyleSheet.create({
        error_calendar: {
            color: "#f00",
            marginTop: Platform.OS == 'web' ? -8 : -20,
            alignSelf: "center",
            paddingLeft: 12
        },
        sparate_calendar: {
            fontSize: 24,
            marginTop: 12,
            color: mycolors.gray2
        },
        text: {
            marginTop: 18,
            marginBottom: Platform.OS == 'web' ? -12 : -24,
            color: '#000',
            alignSelf: "flex-start",
            paddingLeft: 12
        }
    });

    return (
        <View>
            <View style={{ display: "flex", flexDirection: "row" }}>
                <View>
                    <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value, ref } }) => {
                            return (
                                <View>
                                    <Input
                                        style={{ width: 50, textAlign: "center" }}
                                        containerStyle={{ width: 80 }}
                                        keyboardType='numeric'
                                        onBlur={onBlur}
                                        onChangeText={value => {
                                            value = removeCharFromStr(value);
                                            onChange(value);
                                        }}
                                        value={value}
                                        defaultValue={`${date_default}`}
                                        ref={ref}
                                        placeholder="ngày"
                                    />
                                </View>
                            )
                        }}
                        name={field_date}
                        rules={{
                            required: true,
                            min: 1, max: 31,
                            pattern: /^[0-9\/]+$/i
                        }}
                    />
                    {errors[field_date] && <Text style={styles.error_calendar}>01-31</Text>}
                </View>

                <Text style={styles.sparate_calendar}>/</Text>

                <View>
                    <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <Input
                                style={{ width: 50, textAlign: "center" }}
                                containerStyle={{ width: 80 }}
                                keyboardType='numeric'
                                onBlur={onBlur}
                                onChangeText={value => {
                                    value = removeCharFromStr(value);
                                    onChange(value);
                                }}
                                value={value}
                                defaultValue={`${month_default}`}
                                ref={ref}
                                placeholder="tháng"
                            />
                        )}
                        name={field_month}
                        rules={{
                            required: true,
                            min: 1, max: 12,
                            pattern: /^[0-9\/]+$/i
                        }}
                    />
                    {errors[field_month] && <Text style={styles.error_calendar}>01-12</Text>}
                </View>

                <Text style={styles.sparate_calendar}>/</Text>

                <View>
                    <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <Input
                                style={{ width: 50, textAlign: "center" }}
                                containerStyle={{ width: 80 }}
                                keyboardType='numeric'
                                onBlur={onBlur}
                                onChangeText={value => {
                                    value = removeCharFromStr(value);
                                    onChange(value);
                                }}
                                value={value}
                                defaultValue={`${year_default}`}
                                ref={ref}
                                placeholder="năm"
                            />
                        )}
                        name={field_year}
                        rules={{
                            required: true,
                            min: 1900, max: 2050,
                            pattern: /^[0-9\/]+$/i
                        }}
                    />
                    {errors[field_year] && <Text style={styles.error_calendar}>1900-2050</Text>}
                </View>
            </View>
            {visible_hour_minute == true ?
                <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text style={styles.text}>Giờ/phút:</Text>
                    <View>
                        <Controller
                            control={control}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Input
                                    style={{ width: 50, textAlign: "center" }}
                                    containerStyle={{ width: 80 }}
                                    keyboardType='numeric'
                                    onBlur={onBlur}
                                    onChangeText={value => {
                                        value = removeCharFromStr(value);
                                        onChange(value);
                                    }}
                                    value={value}
                                    defaultValue={`${hour_default}`}
                                    ref={ref}
                                    placeholder="giờ"
                                />
                            )}
                            name={field_hour}
                            rules={{
                                required: true,
                                min: 0, max: 23,
                                pattern: /^[0-9\/]+$/i
                            }}
                        />
                        {errors[field_hour] && <Text style={styles.error_calendar}>0-23</Text>}
                    </View>
                    <Text style={styles.sparate_calendar}>/</Text>
                    <View>
                        <Controller
                            control={control}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Input
                                    style={{ width: 50, textAlign: "center" }}
                                    containerStyle={{ width: 80 }}
                                    keyboardType='numeric'
                                    onBlur={onBlur}
                                    onChangeText={value => {
                                        value = removeCharFromStr(value);
                                        onChange(value);
                                    }}
                                    value={value}
                                    defaultValue={`${minute_default}`}
                                    ref={ref}
                                    placeholder="phút"
                                />
                            )}
                            name={field_minute}
                            rules={{
                                required: true,
                                min: 0, max: 59,
                                pattern: /^[0-9\/]+$/i
                            }}
                        />
                        {errors[field_minute] && <Text style={styles.error_calendar}>0-59</Text>}
                    </View>
                </View>
                : <Text></Text>
            }
        </View>
    )
}

export function MyControlShortMenuCustomer(props) {
    // customer them self
    let mContext: any = React.useContext(MyContext);
    let mAuthContext: any = React.useContext(AuthContext);

    const navigation = props.navigation || {};

    const customer_id = 'my_self';
    const customer_name = props.customer_name || '';
    const user_family_id = props.user_family_id || '';
    const user_family_name = props.user_family_name || '';

    const maxHeightWindow = Platform.OS == 'web' ? windowHeight - 240 : windowHeight - 80;
    const width = Platform.OS == 'web' ? windowWidth - 60 : "auto";

    const hiddenMenu = () => {
        mContext.setVisibleShortMenu(!mContext.visibleShortMenu);
    }

    const space = <View style={{ height: 12 }}></View>

    return (
        <Overlay
            fullScreen={Platform.OS == 'web' ? false : true}
            isVisible={mContext.visibleShortMenu}
            onBackdropPress={() => { hiddenMenu() }}
        >
            <ScrollView style={{ maxHeight: maxHeightWindow, width: width, maxWidth: 450, paddingTop: 20}}>
                {mAuthContext.isActivated && (
                    <>
                        <ButtonShortMenu
                            icon="heart"
                            color_icon="#f7e608"
                            title={LANG.HealthCheck}
                            onPress={() => {
                                hiddenMenu();
                                navigation.navigate('SaleServiceCustomer', {
                                    _customer_id: customer_id,
                                    _customer_name: customer_name,
                                    _user_family_id: user_family_id,
                                    _user_family_name: user_family_name,
                                });
                            }}
                        />
                    {space}
                </>
                )}
                {mAuthContext.isActivated && (<>
                    <ButtonShortMenu
                        icon="shopping-cart"
                        title={LANG.BuyMedicine}
                        onPress={() => {
                            hiddenMenu();
                            navigation.navigate('SaleOrderCustomer', {
                                _customer_id: customer_id,
                                _customer_name: customer_name,
                                _user_family_id: user_family_id,
                                _user_family_name: user_family_name,
                            });
                        }}
                    />
                    {space}
                </>)}
                {user_family_id == '' && mAuthContext.isActivated &&
                    <>
                        <ButtonShortMenu
                            icon="money"
                            title={LANG.Deposit}
                            onPress={() => {
                                hiddenMenu();
                                navigation.navigate('PaymentCustomer', {
                                    _customer_id: customer_id,
                                    _customer_name: customer_name,
                                    _user_family_id: user_family_id,
                                    _user_family_name: user_family_name,
                                });
                            }}
                        />
                        {space}
                    </>
                }
                <ButtonShortMenu
                    icon="calendar"
                    title={LANG.CalendarAppointment}
                    onPress={() => {
                        hiddenMenu();
                        navigation.navigate('BookingCustomer', {
                            _customer_id: customer_id,
                            _customer_name: customer_name,
                            _user_family_id: user_family_id,
                            _user_family_name: user_family_name,
                        });
                    }}
                />
                {space}
                <ButtonShortMenu
                    icon="comments"
                    title={LANG.Chat}
                    onPress={() => {
                        hiddenMenu();
                        Promise.all([
                            AsyncStorage.getItem("user_id"),
                            AsyncStorage.getItem("display_name"),
                          ]).then((values) => {
                            navigation.navigate("ChatCustomer", {
                              _user_id: values[0],
                              _user_name: values[1],
                            });
                          })
                    }}
                />
                {space}
                <ButtonShortMenu
                    icon="users"
                    title={LANG.HomeFamily}
                    onPress={() => {
                        hiddenMenu();
                        navigation.navigate('FamilyCustomerCustomer', {
                            _customer_id: customer_id,
                            _customer_name: customer_name,
                        });
                    }}
                />
                {space}
                <ButtonCancel
                    title="Thoát"
                    onPress={() => {
                        hiddenMenu();
                    }}
                    buttonStyle={{ marginTop: 20 }}
                    containerStyle={{ width: 154 }}
                />
            </ScrollView>
        </Overlay>
    )
};

export function MyControlShortMenu(props) {
    let mContext: any = React.useContext(MyContext);

    const navigation = props.navigation || {};

    const customer_id = props.customer_id || '';
    const customer_name = props.customer_name || '';
    const user_family_id = props.user_family_id || '';
    const user_family_name = props.user_family_name || '';

    const maxHeightWindow = Platform.OS == 'web' ? windowHeight - 240 : windowHeight - 80;
    const width = Platform.OS == 'web' ? windowWidth - 60 : "auto";

    const hiddenMenu = () => {
        mContext.setVisibleShortMenu(!mContext.visibleShortMenu);
    }

    const space = <View style={{ height: 12 }}></View>
    return (
        <Overlay
            fullScreen={Platform.OS == 'web' ? false : true}
            isVisible={mContext.visibleShortMenu}
            onBackdropPress={() => { hiddenMenu() }}
        >
            <ScrollView style={{ maxHeight: maxHeightWindow, width: width, maxWidth: 450, paddingTop: 20}}>
                <ButtonShortMenu
                    icon="heart"
                    color_icon="#f7e608"
                    title={LANG.HealthCheck}
                    onPress={() => {
                        hiddenMenu();
                        navigation.navigate('SaleService', {
                            _customer_id: customer_id,
                            _customer_name: customer_name,
                            _user_family_id: user_family_id,
                            _user_family_name: user_family_name,
                        });
                    }}
                />
                {space}
                <ButtonShortMenu
                    icon="shopping-cart"
                    title={LANG.BuyMedicine}
                    onPress={() => {
                        hiddenMenu();
                        navigation.navigate('SaleOrder', {
                            _customer_id: customer_id,
                            _customer_name: customer_name,
                            _user_family_id: user_family_id,
                            _user_family_name: user_family_name,
                        });
                    }}
                />
                {space}
                {user_family_id == '' &&
                    <>
                        <ButtonShortMenu
                            icon="money"
                            title={LANG.Deposit}
                            onPress={() => {
                                hiddenMenu();
                                navigation.navigate('Payment', {
                                    _customer_id: customer_id,
                                    _customer_name: customer_name,
                                    _user_family_id: user_family_id,
                                    _user_family_name: user_family_name,
                                });
                            }}
                        />
                        {space}
                    </>
                }
                <ButtonShortMenu
                    icon="calendar"
                    title={LANG.CalendarAppointment}
                    onPress={() => {
                        hiddenMenu();
                        navigation.navigate('BookingStaff', {
                            _customer_id: customer_id,
                            _customer_name: customer_name,
                            _user_family_id: user_family_id,
                            _user_family_name: user_family_name,
                        });
                    }}
                />
                {space}
                <ButtonShortMenu
                    icon="comments"
                    title={LANG.Chat}
                    onPress={() => {
                        hiddenMenu();
                        navigation.navigate('ChatStaff', {
                            _customer_id: customer_id,
                            _customer_name: customer_name,
                            _user_family_id: user_family_id,
                            _user_family_name: user_family_name,
                        });
                    }}
                />
                {space}
                <ButtonShortMenu
                    icon="users"
                    title={LANG.HomeFamily}
                    onPress={() => {
                        hiddenMenu();
                        navigation.navigate('FamilyCustomerStaff', {
                            _customer_id: customer_id,
                            _customer_name: customer_name,
                            _user_family_id: user_family_id,
                            _user_family_name: user_family_name,
                        });
                    }}
                />
                {space}
                <View style={{ display: "flex", flexDirection: "row", alignSelf: "center" }}>
                    {Platform.OS != 'web' &&
                        <ButtonCancel
                            title="Thoát"
                            onPress={() => {
                                hiddenMenu();
                            }}
                            buttonStyle={{ marginTop: 20 }}
                            containerStyle={{ width: 120 }}
                        />
                    }
                    <ButtonShortMenu
                        icon="users"
                        color_bg={mycolors.gray}
                        color_icon="#fff"
                        title={LANG.Customer}
                        onPress={() => {
                            hiddenMenu();
                            navigation.navigate('CustomerStaff', {
                                _customer_id: customer_id,
                            });
                        }}
                    />
                </View>
            </ScrollView>
        </Overlay>
    )
};

export function MyControlCalendar(props) {
    let mContext: any = React.useContext(MyContext);

    const currentCalendar = props.currentCalendar;
    const subFieldHour = props.subFieldHour != undefined ? true : false;
    const subFieldMinute = props.subFieldMinute != undefined ? true : false;

    let currentValue: any;
    let _hour = "00";
    let _minute = "00";
    if (props.currentValueCalendar != undefined) {
        currentValue = convertDateForMoment(props.currentValueCalendar);

        let hour_minute = props.currentValueCalendar.trim().split(" ");
        if (hour_minute[1] != undefined) {
            const arr = hour_minute[1].trim().split(":");
            if (arr[0] != undefined) {
                _hour = arr[0];
            }
            if (arr[1] != undefined) {
                _minute = arr[1];
            }
        }
    } else {
        currentValue = "";
    }

    const [hour, setHour] = React.useState(_hour);
    const [minute, setMinute] = React.useState(_minute);

    return (
        <Overlay
            fullScreen={Platform.OS == 'web' ? false : true}
            isVisible={mContext.visibleCalendar}
            onBackdropPress={() => mContext.setVisibleCalendar(!mContext.visibleCalendar)}
        >
            <ScrollView>
                <View style={Platform.OS == 'web' && { width: 350 }}>
                    <DatePicker
                        options={{
                            backgroundColor: mycolors.primary,
                            textHeaderColor: '#FFA25B',
                            textDefaultColor: '#F6E7C1',
                            selectedTextColor: '#fff',
                            mainColor: '#F4722B',
                            textSecondaryColor: '#D6C7A1',
                            textFontSize: fontSizeBaseCalendar,
                            textHeaderFontSize: fontSizeBaseCalendar + 2,
                            borderColor: 'rgba(122, 146, 165, 0.75)',
                        }}
                        mode="calendar"
                        current={currentValue}
                        selected={currentValue}
                        minuteInterval={30}
                        style={{ borderRadius: 0 }}
                        onSelectedChange={date => {
                            const fulldate = convertMyDatePicker(date, hour, minute);
                            console.log("fulldate: ", fulldate);
                            mContext.changeCalendarFieldValue(`${currentCalendar}`, fulldate);
                        }}
                    />

                    {subFieldHour && (
                        <>
                            <Text style={{ marginTop: 12 }}>Chọn giờ:</Text>
                            <ScrollView horizontal={true} style={{ maxWidth: 350, flexDirection: "row" }}>
                                {getHoursOfDay().map(index => (
                                    <View key={index}>
                                        {hour == index ? (
                                            <Text style={stylesCalendar.itemActive} onPress={() => setHour(index)}>
                                                {index}
                                            </Text>
                                        ) : (
                                            <Text style={stylesCalendar.item} onPress={() => setHour(index)}>
                                                {index}
                                            </Text>
                                        )}
                                    </View>
                                ))}
                            </ScrollView>
                        </>
                    )}

                    {subFieldMinute && (
                        <>
                            <Text style={{ marginTop: 12 }}>Chọn phút:</Text>
                            <ScrollView horizontal={true} style={{ maxWidth: 350, flexDirection: "row" }}>
                                {getMinutesOfHour().map(index => (
                                    <View key={index}>
                                        {minute == index ? (
                                            <Text style={stylesCalendar.itemActive} onPress={() => setMinute(index)}>
                                                {index}
                                            </Text>
                                        ) : (
                                            <Text style={stylesCalendar.item} onPress={() => setMinute(index)}>
                                                {index}
                                            </Text>
                                        )}
                                    </View>
                                ))}
                            </ScrollView>
                        </>
                    )}

                    <Button
                        icon={
                            <Icon
                                name="check"
                                type="font-awesome"
                                color="white"
                                size={25}
                                iconStyle={{ marginRight: 10 }}
                            />
                        }
                        title="OK"
                        onPress={() => { mContext.setVisibleCalendar(!mContext.visibleCalendar); }}
                        buttonStyle={{ marginTop: 20 }}
                    />
                </View>
            </ScrollView>
        </Overlay>
    )
};


export function MyControlModalListSelect(props) {
    let mContext: any = React.useContext(MyContext);
    const listSelect = mContext.listSelection;
    const currentPerson = mContext.currentPerson;
    const listChildren = mContext.listChildren;
    const fieldOption = mContext.fieldOption;

    const selectOption = (_item) => {
        let list = listChildren.map(person => {
            if (person.id == currentPerson.id) {
                person[fieldOption] = _item.id;
                person['checked'] = true;
            }
            return person;
        });
        mContext.setListChildren(list);
    }

    const maxHeightWindow = Platform.OS == 'web' ? windowHeight - 240 : windowHeight - 80;
    return <Overlay
        fullScreen={Platform.OS == 'web' ? false : true}
        isVisible={mContext.visible} onBackdropPress={mContext.toggleOverlay}
    >
        <Text style={{ fontWeight: "bold", margin: 12 }}>
            {currentPerson.display_name != undefined ? currentPerson.display_name : currentPerson.name}
        </Text>
        <ScrollView style={{ maxHeight: maxHeightWindow, width: windowWidth - 60, maxWidth: 450 }}>
            {listSelect.map(item =>
                <CheckBox
                    key={item.id}
                    title={item.title}
                    checked={currentPerson[fieldOption] == item.id ? true : false}
                    checkedIcon='dot-circle-o'
                    uncheckedIcon='circle-o'
                    onPress={() => { selectOption(item); mContext.toggleOverlay(); }}
                />
            )}
        </ScrollView>
        <View style={{ height: 30 }}></View>
        <ButtonCancel onPress={mContext.toggleOverlay} />
    </Overlay>
};

export function MyControlModalMultiSelect(props) {
    let mContext: any = React.useContext(MyContext);
    const currentPerson = mContext.currentPerson;
    const listSelect = mContext.listSelection;
    const listChildren = mContext.listChildren;
    const fieldOption = mContext.fieldOption;
    const fieldOptionNotMy = mContext.fieldOptionNotMy;

    const optionsNotMyPost = currentPerson[fieldOptionNotMy] || [];

    const [newName, setNewName] = React.useState('');

    function addNewBehavior() {
        BehaviorModel.add_quickly({ 'title': newName })
            .then((results) => {
                const data: any = results.data || {};
                const post = data.post || {};
                if (post['id'] != undefined) {
                    const behavior_id = post['id'];
                    const behavior_title = post['title'];
                    const arr = listSelect.filter(item => item.id == behavior_id);
                    if (!isEmpty(arr)) {
                        const list = listSelect.map(item => {
                            if (item.id == behavior_id) {
                                item.checked = true;
                            }
                            return item;
                        })
                        mContext.setListSelection(list);
                    } else {
                        let arr = []
                        listSelect.forEach(element => {
                            arr.push(element);
                        });
                        arr.push({ 'id': behavior_id, 'title': behavior_title });
                        mContext.setListSelection(arr);
                    }

                    checkOptionBehavior({ 'id': behavior_id })
                    setNewName('');
                }
            });
    }

    function checkOptionBehavior(_item) {
        let list = listChildren.map(person => {
            if (person.id == currentPerson.id) {
                let arrCheck = person[`${fieldOption}`] || [];

                if (!isArray(arrCheck)) {
                    arrCheck = JSON.parse(arrCheck);
                }

                if (arrCheck.indexOf(_item.id) < 0) {
                    arrCheck.push(_item.id);
                } else {
                    const index = arrCheck.indexOf(_item.id);
                    if (index > -1) {
                        arrCheck.splice(index, 1);
                    }
                }
                person[`${fieldOption}`] = arrCheck;
                person['checked'] = true;
            }
            return person;
        });
        mContext.setListChildren(list);
    }

    const maxHeight = Platform.OS == 'web' ? windowHeight - 300 : windowHeight - 200;

    return <Overlay
        fullScreen={Platform.OS == 'web' ? false : true}
        isVisible={mContext.visible} onBackdropPress={mContext.toggleOverlay}
    >
        <View style={{ height: maxHeight }}>
            <Text style={{ fontWeight: "bold", margin: 12 }}>
                {currentPerson.display_name != undefined ? currentPerson.display_name : currentPerson.name}
            </Text>

            <ScrollView style={{ maxHeight: windowHeight - 80, width: windowWidth - 60, maxWidth: 450 }}>
                {listSelect.map(item =>
                    !isEmpty(optionsNotMyPost) && optionsNotMyPost.indexOf(item.id) >= 0 ?
                        <CheckBox
                            key={item.id}
                            title={item.title}
                            checked={true}
                            checkedColor='#ccc'
                            textStyle={{ color: "#827b7b", fontWeight: "normal" }}
                        />
                        :
                        <CheckBox
                            key={item.id}
                            title={item.title}
                            checked={!isEmpty(currentPerson[`${fieldOption}`]) && currentPerson[`${fieldOption}`].indexOf(item.id) >= 0 ? true : false}
                            onPress={() => {
                                checkOptionBehavior(item);
                            }}
                        />

                )}
            </ScrollView>
            <Input
                leftIcon={
                    <Icon
                        name="info-circle"
                        type="font-awesome"
                        color="#86939e"
                        size={25}
                    />
                }
                leftIconContainerStyle={styles.leftIconContainerStyle}
                containerStyle={styles.containerStyle}
                placeholder="Hoặc nhập tên cần thêm"
                value={newName}
                onChangeText={text => setNewName(text)}
            />

            {newName != '' && <ButtonAdd onPress={addNewBehavior} />}
            <View style={{ paddingLeft: 12 }}>
                <Text style={{ color: "red", fontWeight: "bold" }}>Chú ý:</Text>
                <Text style={{ color: '#827b7b' }}>
                    Tùy chọn "màu ghi" đã được tích chọn bởi quản trị viên khác! Bạn không thể thay đổi nó!
                </Text>
            </View>
            <View style={{ height: 30 }}></View>
            <ButtonCancel onPress={mContext.toggleOverlay} />
        </View>
    </Overlay>
};


const styles = StyleSheet.create({
    leftIconContainerStyle: {
        marginLeft: 0,
        marginRight: 10,
    },
    containerStyle:
    {
        marginTop: 6,
        width: '100%',
    },
    text: {
        marginTop: 18,
        marginBottom: Platform.OS == 'web' ? -12 : -24,
        color: '#000',
        alignSelf: "flex-start",
        paddingLeft: 12
    },
    textPassword: {
        marginTop: 10,
        marginBottom: Platform.OS == 'web' ? -8 : -14,
        color: '#000',
        alignSelf: "flex-start",
        paddingLeft: 12,
        fontSize: 12
    },
    checkbox: {
        marginTop: 18,
        color: '#000',
        alignSelf: "flex-start",
        paddingLeft: 12
    },
    input: {
        maxWidth: 500,
        alignSelf: "center",
        borderColor: "#000",
        borderWidth: 1,
    },
    error: {
        color: "#f00",
        marginTop: Platform.OS == 'web' ? -8 : -20,
        alignSelf: "flex-start",
        paddingLeft: 12
    }
});