import React, { useState } from "react";
import { StyleSheet, Text, View, ScrollView } from 'react-native';
import { Dialog } from 'react-native-elements';

import { Header } from '../../components/header';

import { MyPaging, BoxOpenShortMenu, MyListingCustomerFamilyCustomer } from "../../components/myboxs";
import { IsLoadingData } from "../../components/myother";

import { MyContext } from '../../context/mycontext';
import { MyControlShortMenuCustomer } from "../../components/mycontrolinputs";

import { UserFamilyModel } from "../../models/models"
import { CUSTOMER_ID_THEMSELF, LANG } from "../../config/config";
const dialogTitle = LANG.HomeFamily;

function FamilyCustomerCustomer({ route, navigation }) {
    const [isLoading, setLoading] = useState<boolean>(false);

    const [taskItems, setTaskItems] = useState([]);

    const [pagingParams, setPagingParams] = useState({});
    const [currentPage, setCurrentPage] = React.useState(1);

    const [openList, setOpenList] = React.useState(true);
    const [openForm, setOpenForm] = React.useState(false);
    const [openButton, setOpenButton] = React.useState(true);

    const [visible, setVisible] = React.useState(true);
    const [visibleDialog, setVisibleDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');

    const [visibleShortMenu, setVisibleShortMenu] = React.useState(false);

    // fields of table
    const [id, setID] = useState('');
    // same as birthday

    function Paging(page = 1) {
        setLoading(true);
        UserFamilyModel.gets(page)
            .then((results) => {
                const data: any = results["data"] || [];
                setTaskItems(data["posts"] || []);
                setPagingParams(data['paging'])
            })
            .catch(err => {
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false));
    }

    async function gets_init(_customer_id = '') {
        let p_posts = UserFamilyModel.gets(1, _customer_id);

        setLoading(true);
        Promise.all([p_posts]).then((values) => {
            const data_posts = values[0]["data"] || [];

            if (__DEV__) {
                console.log('get init: ', data_posts);
            }

            setTaskItems(data_posts["posts"] || []);
            setPagingParams(data_posts['paging'])
        })
            .catch(err => {
                console.log(`${err.message}`);

                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false))
    }


    React.useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            // if screen is focused then reload
            if (__DEV__) {
                console.log('Screen is focused member ' + Math.random());
            }

            gets_init(CUSTOMER_ID_THEMSELF);
        });

        return () => {
            // cleanup
            // Execute before the next effect or unmount
        };
    }, []);


    const [customerID, setCustomerID] = React.useState('');
    const [customerName, setCustomerName] = React.useState('');

    React.useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            // if screen is focused then reload
            if (__DEV__) {
                console.log('Screen is focused buy order: ' + Math.random());
            }

            const { _customer_id, _customer_name } = route.params || [CUSTOMER_ID_THEMSELF, ''];
            if (_customer_id != undefined) {
                console.log('_customer_id: ', _customer_id);
                console.log('_customer_name: ', _customer_name);

                gets_init(_customer_id);

                setCustomerID(_customer_id);
                setCustomerName(_customer_name);
            }
        });

        // cleanup
        return unsubscribe;
    }, [route]);


    return (
        <View style={styles.container}>
            <Header title={dialogTitle} view="chat" navigation={navigation} />

            <Dialog
                isVisible={visibleDialog}
                onBackdropPress={() => setVisibleDialog(!visibleDialog)}
            >
                <Dialog.Title title={dialogTitle} />
                <Text>{dialogMessage}</Text>
            </Dialog>

            {visibleShortMenu && (
                <MyContext.Provider value={
                    {
                        visibleShortMenu,
                        setVisibleShortMenu,

                    }}>
                    <MyControlShortMenuCustomer
                        navigation={navigation}
                        customer_id={customerID}
                        customer_name={customerName}
                    />
                </MyContext.Provider>
            )}


            <ScrollView
                contentContainerStyle={{
                    flexGrow: 1,
                }}
                keyboardShouldPersistTaps="handled"
            >
                {openList && (
                    <>
                        <MyContext.Provider value={{
                            setVisibleShortMenu,
                        }}>
                            <BoxOpenShortMenu
                                customerName={customerName}
                                customerID={customerID}
                                navigation={navigation}
                            />
                        </MyContext.Provider>

                        {isLoading ? (
                            <IsLoadingData />
                        ) : (
                            <MyContext.Provider value={
                                {
                                    taskItems,

                                    pagingParams,
                                    Paging,

                                    currentPage,
                                    setCurrentPage,
                                }}>
                                <MyListingCustomerFamilyCustomer navigation={navigation} customerName={customerName} />
                                <MyPaging />
                            </MyContext.Provider>
                        )}

                    </>
                )}

            </ScrollView >
        </View >
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#E8EAED',
    }
});

export default FamilyCustomerCustomer;
