import React from "react";
import { Text, View, StyleSheet, ScrollView, Pressable } from 'react-native';

import { Header } from '../../components/header';
import { mycolors } from "../../config/mycolors";

function HomeDoctor({ navigation }) {
    return (
        <View style={styles.container}>
            <Header title="Trang Chủ" view="chat" />
            <ScrollView
                contentContainerStyle={{
                    flexGrow: 1,
                    marginTop: 6,
                }}
                keyboardShouldPersistTaps="handled"
            >
                <View style={styles.boxTop}>
                    <Text style={styles.textTop}>Bác Sỹ</Text>
                </View>
                <View style={styles.box}>
                    <Pressable onPress={() => navigation.navigate('ListCustomer')}>
                        <Text style={styles.text}>Khách Hàng</Text>
                    </Pressable>
                </View>
                <View style={styles.box}>
                    <Pressable onPress={() => navigation.navigate('ListNews')}>
                        <Text style={styles.text}>Đăng Tin</Text>
                    </Pressable>
                </View>
                <View style={styles.box}>
                    <Pressable onPress={() => navigation.navigate('ShowNews')}>
                        <Text style={styles.text}>Xem Tin Tức</Text>
                    </Pressable>
                </View>
                <View style={styles.box}>
                    <Pressable onPress={() => navigation.navigate('ReportInternal')}>
                        <Text style={styles.text}>Xem Thống Kê</Text>
                    </Pressable>
                </View>
            </ScrollView>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#E8EAED',
    },
    box: {
        backgroundColor: mycolors.primary,
        marginTop: 6,
        marginBottom: 6,
        marginLeft: 12,
        marginRight: 12,
        padding: 20
    },
    box2: {
        backgroundColor: mycolors.secondary,
        margin: 12,
        padding: 20
    },
    text: {
        color: '#fff'
    },
    boxTop: {
        padding: 12,
        alignItems: "center",
    },
    textTop: {
        color: "#000",
    }
});

export default HomeDoctor;
