import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, ScrollView, Platform, Pressable } from 'react-native';
import { CheckBox, Dialog, Icon, Overlay } from 'react-native-elements';
import { ButtonAdd, ButtonCancel, ButtonDelete, ButtonEdit, ButtonSave, ButtonSearch, CloseButtons, OpenButtons } from '../../components/mybuttons'

import moment from "moment";

import { BookingModel, PersonModel, UserModel } from "../../models/models"

import { Header } from '../../components/header';

import { stylesButtons, stylesCategory, stylesColumns } from "../../config/mystyles";
import { getsMyItemChecked } from "../../config/myfunc";
import { MyPaging, MySelectCalendarSearch, MyDisplayFromToDate, MyListingBook } from "../../components/myboxs";
import { IsLoadingData } from "../../components/myother";

import { MyContext } from '../../context/mycontext';
import { arr_pattern, MyControlCalendar, MyControlDisplayCalendar, MyControlInputText } from "../../components/mycontrolinputs";
import { windowHeight, windowWidth } from "../../config/config";

import { useForm, Controller } from "react-hook-form";
import { MyInputSearchName } from "../../components/myinputs";
import { isEmpty } from "lodash";

function BookingDoctor({ navigation }) {
    const [isLoading, setLoading] = useState<boolean>(false);

    const [taskItems, setTaskItems] = useState([]);

    const [pagingParams, setPagingParams] = useState({});
    const [currentPage, setCurrentPage] = React.useState(1);
    const [listCategory, setListCategory] = useState([]);

    const [searchName, setSearchName] = React.useState('');
    const [visibleSearchCalendar, setVisibleSearchCalendar] = React.useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const [openList, setOpenList] = React.useState(true);
    const [openForm, setOpenForm] = React.useState(false);
    const [openButton, setOpenButton] = React.useState(true);

    const [visible, setVisible] = React.useState(true);
    const [visibleDialog, setVisibleDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');
    const [visibleCalendar, setVisibleCalendar] = React.useState(false);

    const [id, setID] = useState('');

    const dialogTitle = 'Lịch Khám';

    function checkItem(item: any) {
        let arr: any = [];
        taskItems.forEach(el => {
            let el_tg: any = el;
            if (el_tg.id == item.id) {
                if (!el_tg['checked'])
                    el_tg['checked'] = true;
                else
                    el_tg['checked'] = false;
            } else {
                el_tg['checked'] = false;
            }
            arr.push(el_tg);
        });

        setTaskItems(arr);
    };

    function deleteItems() {
        const arr = getsMyItemChecked(taskItems);

        if (arr.length == 0) {
            setVisibleDialog(true);
            setDialogMessage('Chọn ít nhất 1 mục để xóa!');
            return;
        }

        BookingModel.delete_arr({ 'ids': arr })
            .then((results) => {
                const data: any = results.data || {};
                if (data['posts'] != undefined) {
                    setTaskItems(data['posts'] || []);
                    setPagingParams(data['paging'])
                }

                setVisibleDialog(true);
                setDialogMessage('Xóa thành công các mục đã chọn!');
            })
            .catch(err => {
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \nOr Can not connect API, Please login again!`);
            });
    };

    function editItem() {
        let arr: any = getsMyItemChecked(taskItems);
        const lastItem = arr.pop();

        if (lastItem == undefined) {
            setVisibleDialog(true);
            setDialogMessage('Hãy chọn ít nhất 1 mục!');
        } else {
            BookingModel.get(lastItem)
                .then((results) => {
                    if (__DEV__) {
                        console.log('customer book:', results);
                    }
                    const post: any = results.data.post || {};

                    setCurrentCategory({ 'id': post['customer_id'], 'title': post['customer_name'] });

                    setID(post.id);
                    fields.forEach(field => {
                        if (field.Type == 'calendar') {
                            if (field.subFieldHour != undefined) {
                                setValue(`${field.name}`, moment(post[`${field.name}`]).format('YYYY/MM/DD hh:mm:ss'));
                            } else {
                                setValue(`${field.name}`, moment(post[`${field.name}`]).format('YYYY/MM/DD'));
                            }
                        } else {
                            setValue(`${field.name}`, post[`${field.name}`]);
                        }
                    });

                    setOpenForm(true);
                    setOpenList(false);
                    setOpenButton(false);
                })
                .catch(err => {
                    setVisibleDialog(true);
                    setDialogMessage(`${err.message} \nOr Can not connect API, Please login again!`);
                });
        }
    };

    function Paging(page = 1) {
        setLoading(true);
        BookingModel.gets(page)
            .then((results) => {
                const data: any = results["data"] || [];
                setTaskItems(data["posts"] || []);
                setPagingParams(data['paging'])
            })
            .catch(err => {
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \nOr Can not connect API, Please login again!`);
            })
            .finally(() => setLoading(false));
    };

    function Search(search_name = '', from_date = '') {
        setLoading(true);
        BookingModel.gets(1, search_name, from_date)
            .then((results) => {
                const data: any = results["data"] || [];

                if (__DEV__) {
                    console.log('data search:', data);
                }

                setTaskItems(data["posts"] || []);
                setPagingParams(data['paging'])
                setCurrentPage(1);
            })
            .catch(err => {
                console.log(err);
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \nOr Can not connect API, Please login again!`);
            })
            .finally(() => setLoading(false));
    };

    async function gets_init() {
        const p_posts = BookingModel.gets(1);

        let objPersonModel = new PersonModel('customer');
        const p_customer = objPersonModel.gets(1, '');

        setLoading(true);
        Promise.all([p_posts, p_customer]).then((values) => {
            const data_posts = values[0]["data"] || [];
            const data_customer = values[1]["data"] || [];

            if (__DEV__) {
                console.log('data_customer:', data_customer);
                console.log('data_posts:', data_posts);
            }

            setTaskItems(data_posts["posts"] || []);
            setPagingParams(data_posts['paging'])

            let list_customer = data_customer["posts"] || [];
            list_customer = list_customer.map(item => { item['title'] = item['display_name']; return item; })
            setListCategory(list_customer);
        })
            .catch(err => {
                console.log(`${err.message} \nOr Can not connect API, Please login again!`);
            })
            .finally(() => setLoading(false));
    }

    /***
     * begin: react hook form
    */
    const { control, handleSubmit, reset, formState: { errors }, getValues, setValue } = useForm();

    const onSubmit = data => {
        const data_submit = data;

        if (id != '') {
            BookingModel.update(id, data_submit)
                .then((results) => {
                    if (__DEV__) {
                        console.log('customer update book:', results);
                    }

                    const data: any = results.data || {};
                    if (data.post != undefined && data.post.id != undefined) {
                        const obj_task: any = {
                            'id': data.post.id,
                            'title': data.post.title,
                            'date_created': data.post.date_created,
                            'checked': false
                        };
                        let arr: any = [];
                        taskItems.forEach(el => {
                            let el_tg: any = el;
                            if (el_tg['id'] == obj_task.id) {
                                arr.push(obj_task);
                            } else {
                                arr.push(el);
                            }
                        });
                        setTaskItems(arr);

                        setDialogMessage('Cập nhật thành công!');
                    } else {
                        setVisibleDialog(true);
                        setDialogMessage('Bản ghi không được cập nhật!');
                    }

                    setOpenForm(false);
                    setOpenList(true);
                    setOpenButton(true);
                    setVisibleDialog(true);

                    reset();
                })
                .catch(err => {
                    setVisibleDialog(true);
                    setDialogMessage(`${err.message} \nOr Can not connect API, Please login again!`);
                })
                .finally(() => {
                    setID('');
                    setLoading(false);
                });
        } else {
            BookingModel.create(data_submit)
                .then((results) => {
                    const data: any = results.data || {};
                    if (data['posts'] != undefined) {
                        setTaskItems(data['posts'] || []);
                        setPagingParams(data['paging'])

                        setDialogMessage('Thêm mới thành công!');
                    } else {
                        setVisibleDialog(true);
                        setDialogMessage('Không có bản ghi được thêm!');
                    }

                    setOpenForm(false);
                    setOpenList(true);
                    setOpenButton(true);
                    setVisibleDialog(true);

                    reset();
                })
                .catch(err => {
                    setVisibleDialog(true);
                    setDialogMessage(`${err.message} \nOr Can not connect API, Please login again!`);
                })
                .finally(() => {
                    setID('');
                    setLoading(false);
                });
        }
    };

    const myHandleSubmit = () => {
        // convert before submit
        fields.forEach(field => {
            if (field.Type == "calendar") {
                // do anything
            }
            if (field.Type == "image") {
                // do anything
            }
        });

        handleSubmit(onSubmit)();
    }

    const api_fields = [
        {
            name: "date_created",
            lable: "Ngày khám",
            placeholder: "Ngày khám",
            rules: {
                required: true,
                pattern: false,
            },
            icon: {
                name: "calendar",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
            Type: "calendar",
            subFieldHour: true,
            subFieldMinute: true,
        },
        {
            name: "title",
            lable: "Tiêu đề",
            placeholder: "Hãy nhập tiêu đề",
            rules: {
                required: true,
                pattern: false,
            },
            icon: {
                name: "info-circle",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
        },
        {
            name: "customer_id",
            lable: "Khách hàng",
            placeholder: "Chọn khách cần khám",
            rules: {
                pattern: false,
            },
            icon: {
                name: "info-circle",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
            Type: "category"
        },
        {
            name: "content",
            lable: "Triệu chức",
            placeholder: "Hãy nhập triệu chứng",
            rules: {
                required: true,
                pattern: false,
            },
            icon: {
                name: "info-circle",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
            multiline: true,
        },
        {
            name: "diagnose",
            lable: "Chẩn đoán",
            placeholder: "Hãy nhập chẩn đoán",
            rules: {
                required: true,
                pattern: false,
            },
            icon: {
                name: "info-circle",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
            multiline: true,
        },
    ];

    const arr_validate = {
        validate_password: {
            matchesPreviousPassword: (value) => {
                const { password } = getValues();
                return password === value || "Mật khẩu phải khớp!";
            }
        }
    };

    let column = {};
    let row = {};
    if (windowWidth <= 768) {
        column = stylesColumns.columnSmall;
        row = stylesColumns.rowSmall;
    } else if (windowWidth >= 769) {
        // column = stylesColumns.columnMedium;
        // row = stylesColumns.rowMedium;
    }

    const [fields, setFields] = React.useState([]);
    const [subFieldHour, setSubFieldHour] = React.useState(false);
    const [subFieldMinute, setSubFieldMinute] = React.useState(false);

    const [currentCalendar, setCurrentCalendar] = React.useState('');
    const changeCalendarFieldValue = (_fieldname, _value) => {
        setValue(_fieldname, _value);
    };

    const [visibleCategory, setVisibleCategory] = React.useState(false);

    /***
     * end: react hook form
    */

    const [currentCategory, setCurrentCategory] = React.useState({ id: '', title: '' });
    const selectCategory = (_category) => {
        setCurrentCategory(_category);
    }


    useEffect(() => {
        setFields(api_fields);

        const unsubscribe = navigation.addListener('focus', () => {
            // if screen is focused then reload
            console.log('Screen is focused post.admin ' + Math.random());
            gets_init();
        });

        return () => {
            // cleanup
            // Execute before the next effect or unmount
        };
    }, []);

    return (
        <View style={styles.container}>
            <Header title={dialogTitle} view="chat" navigation={navigation} />

            <Dialog
                isVisible={visibleDialog}
                onBackdropPress={() => setVisibleDialog(!visibleDialog)}
            >
                <Dialog.Title title={dialogTitle} />
                <Text>{dialogMessage}</Text>
            </Dialog>

            {visibleCalendar && (
                <MyContext.Provider value={
                    {
                        changeCalendarFieldValue,
                        visibleCalendar,
                        setVisibleCalendar,

                    }}>
                    <MyControlCalendar
                        currentCalendar={currentCalendar}
                        currentValueCalendar={getValues(currentCalendar)}
                        subFieldHour={subFieldHour}
                        subFieldMinute={subFieldMinute}
                    />
                </MyContext.Provider>
            )}

            {visibleCategory && !isEmpty(listCategory) && (
                <Overlay
                    fullScreen={Platform.OS == 'web' ? false : true}
                    isVisible={visibleCategory}
                    onBackdropPress={() => setVisibleCategory(!visibleCategory)}
                >
                    {currentCategory.id != '' && (
                        <View style={{ flexDirection: "row" }}>
                            <Text style={{ margin: 12, marginBottom: 6, fontWeight: "bold" }}>Bỏ chọn chuyên mục(bấm)</Text>
                            <Icon
                                name="remove"
                                type="font-awesome"
                                color="#f00"
                                size={22}
                                iconStyle={{ marginTop: 10 }}
                                onPress={() => {
                                    setVisibleCategory(!visibleCategory);
                                    setCurrentCategory({ id: '', title: '' });
                                }}
                            />
                        </View>
                    )}
                    <Text style={{ margin: 12, marginBottom: 6, fontWeight: "bold" }}>Chọn chuyên mục:</Text>
                    <ScrollView style={Platform.OS == 'web' && { maxHeight: windowHeight - 240 }}>
                        <View style={Platform.OS == 'web' && { width: 350 }}>
                            <View style={{ ...stylesCategory.row, width: "auto" }}>
                                {listCategory.map((item, index) =>
                                    <CheckBox
                                        key={item.id}
                                        title={item.title}
                                        checked={currentCategory.id == item.id ? true : false}
                                        checkedIcon='dot-circle-o'
                                        uncheckedIcon='circle-o'
                                        onPress={() => {
                                            setVisibleCategory(!visibleCategory);
                                            setCurrentCategory(item);
                                            setValue('customer_id', item.id);
                                        }}
                                    />
                                )}
                            </View>
                        </View>
                    </ScrollView>
                    <View style={{ marginTop: 12 }}>
                        <ButtonCancel onPress={() => setVisibleCategory(!visibleCategory)} />
                    </View>
                </Overlay>
            )}

            <ScrollView
                contentContainerStyle={{
                    flexGrow: 1,
                }}
                keyboardShouldPersistTaps="handled"
            >
                {openForm && (
                    <View style={styles.box}>
                        <View style={row}>
                            {fields.map((field, key) => {
                                switch (field.Type) {
                                    case "calendar":
                                        return (
                                            <View key={key} style={column}>
                                                <Controller
                                                    control={control}
                                                    render={({ field: { value } }) => (
                                                        <Pressable
                                                            style={{ width: 280 }}
                                                            onPress={() => {
                                                                setVisibleCalendar(true);
                                                                setCurrentCalendar(field.name);
                                                                if (field.subFieldHour != undefined) {
                                                                    setSubFieldHour(true);
                                                                } else {
                                                                    setSubFieldHour(null);
                                                                }
                                                                if (field.subFieldMinute != undefined) {
                                                                    setSubFieldMinute(true);
                                                                } else {
                                                                    setSubFieldMinute(null);
                                                                }
                                                            }}
                                                        >
                                                            <MyControlDisplayCalendar
                                                                value={getValues(`${field.name}`)}
                                                                placeholder={field.placeholder}
                                                            />
                                                        </Pressable>
                                                    )}
                                                    name={field.name}
                                                />
                                                {errors[field.name] && <Text style={styles.error}>
                                                    {field.rules.required == true ? "Trường này là bắt buộc" : field.rules.required}
                                                </Text>}
                                            </View>
                                        );
                                        break;
                                    case "category":
                                        return (
                                            <View key={key} style={column}>
                                                <Text style={styles.text}> {field.lable}:</Text>
                                                <Pressable
                                                    style={{ flexDirection: "row", alignSelf: "flex-start", marginTop: 30 }}
                                                    onPress={() => { setVisibleCategory(true) }}>
                                                    <Icon
                                                        name="info-circle"
                                                        type="font-awesome"
                                                        color="#86939e"
                                                        size={25}
                                                        style={{ marginLeft: 12 }}
                                                    />
                                                    <Text style={{ minWidth: 30, marginLeft: 20, marginRight: 6 }}>
                                                        {currentCategory.title != "" ? currentCategory.title : "Chọn khách hàng"}
                                                    </Text>
                                                    <Icon
                                                        name="caret-down"
                                                        type="font-awesome"
                                                        color="#86939e"
                                                        size={22}
                                                        iconStyle={{}}
                                                    />
                                                </Pressable>
                                            </View>
                                        );
                                        break;
                                    default:
                                        return (
                                            <View key={key} style={column}>
                                                <Text style={styles.text}>{field.lable}:</Text>
                                                <MyControlInputText
                                                    field={field}
                                                    control={control}
                                                    errors={errors}
                                                    pattern={field.rules.pattern != undefined ? arr_pattern[field.rules.pattern] : ''}
                                                    validate={field.rules.validate != undefined ? arr_validate[field.rules.validate] : ''}
                                                />
                                            </View>
                                        );
                                        break;
                                }
                            })}
                        </View>

                        <View style={{ flexDirection: "row", alignSelf: "center", marginTop: 30 }}>
                            {isLoading == true ? (
                                <IsLoadingData title="Đang cập nhật nội dung" />
                            ) : (
                                <>
                                    <ButtonCancel onPress={() => { setOpenForm(false); setOpenButton(true); setOpenList(true); }} />
                                    <ButtonSave onPress={() => myHandleSubmit()} />
                                </>
                            )}
                        </View>
                    </View>
                )}

                {openList && (
                    <>
                        <View style={{ flexDirection: "row", alignSelf: "center", marginTop: 12 }}>
                            <MyContext.Provider value={{
                                Search,
                                searchName,
                                setSearchName,
                            }}>
                                <MyInputSearchName />
                            </MyContext.Provider>

                            <MyContext.Provider value={{
                                visibleSearchCalendar,
                                setVisibleSearchCalendar,

                                fromDate,
                                setFromDate,

                                toDate,
                                setToDate,
                            }}>
                                <MySelectCalendarSearch />
                            </MyContext.Provider>

                            <ButtonSearch onPress={() => Search(searchName, fromDate)} />
                        </View>

                        <MyContext.Provider value={{
                            fromDate,
                            setFromDate,

                            toDate,
                            setToDate,
                        }}>
                            <MyDisplayFromToDate />
                        </MyContext.Provider>

                        {isLoading ? (
                            <IsLoadingData />
                        ) : (
                            <MyContext.Provider value={
                                {
                                    taskItems,
                                    checkItem,

                                    pagingParams,
                                    Paging,

                                    currentPage,
                                    setCurrentPage,
                                }}>
                                <MyListingBook />
                                <MyPaging />
                            </MyContext.Provider>
                        )}
                    </>
                )}

            </ScrollView>

            {openButton && (
                <View style={{ flexDirection: "row", alignSelf: "center", "position": "absolute", bottom: 5 }}>
                    <ButtonAdd onPress={() => { setOpenForm(true); setOpenButton(false); setOpenList(false); }} />
                    <ButtonEdit onPress={() => editItem()} />
                    <ButtonDelete onPress={() => deleteItems()} />
                </View>
            )}

            <View style={stylesButtons.open_close}>
                {visible == true ? (
                    <OpenButtons onPress={() => { setOpenButton(false); setVisible(false); }} />
                ) : (
                    <CloseButtons onPress={() => { setOpenButton(true); setVisible(true); }} />
                )}
            </View>

        </View >
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#E8EAED',
    },
    box: {

    },
    leftIconContainerStyle: {
        marginLeft: 0,
        marginRight: 10,
    },
    containerStyle:
    {
        marginTop: 6,
        width: '100%',
    },
    text: {
        marginTop: 18,
        marginBottom: Platform.OS == 'web' ? -12 : -24,
        color: '#000',
        alignSelf: "flex-start",
        paddingLeft: 12
    },
    input: {
        maxWidth: 500,
        alignSelf: "center",
        borderColor: "#000",
        borderWidth: 1,
    },
    error: {
        color: "#f00",
        marginTop: Platform.OS == 'web' ? -8 : -20,
        alignSelf: "flex-start",
        paddingLeft: 12
    }
});

export default BookingDoctor;
