import axios from "axios";
import { Base64, config } from "../config/config";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { Platform } from "react-native";
import Constants from "expo-constants";
import * as Notifications from "expo-notifications";

const setConfigGet = (jwt_token) => {
  const configGet = {
    headers: {
      "x-access-token": `${jwt_token}`,
    },
  };

  return configGet;
};

const setConfigPost = (jwt_token) => {
  const configPost = {
    headers: {
      // 'Content-Type': 'application/x-www-form-urlencoded',
      "Content-Type": "application/json",
      "x-access-token": `${jwt_token}`,
    },
    withCredentials: false,
  };

  return configPost;
};

export async function registerForPushNotificationsAsync(_user_id = "") {
  let token;
  if (Constants.isDevice) {
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== "granted") {
      alert("Không nhận được mã thông báo đẩy!");
      return;
    }
    token = (await Notifications.getExpoPushTokenAsync()).data;
    if (__DEV__) {
      console.log(`My pushnotification token: ${token}`);
    }
  } else {
    alert("Phải sử dụng thỉết bị vật lý cho thông báo đẩy!");
  }

  if (Platform.OS === "android") {
    Notifications.setNotificationChannelAsync("default", {
      name: "default",
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: "#FF231F7C",
    });
  }

  return token;
}

export async function sendPushNotification(expoPushToken) {
  // Can use this function below, OR use Expo's Push Notification Tool-> https://expo.dev/notifications
  const message = {
    to: expoPushToken,
    sound: "default",
    title: "Phòng Khám ĐT - Bạn có tin nhắn mới",
    body: "Đến văn phòng ngay và luôn em nhé!",
  };

  await fetch("https://exp.host/--/api/v2/push/send", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Accept-encoding": "gzip, deflate",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(message),
  });

  console.log(JSON.stringify(message));
}

export class PushnotificationModel {
  static async save(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/notification/add_token`,
      data,
      configPost
    );
  }

  static async add_message_one_user(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/notification/add_message_one_user`,
      data,
      configPost
    );
  }

  static async add_message_all_user(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/notification/add_message_all_user`,
      data,
      configPost
    );
  }

  static async run_schedule() {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    return axios.get(`${config.apiUrl}/notification/send_message`, configGet);
  }
}

export class DownloadModel {
  static async create_file() {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    let url = `${config.apiUrl}/download/create_file`;
    url = `${url}`;

    return axios.get(url, configGet);
  }

  static full_file(file = "") {
    return `${config.apiUrl}/download/file/${file}`;
  }
}

export class TodoListModel {
  static async gets() {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    return axios.get(`${config.apiUrl}/todo`, configGet);
  }

  static async get() {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    return axios.get(`${config.apiUrl}/todo`, configGet);
  }

  static async create(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/todo`, data, configPost);
  }

  static async delete(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/todo`, data, configPost);
  }

  static async update(id, data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/product/update/${id}`,
      data,
      configPost
    );
  }

  static async upload(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/test_upload`, data, configPost);
  }
}

export class CategoryModel {
  static async gets(page = 1, search_name = "", limit = 5) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);
    let args = `page=${page}`;
    if (search_name != undefined && search_name != "") {
      args = `${args}&search_name=${search_name}`;
    }
    if (limit != undefined && limit > 0) {
      args = `${args}&limit=${limit}`;
    }
    const url = `${config.apiUrl}/category?${args}`;
    return axios.get(url, configGet);
  }

  static async get(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    return axios.get(`${config.apiUrl}/category/${id}`, configGet);
  }

  static async create(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/category/add`, data, configPost);
  }

  static async update(id, data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/category/update/${id}`,
      data,
      configPost
    );
  }

  static async delete(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/category/delete/${id}`, configPost);
  }

  static async delete_arr(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/category/delete_arr`, data, configPost);
  }
}

export class PaymentModel {
  static async gets(
    customer_id = "",
    user_family_id = "",
    page = 1,
    search_name = "",
    from_date = "",
    to_date = "",
    limit = 9
  ) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);
    let args = `page=${page}`;

    if (customer_id != undefined && customer_id != "") {
      args = `${args}&customer_id=${customer_id}`;
    }
    if (user_family_id != undefined && user_family_id != "") {
      args = `${args}&user_family_id=${user_family_id}`;
    }

    if (search_name != undefined && search_name != "") {
      args = `${args}&search_name=${search_name}`;
    }
    if (from_date != undefined && from_date != "") {
      args = `${args}&from_date=${from_date}`;
    }
    if (to_date != undefined && to_date != "") {
      args = `${args}&to_date=${to_date}`;
    }

    if (limit != undefined && limit > 0) {
      args = `${args}&limit=${limit}`;
    }
    const url = `${config.apiUrl}/payment?${args}`;
    return axios.get(url, configGet);
  }

  static async gets_report(
    page = 1,
    search_name = "",
    from_date = "",
    to_date = "",
    limit = 9
  ) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);
    let args = `page=${page}`;

    if (search_name != undefined && search_name != "") {
      args = `${args}&search_name=${search_name}`;
    }
    if (from_date != undefined && from_date != "") {
      args = `${args}&from_date=${from_date}`;
    }
    if (to_date != undefined && to_date != "") {
      args = `${args}&to_date=${to_date}`;
    }

    if (limit != undefined && limit > 0) {
      args = `${args}&limit=${limit}`;
    }
    const url = `${config.apiUrl}/payment/report?${args}`;
    return axios.get(url, configGet);
  }

  static async get(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    return axios.get(`${config.apiUrl}/payment/${id}`, configGet);
  }

  static async create(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/payment/add`, data, configPost);
  }

  static async update(id, data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/payment/update/${id}`,
      data,
      configPost
    );
  }

  static async delete(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/payment/delete/${id}`, configPost);
  }

  static async delete_arr(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/payment/delete_arr`, data, configPost);
  }
}

export class SaleServiceModel {
  static async gets(
    customer_id = "",
    user_family_id = "",
    page = 1,
    search_name = "",
    from_date = "",
    to_date = "",
    limit = 9
  ) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);
    let args = `page=${page}`;

    if (customer_id != undefined && customer_id != "") {
      args = `${args}&customer_id=${customer_id}`;
    }
    if (user_family_id != undefined && user_family_id != "") {
      args = `${args}&user_family_id=${user_family_id}`;
    }

    if (search_name != undefined && search_name != "") {
      args = `${args}&search_name=${search_name}`;
    }
    if (from_date != undefined && from_date != "") {
      args = `${args}&from_date=${from_date}`;
    }
    if (to_date != undefined && to_date != "") {
      args = `${args}&to_date=${to_date}`;
    }

    if (limit != undefined && limit > 0) {
      args = `${args}&limit=${limit}`;
    }
    const url = `${config.apiUrl}/sale_service?${args}`;
    return axios.get(url, configGet);
  }

  static async gets_report(
    page = 1,
    search_name = "",
    from_date = "",
    to_date = "",
    limit = 9
  ) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);
    let args = `page=${page}`;

    if (search_name != undefined && search_name != "") {
      args = `${args}&search_name=${search_name}`;
    }
    if (from_date != undefined && from_date != "") {
      args = `${args}&from_date=${from_date}`;
    }
    if (to_date != undefined && to_date != "") {
      args = `${args}&to_date=${to_date}`;
    }

    if (limit != undefined && limit > 0) {
      args = `${args}&limit=${limit}`;
    }
    const url = `${config.apiUrl}/sale_service/report?${args}`;
    return axios.get(url, configGet);
  }

  static async get(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    return axios.get(`${config.apiUrl}/sale_service/${id}`, configGet);
  }

  static async create(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/sale_service/add`, data, configPost);
  }

  static async update(id, data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/sale_service/update/${id}`,
      data,
      configPost
    );
  }

  static async delete(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/sale_service/delete/${id}`, configPost);
  }

  static async delete_arr(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/sale_service/delete_arr`,
      data,
      configPost
    );
  }
}
export class ServiceModel {
  static async gets(
    page = 1,
    search_name = "",
    from_date = "",
    category_id = "",
    limit = 18
  ) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);
    let args = `page=${page}`;
    if (search_name != undefined && search_name != "") {
      args = `${args}&search_name=${search_name}`;
    }
    if (from_date != undefined && from_date != "") {
      args = `${args}&from_date=${from_date}`;
    }
    if (category_id != undefined && category_id != "") {
      args = `${args}&category_id=${category_id}`;
    }
    if (limit != undefined && limit > 0) {
      args = `${args}&limit=${limit}`;
    }

    const url = `${config.apiUrl}/service?${args}`;
    return axios.get(url, configGet);
  }

  static async get(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    return axios.get(`${config.apiUrl}/service/${id}`, configGet);
  }

  static async create(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/service/add`, data, configPost);
  }

  static async update(id, data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/service/update/${id}`,
      data,
      configPost
    );
  }

  static async delete(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/service/delete/${id}`, configPost);
  }

  static async delete_arr(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/service/delete_arr`, data, configPost);
  }
}

export class ServiceCategoryModel {
  static async gets(page = 1, search_name = "", limit = 9) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);
    let args = `page=${page}`;
    if (search_name != undefined && search_name != "") {
      args = `${args}&search_name=${search_name}`;
    }
    if (limit != undefined && limit > 0) {
      args = `${args}&limit=${limit}`;
    }
    const url = `${config.apiUrl}/service_category?${args}`;
    return axios.get(url, configGet);
  }

  static async get(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    return axios.get(`${config.apiUrl}/service_category/${id}`, configGet);
  }

  static async create(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/service_category/add`,
      data,
      configPost
    );
  }

  static async update(id, data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/service_category/update/${id}`,
      data,
      configPost
    );
  }

  static async delete(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/service_category/delete/${id}`,
      configPost
    );
  }

  static async delete_arr(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/service_category/delete_arr`,
      data,
      configPost
    );
  }
}

export class ProductCategoryModel {
  static async gets(page = 1, search_name = "", limit = 9) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);
    let args = `page=${page}`;
    if (search_name != undefined && search_name != "") {
      args = `${args}&search_name=${search_name}`;
    }
    if (limit != undefined && limit > 0) {
      args = `${args}&limit=${limit}`;
    }
    const url = `${config.apiUrl}/product_category?${args}`;
    return axios.get(url, configGet);
  }

  static async get(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    return axios.get(`${config.apiUrl}/product_category/${id}`, configGet);
  }

  static async create(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/product_category/add`,
      data,
      configPost
    );
  }

  static async update(id, data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/product_category/update/${id}`,
      data,
      configPost
    );
  }

  static async delete(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/product_category/delete/${id}`,
      configPost
    );
  }

  static async delete_arr(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/product_category/delete_arr`,
      data,
      configPost
    );
  }
}

export class ProductModel {
  static async gets(
    page = 1,
    search_name = "",
    from_date = "",
    category_id = ""
  ) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);
    let args = `page=${page}`;
    if (search_name != undefined && search_name != "") {
      args = `${args}&search_name=${search_name}`;
    }
    if (from_date != undefined && from_date != "") {
      args = `${args}&from_date=${from_date}`;
    }
    if (category_id != undefined && category_id != "") {
      args = `${args}&category_id=${category_id}`;
    }

    const url = `${config.apiUrl}/product?${args}`;
    return axios.get(url, configGet);
  }

  static async get(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    return axios.get(`${config.apiUrl}/product/${id}`, configGet);
  }

  static async create(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/product/add`, data, configPost);
  }

  static async update(id, data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/product/update/${id}`,
      data,
      configPost
    );
  }

  static async delete(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/product/delete/${id}`, configPost);
  }

  static async delete_arr(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/product/delete_arr`, data, configPost);
  }
}

export class SupplierModel {
  static async gets(page = 1, search_name = "", from_date = "") {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);
    let args = `page=${page}`;
    if (search_name != undefined && search_name != "") {
      args = `${args}&search_name=${search_name}`;
    }
    if (from_date != undefined && from_date != "") {
      args = `${args}&from_date=${from_date}`;
    }

    const url = `${config.apiUrl}/supplier?${args}`;
    return axios.get(url, configGet);
  }

  static async get(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    return axios.get(`${config.apiUrl}/supplier/${id}`, configGet);
  }

  static async create(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/supplier/add`, data, configPost);
  }

  static async update(id, data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/supplier/update/${id}`,
      data,
      configPost
    );
  }

  static async delete(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/supplier/delete/${id}`, configPost);
  }

  static async delete_arr(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/supplier/delete_arr`, data, configPost);
  }
}
export class BuyModel {
  static async gets(
    supplier_id = "",
    page = 1,
    search_name = "",
    from_date = "",
    limit = 9
  ) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);
    let args = `page=${page}`;

    if (supplier_id != undefined && supplier_id != "") {
      args = `${args}&supplier_id=${supplier_id}`;
    }
    if (search_name != undefined && search_name != "") {
      args = `${args}&search_name=${search_name}`;
    }
    if (from_date != undefined && from_date != "") {
      args = `${args}&from_date=${from_date}`;
    }

    if (limit != undefined && limit > 0) {
      args = `${args}&limit=${limit}`;
    }
    const url = `${config.apiUrl}/buy_order?${args}`;
    return axios.get(url, configGet);
  }

  static async get(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    return axios.get(`${config.apiUrl}/buy_order/${id}`, configGet);
  }

  static async create(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/buy_order/add`, data, configPost);
  }

  static async update(id, data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/buy_order/update/${id}`,
      data,
      configPost
    );
  }

  static async delete(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/buy_order/delete/${id}`, configPost);
  }

  static async delete_arr(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/buy_order/delete_arr`,
      data,
      configPost
    );
  }
}

export class SaleModel {
  static async gets(
    customer_id = "",
    user_family_id = "",
    page = 1,
    search_name = "",
    from_date = "",
    category_id = "",
    limit = 9
  ) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);
    let args = `page=${page}`;
    if (customer_id != undefined && customer_id != "") {
      args = `${args}&customer_id=${customer_id}`;
    }
    if (user_family_id != undefined && user_family_id != "") {
      args = `${args}&user_family_id=${user_family_id}`;
    }

    if (search_name != undefined && search_name != "") {
      args = `${args}&search_name=${search_name}`;
    }
    if (from_date != undefined && from_date != "") {
      args = `${args}&from_date=${from_date}`;
    }
    if (category_id != undefined && category_id != "") {
      args = `${args}&category_id=${category_id}`;
    }

    if (limit != undefined && limit > 0) {
      args = `${args}&limit=${limit}`;
    }
    const url = `${config.apiUrl}/sale_order?${args}`;
    return axios.get(url, configGet);
  }

  static async gets_report(
    page = 1,
    search_name = "",
    from_date = "",
    to_date = "",
    limit = 9
  ) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);
    let args = `page=${page}`;

    if (search_name != undefined && search_name != "") {
      args = `${args}&search_name=${search_name}`;
    }
    if (from_date != undefined && from_date != "") {
      args = `${args}&from_date=${from_date}`;
    }
    if (to_date != undefined && to_date != "") {
      args = `${args}&to_date=${to_date}`;
    }

    if (limit != undefined && limit > 0) {
      args = `${args}&limit=${limit}`;
    }
    const url = `${config.apiUrl}/sale_order/report?${args}`;
    return axios.get(url, configGet);
  }

  static async get(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    return axios.get(`${config.apiUrl}/sale_order/${id}`, configGet);
  }

  static async create(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/sale_order/add`, data, configPost);
  }

  static async update(id, data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/sale_order/update/${id}`,
      data,
      configPost
    );
  }

  static async delete(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/sale_order/delete/${id}`, configPost);
  }

  static async delete_arr(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/sale_order/delete_arr`,
      data,
      configPost
    );
  }
}

export class WebAPIModel {
  static async get_all() {
    return axios.get(`${config.apiUrl}/get_data_web_for_app`);
  }

  static async get_news(page = 1, limit = 5) {
    let args = `page=${page}`;
    if (limit != undefined && limit > 0) {
      args = `${args}&limit=${limit}`;
    }
    const url = `${config.apiUrl}/get_news?${args}`;
    return axios.get(url, { timeout: 1000 });
  }

  static async get_categories() {
    const url = `${config.apiUrl}/get_categories`;
    return axios.get(url, { timeout: 1000 });
  }
}
export class PostModel {
  static async gets(page = 1, search_name = "", from_date = "") {
    let args = `page=${page}`;
    if (search_name != undefined && search_name != "") {
      args = `${args}&search_name=${search_name}`;
    }
    if (from_date != undefined && from_date != "") {
      args = `${args}&from_date=${from_date}`;
    }
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);
    const url = `${config.apiUrl}/post?${args}`;
    return axios.get(url, configGet);
  }

  static async gets_to_show(
    category_id = "",
    page = 1,
    search_name = "",
    from_date = ""
  ) {
    // have permission to show and from web
    let args = `page=${page}`;
    if (category_id != undefined && category_id != "") {
      args = `${args}&category_id=${category_id}`;
    }
    if (search_name != undefined && search_name != "") {
      args = `${args}&search_name=${search_name}`;
    }
    if (from_date != undefined && from_date != "") {
      args = `${args}&from_date=${from_date}`;
    }    
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);
    const url = `${config.apiUrl}/gets_to_show?${args}`;
    return axios.get(url, configGet);
  }

  static async get(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);
    return axios.get(`${config.apiUrl}/post/${id}`, configGet);
  }

  static async get_to_show(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);
    return axios.get(`${config.apiUrl}/get_to_show/${id}`, configGet);
  }

  static async create(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/post/add`, data, configPost);
  }

  static async update(id, data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/post/update/${id}`, data, configPost);
  }

  static async delete(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/post/delete/${id}`, configPost);
  }

  static async delete_arr(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/post/delete_arr`, data, configPost);
  }
}

export class ReportModel {
  static async gets(page = 1, search_name = "", from_date = "", to_date = "") {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    let args = `page=${page}`;
    if (search_name != undefined && search_name != "") {
      args = `${args}&search_name=${search_name}`;
    }
    if (from_date != undefined && from_date != "") {
      args = `${args}&from_date=${from_date}`;
    }
    if (to_date != undefined && to_date != "") {
      args = `${args}&to_date=${to_date}`;
    }

    const url = `${config.apiUrl}/report?${args}`;
    return axios.get(url, configGet);
  }

  static async for_customer(from_date = "", to_date = "") {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    let args = ``;
    if (from_date != undefined && from_date != "") {
      args = `${args}&from_date=${from_date}`;
    }
    if (to_date != undefined && to_date != "") {
      args = `${args}&to_date=${to_date}`;
    }

    const url = `${config.apiUrl}/report/for_customer?${args}`;
    return axios.get(url, configGet);
  }
}

export class BehaviorModel {
  static async gets(page = 1, limit = 99) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    let args = `page=${page}`;
    if (limit != undefined && limit > 0) {
      args = `${args}&limit=${limit}`;
    }
    const url = `${config.apiUrl}/behavior?${args}`;
    return axios.get(url, configGet);
  }

  static async add_quickly(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/behavior/add_quickly`,
      data,
      configPost
    );
  }
}

export class GoingModel {
  static async gets(page = 1, limit = 99) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    let args = `page=${page}`;
    if (limit != undefined && limit > 0) {
      args = `${args}&limit=${limit}`;
    }
    const url = `${config.apiUrl}/going?${args}`;
    return axios.get(url, configGet);
  }
}

export class ClassRoomModel {
  static async gets(page = 1, search_name = "", from_date = "", limit = 9) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    let args = `page=${page}`;
    if (search_name != undefined && search_name != "") {
      args = `${args}&search_name=${search_name}`;
    }
    if (from_date != undefined && from_date != "") {
      args = `${args}&from_date=${from_date}`;
    }
    if (limit != undefined && limit > 0) {
      args = `${args}&limit=${limit}`;
    }
    const url = `${config.apiUrl}/classroom?${args}`;
    return axios.get(url, configGet);
  }

  static async gets_of_member(page = 1, limit = 99) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    let args = `page=${page}`;
    if (limit != undefined && limit > 0) {
      args = `${args}&limit=${limit}`;
    }
    const url = `${config.apiUrl}/classroom/of_member?${args}`;
    return axios.get(url, configGet);
  }

  static async gets_of_teacher(page = 1, limit = 99) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    let args = `page=${page}`;
    if (limit != undefined && limit > 0) {
      args = `${args}&limit=${limit}`;
    }
    const url = `${config.apiUrl}/classroom/of_teacher?${args}`;
    return axios.get(url, configGet);
  }

  static async for_teachervip(page = 1, limit = 1) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    let args = `page=${page}`;
    if (limit != undefined && limit > 0) {
      args = `${args}&limit=${limit}`;
    }
    const url = `${config.apiUrl}/classroom/for_teachervip?${args}`;
    return axios.get(url, configGet);
  }

  static async get(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    return axios.get(`${config.apiUrl}/classroom/${id}`, configGet);
  }

  static async create(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/classroom/add`, data, configPost);
  }

  static async update(id, data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/classroom/update/${id}`,
      data,
      configPost
    );
  }

  static async delete(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/classroom/delete/${id}`, configPost);
  }

  static async delete_arr(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/classroom/delete_arr`,
      data,
      configPost
    );
  }
}

export class BookingModel {
  static async gets(
    customer_id = "",
    user_family_id = "",
    page = 1,
    search_name = "",
    from_date = "",
    to_date = ""
  ) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);
    let args = `page=${page}`;
    if (customer_id != undefined && customer_id != "") {
      args = `${args}&customer_id=${customer_id}`;
    }
    if (user_family_id != undefined && user_family_id != "") {
      args = `${args}&user_family_id=${user_family_id}`;
    }

    if (search_name != undefined && search_name != "") {
      args = `${args}&search_name=${search_name}`;
    }
    if (from_date != undefined && from_date != "") {
      args = `${args}&from_date=${from_date}`;
    }
    if (to_date != undefined && to_date != "") {
      args = `${args}&to_date=${to_date}`;
    }

    const url = `${config.apiUrl}/book?${args}`;
    return axios.get(url, configGet);
  }

  static async get(id = "") {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    return axios.get(`${config.apiUrl}/book/${id}`, configGet);
  }

  static async create(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/book/add`, data, configPost);
  }

  static async update(id, data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/book/update/${id}`, data, configPost);
  }

  static async reply(parent_id, data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/book/reply/${parent_id}`,
      data,
      configPost
    );
  }

  static async delete(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/book/delete/${id}`, configPost);
  }

  static async delete_arr(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/book/delete_arr`, data, configPost);
  }
}

export class PersonModel {
  // this class no static function
  typeMember: string;
  constructor(_type_member) {
    this.typeMember = _type_member;
  }

  async gets(page = 1, search_name = "", from_date = "", teachervip = "") {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);
    let args = `page=${page}`;

    args = `${args}&type_member=${this.typeMember}`;

    if (teachervip != undefined && teachervip != "") {
      args = `${args}&teachervip=${teachervip}`;
    }

    if (search_name != undefined && search_name != "") {
      args = `${args}&search_name=${search_name}`;
    }
    if (from_date != undefined && from_date != "") {
      args = `${args}&from_date=${from_date}`;
    }
    const url = `${config.apiUrl}/user?${args}`;
    return axios.get(url, configGet);
  }

  async create(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    const url = `${config.apiUrl}/user/add?type_member=${this.typeMember}`;
    return axios.post(url, data, configPost);
  }

  async get(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    const url = `${config.apiUrl}/user/${id}?type_member=${this.typeMember}`;
    return axios.get(url, configGet);
  }

  async update(id, data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    const url = `${config.apiUrl}/user/update/${id}?type_member=${this.typeMember}`;
    return axios.post(url, data, configPost);
  }

  async delete(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    const url = `${config.apiUrl}/user/delete/${id}?type_member=${this.typeMember}`;
    return axios.post(url, configPost);
  }

  async delete_arr(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    const url = `${config.apiUrl}/user/delete_arr?type_member=${this.typeMember}`;
    return axios.post(url, data, configPost);
  }

  async activate(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    const url = `${config.apiUrl}/user/activate/${id}?type_member=${this.typeMember}`;
    return axios.get(url, configGet);
  }
}

export class UserFamilyModel {
  static async gets(page = 1, customer_id = "") {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);
    let args = `page=${page}`;
    if (customer_id != undefined && customer_id != "") {
      args = `${args}&customer_id=${customer_id}`;
    }
    const url = `${config.apiUrl}/user_family?${args}`;
    return axios.get(url, configGet);
  }

  static async get(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    return axios.get(`${config.apiUrl}/user_family/${id}`, configGet);
  }

  static async create(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/user_family/add`, data, configPost);
  }

  static async update(id, data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/user_family/update/${id}`,
      data,
      configPost
    );
  }

  static async delete(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/user_family/delete/${id}`, configPost);
  }

  static async delete_arr(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/user_family/delete_arr`,
      data,
      configPost
    );
  }
}

export class UserModel extends PersonModel {
  // this class static function

  static async gets_roles() {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    let url = `${config.apiUrl}/roles`;
    return axios.get(url, configGet);
  }

  static async register(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/user/register`, data, configPost);
  }

  static async login(data = { username: "", password: "" }) {
    let jwt_authorization_api = data.username + ":" + data.password;
    jwt_authorization_api = "Basic " + Base64.btoa(jwt_authorization_api);

    return await axios.get(`${config.apiUrl}/login`, {
      headers: {
        Authorization: jwt_authorization_api,
      },
    });
  }

  static async get_myself() {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    return axios.get(`${config.apiUrl}/user/myself`, configGet);
  }

  static async update_myself(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/user/update_myself`, data, configPost);
  }

  static async change_password(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/user/change_password`,
      data,
      configPost
    );
  }
}

export class UserMainModel {
  static async gets(page = 1, search_name = "", from_date = "") {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);
    let args = `page=${page}`;

    if (search_name != undefined && search_name != "") {
      args = `${args}&search_name=${search_name}`;
    }
    if (from_date != undefined && from_date != "") {
      args = `${args}&from_date=${from_date}`;
    }
    const url = `${config.apiUrl}/usermain?${args}`;
    return axios.get(url, configGet);
  }

  static async get(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    return axios.get(`${config.apiUrl}/usermain/${id}`, configGet);
  }

  static async create(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/usermain/add`, data, configPost);
  }

  static async update(id, data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/usermain/update/${id}`,
      data,
      configPost
    );
  }

  static async delete(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/usermain/delete/${id}`, configPost);
  }
}

export class ChildrenModel {
  // note: this app, children no extends Person

  static async gets(
    classroom_id = "",
    page = 1,
    search_name = "",
    from_date = "",
    to_date = ""
  ) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);
    let args = `page=${page}`;

    if (classroom_id != undefined && classroom_id != "") {
      args = `${args}&classroom_id=${classroom_id}`;
    }
    if (search_name != undefined && search_name != "") {
      args = `${args}&search_name=${search_name}`;
    }
    if (from_date != undefined && from_date != "") {
      args = `${args}&from_date=${from_date}`;
    }
    if (to_date != undefined && to_date != "") {
      args = `${args}&to_date=${to_date}`;
    }
    const url = `${config.apiUrl}/children?${args}`;
    return axios.get(url, configGet);
  }

  static async gets_of_parent() {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);
    const url = `${config.apiUrl}/children/of_parent`;
    return axios.get(url, configGet);
  }

  static async get(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    return axios.get(`${config.apiUrl}/children/${id}`, configGet);
  }

  static async create(data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/children/add`, data, configPost);
  }

  static async update(id, data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/children/update/${id}`,
      data,
      configPost
    );
  }

  static async delete(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(`${config.apiUrl}/children/delete/${id}`, configPost);
  }

  static async delete_arr(classroom_id = "", data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    let args = ``;
    if (classroom_id != undefined && classroom_id != "") {
      args = `classroom_id=${classroom_id}`;
    }

    return axios.post(
      `${config.apiUrl}/children/delete_arr?${args}`,
      data,
      configPost
    );
  }
}

export class ChildrenLessonModel {
  static async gets_doing_childrens(
    classroom_id = "",
    page = 1,
    search_name = "",
    from_date = "",
    to_date = "",
    limit = 18
  ) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);
    let args = `page=${page}`;

    if (classroom_id != undefined && classroom_id != "") {
      args = `${args}&classroom_id=${classroom_id}`;
    }

    if (limit != undefined && limit > 0) {
      args = `${args}&limit=${limit}`;
    }

    if (search_name != undefined && search_name != "") {
      args = `${args}&search_name=${search_name}`;
    }
    if (from_date != undefined && from_date != "") {
      args = `${args}&from_date=${from_date}`;
    }
    if (to_date != null) {
      args = `${args}&to_date=${to_date}`;
    }
    const url = `${config.apiUrl}/childrenlesson/childrensdoing?${args}`;
    return axios.get(url, configGet);
  }

  static async gets_doing_the_children(
    children_id = "",
    page = 1,
    search_name = "",
    from_date = "",
    to_date = "",
    limit = 18
  ) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);
    let args = `page=${page}`;

    if (children_id != undefined && children_id != "") {
      args = `${args}&children_id=${children_id}`;
    }

    if (limit != undefined && limit > 0) {
      args = `${args}&limit=${limit}`;
    }

    if (search_name != undefined && search_name != "") {
      args = `${args}&search_name=${search_name}`;
    }
    if (from_date != undefined && from_date != "") {
      args = `${args}&from_date=${from_date}`;
    }
    if (to_date != null) {
      args = `${args}&to_date=${to_date}`;
    }
    const url = `${config.apiUrl}/childrenlesson/the_childrendoing?${args}`;
    return axios.get(url, configGet);
  }

  static async gets(
    classroom_id = "",
    page = 1,
    search_name = "",
    from_date = "",
    to_date = ""
  ) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);
    let args = `page=${page}`;

    if (classroom_id != undefined && classroom_id != "") {
      args = `${args}&classroom_id=${classroom_id}`;
    }

    if (search_name != undefined && search_name != "") {
      args = `${args}&search_name=${search_name}`;
    }
    if (from_date != undefined && from_date != "") {
      args = `${args}&from_date=${from_date}`;
    }
    if (to_date != null) {
      args = `${args}&to_date=${to_date}`;
    }
    const url = `${config.apiUrl}/childrenlesson?${args}`;
    return axios.get(url, configGet);
  }

  static async get(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    return axios.get(`${config.apiUrl}/childrenlesson/${id}`, configGet);
  }

  static async create(classroom_id = "", data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);
    const url = `${config.apiUrl}/childrenlesson/add?classroom_id=${classroom_id}`;

    return axios.post(url, data, configPost);
  }

  static async update(id, data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/childrenlesson/update/${id}`,
      data,
      configPost
    );
  }

  static async approval_infor(id, data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/childrenlesson/approval_infor/${id}`,
      data,
      configPost
    );
  }

  static async delete(id, classroom_id = "") {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);
    const url = `${config.apiUrl}/childrenlesson/delete/${id}?classroom_id=${classroom_id}`;

    return axios.post(url, configPost);
  }

  static async delete_arr(classroom_id = "", data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);
    const url = `${config.apiUrl}/childrenlesson/delete_arr?classroom_id=${classroom_id}`;

    return axios.post(url, data, configPost);
  }
}

export class SettingModel {
  static async get(id) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configGet = setConfigGet(jwt_token);

    return axios.get(`${config.apiUrl}/setting/${id}`, configGet);
  }

  static async update(id, data = {}) {
    const jwt_token = await AsyncStorage.getItem("jwt_token");
    const configPost = setConfigPost(jwt_token);

    return axios.post(
      `${config.apiUrl}/setting/update/${id}`,
      data,
      configPost
    );
  }
}
