import React, { useState, useEffect, useRef } from "react";
import { View, StyleSheet, ScrollView, Platform } from 'react-native';
import * as Notifications from 'expo-notifications';

import { Header } from '../../components/header';
import { ButtonCustomerFunc } from "../../components/mybuttons";
import { CUSTOMER_ID_THEMSELF, LANG } from "../../config/config";
import { mycolors } from "../../config/mycolors";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { PushnotificationModel, registerForPushNotificationsAsync } from "../../models/models";

import { AuthContext } from "../../context/mycontext";


if (Platform.OS != 'web') {
    Notifications.setNotificationHandler({
        handleNotification: async () => ({
            shouldShowAlert: true,
            shouldPlaySound: true,
            shouldSetBadge: true,
        }),
    });
}

function HomeCustomer({ navigation }) {
    const customer_id = CUSTOMER_ID_THEMSELF;

    let mAuthContext = React.useContext(AuthContext);

    const [expoPushToken, setExpoPushToken] = useState('');
    const [notification, setNotification]: any = useState(false);
    const notificationListener: any = useRef();
    const responseListener: any = useRef();

    if (Platform.OS != 'web') {
        useEffect(() => {
            registerForPushNotificationsAsync().then(token => {
                setExpoPushToken(token);
                if (__DEV__) {
                    console.log('token:', token);
                }
                PushnotificationModel.save({ 'push_token': token }).
                    then((result) => {
                        if (__DEV__) {
                            console.log('data save token:', result.data);
                        }
                    });
            });

            // This listener is fired whenever a notification is received while the app is foregrounded
            notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
                setNotification(notification);
            });

            // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
            responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
                console.log(response);
            });

            return () => {
                Notifications.removeNotificationSubscription(notificationListener.current);
                Notifications.removeNotificationSubscription(responseListener.current);
            };
        }, []);
    }

    return (
        <View style={styles.container}>
            <Header title={`${LANG.HomePage}`} view="chat" navigation={navigation} />
            <ScrollView
                contentContainerStyle={{
                    flexGrow: 1,
                    marginTop: 6,
                }}
                keyboardShouldPersistTaps="handled"
            >
                {mAuthContext.isActivated && <View style={styles.box}>
                    <ButtonCustomerFunc
                        icon="heart"
                        color_icon="#f7e608"
                        title={LANG.HealthCheck}
                        onPress={() => {
                            navigation.navigate('SaleServiceCustomer', {
                                _customer_id: customer_id,
                                _customer_name: '',
                            });
                        }}
                    />
                </View>}
                
                <View style={styles.box}>
                    <ButtonCustomerFunc
                        icon="calendar"
                        title={LANG.CalendarAppointment}
                        onPress={() => {
                            navigation.navigate('BookingCustomer', {
                                _customer_id: customer_id,
                                _customer_name: '',
                            });
                        }}
                    />
                </View>
                
                {mAuthContext.isActivated && <View style={styles.box}>
                    <ButtonCustomerFunc
                        icon="shopping-cart"
                        title={LANG.BuyMedicine}
                        onPress={() => {
                            navigation.navigate('SaleOrderCustomer', {
                                _customer_id: customer_id,
                                _customer_name: '',
                            });
                        }}
                    />
                </View>}

                {mAuthContext.isActivated && <View style={styles.box}>
                    <ButtonCustomerFunc
                        icon="money"
                        title={LANG.Deposit}
                        onPress={() => {
                            navigation.navigate('PaymentCustomer', {
                                _customer_id: customer_id,
                                _customer_name: '',
                            });
                        }}
                    />
                </View>}

                <View style={styles.box}>
                    <ButtonCustomerFunc
                        icon="comments"
                        title={LANG.Chat}
                        onPress={() => {
                            const asyncParamsForChat = async () => {
                                try {
                                    await Promise.all([AsyncStorage.getItem('user_id'), AsyncStorage.getItem('display_name')]).then((values) => {
                                        const _user_id = values[0];
                                        const _user_name = values[1];
                                        if (_user_id !== null) {
                                            navigation.navigate('ChatCustomer', {
                                                _user_id, _user_name,
                                            });
                                        }
                                    });
                                } catch (error) {
                                    if (__DEV__) {
                                        console.log('home.customer: error get Storage: ', error);
                                    }
                                }
                            };
                            asyncParamsForChat();
                        }}
                    />
                </View>
                <View style={styles.box}>
                    <ButtonCustomerFunc
                        icon="users"
                        title={LANG.HomeFamily}
                        onPress={() => {
                            navigation.navigate('FamilyCustomerCustomer', {
                                _customer_id: customer_id,
                                _customer_name: '',
                            });
                        }}
                    />
                </View>
                <View style={styles.box}>
                    <ButtonCustomerFunc
                        icon="newspaper-o"
                        title={LANG.News}
                        onPress={() => {
                            navigation.navigate('ShowNews');
                        }}
                    />
                </View>

                {mAuthContext.isActivated && <View style={styles.box}>
                    <ButtonCustomerFunc
                        icon="bar-chart"
                        title={LANG.Report}
                        onPress={() => {
                            navigation.navigate('ReportCustomer');
                        }}
                    />
                </View>}
            </ScrollView>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#E8EAED',
    },
    box: {
        backgroundColor: mycolors.primary,
        alignContent: "center",
        marginTop: 6,
        marginBottom: 6,
        marginLeft: 12,
        marginRight: 12,
        padding: 10
    },
    text: {
        color: '#fff'
    },
    boxTop: {
        padding: 12,
        alignItems: "center",
    },
    textTop: {
        color: "#000",
    }
});

export default HomeCustomer;
