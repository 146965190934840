import React, { useState } from "react";
import { StyleSheet, Text, View, ScrollView, Platform, Pressable } from 'react-native';
import { CheckBox, Dialog, Icon, Overlay } from 'react-native-elements';

import { Header } from '../../components/header';

import { ButtonAdd, ButtonCancel, ButtonDelete, ButtonEdit, ButtonSave, ButtonSearch, CloseButtons, OpenButtons } from '../../components/mybuttons'
import { stylesButtons, stylesCategory, stylesColumns } from "../../config/mystyles";
import { checkSingleItem, getsMyItemChecked } from "../../config/myfunc";
import { MyPaging, SelectOneOptionModal, MyListingService } from "../../components/myboxs";
import { IsLoadingData } from "../../components/myother";

import { MyContext } from '../../context/mycontext';
import { arr_pattern, MyControlInputText } from "../../components/mycontrolinputs";
import { LANG, windowHeight, windowWidth } from "../../config/config";

import { useForm } from "react-hook-form";

import { ServiceModel, ServiceCategoryModel } from "../../models/models"
import { isEmpty } from "lodash";
import { MyInputSearchName } from "../../components/myinputs";
const dialogTitle = LANG.Service;


function Service({ route, navigation }) {
    const [isLoading, setLoading] = useState<boolean>(false);

    const [taskItems, setTaskItems] = useState([]);

    const [pagingParams, setPagingParams] = useState({});
    const [currentPage, setCurrentPage] = React.useState(1);
    const [listCategory, setListCategory] = useState([]);

    const [searchName, setSearchName] = React.useState('');
    const [fromDate, setFromDate] = useState('');

    const [openList, setOpenList] = React.useState(true);
    const [openForm, setOpenForm] = React.useState(false);
    const [openButton, setOpenButton] = React.useState(true);

    const [visible, setVisible] = React.useState(true);
    const [visibleDialog, setVisibleDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');

    const [permission, setPermission] = React.useState(false);

    // fields of table
    const [id, setID] = useState('');
    // same as birthday

    function checkItem(item: any) {
        const arr = checkSingleItem(taskItems, item);
        setTaskItems(arr);

        setOpenButton(true);
        setVisible(true);
    }

    function deleteItems() {
        let arr: any = getsMyItemChecked(taskItems);

        if (arr.length == 0) {
            setVisibleDialog(true);
            setDialogMessage(LANG.ChooseAtLeastOneToDelete);
            return;
        }

        ServiceModel.delete_arr({ 'ids': arr })
            .then((results) => {
                const data = results.data || {};
                const posts_reload = data.posts_reload || {};
                if (posts_reload.posts != null) {
                    setTaskItems(posts_reload.posts);
                } else if (data.posts != undefined) {
                    setTaskItems(data.posts);
                }

                setVisibleDialog(true);
                setDialogMessage(`${data.message}`);
            })
            .catch(err => {
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            });
    }

    function editItem() {
        let arr: any = getsMyItemChecked(taskItems);
        const lastItem = arr.pop();

        if (lastItem == undefined) {
            setVisibleDialog(true);
            setDialogMessage(LANG.ChooseAtLeastOne);
        } else {
            ServiceModel.get(lastItem)
                .then((results) => {
                    const post: any = results.data.post || {};

                    setID(post.id);
                    fields.forEach(field => {
                        switch (field.Type) {
                            case 'category':
                                const category_id = post[`${field.name}`] || '';
                                const currentCat = listCategory.filter(item => category_id == item.id);
                                if (!isEmpty(currentCat)) {
                                    setCurrentCategory(currentCat[0]);
                                }
                                break;
                            case 'calendar':
                            default:
                                setValue(`${field.name}`, post[`${field.name}`]);
                                break;
                        }
                    });

                    setOpenForm(true);
                    setOpenList(false);
                    setOpenButton(false);
                })
                .catch(err => {
                    setVisibleDialog(true);
                    setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
                });
        }
    }

    function Paging(page = 1, category_id = currentCategory.id) {
        setLoading(true);
        ServiceModel.gets(page, '', '', category_id)
            .then((results) => {
                const data: any = results["data"] || [];
                setTaskItems(data["posts"] || []);
                setPagingParams(data['paging'])
            })
            .catch(err => {
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false));
    }

    function Search(search_name = '', from_date = '') {
        setLoading(true);
        ServiceModel.gets(1, search_name, from_date, currentCategory.id)
            .then((results) => {
                const data: any = results["data"] || [];

                if (__DEV__) {
                    console.log('data search:', data);
                }

                setTaskItems(data["posts"] || []);
                setPagingParams(data['paging'])
                setCurrentPage(1);
            })
            .catch(err => {
                console.log(err);
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false));
    };

    async function gets_init() {
        const p_posts = ServiceModel.gets(1, '');
        const p_category = ServiceCategoryModel.gets(1, '', 99);

        setLoading(true);
        Promise.all([p_posts, p_category]).then((values) => {
            const data_posts = values[0]["data"] || [];

            const data_category = values[1]["data"] || [];

            if (__DEV__) {
                console.log('data_category:', data_category);
                console.log('data_posts:', data_posts);
            }

            setPermission(data_posts["permission"] || false);

            setTaskItems(data_posts["posts"] || []);
            setPagingParams(data_posts['paging']);

            setListCategory(data_category["posts"] || []);
        })
            .catch(err => {
                console.log(`${err.message}`);

                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false))
    }


    /***
     * begin: react hook form
    */
    const { control, handleSubmit, reset, formState: { errors }, getValues, setValue } = useForm();

    const onSubmit = data => {
        const data_submit = data;

        if (id != '') {
            ServiceModel.update(id, data_submit)
                .then((results) => {
                    const data: any = results.data || {};

                    if (__DEV__) {
                        console.log('Data update: ', data);
                    }

                    if (data.post != undefined && data.post.id != undefined) {
                        const obj_task: any = {
                            'id': data.post.id,
                            'title': data.post.title,
                            'code': data.post.code,
                            'price': data.post.price,
                            'checked': false
                        };
                        let arr: any = [];
                        taskItems.forEach(el => {
                            let el_tg: any = el;
                            if (el_tg['id'] == obj_task.id) {
                                arr.push(obj_task);
                            } else {
                                arr.push(el);
                            }
                        });
                        setTaskItems(arr);

                        setVisibleDialog(true);
                        setDialogMessage(`${data.message}`);
                    } else {
                        setVisibleDialog(true);
                        setDialogMessage(`${data.message}`);
                    }

                    setOpenForm(false);
                    setOpenList(true);
                    setOpenButton(true);
                    setVisibleDialog(true);

                    reset();
                })
                .catch(err => {
                    setVisibleDialog(true);
                    setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
                })
                .finally(() => {
                    setID('');
                    setLoading(false);
                });
        } else {
            ServiceModel.create(data_submit)
                .then((results) => {
                    const data: any = results.data || {};
                    if (data.post != undefined && data.post.id != undefined) {
                        const obj_task: any = {
                            'id': data.post.id,
                            'title': data.post.title,
                            'code': data.post.code,
                            'price': data.post.price,
                        };
                        setTaskItems([obj_task, ...taskItems]);

                        setVisibleDialog(true);
                        setDialogMessage(`${data.message}`);
                    } else {
                        setVisibleDialog(true);
                        setDialogMessage(`${data.message}`);
                    }

                    setOpenForm(false);
                    setOpenList(true);
                    setOpenButton(true);
                    setVisibleDialog(true);

                    reset();
                })
                .catch(err => {
                    setVisibleDialog(true);
                    setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
                    setLoading(false);
                })
                .finally(() => {
                    setID('');
                    setLoading(false);
                });
        }
    };

    const myHandleSubmit = () => {
        // convert before submit
        fields.forEach(field => {
            switch (field.Type) {
                case 'category':
                    setValue(`${field.name}`, currentCategory.id);
                    break;
                case 'calendar':
                case 'image':
                default:
                    break;
            }
        });

        handleSubmit(onSubmit)();
    }

    const api_fields = [
        {
            name: "title",
            lable: "Tên dịch vụ",
            placeholder: "Hãy nhập tên dịch vụ",
            rules: {
                required: true,
                pattern: false,
            },
            icon: {
                name: "info-circle",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
        },
        {
            name: "category_id",
            lable: "Loại hình dịch vụ",
            placeholder: "Dịch vụ thuộc loại hình nào",
            rules: {
                pattern: false,
            },
            icon: {
                name: "info-circle",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
            Type: "category"
        },
        {
            name: "price",
            lable: "Giá",
            placeholder: "Giá dịch vụ",
            rules: {
                required: false,
                pattern: false,
            },
            icon: {
                name: "info-circle",
                size: 25
            },
            keyboardType: "numeric",
            isCurrency: true,
            isPassword: false,
        },
        {
            name: "content",
            lable: "Ghi chú",
            placeholder: "Hãy nhập ghi chú",
            rules: {
                pattern: false,
            },
            icon: {
                name: "info-circle",
                size: 25
            },
            keyboardType: "default",
            isPassword: false,
            multiline: true
        },
    ];

    const arr_validate = {
        validate_password: {
            matchesPreviousPassword: (value) => {
                const { password } = getValues();
                return password === value || LANG.PasswordRequiredValidate;
            }
        }
    };

    let column = {};
    let row = {};
    if (windowWidth <= 768) {
        column = stylesColumns.columnSmall;
        row = stylesColumns.rowSmall;
    } else if (windowWidth >= 769) {
        // column = stylesColumns.columnMedium;
        // row = stylesColumns.rowMedium;
    }

    const [fields, setFields] = React.useState([]);

    const [visibleCategory, setVisibleCategory] = React.useState(false);

    /***
      * end: react hook form
    */

    const [currentCategory, setCurrentCategory] = React.useState({ id: '', title: '' });
    const selectCategory = (_category) => {
        setCurrentCategory(_category);
    }

    React.useEffect(() => {
        setFields(api_fields);

        const unsubscribe = navigation.addListener('focus', () => {
            // if screen is focused then reload
            console.log('Screen is focused member ' + Math.random());
            gets_init();

            setOpenForm(false);
            setOpenButton(true);
            setOpenList(true);
        });

        return () => {
            // cleanup
        };
    }, []);


    return (
        <View style={styles.container}>
            <Header title={dialogTitle} view="chat" navigation={navigation} />

            <Dialog
                isVisible={visibleDialog}
                onBackdropPress={() => setVisibleDialog(!visibleDialog)}
            >
                <Dialog.Title title={dialogTitle} />
                <Text>{dialogMessage}</Text>
            </Dialog>

            {visibleCategory && !isEmpty(listCategory) && (
                <Overlay
                    fullScreen={Platform.OS == 'web' ? false : true}
                    isVisible={visibleCategory}
                    onBackdropPress={() => setVisibleCategory(!visibleCategory)}
                >
                    {currentCategory.id != '' && (
                        <View style={{ flexDirection: "row" }}>
                            <Text style={{ margin: 12, marginBottom: 6, fontWeight: "bold" }}>Bỏ chọn chuyên mục(bấm)</Text>
                            <Icon
                                name="remove"
                                type="font-awesome"
                                color="#f00"
                                size={22}
                                iconStyle={{ marginTop: 10 }}
                                onPress={() => {
                                    setVisibleCategory(!visibleCategory);
                                    setCurrentCategory({ id: '', title: '' });
                                }}
                            />
                        </View>
                    )}
                    <Text style={{ margin: 12, marginBottom: 6, fontWeight: "bold" }}>Chọn chuyên mục:</Text>
                    <ScrollView style={Platform.OS == 'web' && { maxHeight: windowHeight - 240 }}>
                        <View style={Platform.OS == 'web' && { width: 350 }}>
                            <View style={{ ...stylesCategory.row, width: "auto" }}>
                                {listCategory.map((item, index) =>
                                    <CheckBox
                                        key={item.id}
                                        title={item.title}
                                        checked={currentCategory.id == item.id ? true : false}
                                        checkedIcon='dot-circle-o'
                                        uncheckedIcon='circle-o'
                                        onPress={() => {
                                            setVisibleCategory(!visibleCategory);
                                            setCurrentCategory(item);
                                        }}
                                    />
                                )}
                            </View>
                        </View>
                    </ScrollView>
                    <View style={{ marginTop: 12 }}>
                        <ButtonCancel onPress={() => setVisibleCategory(!visibleCategory)} />
                    </View>
                </Overlay>
            )}

            <ScrollView
                contentContainerStyle={{
                    flexGrow: 1,
                }}
                keyboardShouldPersistTaps="handled"
            >
                {openForm && (
                    <View style={styles.box}>
                        <View style={row}>
                            {fields.map((field, key) => {
                                switch (field.Type) {
                                    case "category":
                                        return (
                                            <View key={key} style={column}>
                                                <Text style={styles.text}> {field.lable}:</Text>
                                                <Pressable
                                                    style={{ flexDirection: "row", alignSelf: "flex-start", marginTop: 30 }}
                                                    onPress={() => { setVisibleCategory(true) }}>
                                                    <Icon
                                                        name="info-circle"
                                                        type="font-awesome"
                                                        color="#86939e"
                                                        size={25}
                                                        style={{ marginLeft: 12 }}
                                                    />
                                                    <Text style={{ minWidth: 30, marginLeft: 20, marginRight: 6 }}>
                                                        {currentCategory.title != "" ? currentCategory.title : "Chọn chuyên mục"}
                                                    </Text>
                                                    <Icon
                                                        name="caret-down"
                                                        type="font-awesome"
                                                        color="#86939e"
                                                        size={22}
                                                        iconStyle={{}}
                                                    />
                                                </Pressable>
                                            </View>
                                        );
                                        break;
                                    default:
                                        return (
                                            <View key={key} style={column}>
                                                <Text style={styles.text}> {field.lable}:</Text>
                                                <MyControlInputText
                                                    action={id != '' ? 'edit' : 'add'}
                                                    field={field}
                                                    control={control}
                                                    errors={errors}
                                                    pattern={field.rules.pattern != undefined ? arr_pattern[field.rules.pattern] : ''}
                                                    validate={field.rules.validate != undefined ? arr_validate[field.rules.validate] : ''}
                                                />
                                                {field.confirmField != null && (
                                                    <>
                                                        <Text style={styles.text}>{field.confirmField.lable}:</Text>
                                                        <MyControlInputText
                                                            action={id != '' ? 'edit' : 'add'}
                                                            field={field.confirmField}
                                                            control={control}
                                                            errors={errors}
                                                            pattern={field.confirmField.rules.pattern != undefined ? arr_pattern[field.confirmField.rules.pattern] : ''}
                                                            validate={field.confirmField.rules.validate != undefined ? arr_validate[field.confirmField.rules.validate] : ''}
                                                        />
                                                    </>
                                                )}
                                            </View>
                                        );
                                        break;
                                }
                            })}
                        </View>

                        <View style={{ flexDirection: "row", alignSelf: "center", marginTop: 30 }}>
                            {isLoading == true ? (
                                <IsLoadingData title={LANG.Updating} />
                            ) : (
                                <>
                                    <ButtonCancel onPress={() => {
                                        setID('');
                                        setOpenForm(false);
                                        setOpenButton(true);
                                        setOpenList(true);
                                    }}
                                    />
                                    <ButtonSave onPress={() => myHandleSubmit()} />
                                </>
                            )}
                        </View>
                    </View>
                )}

                {openList && (
                    <>
                        <View style={{ flexDirection: "row", alignSelf: "center", marginTop: 12 }}>
                            <MyContext.Provider value={{
                                listOptions: [
                                    { 'id': '', "title": "Tất cả" },
                                    ...listCategory,
                                ],
                                currentType: currentCategory,
                                selectOption: selectCategory,
                            }}>
                                <SelectOneOptionModal />
                            </MyContext.Provider>

                            <MyContext.Provider value={{
                                Search,
                                searchName,
                                setSearchName,
                            }}>
                                <MyInputSearchName />
                            </MyContext.Provider>

                            <ButtonSearch onPress={() => Search(searchName, fromDate)} />
                        </View>

                        {isLoading ? (
                            <IsLoadingData />
                        ) : (
                            <MyContext.Provider value={
                                {
                                    taskItems,
                                    checkItem,

                                    pagingParams,
                                    Paging,

                                    currentPage,
                                    setCurrentPage,
                                }}>
                                <MyListingService />
                                <MyPaging />
                            </MyContext.Provider>
                        )}

                    </>
                )}

            </ScrollView>

            {permission == true && <>
                {openButton && (
                    <View style={{ flexDirection: "row", alignSelf: "center", "position": "absolute", bottom: 5 }}>
                        <ButtonAdd onPress={() => {
                            // begin reset all
                            reset();
                            let listClass = listCategory.map(item => { item.checked = false; return item; });
                            setListCategory(listClass);
                            setCurrentCategory({ id: '', title: '' });
                            // end reset all

                            setOpenForm(true); setOpenButton(false); setOpenList(false);
                        }}
                        />
                        <ButtonEdit onPress={() => editItem()} />
                        <ButtonDelete onPress={() => deleteItems()} />
                    </View>
                )}

                <View style={stylesButtons.open_close}>
                    {visible == true ? (
                        <OpenButtons onPress={() => { setOpenButton(false); setVisible(false); }} />
                    ) : (
                        <CloseButtons onPress={() => { setOpenButton(true); setVisible(true); }} />
                    )}
                </View>
            </>}

        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#E8EAED',
    },
    box: {
        flex: 1,
        margin: 12,
        marginTop: 6,
        marginBottom: 6,
        padding: 20
    },
    text: {
        marginTop: 18,
        marginBottom: Platform.OS == 'web' ? -12 : -24,
        color: '#000',
        alignSelf: "flex-start",
        paddingLeft: 12
    }
});

export default Service;
