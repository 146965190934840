import React, { useState } from "react";
import { StyleSheet, Text, View, ScrollView } from 'react-native';
import { Dialog } from 'react-native-elements';

import { Header } from '../../components/header';

import { ButtonAdd, ButtonSearch } from '../../components/mybuttons'
import { checkItemByIds, checkMyItem } from "../../config/myfunc";
import { MyPaging, SelectOneOptionModal, MyListProductToStore } from "../../components/myboxs";
import { IsLoadingData } from "../../components/myother";

import { MyContext } from '../../context/mycontext';

import { ProductModel, ProductCategoryModel } from "../../models/models"
import { MyInputSearchName } from "../../components/myinputs";
import { LANG } from "../../config/config";
const dialogTitle = 'Chọn Sản Phẩm';


function SaleOrderItem({ route, navigation }) {
    const [isLoading, setLoading] = useState<boolean>(false);

    const [taskItems, setTaskItems] = useState([]);

    const [pagingParams, setPagingParams] = useState({});
    const [currentPage, setCurrentPage] = React.useState(1);
    const [listCategory, setListCategory] = useState([]);

    const [searchName, setSearchName] = React.useState('');

    const [openList, setOpenList] = React.useState(true);

    const [visible, setVisible] = React.useState(true);
    const [visibleDialog, setVisibleDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');

    const [allItemsChecked, setAllItemsChecked] = React.useState([]);

    function checkItem(item: any) {
        // for curent list task
        const arr = checkMyItem(taskItems, item);
        setTaskItems(arr);

        // for global without paging, search...
        let all_checked = [];
        if (item['checked']) {
            all_checked = _.union(allItemsChecked, [
                {
                    id: item.id,
                    title: item.title,
                    price_sell: item.price_sell,
                    value: 1,
                },
            ]);
        } else {
            all_checked = _.remove(allItemsChecked, function (n) {
                return n.id != item.id;
            });
        }
        setAllItemsChecked(all_checked);
        console.log('all_checked: ', all_checked);

        setVisible(true);
    }

    function Paging(page = 1, category_id = currentCategory.id) {
        setLoading(true);
        ProductModel.gets(page, '', '', category_id)
            .then((results) => {
                const data: any = results["data"] || [];

                let task_items = data["posts"] || [];
                let all_checked = allItemsChecked.map(item => item.id);
                task_items = checkItemByIds(task_items, all_checked);
                setTaskItems(task_items);

                setPagingParams(data['paging'])
            })
            .catch(err => {
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false));
    }

    function Search(search_name = '', from_date = '') {
        setLoading(true);
        ProductModel.gets(1, search_name, from_date, currentCategory.id)
            .then((results) => {
                const data: any = results["data"] || [];

                let task_items = data["posts"] || [];
                let all_checked = allItemsChecked.map(item => item.id);
                task_items = checkItemByIds(task_items, all_checked);
                setTaskItems(task_items);

                setPagingParams(data['paging'])
                setCurrentPage(1);
            })
            .catch(err => {
                console.log(err);
                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false));
    };

    async function gets_init() {
        const p_posts = ProductModel.gets(1, '');
        const p_category = ProductCategoryModel.gets(1, '', 99);

        setLoading(true);
        Promise.all([p_posts, p_category]).then((values) => {
            const data_posts = values[0]["data"] || [];

            const data_category = values[1]["data"] || [];

            if (__DEV__) {
                console.log('data_category:', data_category);
                console.log('data_posts:', data_posts);
            }

            setTaskItems(data_posts["posts"] || []);

            setPagingParams(data_posts['paging']);

            setListCategory(data_category["posts"] || []);

            setAllItemsChecked([]);
        })
            .catch(err => {
                console.log(`${err.message}`);

                setVisibleDialog(true);
                setDialogMessage(`${err.message} \n${LANG.CanNotConnect}`);
            })
            .finally(() => setLoading(false))
    }


    const [currentCategory, setCurrentCategory] = React.useState({ id: '', title: '' });
    const selectCategory = (_category) => {
        setCurrentCategory(_category);
    }

    const { _order_id } = route.params || '';

    React.useEffect(() => {
        console.log('route.params item: ', route.params);

        const unsubscribe = navigation.addListener('focus', () => {
            // if screen is focused then reload
            console.log('Screen is focused member ' + Math.random());
            gets_init();

            setOpenList(true);
        });

        return () => {
            // cleanup
            // Execute before the next effect or unmount
        };

    }, [_order_id]);


    return (
        <View style={styles.container}>
            <Header title={dialogTitle} view="chat" navigation={navigation} />

            <Dialog
                isVisible={visibleDialog}
                onBackdropPress={() => setVisibleDialog(!visibleDialog)}
            >
                <Dialog.Title title={dialogTitle} />
                <Text>{dialogMessage}</Text>
            </Dialog>

            <ScrollView
                contentContainerStyle={{
                    flexGrow: 1,
                }}
                keyboardShouldPersistTaps="handled"
            >
                {openList && (
                    <>
                        <View style={{ flexDirection: "row", alignSelf: "center", marginTop: 12 }}>
                            <MyContext.Provider value={{
                                listOptions: [
                                    { 'id': '', "title": "Tất cả" },
                                    ...listCategory,
                                ],
                                currentType: currentCategory,
                                selectOption: selectCategory,
                            }}>
                                <SelectOneOptionModal />
                            </MyContext.Provider>

                            <MyContext.Provider value={{
                                Search,
                                searchName,
                                setSearchName,
                            }}>
                                <MyInputSearchName />
                            </MyContext.Provider>

                            <ButtonSearch onPress={() => Search(searchName, '')} />
                        </View>

                        {isLoading ? (
                            <IsLoadingData />
                        ) : (
                            <MyContext.Provider value={
                                {
                                    taskItems,
                                    checkItem,

                                    pagingParams,
                                    Paging,

                                    currentPage,
                                    setCurrentPage,
                                }}>
                                <MyListProductToStore />

                                <View style={{ flexDirection: "row", alignSelf: "center", marginTop: 12, marginBottom: 20 }}>
                                    <View style={{ marginTop: 28, marginRight: 12 }}>
                                        <ButtonAdd
                                            title={`${allItemsChecked.length} vào đơn hàng`}
                                            onPress={() => {
                                                navigation.navigate('SaleOrder', {
                                                    _order_id: route.params?._order_id,
                                                    _items_checked: allItemsChecked
                                                });
                                            }}
                                        />
                                    </View>
                                    <View>
                                        <MyPaging />
                                    </View>
                                </View>

                            </MyContext.Provider>
                        )}

                    </>
                )}

            </ScrollView >

        </View >
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#E8EAED',
    }
});

export default SaleOrderItem;
