import { Platform, StyleSheet } from 'react-native';
import { mycolors } from './mycolors';

export const stylesICustomer = StyleSheet.create({
    item: {
        backgroundColor: '#FFF',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 5,
        borderRadius: 5,
        marginBottom: 12,
    },
    itemLeft: {
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    checkbox: {
        padding: 0,
        margin: 0,
    }
});

export const stylesI22Customer = StyleSheet.create({
    item: {
        backgroundColor: '#FFF',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 5,
        borderRadius: 5,
        marginBottom: 12,
    },
    itemLeft: {
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    itemText: {
        width: '62%',
        alignSelf: "flex-start"
    },
    iconStyle: {
        marginLeft: 4,
        marginRight: 6,
    },
    itemRight: {
        marginLeft: 32
    },
    itemRightText: {
        lineHeight: 22
    },
});

export const stylesItem = StyleSheet.create({
  item: {
    backgroundColor: "#FFF",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 5,
    borderRadius: 5,
    marginBottom: 12,
  },
  itemLeft: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  itemText: {
    width: "62%",
    alignSelf: "flex-start",
  },
  checkbox: {
    padding: 0,
    margin: 0,
  },
});


export const stylesInput = StyleSheet.create({
    containerStyle: {
        borderBottomColor: 'rgba(0, 0, 0, 0.38)',
    },
    inputStyle: {
        marginLeft: 10
        , color: 'grey'
    },
    icon: {
        color: 'rgba(0, 0, 0, 0.38)'
    },
});

export const stylesButtons = StyleSheet.create({
  open_close: {
    flexDirection: "row",
    alignSelf: "flex-end",
    position: "absolute",
    bottom: 42,
  },
  buttonStyle: {
    backgroundColor: mycolors.primary,
    borderRadius: 3,
    height: 45,
    width: 180,
  },
  titleStyle: {
    fontSize: 16,
    color: "white",
    fontWeight: "300",
  },
  loginButton: {
    backgroundColor: mycolors.primary,
    borderRadius: 5,
    height: 50,
    lineHeight: Platform.OS === "ios" ? 25 : 40,
    paddingLeft: 12,
    paddingRight: 12,
    marginTop:10,
    marginRight: 4,
    marginLeft: 4,
    color: "white",
    fontSize: 13,
  },
});


const itemCalendar = {
    marginLeft: 5,
    marginRight: 5,
    color: '#F6E7C1',
    padding: 10,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 25,
}
export const stylesCalendar = StyleSheet.create({
    item: {
        ...itemCalendar,
        backgroundColor: mycolors.primary,
    },
    itemActive: {
        ...itemCalendar,
        backgroundColor: 'rgba(244, 114, 43, 1)'
    },
    label: {
        color: mycolors.gray,
        fontSize: 15,
        marginLeft: 10
    },
});

export const stylesTextNote = StyleSheet.create({
    label: {
        fontSize: 12,
        fontStyle: "italic"
    },
});

export const stylesClassRoom = StyleSheet.create({
    row: {
        flexDirection: "column",
        margin: 12,
        marginBottom: 0
    },
    item: {
        marginRight: 10,
        padding: 6,
        backgroundColor: '#ccc',
        color: '#000'
    },
    itemActive: {
        marginRight: 10,
        padding: 6,
        backgroundColor: mycolors.primary,
        color: '#fff'
    }
});

export const stylesCategory = StyleSheet.create({
    row: {
        flexDirection: "column",
        margin: 12,
        marginBottom: 0
    },
    item: {
        marginRight: 10,
        padding: 6,
        backgroundColor: '#ccc',
        color: '#000'
    },
    itemActive: {
        marginRight: 10,
        padding: 6,
        backgroundColor: mycolors.primary,
        color: '#fff'
    }
});

// for table
const styColumn = {
    padding: 6,
    paddingLeft: 0,
    borderBottomColor: "#ccc",
    borderBottomWidth: 1,
}
const styleHeader = {
    marginLeft: 12,
    marginRight: 12,
}
export const stylesTable = StyleSheet.create({
    header: {
        ...styleHeader,
        flexDirection: "row",
    },
    headerEvent: {
        ...styleHeader,
        flexDirection: "row",
        backgroundColor: "#fff"
    },
    headerOld: {
        ...styleHeader,
        flexDirection: "row",
        backgroundColor: "#D5D8DC"
    },

    subRow: {
        flexDirection: "row"
    },
    subRow2: {
        flexDirection: "row"
    },

    headerColumn1: {
        ...styColumn,
        width: 35,
        fontWeight: "bold",
    },
    column1: {
        ...styColumn,
        width: 35,
        textAlign: "center",
    },

    headerColumn2: {
        ...styColumn,
        width: 120,
        fontWeight: "bold",
    },
    column2: {
        ...styColumn,
        width: 120,
    },

    headerColumn3: {
        ...styColumn,
        width: 300,
        fontWeight: "bold",
    },
    column3: {
        ...styColumn,
        width: 300,
    },


    headerColumn4: {
        ...styColumn,
        minWidth: 110,
        fontWeight: "bold",
    },
    column4: {
        ...styColumn,
        minWidth: 110,
    }
});

export const stylesColumns = StyleSheet.create({
    rowSmall: {
        flexDirection: "column",
    },
    columnSmall: {
        width: "98%",
    },

    rowMedium: {
        flexDirection: "row",
    },
    columnMedium: {
        width: "33%",
    },
});

// for table doing

const styColumnDoing = {
    padding: 6,
    paddingLeft: 0,
    borderBottomColor: "#ccc",
    borderBottomWidth: 1,
}
const styleHeaderDoing = {
    marginLeft: 12,
    marginRight: 12,
}

export const stylesTheChildrenTableDoing = StyleSheet.create({
    header: {
        ...styleHeaderDoing,
        flexDirection: "row",
    },
    headerEvent: {
        ...styleHeaderDoing,
        flexDirection: "row",
        backgroundColor: "#fff"
    },
    headerOld: {
        ...styleHeaderDoing,
        flexDirection: "row",
        backgroundColor: "#D5D8DC"
    },

    headerColumn1: {
        ...styColumnDoing,
        width: 80,
        fontWeight: "bold",
    },
    column1: {
        ...styColumnDoing,
        width: 80,
        textAlign: "center",
    },

    headerColumn2: {
        ...styColumnDoing,
        width: 150,
        fontWeight: "bold",
    },
    column2: {
        ...styColumnDoing,
        width: 150,
    },

    headerColumn3: {
        ...styColumnDoing,
        width: 100,
        fontWeight: "bold",
    },
    column3: {
        ...styColumnDoing,
        width: 100,
    },
});


export const stylesChildrensTableDoing = StyleSheet.create({
    header: {
        ...styleHeaderDoing,
        flexDirection: "row",
    },
    headerEvent: {
        ...styleHeaderDoing,
        flexDirection: "row",
        backgroundColor: "#fff"
    },
    headerOld: {
        ...styleHeaderDoing,
        flexDirection: "row",
        backgroundColor: "#D5D8DC"
    },

    headerColumn1: {
        ...styColumnDoing,
        width: 45,
        fontWeight: "bold",
    },
    column1: {
        ...styColumnDoing,
        width: 45,
        textAlign: "center",
    },

    headerColumn2: {
        ...styColumnDoing,
        width: 100,
        fontWeight: "bold",
    },
    column2: {
        ...styColumnDoing,
        width: 100,
    },

    headerColumn3: {
        ...styColumnDoing,
        width: 200,
        fontWeight: "bold",
    },
    column3: {
        ...styColumnDoing,
        width: 200,
    },
});

export const stylesNews = StyleSheet.create({
  item: {
    backgroundColor: "#FFF",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 5,
    borderRadius: 5,
    marginBottom: 12,
  },
  itemLeft: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  itemText: {
    width: "80%",
    alignSelf: "flex-start",
  },
  checkbox: {
    padding: 0,
    margin: 0,
  },
  img: {
    width: 50,
    height: 50,
    borderRadius: 3,
    marginRight: 5,
  },
});