import React, { useEffect } from "react";
import { View, StyleSheet } from 'react-native';
import { Dialog } from 'react-native-elements';

import AsyncStorage from "@react-native-async-storage/async-storage";

import { Header } from '../../components/header';
import { LogoutDialog } from "../../components/myboxs";
import { ButtonPrimary } from "../../components/mybuttons";
import { windowWidth } from "../../config/config";

import { AuthContext } from "../../context/mycontext"

function Logout({ navigation }) {
    const authContext = React.useContext(AuthContext);

    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            // if screen is focused then reload
            AsyncStorage.setItem('jwt_token', '');
            AsyncStorage.setItem('user_role', '');
            AsyncStorage.setItem('display_name', '');
            AsyncStorage.setItem('user_id', '');
            AsyncStorage.setItem('is_activated', '');

            authContext.signOut();
        });

        return () => {
            // cleanup
            // Execute before the next effect or unmount
        };
    }, []);

    return (
        <View style={styles.container}>
            <Header title="Thành Viên" view="chat" navigation={navigation} />
            <View style={{ maxWidth: windowWidth, flex: 1, alignItems: "center", justifyContent: "center" }}>
                <View style={{ alignSelf: "center" }}>
                    <Dialog.Title title='Bạn Đã Đăng Xuất!' />
                    <ButtonPrimary title="Đăng nhập lại"
                        onPress={() => navigation.navigate('Login')}
                    />
                </View>
            </View>
            <LogoutDialog navigation={navigation} />
        </View >
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#E8EAED',
    },
});

export default Logout;
